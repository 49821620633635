//-------------------
//  Role actions
//-------------------
export const CHANGE_ACTIVE_ROLE_REQUEST = "CHANGE_ACTIVE_ROLE_REQUEST";
export const CHANGE_ACTIVE_ROLE_SUCCESS = "CHANGE_ACTIVE_ROLE_SUCCESS";
export const CHANGE_ACTIVE_ROLE_FAILURE = "CHANGE_ACTIVE_ROLE_FAILURE";

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const GET_ROLE_STATS_REQUEST = "GET_ROLE_STATS_REQUEST";
export const GET_ROLE_STATS_SUCCESS = "GET_ROLE_STATS_SUCCESS";
export const GET_ROLE_STATS_FAILURE = "GET_ROLE_STATS_FAILURE";

//-------------------
//  Get Role Detail
//-------------------
export const GET_ROLE_DETAIL_REQUEST = "GET_ROLE_DETAIL_REQUEST";
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS";
export const GET_ROLE_DETAIL_FAILURE = "GET_ROLE_DETAIL_FAILURE";

export const GET_MY_ROLE_DETAIL_REQUEST = "GET_MY_ROLE_DETAIL_REQUEST";
export const GET_MY_ROLE_DETAIL_SUCCESS = "GET_MY_ROLE_DETAIL_SUCCESS";
export const GET_MY_ROLE_DETAIL_FAILURE = "GET_MY_ROLE_DETAIL_FAILURE";

//-------------------
//  Role details actions
//-------------------
export const UPDATE_ROLE_DETAILS_REQUEST = "UPDATE_ROLE_DETAILS_REQUEST";
export const UPDATE_ROLE_DETAILS_SUCCESS = "UPDATE_ROLE_DETAILS_SUCCESS";
export const UPDATE_ROLE_DETAILS_FAILURE = "UPDATE_ROLE_DETAILS_FAILURE";

//-------------------
//  Role Image actions
//-------------------
export const UPLOAD_ROLE_PROFILE_PICTURE_REQUEST = "UPLOAD_ROLE_PROFILE_PICTURE_REQUEST";
export const UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS = "UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_ROLE_PROFILE_PICTURE_FAILURE = "UPLOAD_ROLE_PROFILE_PICTURE_FAILURE";

export const UPLOAD_ROLE_COVER_PICTURE_REQUEST = "UPLOAD_ROLE_COVER_PICTURE_REQUEST";
export const UPLOAD_ROLE_COVER_PICTURE_SUCCESS = "UPLOAD_ROLE_COVER_PICTURE_SUCCESS";
export const UPLOAD_ROLE_COVER_PICTURE_FAILURE = "UPLOAD_ROLE_COVER_PICTURE_FAILURE";

//-------------------
//  Freelancer actions
//-------------------
export const CHANGE_FREELANCER_FILTERS = "CHANGE_FREELANCER_FILTERS";

export const GET_FREELANCERS_REQUEST = "GET_FREELANCERS_REQUEST";
export const GET_FREELANCERS_SUCCESS = "GET_FREELANCERS_SUCCESS";
export const GET_FREELANCERS_FAILURE = "GET_FREELANCERS_FAILURE";

//-------------------
//  Client actions
//-------------------
export const CHANGE_CLIENT_FILTERS = "CHANGE_CLIENT_FILTERS";

export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

//-------------------
//  Auditor actions
//-------------------
export const CHANGE_AUDITOR_FILTERS = "CHANGE_AUDITOR_FILTERS";

export const GET_AUDITORS_REQUEST = "GET_AUDITORS_REQUEST";
export const GET_AUDITORS_SUCCESS = "GET_AUDITORS_SUCCESS";
export const GET_AUDITORS_FAILURE = "GET_AUDITORS_FAILURE";

//-------------------
//  Edu actions
//-------------------
export const CHANGE_EDUS_FILTERS = "CHANGE_EDUS_FILTERS";

export const GET_EDUS_REQUEST = "GET_EDUS_REQUEST";
export const GET_EDUS_SUCCESS = "GET_EDUS_SUCCESS";
export const GET_EDUS_FAILURE = "GET_EDUS_FAILURE";

//-------------------
//  Select Profile
//-------------------
export const SELECT_PROFILE_REQUEST = "SELECT_PROFILE_REQUEST";
export const SELECT_PROFILE_SUCCESS = "SELECT_PROFILE_SUCCESS";
export const SELECT_PROFILE_FAILURE = "SELECT_PROFILE_FAILURE";

//--------
// Socials
//--------
export const GET_SOCIALS_REQUEST = "GET_SOCIALS_REQUEST";
export const GET_SOCIALS_SUCCESS = "GET_SOCIALS_SUCCESS";
export const GET_SOCIALS_FAILURE = "GET_SOCIALS_FAILURE";

//---------------
// Subscriptions
//---------------
export const UPDATE_ACTIVE_SUBSCRIPTION_SUCCESS = "CHANGE_ACTIVE_SUBSCRIPTION_SUCCESS";
import React from 'react';
import './notifications.scss';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import Switch from '../../components/Common/ToggleSwitch';
import { GetNotificationSettingsStart, UpdateNotificationSettingStart } from '../../core/actions/notification/';


class SettingsNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.props.GetNotificationSettingsStart();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.settings.general.id) {
            this.setState({
                generalNotificationActive: nextProps.settings.general.notification_active,
                generalPushActive: nextProps.settings.general.push_notification_active,
                generalEmailActive: nextProps.settings.general.email_active,
                messagingNotificationActive: nextProps.settings.messaging.notification_active,
                messagingPushActive: nextProps.settings.messaging.push_notification_active,
                messagingEmailActive: nextProps.settings.messaging.email_active,
                tasksNotificationActive: nextProps.settings.related.notification_active,
                tasksPushActive: nextProps.settings.related.push_notification_active,
                tasksEmailActive: nextProps.settings.related.email_active,
                disputesNotificationActive: nextProps.settings.disputes.notification_active,
                disputesPushActive: nextProps.settings.disputes.push_notification_active,
                disputesEmailActive: nextProps.settings.disputes.email_active,
                hasData: true
            });
        }

    }

    toggleNotificationSettings = activity => ({ enabled }) => {
        const { settings } = this.props;

        switch (activity) {
            case "general-notif":
                this.props.UpdateNotificationSettingStart({ id: settings.general.id, notification_active: enabled });
                break;
            case "general-push":
                this.props.UpdateNotificationSettingStart({ id: settings.general.id, push_notification_active: enabled });
                break;
            case "general-email":
                this.props.UpdateNotificationSettingStart({ id: settings.general.id, email_active: enabled });
                break;
            case "messaging-notif":
                this.props.UpdateNotificationSettingStart({ id: settings.messaging.id, notification_active: enabled });
                break;
            case "messaging-push":
                this.props.UpdateNotificationSettingStart({ id: settings.messaging.id, push_notification_active: enabled });
                break;
            case "messaging-email":
                this.props.UpdateNotificationSettingStart({ id: settings.messaging.id, email_active: enabled });
                break;
            case "tasks-notif":
                this.props.UpdateNotificationSettingStart({ id: settings.related.id, notification_active: enabled });
                break;
            case "tasks-push":
                this.props.UpdateNotificationSettingStart({ id: settings.related.id, push_notification_active: enabled });
                break;
            case "tasks-email":
                this.props.UpdateNotificationSettingStart({ id: settings.related.id, email_active: enabled });
                break;

            case "disputes-notif":
                this.props.UpdateNotificationSettingStart({ id: settings.disputes.id, notification_active: enabled });
                break;
            case "disputes-push":
                this.props.UpdateNotificationSettingStart({ id: settings.disputes.id, push_notification_active: enabled });
                break;
            case "disputes-email":
                this.props.UpdateNotificationSettingStart({ id: settings.disputes.id, email_active: enabled });
                break;
            default:
                break;
        }
    }

    render() {
        if (!this.state.hasData) { return null; }
        return (
            <div className="card p-0">
                <div className="card-head">
                    <FormattedMessage id="settings.notification_pref" defaultMessage="Notification preferences" />
                </div>
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className="sett-col-title">
                                <FormattedMessage id="settings.general" defaultMessage="General" />
                            </div>
                            <hr />
                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.notification" defaultMessage="Notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.generalNotificationActive} onStateChanged={this.toggleNotificationSettings("general-notif")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.push_notification" defaultMessage="Push notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.generalPushActive} onStateChanged={this.toggleNotificationSettings("general-push")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="forms.labels.email" defaultMessage="Email" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.generalEmailActive} onStateChanged={this.toggleNotificationSettings("general-email")} />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div className="sett-col-title">
                                <FormattedMessage id="settings.messaging" defaultMessage="Messaging" />
                            </div>
                            <hr />

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.notification" defaultMessage="Notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.messagingNotificationActive} onStateChanged={this.toggleNotificationSettings("messaging-notif")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.push_notification" defaultMessage="Push notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.messagingPushActive} onStateChanged={this.toggleNotificationSettings("messaging-push")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="forms.labels.email" defaultMessage="Email" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.messagingEmailActive} onStateChanged={this.toggleNotificationSettings("messaging-email")} />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div className="sett-col-title">
                                <FormattedMessage id="settings.task_related" defaultMessage="Task related" />
                            </div>
                            <hr />

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.notification" defaultMessage="Notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.tasksNotificationActive} onStateChanged={this.toggleNotificationSettings("tasks-notif")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.push_notification" defaultMessage="Push notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.tasksPushActive} onStateChanged={this.toggleNotificationSettings("tasks-push")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="forms.labels.email" defaultMessage="Email" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.tasksEmailActive} onStateChanged={this.toggleNotificationSettings("tasks-email")} />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div className="sett-col-title">
                                <FormattedMessage id="settings.dispute_related" defaultMessage="Disputes" />
                            </div>
                            <hr />

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.notification" defaultMessage="Notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.disputesNotificationActive} onStateChanged={this.toggleNotificationSettings("disputes-notif")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="settings.push_notification" defaultMessage="Push notifications" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.disputesPushActive} onStateChanged={this.toggleNotificationSettings("disputes-push")} />
                            </div>

                            <div className="sett-col-item">
                                <span className="">
                                    <FormattedMessage id="forms.labels.email" defaultMessage="Email" />
                                </span>
                                <Switch theme="default" className="d-flex" enabled={this.state.disputesEmailActive} onStateChanged={this.toggleNotificationSettings("disputes-email")} />
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        settings: state.notification.settings
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetNotificationSettingsStart,
    UpdateNotificationSettingStart
})(SettingsNotifications));
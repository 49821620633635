import React from 'react';
import './index.scss';
import './DropdownNotifications.scss';
import history from '../../utils/routes/history';
import DateRangeDuration from '../../components/Common/DateRangeDuration';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { formatDateRange } from './../../utils/common/index';
import { langService } from '../../utils/languageService';
import { MarkNotificationAsReadStart } from '../../core/actions/notification';
import { SelectMyTaskStart } from '../../core/actions/tasks';
import { SelectMyProjectStart } from './../../core/actions/projects/index';
import { GetMyTaskDisputeCasesStart, SelectMyTaskDisputeCase } from '../../core/actions/taskDisputeCases';
import { REACT_APP_BASE_BACKEND_URL_BARE } from '../../utils/common/env-variables';

class DropdownNotification extends React.Component {

    handleClick = () => {
        const { not, role } = this.props;
        var data = not.object_data;

        // MARK AS READ
        this.props.MarkNotificationAsReadStart(not.id);

        switch (not.event) {

            // FOR CLIENT & FREELANCER
            case "OnReviewRequestSubmitted":
                history.push("/profile");
                break;

            // FOR CLIENT
            case "OnFreelancerTaskProposalProposed":     // OK
            case "OnFreelancerTaskProposalCountered":    // OK
            case "OnFreelancerTaskProposalRejected":     // Disabled
            case "OnFreelancerTaskProposalRejectedLast": // OK
            case "OnFreelancerTaskProposalAccepted":     // OK
            case "OnFreelancerTaskRejected":             // OK
                history.push("/my-projects/" + data?.task?.project.id + "/" + data?.task?.id + "/2");
                break;

            case "OnFreelancerTaskResultSubmitted":      // OK
                history.push("/my-projects/" + data?.task?.project.id + "/" + data?.task?.id + "/3");
                break;

            case "OnTaskPublishedStartTimeWarning":      // OK
                this.props.SelectMyTaskStart(data?.task.id);
                break;

            case "OnFreelancerTaskSubmitted":            // OK
                history.push("/my-projects/" + data?.project.id + "/" + data?.id + "/3");
                break;

            case "OnTaskAuditorFailedForClient":         // OK
            case "OnTaskAuditorConfirmedForClient":      // OK
            case "OnTaskInAuditForClient":               // OK
                // history.push("/my-projects");
                history.push("/my-projects/" + data?.project.id + "/" + data?.id + "/4");
                // this.props.SelectMyTaskStart(data?.id);
                break;

            // DISPUTE
            case "OnClientTaskInDispute":               // OK
                history.push("/my-work/" + data?.id + "/5");
                break;
            case "OnClientTaskDisputeSetInNegotiation":
            case "OnClientTaskDisputeSettlementComment":
                history.push("/my-work/" + data?.task + "/5");
                break;
            case "OnFreelancerTaskDisputeSetInNegotiation":
            case "OnFreelancerTaskDisputeSettlementComment":
                history.push("/my-projects/" + data?.project + "/" + data?.task + "/5");
                break;

            case "OnProjectMayCompleted":               // OK
                history.push("/my-projects" + data?.id);
                break;

            // FOR FREELANCER
            case "OnClientTaskProposalProposed":        // OK
            case "OnClientTaskProposalCountered":       // OK
            case "OnClientTaskProposalRejected":        // OK
            case "OnClientTaskProposalSentFinal":       // OK
            case "OnClientTaskRejected":                // OK
                history.push("/my-work");
                this.props.SelectMyTaskStart(data?.task.id);
                break;

            case "OnTaskInProgress":                    // OK
            case "OnClientTaskCompleted":               // OK
                history.push("/my-work");
                this.props.SelectMyTaskStart(data?.id);
                break;

            case "OnTaskAuditorConfirmedForFreelancer": // OK
            case "OnTaskAuditorFailedForFreelancer":    // OK
            case "OnTaskInAuditForFreelancer":          // OK
            case 'OnClientTaskPendingAudit':            // OK
            case "OnClientTaskInAudit":                 // OK (auditor has accepted case)
                history.push("/my-work/" + data?.id + '/4');
                break;

            // case "OnClientTaskInAudit":              // Disabled
            case 'OnTaskRecurringCreated':              // OK
                if (role.type === 'DEV') {
                    history.push("/my-work");
                    this.props.SelectMyTaskStart(data?.id);
                }
                else if (role.type === 'INV') {
                    history.push("/my-projects");
                    this.props.SelectMyTaskStart(data?.id);
                }
                break;

            case 'OnTaskProposalAutoRejected':          // OK
                history.push("browse/tasks");
                break;

            // AUDITOR
            case "OnNewTaskDisputeCase":                // OK
                history.push("/my-audits");
                this.props.SelectMyTaskDisputeCase(data?.id, false);
                break;

            // EDU
            case "OnNewRoleEducationRequest":
                history.push("/my-institution");
                break;

            // Funding
            case "OnTaskPendingFundingFreelancer":
            case "OnTaskFundingInProgressFreelancer":
                history.push("/my-work");
                this.props.SelectMyTaskStart(data?.id);
                break;

            case "OnTaskPendingFundingClient":
            case "OnTaskFundingInProgressClient":
                history.push("/my-projects");
                this.props.SelectMyTaskStart(data?.id);
                break;

            /* case "OnEscrowResolveTaskArrangementFreelancer":
            case "OnEscrowResolveTaskArrangementAuditor": */
            case "OnEscrowResolveTaskArrangementClientRefund":
            case "OnGetChipsAfterBuy":
            case "OnChipsBalanceChanged":
                history.push("/wallet");
                break;

            case "OnEscrowTaskArrangementFail":
            case "OnEscrowTaskArrangementInProgress":
                if (role.type === 'DEV') {
                    history.push("/my-work");
                    this.props.SelectMyTaskStart(data?.task.id);
                }
                else if (role.type === 'INV') {
                    history.push("/my-projects");
                    this.props.SelectMyTaskStart(data?.task.id);
                }
                else if (role.type === 'AUD') {
                    history.push("/my-audits")
                    this.props.SelectMyTaskDisputeCase(data?.id);
                }
                break;

            //Edducation
            case "OnRoleEducationRequestApproved":
            case "OnRoleEducationRequestRejected":
                history.push("/profile");
                break;

            default:
                break;
        }
    }

    render() {
        const { not, role } = this.props;
        var data = not.object_data;
        var title = langService.messages["notification." + not.event + ".title"];
        var text = langService.messages["notification." + not.event + ".text"];
        var img = '/assets/img/default-avatar.png';

        switch (not.event) {

            // BOTH (Freelancer or Client)
            case "OnReviewRequestSubmitted":
                text += data?.reviewer?.name;
                img = data?.reviewer?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.reviewer.profile_image : img; // REACT_APP_BASE_BACKEND_URL_BARE()
                break;

            // FOR CLIENT
            case "OnFreelancerTaskProposalProposed":
            case "OnFreelancerTaskProposalCountered":
            case "OnFreelancerTaskProposalRejected":
            case "OnFreelancerTaskProposalRejectedLast":
            case "OnFreelancerTaskProposalAccepted":
            case "OnFreelancerTaskRejected":
                text = data?.freelancer.name + ' ' + text;
                img = data?.freelancer?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.freelancer.profile_image : img;
                break;

            case "OnFreelancerTaskSubmitted":
                text = data?.active_freelancer?.name + ' ' + text;
                img = data?.active_freelancer?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.active_freelancer.profile_image : img;
                break;

            case "OnFreelancerTaskResultSubmitted":
                text = data?.task?.active_freelancer?.name + ' ' + text;
                img = data?.task?.active_freelancer?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.active_freelancer.profile_image : img;
                break;

            case "OnProjectMayCompleted":
            case "OnTaskAuditorFailedForClient":
            case "OnTaskAuditorConfirmedForClient":
            case "OnTaskInAuditForClient":
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-project.png';
                break;

            case "OnTaskPublishedStartTimeWarning":
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-project.png';
                break;

            // FOR FREELANCER
            case "OnClientTaskProposalProposed":
            case "OnClientTaskProposalCountered":
            case "OnClientTaskProposalRejected":
            case "OnClientTaskProposalSentFinal":
                text = data?.client?.name + ' ' + text;
                img = data?.client?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.client.profile_image : img;
                break;

            case "OnClientTaskRejected":
            case "OnClientTaskCompleted":
                text = data?.project?.client?.name + ' ' + text;
                img = data?.project?.client?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.client.profile_image : img;
                break;

            case "OnTaskInProgress":
                text = langService.messages["common.task"] + ' "' + data.name + '"' + langService.messages["notification." + not.event + ".text"]
                    + formatDateRange(new Date(), new Date(data.end_date)) + '.';
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnTaskPendingFundingFreelancer":
            case "OnTaskPendingFundingClient":
            case "OnTaskFundingInProgressClient":
            case "OnTaskFundingInProgressFreelancer":
                text = langService.messages["notification." + not.event + ".text"]
                    + data.name;
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnEscrowResolveTaskArrangementAuditor":
            case "OnEscrowResolveTaskArrangementClientRefund":
                text = langService.messages["notification." + not.event + ".text"];
                img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnGetChipsAfterBuy":
                text = "you have successfuly bought chips";
                img = '/assets/img/icon/chip.svg'
                break;

            case "OnChipsBalanceChanged":
                text = "Your chips balance changed";
                break;

            case "OnTransactionStatusChangedFrom":
                text = "Transaction status changed from";
                break;

            case "OnTransactionStatusChangedTo":
                text = "Transaction status changed to";
                break;

            case "OnNewTransactionFrom":
                text = "New transaction to";
                break;

            case "OnNewTransactionTo":
                text = "New transaction from";
                break;

            case "OnEscrowTaskArrangementFail":
                text = langService.messages["notification." + not.event + ".text"] + ' "' + data.task.name + '"';
                img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnEscrowTaskArrangementInProgress":
                if (role.type === "DEV") {
                    text = langService.messages["notification." + not.event + ".text.freelancer"] + ' "' + data.task.name + '"';
                    img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                }
                else if (role.type === "INV") {
                    text = langService.messages["notification." + not.event + ".text.client"] + ' "' + data.task.name + '"';
                    img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                }
                break;

            case 'OnTaskRecurringCreated':
                text = langService.messages["common.rec_task"] + ' "' + data?.name + '"' + langService.messages["notification." + not.event + ".text"];
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-task.png';
                break;

            case 'OnTaskProposalAutoRejected':
                text = langService.messages["notification." + not.event + ".text"];
                img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                break;

            // AUDIT 
            case "OnClientTaskPendingAudit":
                text = langService.messages["notification." + not.event + ".text"] + data?.name;
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnClientTaskInDispute":
            case "OnClientTaskDisputeSetInNegotiation":
            case "OnFreelancerTaskDisputeSetInNegotiation":
            case "OnClientTaskDisputeSettlementComment":
            case "OnFreelancerTaskDisputeSettlementComment":
            case "OnClientTaskInAudit": // (auditor has accepted case)
            case "OnTaskAuditorConfirmedForFreelancer":
            case "OnTaskAuditorFailedForFreelancer":
            case "OnTaskInAuditForFreelancer":
            case "OnTaskDisputeCaseAutoRejected":
            case "OnTaskDisputeCaseFailed":
                img = data?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.project.logo_image : '/assets/img/default-task.png';
                break;

            case "OnNewTaskDisputeCase":
                img = data?.task?.project?.logo_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.task.project.logo_image : '/assets/img/default-task.png';
                break;

            // EDU
            case "OnNewRoleEducationRequest":
                text = data?.applicant?.name + ' ' + text;
                img = data?.applicant?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.applicant.profile_image : img;
                break;

            case "OnRoleEducationRequestApproved":
                text = data?.edu?.name + ' ' + text;
                img = data?.edu?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.edu.profile_image : img;
                break;

            case "OnRoleEducationRequestRejected":
                text = data?.edu?.name + ' ' + text;
                img = data?.edu?.profile_image ? REACT_APP_BASE_BACKEND_URL_BARE() + data.edu.profile_image : img;
                break;

            default:
                break;
        }

        return (
            <div className={`n-item ${!not.read && "n-item-red"}`} onClick={() => this.handleClick()}>
                <Row className="n-row">
                    <Col xs={2} className="p-0 text-center">
                        <img src={img} className="rounded" width="45" alt="" />
                    </Col>
                    <Col xs={8} className="p-0">
                        <span className="n-title">{title}</span>
                        <span className="n-text">{text}</span>
                    </Col>
                    <Col xs={2} className="p-0 text-right">
                        <span className="n-info">
                            <DateRangeDuration start_date={new Date()} end_date={not.datetime} />
                        </span>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default connect(mapStateToProps, {
    MarkNotificationAsReadStart,
    SelectMyTaskStart,
    GetMyTaskDisputeCasesStart,
    SelectMyProjectStart,
    SelectMyTaskDisputeCase
})(DropdownNotification);
import React from 'react';
import './addRemoveInputFields.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import AutosizeTextarea from './../../components/Common/AutosizeTextarea/index';
import { langService } from "../../utils/languageService";
import { connect } from "react-redux";
import { CreateDisputeSettlementTopicStart, UpdateTaskDisputeSettlementTopicStart, DeleteDisputeSettlementTopicStart } from '../../core/actions/taskDisputeSettlementTopic';


class AddRemoveInputFields extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newItem: { topic: '', comment: '', topicValid: true, topicCharsLeft: 10, commentValid: true, commentCharsLeft: 10},
            topics: props.topics.map(x => {
                return { ...x, topicValid: true, commentValid: true }
            })
        };
        this.handleTopicChange = this.handleTopicChange.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.topics) {
            this.setState({
                topics: newProps.topics.map(x => {
                    return { ...x, topicValid: true, commentValid: true }
                })
            });
        }
    }

    handleNewTopicChange = (e) => {
        const { name, value } = e.target;
        const newTopic = { ...this.state.newItem };

        if (value.length > 9) {
            newTopic['topicValid'] = true;
            newTopic[name] = value;
            // setNewItem(newTopic);
            this.setState({ newItem: newTopic });
        } else {
            newTopic['topicValid'] = false;
            newTopic['topicCharsLeft'] = 10 - value.length;
            newTopic[name] = value;
            this.setState({ newItem: newTopic });
        }
    };

    handleNewCommentChange = (input, e) => {
        const name = input;
        const value = e.target.value;
        const newTopic = { ...this.state.newItem };

        if (value.length > 9) {
            newTopic['commentValid'] = true;
            newTopic[name] = value;
            this.setState({ newItem: newTopic });
        } else {
            newTopic['commentValid'] = false;
            newTopic['commentCharsLeft'] = 10 - value.length;
            newTopic[name] = value;
            this.setState({ newItem: newTopic });
        }
    };

    handleTopicChange = (e, i) => {
        const { value } = e.target;
        const temp = this.state.topics;
        temp[i].topic = value;

        if (value.length > 9) {
            temp[i].topicValid = true;
        } else {
            temp[i].topicValid = false;
        }

        this.setState({ topics: temp });
    }

    handleCommentChange = (e, i) => {
        const value = e.target.value;
        const temp = this.state.topics;
        temp[i].comment = value;

        if (value.length > 9) {
            temp[i].commentValid = true;
        } else {
            temp[i].commentValid = false;
        }

        this.setState({ topics: temp });
    }

    handleAddClick = () => {
        if (this.state.newItem.topicValid && this.state.newItem.commentValid && this.state.newItem.topic !== '' && this.state.newItem.comment !== '') {
            this.props.CreateDisputeSettlementTopicStart(this.props.settlement.task, this.state.newItem.topic, this.state.newItem.comment);
            this.setState({ newItem: { topic: '', comment: '', topicValid: true, commentValid: true } });
        }
    };

    handleUpdateClick = item => {
        if (item.topicValid && item.commentValid) {
            this.props.UpdateTaskDisputeSettlementTopicStart(item.id, item.topic, item.comment);
        }
    };

    handleRemoveClick = id => {
        this.props.DeleteDisputeSettlementTopicStart(id);
    };

    render() {
        return (
            <div className="add-remove-fields-container">

                { this.state.topics.map((x, i) => {
                    return (
                        <div className="addremove-inputs-wrapper mb-3" key={i}>
                            <div className="inputs">
                                <input name="topic" className={`form-control ` + ((x.topicValid === undefined || x.topicValid === true) ? 'mb-3' : 'is-invalid')} placeholder={langService.messages['auditors.keypoint']} value={x.topic} onChange={e => this.handleTopicChange(e, i)} />
                                {!x.topicValid && <small className="form-field-error mt-1 mb-3"><FormattedMessage id="validators.minChars" defaultMessage="Minimum 10 characters" /> ({x.topicCharsLeft} <FormattedMessage id="validators.left" defaultMessage=" left" />)</small>}
                                <div className="mb-3">
                                    <AutosizeTextarea isValid={(x.commentValid === undefined || x.commentValid === true) ? '' : 'is-invalid'} value={x.comment} rows={2} input="comment" edit={false} handleChange={(input, e) => this.handleCommentChange(e, i)} placeholder={langService.messages['auditors.comment']} />
                                    {!x.commentValid && <small className="form-field-error mt-1 mb-3"><FormattedMessage id="validators.minChars" defaultMessage="Minimum 10 characters" /> ({x.commentCharsLeft} <FormattedMessage id="validators.left" defaultMessage=" left" />)</small>}
                                </div>
                            </div>
                            <div className="btn-box">
                                <button className="btn btn-default btn-projects mr-2" onClick={() => this.handleRemoveClick(x.id)}>
                                    <FormattedMessage id="common.remove" defaultMessage="Remove" />
                                </button>
                                <button className="btn btn-default btn-projects" onClick={() => this.handleUpdateClick(x)}>
                                    <FormattedMessage id="forms.labels.update" defaultMessage="Update" />
                                </button>
                            </div>
                        </div>
                    );
                })}

                <div className="addremove-inputs-wrapper mb-3">
                    <div className="inputs">
                        <input name="topic" className={`form-control ` + (this.state.newItem.topicValid ? 'mb-3' : 'is-invalid')} placeholder={langService.messages['auditors.keypoint']} 
                            value={this.state.newItem.topic} 
                            onChange={e => this.handleNewTopicChange(e)}
                            onBlur={e => this.handleAddClick()}
                        />
                        {!this.state.newItem.topicValid && <small className="form-field-error mt-1 mb-3"><FormattedMessage id="validators.minChars" defaultMessage="Minimum 10 characters" /> ({this.state.newItem.topicCharsLeft} <FormattedMessage id="validators.left" defaultMessage=" left" />)</small>}
                        <div className="mb-3">
                            <AutosizeTextarea placeholder={langService.messages['auditors.comment']} isValid={this.state.newItem.commentValid ? '' : 'is-invalid'} 
                                value={this.state.newItem.comment} rows={2} input="comment" edit={false} 
                                handleChange={(input, e) => this.handleNewCommentChange(input, e)}
                                handleOnBlur={e => this.handleAddClick()}
                            />
                            {!this.state.newItem.commentValid && <small className="form-field-error mt-1 mb-3"><FormattedMessage id="validators.minChars" defaultMessage="Minimum 10 characters" /> ({this.state.newItem.commentCharsLeft} <FormattedMessage id="validators.left" defaultMessage=" left" />)</small>}
                        </div>
                    </div>
                    <div className="btn-box">
                        <button className="btn btn-default btn-projects" onClick={() => this.handleAddClick()}>
                            <FormattedMessage id="common.save_and_add" defaultMessage="Add more" />
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    settlement: state.myWork.myDisputeSettlements.selected,
    topics: state.myWork.myDisputeSettlements.selected.topics
})


export default injectIntl(connect(mapStateToProps, {
    CreateDisputeSettlementTopicStart,
    UpdateTaskDisputeSettlementTopicStart,
    DeleteDisputeSettlementTopicStart
})(AddRemoveInputFields))



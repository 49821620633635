import { GET_PROJECT_CATEGORIES_SUCCESS } from '../../actions/projectCategories/types'

const INITIAL_STATE = {
    list: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    }    
}

export default function variable(state = INITIAL_STATE, action)  {
    switch(action.type){
        case GET_PROJECT_CATEGORIES_SUCCESS:
            return{ ...state, list: action.payload.list };
        default:
            return state;
    }
}
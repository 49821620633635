import store from '../../core/store';
import { createLoadingSelector } from '../../API/selector';
import Levels from '../../assets/json/blooms-taxonomy.js';

export const getTasksFromMilestones = milestoneList => {
    const taskList = [];

    milestoneList.forEach(milestone => {
        milestone.tasks.forEach(task => taskList.push(task));
    });

    return taskList;
}

// async fun
// usage:
// const submitFormState = await testingReduxAction('ACTION_NAME'); // returns object if call on api was successful or failed but
// you need to manually set and manage loading variable in component
export const testingReduxAction = action => {
    return new Promise((resolve, reject) => {
        const loadingSelector = createLoadingSelector([action]);
        let isLoading = true;
        let errors = '';

        const unsubscribe = store.subscribe(() => {
            const state = store.getState();

            isLoading = loadingSelector(state);
            errors = state.errors[action];

            if (!isLoading) {
                unsubscribe();

                process.env.NODE_ENV !== "production" && console.log('e => ', errors);

                if (!errors)
                    resolve({ success: true, msg: 'Successfully doned this action.' });
                else
                    reject({ success: false, msg: errors });
            }
        });
    });
}

// wrapper function
export const wrapperTestingReduxAction = (actionFn, action) => {
    const actionFnExecute = async () => await actionFn;
    actionFnExecute();

    return 'yes';
}

function convertToslug(text, separator) {
    text = text.toString().toLowerCase().trim()
  
    const sets = [
      { to: "a", from: "[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
      { to: "ae", from: "[Ä]" },
      { to: "c", from: "[ÇĆĈČ]" },
      { to: "d", from: "[ÐĎĐÞ]" },
      { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
      { to: "g", from: "[ĜĞĢǴ]" },
      { to: "h", from: "[ĤḦ]" },
      { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
      { to: "j", from: "[Ĵ]" },
      { to: "ij", from: "[Ĳ]" },
      { to: "k", from: "[Ķ]" },
      { to: "l", from: "[ĹĻĽŁ]" },
      { to: "m", from: "[Ḿ]" },
      { to: "n", from: "[ÑŃŅŇ]" },
      { to: "o", from: "[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
      { to: "oe", from: "[ŒÖ]" },
      { to: "p", from: "[ṕ]" },
      { to: "r", from: "[ŔŖŘ]" },
      { to: "s", from: "[ŚŜŞŠ]" },
      { to: "ss", from: "[ß]" },
      { to: "t", from: "[ŢŤ]" },
      { to: "u", from: "[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
      { to: "ue", from: "[Ü]" },
      { to: "w", from: "[ẂŴẀẄ]" },
      { to: "x", from: "[ẍ]" },
      { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
      { to: "z", from: "[ŹŻŽ]" },
      { to: "-", from: "[·/_,:;']" },
    ]
  
    sets.forEach((set) => {
      text = text.replace(new RegExp(set.from, "gi"), set.to)
    })
  
    text = text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
  
    if (typeof separator !== "undefined" && separator !== "-") {
      text = text.replace(/-/g, separator)
    }
  
    return text
  }

export const slugify = (input) => {
    // value = "neki"
    // 1. Odstrani vse številke iz value: r'\d+'
    // 2. slugify pretvorba value (https://gist.github.com/mathewbyrne/1280286): value = slugify(value)
    // 3. Odstrani vse kar ne spada v regular exp. iz value: r'[^-a-z_]+'
    // 4. Zloži skupi po formatu: value_level (level = 1)
    var value = input.replace(/[0-9]/g, '');
    var slug = convertToslug(value);
    var res = slug.replace(/[^a-zA-Z]+/g, '');
    return res;
}

export const getBloomVerb = (level, id, lang) => {
    const data = Levels;
    var res = '...';
    data.levels.forEach(l => {
        if (level === 1) {
            if (l.id === id)
                res = lang === 'en' ? l.verb : l.verb_si;
        } else {
            l.verbs.forEach(v => {
                if (v.id === id)
                    res = lang === 'en' ? v.verb : v.verb_si;
            });
        }
    });
    return res;
}

export const getBloomName = (id) => {
    const data = Levels;
    var res = '...';
    data.levels.forEach(l => {
        if (l.id === id)
            res =  l.name;
    });
    return res;
}

export const getShortString = (txt, max) => {
    if (txt.length > max) { return txt.substring(0, max) + '...'; }
    return txt;
}
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { GET_NOTIFICATION_SETTINGS_SUCCESS, UPDATE_NOTIFICATION_SETTING_SUCCESS, SELECT_NOTIFICATION_SETTING_SUCCESS } from './../../actions/notification/types';
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';

const INITIAL_STATE = {
    general: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    },
    messaging: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    },
    related: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    },
    disputes: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case SELECT_NOTIFICATION_SETTING_SUCCESS:
            var notSett = action.payload.selected
            return {
                ...state,
                general: notSett.notification_type === 0 ? notSett : state.general,
                messaging: notSett.notification_type === 1 ? notSett : state.messaging,
                related: notSett.notification_type === 2 ? notSett : state.related,
                disputes: notSett.notification_type === 3 ? notSett : state.disputes,
            };
        case GET_NOTIFICATION_SETTINGS_SUCCESS:
            var notSett1 = action.payload.list?.results
            return {
                ...state,
                general: notSett1.filter(x => x.notification_type === 0)[0],
                messaging: notSett1.filter(x => x.notification_type === 1)[0],
                related: notSett1.filter(x => x.notification_type === 2)[0],
                disputes: notSett1.filter(x => x.notification_type === 3)[0],
            };
        case UPDATE_NOTIFICATION_SETTING_SUCCESS:
            var notSett2 = action.payload.selected
            return {
                ...state,
                general: notSett2.notification_type === 0 ? notSett2 : state.general,
                messaging: notSett2.notification_type === 1 ? notSett2 : state.messaging,
                related: notSett2.notification_type === 2 ? notSett2 : state.related,
                disputes: notSett2.notification_type === 3 ? notSett2 : state.disputes,
            };
        default:
            return state;
    }
}
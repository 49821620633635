import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { RegisterStart } from '../../../core/actions/auth';
import { RegisterGoogleStart } from '../../../core/actions/googleAuth';
import { injectIntl } from 'react-intl';
import FormRegister from './formRegister';

class RegisterPage extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = credentials => {
        this.props.RegisterStart(credentials);
    }

    onRegisterGoogle = async (google_response) => {
        this.props.RegisterGoogleStart(google_response.accessToken)
    }

    goToLogin = e => {
        e.preventDefault();
        history.push("/auth/login");
    }

    render() {
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <FormRegister modal={false} />
                </Container>
            </>
        )
    }

}

export default injectIntl(connect(null, { RegisterStart, RegisterGoogleStart })(RegisterPage));
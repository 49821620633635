import React from 'react';
import '../../containers/Profile/index.scss';
import './profileCompetenciesSection.scss';
import { connect } from 'react-redux';
import { Image, Nav, Tab } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalEditFreelancerCompetences from './modalEditFreelancerCompetences';
import ReactTooltip from 'react-tooltip';
import ModalMintCompetencies from './modalMintCompetencies';
import { GetMyCompetencesMintStart } from '../../core/actions/blockchain/competences/index';
import ProfileCompetenciesSectionEarned from './profileCompetenciesSectionEarned';
import ProfileCompetenciesSectionUserDefined from './profileCompetenciesSectionUserDefined';

class ProfileCompetenciesSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalFreelancerCompetencesVisible: false,
            modalFreelancerMintCompetenciesVisible: false,
            activeCompetenciesTabKey: this.props.role.user_competences?.length > 0 ? 1 : 2,
            selectedKey: '',
        };
    }

    componentDidMount() {
        this.props.GetMyCompetencesMintStart();
    }

    handleCompetenciesTabChange(selectedKey) {
        this.setState({
            activeCompetenciesTabKey: selectedKey
        });
    }

    delete_me(e) {
        const buttons = this.state.buttons.filter((button) => button !== e._targetInst._currentElement);
        this.setState({ buttons });
    }

    getUserTopSkill(skills) {
        let res = skills.reduce(function(prev, current) {
            return (prev.total_weight > current.total_weight) ? prev : current;
        })
        return (
            <>
            <span className="skill-nolink">{res.issuer_competence_name} {res?.competence?.level ? "L" + res.competence.level : ''}</span>
            <span>...</span>
            </>
        );
    }

    render() {
        const { role, roleMintCompetences } = this.props;
        return (
            <>
                <div className="card p-0">
                    <span className="icon-edit" onClick={() => this.setState({ modalFreelancerCompetencesVisible: true })}></span>
                    <div className="card-head card-head-profile-competencies" style={{ 'borderBottom': '0' }}>
                        <div>
                            <FormattedMessage id="profile.competences" defaultMessage="Competences" />{(role.user_competences?.length || role.competences?.length) > 0 && <span className=""> ({role.user_competences?.length + role.competences?.length})</span>}
                        </div>
                        {roleMintCompetences.count > 0 && <>
                            <button className="btn btn-developers" onClick={() => this.setState({ modalFreelancerMintCompetenciesVisible: true })} data-tip data-for='what-is-minting' >
                                <FormattedMessage id="profile.save_to_blockchain" defaultMessage="Save to Blockchain" />
                            </button>
                            <ReactTooltip id="what-is-minting" place="top" type="dark" effect="solid">
                                <div className="tooltip-txt" style={{ "textAlign": "center", "whiteSpace": "pre-line" }}>
                                    <FormattedMessage id="tooltip.mint_competencies" defaultMessage='You can "mint" or generate NFT tokens on Blockchain as a proof for your earned skills. This does cost something, but you become the actual owner of your "certified skills".' />
                                </div>
                            </ReactTooltip>
                        </>
                        }
                    </div>

                    <div className="competencies-public-tabs">
                        <Tab.Container defaultActiveKey={1} activeKey={this.state.activeCompetenciesTabKey} onSelect={(x) => this.handleCompetenciesTabChange(x)}>
                            <Nav className="nav-bar competences-nav-public">
                                {role.user_competences?.length > 0 &&
                                    <>
                                        <Nav.Link eventKey="1" className="tab-general nav-tab-competences"><FormattedMessage id="competences.earned" defaultMessage="Earned" /> ({role.user_competences?.length}) <img src="/assets/img/icon/info.png" data-tip data-for='earned' alt="info-icon" width="13"></img></Nav.Link>
                                        <ReactTooltip id="earned" place="right" type="dark" effect="solid">
                                            <div className="tooltip-txt">
                                                <FormattedMessage id="tooltip.earned_competencies" defaultMessage="Skills the user was awarded either by completing tasks or through a verified educational program." />
                                            </div>
                                        </ReactTooltip>
                                    </>
                                }

                                {/* EDU TAB IF WE DECIDE TO SPLIT EARNED vs EDU COMPETENCIES */}
                                {/* <Nav.Link eventKey="3" className="tab-general nav-tab-competences">Education</Nav.Link> */}

                                {role.competences?.length > 0 &&
                                    <>
                                        <ReactTooltip id="user-defined" place="right" type="dark" effect="solid">
                                            <div className="tooltip-txt">
                                                <FormattedMessage id="tooltip.userdefined_competencies" defaultMessage="Skills added to his profile by the user himself or herself." />
                                            </div>
                                        </ReactTooltip>
                                        <Nav.Link eventKey="2" className="tab-general nav-tab-competences"><FormattedMessage id="competences.user_defined" defaultMessage="User defined" /> ({role.competences?.length}) <img src="/assets/img/icon/info.png" data-tip data-for='user-defined' alt="info-icon" width="13"></img></Nav.Link>
                                    </>
                                }
                            </Nav>
                            <div className="card-body c-card-body pt-0 pb-0">
                                <Tab.Content>

                                    {/* EARNED from completed tasks and confirmed educations */}
                                    <Tab.Pane eventKey="1">
                                        {role.user_competences?.length > 0 && <ProfileCompetenciesSectionEarned role={role} minted={roleMintCompetences} />}
                                    </Tab.Pane>

                                    {/* USE DEFINED */}
                                    <Tab.Pane eventKey="2" style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem' }}>
                                        {role.competences?.length > 0
                                            ? <ProfileCompetenciesSectionUserDefined role={role} />
                                            :
                                            <div className="p-section empty-section">
                                                <div className="">
                                                    <Image src="/assets/img/svg/competences.svg" className="section-img"></Image>
                                                </div>
                                                <div className="">
                                                    <div className=""><FormattedMessage id="competences.add.desc" defaultMessage="Add your skills and we will suggest the perfect projects for you!" /></div>
                                                    <div className="add-link" onClick={() => this.setState({ modalFreelancerCompetencesVisible: true })}><FormattedMessage id="competences.add" defaultMessage="Add Competences" /></div>
                                                </div>
                                            </div>
                                        }
                                    </Tab.Pane>

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
                {
                    this.state.modalFreelancerCompetencesVisible &&
                    <ModalEditFreelancerCompetences
                        obj_competences={role.competences}
                        visible={this.state.modalFreelancerCompetencesVisible}
                        onClose={() => this.setState({ modalFreelancerCompetencesVisible: false })} />
                }
                {
                    this.state.modalFreelancerMintCompetenciesVisible &&
                    <ModalMintCompetencies
                        competences={this.props.roleMintCompetences}
                        visible={this.state.modalFreelancerMintCompetenciesVisible}
                        onClose={() => this.setState({ modalFreelancerMintCompetenciesVisible: false })} />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleMintCompetences: state.user.info.competencesMint,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyCompetencesMintStart
})(ProfileCompetenciesSection));
import React, { useState, useEffect } from 'react';
import './contactList.scss';
import { FormattedMessage } from 'react-intl';
import { Image } from 'react-bootstrap';
import { langService } from '../../utils/languageService';
import { useSelector, useDispatch } from 'react-redux';
import { GetContactsV2Start, SelectChatV2Start, DeselectChatV2, SendMessageV2Start } from '../../core/actions/chat_v2';


const GlobalContactList = props => {
    // Component state
    const [message, setMessage] = useState('');

    // Store state
    const auth = useSelector(state => state.user.auth);
    const role = useSelector(state => state.user?.info?.settings?.active_role_obj);
    const contactList = useSelector(state => state.chat_v2.contactList);
    const selectedChat = useSelector(state => state.chat_v2.selected);
    const dispatch = useDispatch();

    // Hooks
    useEffect(() => {
        if (!auth.isAuthenticated)
            return;
        if (!role)
            return;

        dispatch(GetContactsV2Start());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated])


    const handleMessageChange = e => setMessage(e.target.value);

    const handlekeyPress = e => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            setMessage(e.target.value);
            sendMessage();
        }
    }

    const openForm = () => document.getElementById('myForm').style.display = 'block';

    const closeForm = () => document.getElementById('myForm').style.display = 'none';

    const openChat = contactItem => {
        dispatch(SelectChatV2Start(contactItem.role, contactItem.contact));
        document.getElementById('myChat').style.display = 'block';
    }

    const closeChat = () => {
        document.getElementById('myChat').style.display = 'none';
        dispatch(DeselectChatV2());
    }

    const sendMessage = () => {
        if (selectedChat?.blocked)
            return;

        if (message === '')
            return;

        dispatch(SendMessageV2Start(selectedChat?.role?.id, selectedChat?.contact?.id, message, null));
        setMessage('');
    }

    const renderChat = () => {
        return (
            <div className="bottom-fixed-wrap">
                <div className="chat-box" id="myChat">
                    <div className="chatbox-header">
                        <div>
                            <span>{selectedChat?.contact?.name} </span>
                            <span className="chat-circle status-color-active"> ●</span>
                            <span className="close chat-close" onClick={closeChat}></span>
                        </div>
                        <div>
                            N/A
                        </div>
                    </div>

                    <div className="chat-log">
                        {selectedChat?.messages?.results?.map((chatItem, chatIndex) =>
                            <div className="message" key={chatIndex}>
                                {chatItem.message}
                            </div>
                        )}
                    </div>

                    <div className="chatbox-bottom">
                        <textarea className="chatbox-textarea"
                            value={message}
                            onChange={handleMessageChange}
                            onKeyDown={handlekeyPress}
                            disabled={selectedChat?.blocked}
                            placeholder={selectedChat.blocked ? 'You blocked this user.' : langService.messages['forms.placeholders.messages']}
                        >
                        </textarea>
                        {/* TODO: Add message send loading indicator... */}
                        {<Image src="/assets/img/svg/send.svg" onClick={sendMessage} />}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bottom-fixed-wrap">
                <button className="open-button" onClick={openForm}>
                    <span className="chat-circle status-color-active">●</span>
                    <span style={{ marginLeft: "6px" }}><FormattedMessage id="messages.messages" defaultMessage="Messages" /></span>
                </button>
            </div>


            <div className="bottom-fixed-wrap">
                <div className="chat-popup" id="myForm">

                    <div className="chat-popup-header" onClick={closeForm}>
                        <span className="chat-circle status-color-active">●</span>
                        <span style={{ marginLeft: "6px" }}><FormattedMessage id="messages.messages" defaultMessage="Messages" /></span>
                    </div>

                    <div className="form-container">
                        {/* CONTACT LIST */}
                        {contactList?.results?.map((contactItem, contactIndex) => (
                            <div className="user-row" key={contactIndex} onClick={() => openChat(contactItem)}>
                                {contactItem.contact.profile_image
                                    ? <Image src={contactItem.contact.profile_image} className="chat-thumb" />
                                    : <Image src="/assets/img/default-avatar.png" className="chat-thumb" />
                                }
                                <div className="user-name">
                                    <div key={contactIndex}>{contactItem.contact.name}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {renderChat()}
        </>
    );
}

export default GlobalContactList;
import { GET_CRYPTO_DONE } from '../../actions/crypto/types'

const INITIAL_STATE = {
    ethereum: {},
    dai: {},
    bnb: {}
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_CRYPTO_DONE:
            return { ...state, ethereum: action.payload.ethereum, dai: action.payload.dai, bnb: action.payload.binancecoin };
        default:
            return state;
    }
}
import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ReactFlagsSelect from 'react-flags-select';
// import 'react-flags-select/css/react-flags-select.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';
import { UpdateRoleStart } from './../../core/actions/role/index';
import { createLoadingSelector } from '../../API/selector';
import { createErrorMessageSelector } from './../../API/selector';
import { langService } from '../../utils/languageService';


class ModalEditProfileInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            id: this.props.role.id,
            name: this.props.role.name,
            company_name: this.props.role.company_name,
            vat: this.props.role.vat,
            first_name: this.props.role.first_name,
            last_name: this.props.role.last_name,
            description: this.props.role.description,
            address_line_1: this.props.role.address_line_1,
            address_line_2: this.props.role.address_line_2,
            post_code: this.props.role.post_code,
            city: this.props.role.city,
            state: this.props.role.state,
            country: this.props.role.country,
            isValid: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.hide()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role !== this.props.role) {
            this.setState({
                id: nextProps.role.id,
                name: nextProps.role.name,
                company_name: nextProps.role.company_name,
                vat: nextProps.role.vat,
                first_name: nextProps.role.first_name,
                last_name: nextProps.role.last_name,
                description: nextProps.role.description,
                address_line_1: nextProps.role.address_line_1,
                address_line_2: nextProps.role.address_line_2,
                post_code: nextProps.role.post_code,
                city: nextProps.role.city,
                state: nextProps.role.state,
                country: nextProps.role.country
            });
        }
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    onSelectFlag(countryCode) {
        this.setState({ country: countryCode });
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }

        this.setState({ validated: true });
    };

    save() {
        this.props.UpdateRoleStart({
            name: this.state.name,
            description: this.state.description,
            country: this.state.country,
            city: this.state.city
        });
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={600}
                height={370}
                closeMaskOnClick={false}
                className="rodal-dialog-edit-profile-info"
                >
                <div style={{ "padding": "10px" }}>
                    <p><FormattedMessage id="modalEditProfileInfo.heading" defaultMessage="Edit profile information" /></p>
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                        <Row>
                            <Form.Group as={Col} controlId="formName">
                                <Form.Label><FormattedMessage id="modalEditProfileInfo.displayname" defaultMessage="Display Name" /></Form.Label>
                                <Form.Control type="text" onChange={this.handleChange('name')} defaultValue={this.props.name}  value={this.state.name || ''} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label><FormattedMessage id="modalEditProfileInfo.description" defaultMessage="Description" /> (500)</Form.Label>
                                <Form.Control as="textarea" rows="2" onChange={this.handleChange('description')} defaultValue={this.props.description} value={this.state.description || ''} />
                            </Form.Group>
                        </Row>
                        <Row className="edit-profile-break ">
                            <Form.Group as={Col} controlId="formCountry">
                                <Form.Label><FormattedMessage id="modalEditProfileInfo.country" defaultMessage="Country" /></Form.Label>
                                <ReactFlagsSelect type="button"
                                    searchable={true} 
                                    selected={this.state.country ? this.state.country : ''}
                                    defaultCountry={this.state.country || ''} 
                                    placeholder={langService.messages['forms.placeholders.select_country']}
                                    searchPlaceholder={langService.messages['forms.placeholders.search_country']}
                                    onSelect={(c) => this.onSelectFlag(c)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formCity">
                                <Form.Label><FormattedMessage id="modalEditProfileInfo.city" defaultMessage="City" /></Form.Label>
                                <Form.Control type="text" onChange={this.handleChange('city')} value={this.state.city || ''} />
                            </Form.Group>
                        </Row>
                        <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                            <FormattedMessage id="common.save" defaultMessage="Save" />
                        </button>
                        <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()} >
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </div>
                    </Form>
                </div>
            </Rodal>
        )
    }

}

const loadingSelector = createLoadingSelector(['UPDATE_ROLE']);
const errorSelector = createErrorMessageSelector(['UPDATE_ROLE']);
const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleStart
})(ModalEditProfileInfo));
import { SELECT_MY_TASK_PROPOSAL_SUCCESS, DESELECT_MY_TASK_PROPOSAL, GET_MY_TASK_PROPOSALS_SUCCESS, CREATE_TASK_PROPOSAL_SUCCESS, UPDATE_TASK_PROPOSAL_SUCCESS } from '../../actions/taskProposals/types'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';

const INITIAL_STATE = {
    selected: {
        id: null,
        competences: [],
        task: {},
        client: {},
        freelancer: {},
        last_task_proposal_change: '',
        history: null,
        available_actions: [],
        last_action_type: '',
        status: '',
        price: null,
        start_date: '',
        end_date: ''
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_MY_TASK_PROPOSALS_SUCCESS:
            return { ...state, list: action.payload.list };
        case SELECT_MY_TASK_PROPOSAL_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_MY_TASK_PROPOSAL:
            return { ...state, selected: INITIAL_STATE.selected };
        case UPDATE_TASK_PROPOSAL_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(taskProp =>
                        taskProp.id === action.payload.selected.id ?
                            action.payload.selected : taskProp)
                }
            };
        case CREATE_TASK_PROPOSAL_SUCCESS:

            return action.payload.add ? {
                ...state, selected: action.payload.selected,
                list: {
                    ...state.list, count: state.list.count + 1,
                    results: [action.payload.selected].concat(state.list?.results)
                }
            } : state;
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE };
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE };
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE };
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}
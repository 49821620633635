import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import ProfileSwitcher from '../../components/ProfileSwitcher';
import Infobar from '../../components/Infobar';
import { GetRoleStatsStart } from '../../core/actions/role';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.GetRoleStatsStart();
    }

    makeChartData = (stats) => {
        let data = [];
        stats?.forEach(s => {
            data.push({
                name: moment(s.day).format('DD.MM.YYYY'),
                Visits: s.count,
                pv: 0,
                amt: 0,
            })
        })
        return data;
    }

    /* percentage = (stats) => {
        let percentage = "";
        stats?.forEach(s => {
            percentage = moment().subtract(1, 'week');
        })
        return percentage;
    } */

    render() {
        const { role, stats } = this.props;
        return (
            <>
                <Infobar />
                <div className="jumbo-dash">
                    {/* <Infobar /> */}
                    <Container>
                        <h1 className="dashboard-bannertitle"><FormattedMessage id="dashboard.welcome" defaultMessage="Welcome" />, {role.name}</h1>
                        <p className="p-responsive">Your account dashboard overview and monitoring.</p>
                    </Container>
                </div>
                <Container className="mt-4">
                    <Row>
                        <Col>
                            <ProfileSwitcher />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.welcome" defaultMessage="Welcome" /></div>
                                <div className="card-body"></div>
                            </div>
                            {/* <div className="card p-0">
                                {/* <span className="icon-edit"></span>
                                <div className="card-head"><FormattedMessage id="dashboard.setup" defaultMessage="Setup" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span>
                                <div className="card-head"><FormattedMessage id="dashboard.browse" defaultMessage="Browse" /></div>
                                <div className="card-body"></div>
                            </div> */}
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.statistics" defaultMessage="Statistics" /></div>
                                <div className="card-body">

                                    <div className="statistics-container">

                                        <div className="statistics-count-card">
                                            <span className="statistics-title">Profile</span>
                                            <span className="statistics-count">{role.hit_count}</span>
                                            <span className="statistics-views-label">Views</span>
                                            <span className="statistics-views-label">55% more than last week</span>
                                        </div>
                                        
                                        <div className="statistics-chart-container">
                                            <div className="statistics-chart-labels">
                                                <span className="statistics-chart-title">Profile views</span>
                                                <div className="statistics-chart-last">
                                                    <span><span style={{'color':'#9EEB47','fontWeight':'bold'}}>{role.hit_count}</span> views in the last 90 days**</span>
                                                </div>
                                                <div className="statistics-chart-since">
                                                    <span><span style={{'color':'#F7DA5F','fontWeight':'bold'}}>- 32% {/* {this.percentage(stats)} */}</span> since last week**</span>
                                                </div>
                                            </div>
                                            <AreaChart 
                                                width={800}
                                                height={200}
                                                data={this.makeChartData(stats)}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="Visits" stroke="#8884d8" fill="#8884d8" />
                                            </AreaChart>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* <div className="card p-0">
                                {/* <span className="icon-edit"></span>
                                <div className="card-head"><FormattedMessage id="dashboard.clients" defaultMessage="Clients" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span>
                                <div className="card-head"><FormattedMessage id="dashboard.myactivity" defaultMessage="My Activity" /></div>
                                <div className="card-body"></div>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isEmailVerified: state.user.info.isEmailVerified,
        role: state.user.info.settings.active_role_obj,
        stats: state.user.roleStats.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetRoleStatsStart
})(DashboardPage));
import * as types from './types';
import { apiCon } from '../../../API';

// Contacts
export const GetContactsV2Start = (limit = 10) => async dispatch => {
    dispatch({ type: types.GET_CONTACTS_V2_REQUEST });
    const url = `/me/messaging/contacts/?limit=${limit}`;

    try {
        const response = await apiCon.get(url);
        dispatch(GetContactsV2Success(response.data))
    } catch (error) {
        dispatch(GetContactsV2Failure(error.response));
    }
}

export const GetContactsV2ChangePageStart = url => async dispatch => {
    dispatch({ type: types.GET_CONTACTS_V2_REQUEST });

    try {
        const response = await apiCon.get(url);
        dispatch(GetContactsV2Success(response.data, true))
    } catch (error) {
        dispatch(GetContactsV2Failure(error.response));
    }
}

export const GetContactsV2Success = (data, add = false) => ({
    type: types.GET_CONTACTS_V2_SUCCESS,
    payload: {
        succ_msg: 'Successfully received contacts.',
        list: data,
        add: add
    }
});

export const GetContactsV2Failure = error => ({
    type: types.GET_CONTACTS_V2_FAILURE,
    payload: {
        err_msg: {
            errorType: error.data.error,
            detail: error.data.detail
        },
        status: error.status,
        toast_msg: 'Ooops! Get contacts failed. Please try again or contact us.'
    }
});

// Select chat
export const SelectChatV2Start = (role, contact) => async dispatch => {
    dispatch({ type: types.SELECT_CHAT_V2_REQUEST });
    const url = `/me/messaging/chat/?sender=${role.id}&receiver=${contact.id}`;

    try {
        const response = await apiCon.get(url);
        dispatch(SelectChatV2Success(response.data, role, contact))
    } catch (error) {
        dispatch(SelectChatV2Failure(error.response));
    }
}

export const SelectChatV2Success = (data, role, contact) => ({
    type: types.SELECT_CHAT_V2_SUCCESS,
    payload: {
        succ_msg: 'Successfully received chat info.',
        selected: {
            role: role,
            contact: contact,
            messages: data
        }
    }
});

export const SelectChatV2Failure = error => ({
    type: types.SELECT_CHAT_V2_FAILURE,
    payload: {
        err_msg: {
            errorType: error.data.error,
            detail: error.data.detail
        },
        status: error.status,
        toast_msg: 'Ooops! Select chat failed. Please try again.'
    }
});

// Deselect chat
export const DeselectChatV2 = () => ({
    type: types.DESELECT_CHAT_V2,
    payload: {
        succ_msg: 'Successfully deselected chat info.'
    }
});

// Send message
export const SendMessageV2Start = (roleId, contactId, message, files) => async dispatch => {
    dispatch({ type: types.SEND_MESSAGE_V2_REQUEST });

    let fileIdList = [];

    if (files) {
        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);


            try {
                const response = await apiCon.post('/me/messaging/attachments/', formData, { headers: { 'content-type': 'multipart/form-data' } });
                fileIdList.concat(response.data?.id);
            } catch (error) {
                // TODO: Handle file upload fail
            }
        }
    }

    const data = {
        receiver: contactId,
        message: message,
        attachments: fileIdList
    }

    const url = '/me/messaging/send/';

    try {
        const response = await apiCon.post(url, data, { headers: { 'Content-Type': 'application/json' } });
        dispatch(SendMessageV2Success(response.data));
    } catch (error) {
        dispatch(SendMessageV2Failure(error.response));
    }
}

export const SendMessageV2Success = data => ({
    type: types.SEND_MESSAGE_V2_SUCCESS,
    payload: {
        succ_msg: 'Message sent successfully.',
        message: data
    }
});

export const SendMessageV2Failure = error => ({
    type: types.SEND_MESSAGE_V2_FAILURE,
    payload: {
        err_msg: {
            errorType: error.data.error,
            detail: error.data.detail
        },
        status: error.status,
        toast_msg: 'Ooops! Send message failed. Please try again.'
    }
});
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { GetChatsStart } from './../core/actions/chat';
import store from './../core/store';
import {
    WebSocketRoleChanged,
    WebSocketTaskProposalChanged,
    WebSocketTaskProposalCreated,
    WebSocketTaskChanged,
    WebSocketTaskCreated,
    WebSocketTaskDisputeCreated,
    WebSocketTaskDisputeSettlementCreated,
    WebSocketTaskDisputeSettlementChanged,
    WebSocketEscrowArrangementChanged,
    WebSocketGamificationBalanceChanged,
    WebSocketGamificationChipsAfterBuy,
    WebSocketTransactionStatusChanged,
    WebSocketNewTransaction
} from './../core/actions/notification/index';
import {
    WEB_SOCKET_RECEIVE_MESSAGE, WEB_SOCKET_SEND_MESSAGE,
    WEB_SOCKET_USER_STATUS_CHANGED,
} from './../core/actions/chat/types';
import { SelectMyTaskStart } from './../core/actions/tasks/index';
import { WEB_SOCKET_ADD_NOTIFICATION, WEB_SOCKET_PROJECT_MAY_COMPLETE } from "../core/actions/notification/types";
import { UPDATE_COMPETENCE_SUCCESS } from "../core/actions/competences/types";
import { GetContactsV2Start } from "../core/actions/chat_v2";
import { REACT_APP_BASE_WS_URL_BARE } from '../utils/common/env-variables';

export const baseWsUrl = REACT_APP_BASE_WS_URL_BARE(); // process.env.

export var client = null;

export var channelsToConnect = [];
export var connectedChannels = [];


export function sendMessage(taskId, receiverId, message) {
    if (client?.readyState && client.readyState === client.OPEN) {
        var post = {
            "type": "send.chat.message",
            "payload": {
                "task": taskId,
                "receiver": receiverId,
                "message": message
            }
        }
        post = JSON.stringify(post)
        client.send(post);
    }
}

export function connectToChannel(channelName) {
    if (client?.readyState && client.readyState === client.OPEN && connectedChannels.indexOf(channelName) === -1) {
        var post = {
            "type": "channel.subscribe",
            "payload": {
                "channel": channelName
            }
        }
        post = JSON.stringify(post)
        client.send(post);
    }
    else {
        if (channelsToConnect.indexOf(channelName) === -1) {
            channelsToConnect.push(channelName)
        }
    }
}

export function disconnectFromChannel(channelName) {
    if (client?.readyState && client.readyState === client.OPEN && connectedChannels.indexOf(channelName) !== -1) {
        var post = {
            "type": "channel.unsubscribe",
            "payload": {
                "channel": channelName
            }
        }
        post = JSON.stringify(post)
        client.send(post);
    }
    else {
        if (channelsToConnect.indexOf(channelName) !== -1) {
            channelsToConnect = channelsToConnect.filter(x => channelName !== x)
        }
    }
}

export function connect() {
    try {
        var access_token = localStorage.getItem('django_access_token');
        if (access_token) {
            disconnect();
            if (client?.readyState || client?.readyState === client?.CLOSED) {
                client = new W3CWebSocket(baseWsUrl + access_token);

                client.onopen = () => {
                    process.env.NODE_ENV !== "production" && console.log('WebSocket Client Connected');
                    if (channelsToConnect.length !== 0) {
                        channelsToConnect.map(x => connectToChannel(x))
                    }
                };

                client.onerror = function () {
                    process.env.NODE_ENV !== "production" && console.log('Connection Error');
                };

                client.onclose = function () {
                    process.env.NODE_ENV !== "production" && console.log('WebSocket Client Closed');
                };

                client.onmessage = (message) => {
                    const dataFromServer = JSON.parse(message.data);

                    process.env.NODE_ENV !== "production" && console.log('WebSocket Message', dataFromServer);
                    if (dataFromServer.type === "chat.user.me") {
                        //When you connect to websocket
                        store.dispatch(GetChatsStart());
                    } else if (dataFromServer.type === "chat.message") {
                        //When you send the message
                        store.dispatch({ type: WEB_SOCKET_SEND_MESSAGE, payload: { message: dataFromServer.payload } })
                    } else if (dataFromServer.type === "chats.status") {
                        //When someone send you chat message
                        store.dispatch({ type: WEB_SOCKET_RECEIVE_MESSAGE, payload: { message: dataFromServer.payload?.last_message } })
                    } else if (dataFromServer.type === "chat.user.status") {
                        //When someone from your contact list change online status
                        const payload = { user: dataFromServer.payload.sender?.id, is_online: dataFromServer.payload.is_online };
                        store.dispatch({ type: WEB_SOCKET_USER_STATUS_CHANGED, payload: payload })
                    }
                    else if (dataFromServer.type === "send.chat.message.error") {
                        //When error occurs
                    }
                    // NOTIFICATIONS
                    // https://gitlab.com/ECTA-Group/djangoapi/-/wikis/API%40Messaging%40Notification%40Websocket-specifications
                    else if (dataFromServer.type === "notification.status") {
                        //Increase counter for notifications
                        store.dispatch({ type: WEB_SOCKET_ADD_NOTIFICATION, payload: {} })
                    }
                    else if (dataFromServer.type === "channel.subscribe") {
                        const notification = dataFromServer.payload
                        if (notification.status === "OK") {
                            if (connectedChannels.indexOf(notification.channel) === -1) {
                                connectedChannels.push(notification.channel)
                            }
                            channelsToConnect = channelsToConnect.filter(x => x !== notification.channel)
                        }
                    }
                    else if (dataFromServer.type === "channel.unsubscribe") {
                        const notification = dataFromServer.payload
                        if (notification.status === "OK") {
                            if (connectedChannels.indexOf(notification.channel) !== -1) {
                                connectedChannels = connectedChannels.filter(x => x !== notification.channel)
                            }
                        }
                    }
                    else if (dataFromServer.type === "channel.changes") {
                        const notification = dataFromServer.payload
                        if (notification.channel === 'competences') {
                            const payload = { succ_msg: 'Skill changed with WebSocket', selected: notification.object_data };
                            store.dispatch({ type: UPDATE_COMPETENCE_SUCCESS, payload: payload });
                        }
                    }
                    else if (dataFromServer.type === "system.message") {
                        const tp_created_events = ['OnFreelancerTaskProposalProposed', 'OnClientTaskProposalProposed']
                        const tp_changed_events = ['OnFreelancerTaskProposalCountered', 'OnFreelancerTaskProposalRejected',
                            'OnFreelancerTaskProposalRejectedLast', 'OnFreelancerTaskProposalAccepted', 'OnClientTaskProposalCountered',
                            'OnClientTaskProposalRejected', 'OnClientTaskProposalSentFinal', 'OnTaskProposalAutoRejected']
                        const t_changed_events = ['OnFreelancerTaskRejected', 'OnFreelancerTaskSubmitted', 'OnClientTaskRejected',
                            'OnClientTaskCompleted', 'OnTaskInProgress', 'OnClientTaskPendingAudit', 'OnTaskInAuditForFreelancer', 'OnTaskAuditorFailedForFreelancer',
                            'OnTaskAuditorConfirmedForFreelancer', 'OnClientTaskInAudit', 'OnTaskAuditorFailedForClient', 'OnTaskAuditorConfirmedForClient',
                            'OnTaskPendingFundingFreelancer', 'OnTaskPendingFundingClient', 'OnTaskFundingInProgressFreelancer',
                            'OnTaskFundingInProgressClient']
                        const t_created_events = ['OnTaskRecurringCreated']
                        const td_created_events = ['OnNewTaskDisputeCase']
                        const td_changed_events = ['OnTaskDisputeCaseAutoRejected', 'OnTaskDisputeCaseFailed']

                        //Escrow task arrangement
                        const eta_created_events = ['OnEscrowResolveTaskArrangementClientRefund', 'OnEscrowResolveTaskArrangementFreelancer',
                            'OnEscrowResolveTaskArrangementAuditor', 'OnEscrowTaskArrangementInProgress']

                        // DisputeSettlement
                        const tds_created_events = ['OnClientTaskInDispute']
                        const tds_changed_events = ['OnClientTaskDisputeSetInNegotiation', 'OnFreelancerTaskDisputeSetInNegotiation']
                        const tdst_changed_events = ['OnClientTaskDisputeSettlementComment', 'OnFreelancerTaskDisputeSettlementComment']

                        //Wallet events for test
                        const cbc_change_events = ['OnChipsBalanceChanged']
                        const cbc_after_events = ['OnGetChipsAfterBuy'];

                        //Transaction status
                        const ts_changed = ['OnTransactionStatusChangedFrom', 'OnTransactionStatusChangedTo']

                        //New transaction
                        const nt_created_events = ['OnNewTransactionFrom', 'OnNewTransactionTo']

                        const p_changed_events = ['OnProjectMayCompleted']

                        const notification = dataFromServer.payload
                        const state = store.getState()
                        console.log(state);

                        if (notification.event === 'OnSysChangedActiveRole') {
                            if (!('CHANGE_ACTIVE_ROLE' in state.loading) ||
                                ('CHANGE_ACTIVE_ROLE' in state.loading && state.loading.CHANGE_ACTIVE_ROLE === false)) {
                                store.dispatch(WebSocketRoleChanged(notification.object_data?.active_role?.id));
                            }
                        }

                        //TaskProposal Created
                        else if (tp_created_events.indexOf(notification.event) !== -1) {
                            if (state.myWork.myTasks?.selected?.id === notification.object_data?.task?.id ||
                                state.myWork.myTasks?.list?.results.some(task => task.id === notification.object_data?.task?.id) ||
                                window.location.pathname === '/my-work' ||
                                state.myWork.myProjects.selected?.milestones?.list?.some(mile => mile.tasks.some(task => task.id === notification.object_data?.task?.id))) {
                                store.dispatch(WebSocketTaskProposalCreated(notification.object_data?.id, window.location.pathname === '/my-work',
                                    notification.object_data?.task?.id === state.myWork.myTasks.selected?.id));
                            }
                        }
                        //TaskProposal Changed
                        else if (tp_changed_events.indexOf(notification.event) !== -1) {
                            if (state.myWork.myTaskProposals.selected?.id === notification.object_data.id ||
                                state.myWork.myTaskProposals.list?.results.some(tp => tp.id === notification.object_data.id) ||
                                notification.object_data?.status === 'CONFIRMED') {
                                store.dispatch(WebSocketTaskProposalChanged(notification.object_data?.id, state.myWork.myTasks.selected?.id === notification.object_data.task.id));
                                store.dispatch(GetContactsV2Start())
                            }
                        }
                        //Task Changed
                        else if (t_changed_events.indexOf(notification.event) !== -1) {
                            if (state.myWork.myTasks.selected?.id === notification.object_data.id ||
                                state.myWork.myTasks.list?.results.some(t => t.id === notification.object_data.id) ||
                                state.myWork.myProjects.selected?.milestones?.list?.some(mile => mile.tasks.some(task => task.id === notification.object_data.id))) {
                                store.dispatch(WebSocketTaskChanged(notification.object_data?.id));
                            }
                        }
                        //Task Created
                        else if (t_created_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/my-work' ||
                                state.myWork.myProjects.selected?.id === notification.object_data.project.id) {
                                store.dispatch(WebSocketTaskCreated(notification.object_data?.id, false));
                            }
                        }
                        //Task Arrangement
                        else if (eta_created_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/wallet') {
                                store.dispatch(WebSocketEscrowArrangementChanged());
                            }
                            if (window.location.pathname === '/my-work' || window.location.pathname === 'my-projects') {
                                if (state.myWork.myTasks.selected?.id === notification.object_data.task.id ||
                                    state.myWork.myTasks.list?.results.some(t => t.id === notification.object_data.task.id) ||
                                    state.myWork.myProjects.selected?.milestones?.list?.some(mile => mile.tasks.some(task => task.id === notification.object_data.task.id))) {
                                    store.dispatch(SelectMyTaskStart(notification.object_data?.task.id, true));
                                }
                            }
                        }
                        //Task Dispute Created
                        else if (td_created_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/my-audits' || window.location.pathname === '/my-work') {
                                store.dispatch(WebSocketTaskDisputeCreated(notification.object_data?.id));
                            }
                        }
                        //Task Dispute Changed
                        else if (td_changed_events.indexOf(notification.event) !== -1) {
                            if (state.myWork.myDisputeCases.list?.some(t => t.id === notification.object_data.id) ||
                                state.myWork.myDisputeCases.selected?.id === notification.object_data.id) {
                                store.dispatch(WebSocketTaskProposalChanged(notification.object_data?.id));
                            }
                        }
                        // Task Dispute Settlement Created
                        else if (tds_created_events.indexOf(notification.event) !== -1) {
                            if ((window.location.pathname.includes('/my-projects') || window.location.pathname.includes('/my-work')) &&
                                state.myWork.myTasks.selected?.id === notification.object_data.id) {
                                store.dispatch(WebSocketTaskDisputeSettlementCreated(notification.object_data?.id));
                                store.dispatch(SelectMyTaskStart(notification.object_data?.id, true));
                            }
                        }
                        // Task Dispute Settlement Changed
                        else if (tds_changed_events.indexOf(notification.event) !== -1) {
                            if ((window.location.pathname.includes('/my-projects') || window.location.pathname.includes('/my-work')) &&
                                state.myWork.myTasks.selected?.id === notification.object_data.task) {
                                store.dispatch(WebSocketTaskDisputeSettlementChanged(notification.object_data?.task));
                            }
                        }
                        // Task Dispute Settlement Comment Added
                        else if (tdst_changed_events.indexOf(notification.event) !== -1) {
                            if ((window.location.pathname.includes('/my-projects') || window.location.pathname.includes('/my-work')) &&
                                state.myWork.myTasks.selected?.id === notification.object_data.task) {
                                store.dispatch(WebSocketTaskDisputeSettlementChanged(notification.object_data?.task));
                                // store.dispatch(WebSocketTaskDisputeSettlementCommentsChanged(notification.object_data?.id));
                            }
                        }
                        //Project Changed
                        else if (p_changed_events.indexOf(notification.event) !== -1) {
                            if (state.myWork.myProjects.selected?.id === notification.object_data.project.id) {
                                store.dispatch({ type: WEB_SOCKET_PROJECT_MAY_COMPLETE });
                            }
                        }
                        //Chips balance changed
                        else if (cbc_change_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/wallet') {
                                store.dispatch(WebSocketGamificationBalanceChanged());
                            }
                        }

                        //Chips after buy
                        else if (cbc_after_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/wallet') {
                                store.dispatch(WebSocketGamificationChipsAfterBuy());
                            }
                        }

                        //Transaction status changed
                        else if (ts_changed.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/wallet') {
                                store.dispatch(WebSocketTransactionStatusChanged());
                            }
                        }

                        //New transactions
                        else if(nt_created_events.indexOf(notification.event) !== -1) {
                            if (window.location.pathname === '/wallet') {
                                store.dispatch(WebSocketNewTransaction());
                            }
                        }
                    }
                };
            }
        }
    }
    catch (e) {
        console.log(e)
    }

}

export function disconnect() {
    if (client?.readyState && client.readyState === client.OPEN)
        client.close();
}

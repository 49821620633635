import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Form as BSForm, Col, Row } from 'react-bootstrap';
import { required, strongPassword, passwordConfirmMatch } from '../../../utils/forms/validators';
import { composeRecordLevelValidators } from '../../../utils/forms/validatorUtilities'
import { Input, CheckBoxAgreeWithTerms } from '../../../utils/forms/formElements';
import { RegisterStart } from '../../../core/actions/auth';
import { RegisterGoogleStart } from '../../../core/actions/googleAuth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { REACT_APP_GOOGLE_CLIENT_ID } from '../../../utils/common/env-variables';

class FormRegister extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = credentials => {
        this.props.RegisterStart(credentials, this.props.modal ? false : true);
    }

    onRegisterGoogle = async (google_response) => {
        this.props.RegisterGoogleStart(google_response.accessToken, this.props.modal ? false : true)
    }

    render() {
        return (
            <Form
                onSubmit={this.onSubmit}
                initialValues={this.props.values}
                validate={composeRecordLevelValidators(passwordConfirmMatch)}
                render={({ handleSubmit, valid, values }) => (
                    <div className={this.props.modal ? "auth auth-modal" : "auth"}>
                        <p><span className="underline"><FormattedMessage id="forms.registrationForm" defaultMessage="Register" /></span></p>
                        <BSForm validated={valid} onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Field
                                        name="email"
                                        label={<FormattedMessage id={`${this.FORM_LABEL}.email`} defaultMessage="email" />}
                                        type="email"
                                        component={Input}
                                        validate={required}
                                    />
                                    <Field
                                        name="password1"
                                        type="password"
                                        label={<FormattedMessage id={`${this.FORM_LABEL}.password`} defaultMessage="password" />}
                                        component={Input}
                                        validate={strongPassword}
                                    />
                                    <Field
                                        name="password2"
                                        type="password"
                                        label={<FormattedMessage id={`${this.FORM_LABEL}.confirmPassword`} defaultMessage="confirmPassword" />}
                                        component={Input}
                                    // validate={minLen8}
                                    />
                                </Col>
                            </Row>
                            <Field
                                name="agreeWithTerms"
                                type="checkbox"
                                component={CheckBoxAgreeWithTerms}
                                defaultValue={false}
                                validate={required}
                            />
                            <button type="submit" className="btn btn-default btn-generic btn-block"><FormattedMessage id="forms.labels.register" defaultMessage="Register" /></button>
                        </BSForm>

                        <BSForm.Text className="auth-small">
                            <FormattedMessage id={`${this.FORM_LABEL}.alreadyHaveAccount`} defaultMessage="alreadyHaveAccount" />
                            <Link to="/auth/login"><FormattedMessage id={`${this.FORM_LABEL}.goToLogin`} defaultMessage="goToLogin" /></Link>
                        </BSForm.Text>
                        {/* {!prod ? <pre><p ><code><small>{JSON.stringify(values, 0, 2)}</small></code></p></pre> : ''} */}
                        <div className="separator">or</div>

                        <GoogleLogin
                            clientId={REACT_APP_GOOGLE_CLIENT_ID()}
                            buttonText={<FormattedMessage id={`${this.FORM_LABEL}.registerWithGoogle`} defaultMessage="Register with Google" />}
                            onSuccess={this.onRegisterGoogle}
                            onFailure={this.onRegisterGoogle}
                            cookiePolicy={'single_host_origin'}
                            className="btn-block"
                        />
                    </div>
                )}
            />

        )
    }
}

export default injectIntl(connect(null, { RegisterStart, RegisterGoogleStart })(FormRegister));
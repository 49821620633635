import React from 'react';
import './modalDeleteRole.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Image } from 'react-bootstrap';
import { DeleteRoleStart } from '../../core/actions/role';


class ModalDeleteRole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    hide() {
        this.props.onClose();
    }

    delete(id) {
        this.props.DeleteRoleStart(id);
        this.hide();
    }

    render() {
        const { visible, role } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={460}
                height={300}
                closeMaskOnClick={false}
                className="rodal-dialog-delete-role"
            >
                <div>

                    <div className="modal-title mb-4">
                        Delete role
                    </div>

                    <div className="delete-role-info">
                        <div className="">
                            <Image src={role.profile_image} height="64"></Image>
                        </div>
                        <div className="delete-role-details">
                            <div className="">{role.first_name} {role.last_name}</div>
                            <div className=""><FormattedMessage id={"roles.name." + role.type} defaultMessage="" /></div>
                        </div>
                    </div>

                    <p>You can delete a role only when you have no active tasks.</p>
                    <p>Are you sure you want to delete this role?</p>
                    <div className="delete-role-buttons">
                        <div className="btn btn-projects mr-2" onClick={() => this.hide()}>Cancel</div>
                        <div className="btn btn-projects-inv" onClick={() => this.delete(role.id)}>YES, DELETE ROLE PERMANENTLY</div>
                    </div>
                </div>
            </Rodal>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj
    }
}

export default injectIntl(connect(mapStateToProps, {
    DeleteRoleStart
})(ModalDeleteRole));
// GET /v1/api/edu/degrees/ (Pregled stopenj izobrazb, samo za izobraževalne institucije in programskega admina)
// POST /v1/api/edu/degrees/ (Dodajanje stopenj izobrazb, samo za izobraževalne institucije in programskega admina)
// GET /v1/api/edu/degree/ (Pregled posamezne stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)
// PATCH /v1/api/edu/degree/ (Urejanje stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)
// DELETE /v1/api/edu/degree/ (Brisanje oz. deaktiviranje stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)

// GET /v1/api/eduinstitutions/programs/?edu_id=[edu_id] (Public pregled izobraževalnih programov za neko izobraževalno ustanovo)
// GET /v1/api/edu/programs/ (Pregled izobraževalnih programov, samo za izobraževalne institucije in programskega admina)
// POST /v1/api/edu/programs/ (Dodajanje izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// GET /v1/api/edu/program/{0} (Pregled posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// PATCH /v1/api/edu/program/{0} (Urejanje posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// DELETE /v1/api/edu/program/{0} (Brisanje oz. deaktiviranje posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)


//-------------------
//  DEGREEs actions
//-------------------
export const GET_DEGREES_REQUEST = "GET_DEGREES_REQUEST";
export const GET_DEGREES_SUCCESS = "GET_DEGREES_SUCCESS";
export const GET_DEGREES_FAILURE = "GET_DEGREES_FAILURE";

export const CREATE_DEGREE_REQUEST = "CREATE_DEGREE_REQUEST";
export const CREATE_DEGREE_SUCCESS = "CREATE_DEGREE_SUCCESS";
export const CREATE_DEGREE_FAILURE = "CREATE_DEGREE_FAILURE";

export const GET_DEGREE_REQUEST = "GET_DEGREE_REQUEST";
export const GET_DEGREE_SUCCESS = "GET_DEGREE_SUCCESS";
export const GET_DEGREE_FAILURE = "GET_DEGREE_FAILURE";

export const UPDATE_DEGREE_REQUEST = "UPDATE_DEGREE_REQUEST";
export const UPDATE_DEGREE_SUCCESS = "UPDATE_DEGREE_SUCCESS";
export const UPDATE_DEGREE_FAILURE = "UPDATE_DEGREE_FAILURE";

export const DELETE_DEGREE_REQUEST = "DELETE_DEGREE_REQUEST";
export const DELETE_DEGREE_SUCCESS = "DELETE_DEGREE_SUCCESS";
export const DELETE_DEGREE_FAILURE = "DELETE_DEGREE_FAILURE";

//-------------------
//  PROGRAMs actions
//-------------------
export const GET_INSTITUTION_PROGRAMS_REQUEST = "GET_INSTITUTION_PROGRAMS_REQUEST";
export const GET_INSTITUTION_PROGRAMS_SUCCESS = "GET_INSTITUTIONS_PROGRAMS_SUCCESS";
export const GET_INSTITUTION_PROGRAMS_FAILURE = "GET_INSTITUTIONS_PROGRAMS_FAILURE";

export const GET_MY_INSTITUTION_PROGRAMS_REQUEST = "GET_MY_INSTITUTION_PROGRAMS_REQUEST";
export const GET_MY_INSTITUTION_PROGRAMS_SUCCESS = "GET_MY_INSTITUTION_PROGRAMS_SUCCESS";
export const GET_MY_INSTITUTION_PROGRAMS_FAILURE = "GET_MY_INSTITUTION_PROGRAMS_FAILURE";

export const CREATE_PROGRAM_REQUEST = "CREATE_PROGRAM_REQUEST";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_FAILURE = "CREATE_PROGRAM_FAILURE";

export const GET_MY_PROGRAM_REQUEST = "GET_MY_PROGRAM_REQUEST";
export const GET_MY_PROGRAM_SUCCESS = "GET_MY_PROGRAM_SUCCESS";
export const GET_MY_PROGRAM_FAILURE = "GET_MY_PROGRAM_FAILURE";

export const UPDATE_MY_PROGRAM_REQUEST = "UPDATE_MY_PROGRAM_REQUEST";
export const UPDATE_MY_PROGRAM_SUCCESS = "UPDATE_MY_PROGRAM_SUCCESS";
export const UPDATE_MY_PROGRAM_FAILURE = "UPDATE_MY_PROGRAM_FAILURE";

export const DELETE_MY_PROGRAM_REQUEST = "DELETE_MY_PROGRAM_REQUEST";
export const DELETE_MY_PROGRAM_SUCCESS = "DELETE_MY_PROGRAM_SUCCESS";
export const DELETE_MY_PROGRAM_FAILURE = "DELETE_MY_PROGRAM_FAILURE";

//-------------------
//  COMPETENCEs actions
//-------------------
export const CHANGE_MY_COMPETENCES_FILTERS = "CHANGE_MY_COMPETENCES_FILTERS";

/* export const GET_MY_COMPETENCE_DESCRIPTIONS_SUCCESS = "GET_MY_COMPETENCE_DESCRIPTIONS_SUCCESS"; */

export const SELECT_MY_COMPETENCE_REQUEST = "SELECT_MY_COMPETENCE_REQUEST";
export const SELECT_MY_COMPETENCE_SUCCESS = "SELECT_MY_COMPETENCE_SUCCESS";
export const SELECT_MY_COMPETENCE_FAILURE = "SELECT_MY_COMPETENCE_FAILURE";

export const DESELECT_MY_COMPETENCE = 'DESELECT_MY_COMPETENCE';

export const GET_MY_COMPETENCES_REQUEST = "GET_MY_COMPETENCES_REQUEST";
export const GET_MY_COMPETENCES_SUCCESS = "GET_MY_COMPETENCES_SUCCESS";
export const GET_MY_COMPETENCES_FAILURE = "GET_MY_COMPETENCES_FAILURE";

export const UPDATE_MY_COMPETENCE_REQUEST = "UPDATE_MY_COMPETENCE_REQUEST";
export const UPDATE_MY_COMPETENCE_SUCCESS = "UPDATE_MY_COMPETENCE_SUCCESS";
export const UPDATE_MY_COMPETENCE_FAILURE = "UPDATE_MY_COMPETENCE_FAILURE";
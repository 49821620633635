import React from "react";
import "../../containers/Profile/index.scss";
import "./profileCompetenciesSection.scss";
import { connect } from "react-redux";
import {
  Image,
  Accordion,
  Card,
  Dropdown,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import groupBy from "lodash/groupBy";
import history from "../../utils/routes/history";
import moment from "moment";
import _ from "lodash";

const result = (array) =>
  array.reduce((unique, o) => {
    if (
      !unique.some((obj) => {
        if (obj.competence !== null) {
          if (obj.competence.id === o.competence.id) {
            unique.push(o);
          }
        }
      })
    )
      return unique;
  }, []);

class ProfileCompetenciesSectionEarned extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFreelancerCompetencesVisible: false,
      modalFreelancerMintCompetenciesVisible: false,
      activeCompetenciesTabKey:
        this.props.role.user_competences?.length > 0 ? 1 : 2,
      selectedKey: "",
      isOpen: false,
      openedDropdown: "",
    };
  }

  componentDidMount() { }

  activateToggle(eventKey) {
    this.setState({
      selectedKey: eventKey,
    });
  }

  handleOpen = (e) => {
    const id = e.target.id;
    this.setState({
      isOpen: true,
      openedDropdown: id,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
      openedDropdown: "",
    });
  };

  getSortedSkills(skills) {
    let res = skills.sort((a, b) =>
      a.last_nom > b.last_nom ? 1 : b.last_nom > a.last_nom ? -1 : 0
    );
    return res;
  }

  renderDropdownContent(competency) {
    const { role, roleMintCompetences } = this.props;
    if (_.isEmpty(competency)) {
      return <></>;
    }
    return (
      <>
        <div className="c-dropdown-content">
          <div>
            {roleMintCompetences?.results?.map((x) =>
              !x.competence ? (
                <span className="c-dropdown-title">
                  <FormattedMessage
                    id="competences.weights_for"
                    defaultMessage="Weights for"
                  />
                  <span className="link-blue">
                    {" "}
                    {competency.issuer_competence_name}
                  </span>
                </span>
              ) : (
                x.competence.id === competency?.competence?.id &&
                x.competence_token !== null && (
                  <>
                    <Image
                      className="c-minted-icon-dropdown"
                      src="/assets/img/icon/check_minted.svg"
                    />
                    <span className="c-dropdown-title">
                      <FormattedMessage
                        id="competences.weights_for"
                        defaultMessage="Weights for"
                      />
                      <span className="link-blue">
                        {" "}
                        {competency.issuer_competence_name} L
                        {competency.competence?.level}
                      </span>
                    </span>
                  </>
                )
              )
            )}
          </div>
          <span className="c-gray-text-sm">
            <FormattedMessage
              id="competences.weights_task_provided"
              defaultMessage="Tasks that provided these weights"
            />
          </span>
          <Dropdown.Divider />
          <div className="c-gray-text-sm">
            <span>
              <FormattedMessage id="projects.tasks" defaultMessage="Tasks" />
            </span>
            <span style={{ float: "right" }}>
              <FormattedMessage
                id="competences.weights"
                defaultMessage="Weights"
              />
            </span>
          </div>
          {role.participated_in_tasks?.map((x, index) =>
            x.competences?.map(
              (c) =>
                c.id === competency.competence?.id && (
                  <div className="c-main-dropdown-content" key={index}>
                    <Row>
                      <Col>
                        <div className="c-dropdown-left-row">
                          <span className="c-dropdown-project-name">
                            {x.name}
                          </span>
                          <div className="c-dropdown-left-content">
                            <Image
                              src="/assets/img/icon/calendar_timeframe_black.svg"
                              className="c-dropdown-icon"
                            />
                            <span
                              className="c-dropdown-label"
                              style={{ marginRight: "20px" }}
                            >
                              <FormattedMessage
                                id="common.time"
                                defaultMessage="Time: "
                              />{" "}
                              {Math.abs(
                                parseInt(
                                  moment
                                    .duration(
                                      moment(x.start_date).diff(
                                        moment(x.end_date)
                                      )
                                    )
                                    .asDays()
                                )
                              )}{" "}
                              days
                            </span>
                            <Image
                              src="/assets/img/icon/budget_black.svg"
                              className="c-dropdown-icon"
                            />
                            <span className="c-dropdown-label">
                              <FormattedMessage
                                id="common.amount"
                                defaultMessage="Amount: "
                              />{" "}
                              {parseInt(x.price)} $
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col className="c-dropdown-right-col">
                        <div className="c-dropdown-rating">
                          <span className="c-rating-label">
                            {parseInt(x.total_competences_weight)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
            )
          )}
        </div>
      </>
    );
  }

  renderDropdownContentMint() {
    return (
      <>
        <div className="c-dropdown-content-mint">
          <div>
            <Image
              className="c-minted-icon-dropdown"
              src="/assets/img/icon/check_minted.svg"
            />
            <span className="c-dropdown-title">
              Competency is saved on the blockchain
            </span>
          </div>
          <Dropdown.Divider />
          {/* <div>
                        <span className="c-mint-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Ecta magna aliqua.
                        </span>
                    </div> */}
          <div className="c-mint-info">
            <span className="c-more-info">More information</span>
            <i className="arrow right" />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { role, minted } = this.props;
    const group = groupBy(role?.user_competences, "issuer.name");
    return (
      <Accordion
        defaultActiveKey={Object.keys(group)[0]}
        bsPrefix="accordion-user-skills"
        flush
      >
        {Object.keys(group).map((c, i) => {
          return (
            <div key={i}>
              <Accordion.Item
                eventKey={i}
                bsPrefix="accordion-user-skills_item"
              >
                <Accordion.Header
                  className="c-accordion pt-0 pb-0"
                  bsPrefix="accordion-user-skills_header"
                  as={Card.Header}
                  onClick={() => this.activateToggle(c)}
                >
                  <div className="user-skills-header">
                    <div className="skill-issued-by">
                      <FormattedMessage
                        id="issuers.issued_by"
                        defaultMessage="Issued by"
                      />
                      {c !== "wns" ? (
                        <span
                          className="clink"
                          onClick={() =>
                            history.push(
                              "/user/" +
                              group[c]
                                .slice(0, 1)
                                .map((issuer) => issuer.issuer.owner.id)
                            )
                          }
                        >
                          {c.toUpperCase()}
                        </span>
                      ) : (
                        <span>{c.toUpperCase()}</span>
                      )}
                      <span> ({group[c].length})</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="c-group-tags earned-skills">
                    {group[c].map((grp, x) => (
                      <div className="c-tag-div skill-tag" key={x}>
                        {minted.results &&
                          result(minted?.results).map(
                            (x, key) =>
                              x.competence !== null &&
                              x.competence.id === grp.competence.id &&
                              x.competence_token !== null && (
                                <Dropdown
                                  key={key}
                                  as={Nav.Item}
                                  onMouseEnter={this.handleOpen}
                                  onMouseLeave={this.handleClose}
                                  show={
                                    this.state.isOpen &&
                                    this.state.openedDropdown ===
                                    "dropdownMint" + grp.competence?.id
                                  }
                                >
                                  <Dropdown.Toggle
                                    className="c-mint-dropdown-toggle"
                                    as={Nav.Link}
                                    id={"dropdownMint" + grp.competence?.id}
                                  >
                                    <Image
                                      className="c-minted-icon"
                                      src="/assets/img/icon/check_minted.svg"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="c-dropdown-menu">
                                    {this.renderDropdownContentMint()}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )
                          )}
                        <span
                          className="earned-skill"
                          onClick={() =>
                            history.push("/skill/" + grp.competence?.id)
                          }
                          key={x}
                        >
                          {grp.issuer_competence_name}{" "}
                          {grp.competence?.level && "L" + grp.competence?.level}
                        </span>
                        <Dropdown
                          as={Nav.Item}
                          onMouseEnter={this.handleOpen}
                          onMouseLeave={this.handleClose}
                          show={
                            this.state.isOpen &&
                            this.state.openedDropdown ===
                            "dropdown" + grp.competence?.id
                          }
                        >
                          <Dropdown.Toggle
                            as={Nav.Link}
                            id={"dropdown" + grp.competence?.id}
                          >
                            <span className="c-weights-count">
                              {grp.total_weight}{" "}
                              {grp.total_weight === 1 ? (
                                <FormattedMessage
                                  id="competences.weights_singular"
                                  defaultMessage="Weight"
                                />
                              ) : (
                                <FormattedMessage
                                  id="competences.weights"
                                  defaultMessage="Weights"
                                />
                              )}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="c-dropdown-menu">
                            {this.renderDropdownContent(grp)}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          );
        })}
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default injectIntl(
  connect(mapStateToProps, {})(ProfileCompetenciesSectionEarned)
);

import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from '../../../utils/languageService';
import { DeleteCompetenceStart } from '../../../core/actions/competences';
import { GetMyCompetencesStart, } from '../../../core/actions/institution/competences';

import { createErrorMessageSelector, createLoadingSelector } from '../../../API/selector';

const customStyles = {
    width: 'inherit',
    height: '180px',
    maxWidth: '410px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};

class ModalConfirmDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            visible: false,
            competencyId: this.props.selectedCompetency.id
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.hide();
            this.props.GetMyCompetencesStart({ issuers: this.props.issuer[0].owner })
        }
    }

    hide() {
        this.props.onClose();
    }

    onConfirmDelete() {
        this.props.DeleteCompetenceStart(this.state.competencyId);
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>
                    <div className="modal-title">
                        {langService.messages['common.confirmDeleteTitle']}
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <h6>{langService.messages['common.confirmDeleteText']}</h6>
                        
                        <br/><br/>

                        <div className="mt-4 modal-submit-buttons modal-abs-bottom">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.props.onClose()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.onConfirmDelete()}>
                                <FormattedMessage id="common.yes_delete" defaultMessage="YES, DELETE" />
                            </button>
                        </div>

                    </div>
                </div>
            </Rodal>
        )
    }

}
const loadingSelector = createLoadingSelector(['DELETE_COMPETENCE']);
const errorSelector = createErrorMessageSelector(['DELETE_COMPETENCE']);
const mapStateToProps = state => {
    return {
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        selectedCompetency: state.myInstitution.myCompetences.selected,
        issuer: state.issuers.my.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    DeleteCompetenceStart,
    GetMyCompetencesStart
})(ModalConfirmDelete));
import { GET_DEGREES_SUCCESS, UPDATE_DEGREE_SUCCESS } from "../../actions/institution/types";


const INITIAL_STATE = {
    selected: {
        id: null,
        is_locked: '',
        name: '',
        description: ''
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    }    
}


export default function variable(state = INITIAL_STATE, action)  {
    switch(action.type){
        case GET_DEGREES_SUCCESS:
            return{ ...state, list: action.payload.list };
        case UPDATE_DEGREE_SUCCESS:
            return {
                ...state, selected: state.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(item =>
                        item.id === action.payload.details.id ? action.payload.details : item)
                }
            };
        // case CREATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case UPDATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case DELETE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };            
        default:
            return state;
    }
}
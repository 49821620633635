import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { SELECT_PROFILE_SUCCESS, GET_ROLE_DETAIL_SUCCESS } from './../../actions/role/types';
import { GET_RATING_SUCCESS, GET_REVIEWS_SUCCESS } from './../../actions/rating/types';

const INITIAL_STATE = {
    selected: {
        id: null,
        name: '',
        description: '',
        type: '',
        profile_image: null,
        cover_image: null,
        city: '',
        country: '',
        language: '',
        educations: [],
        experiences: [],
        competences: [],
        details: {},
        rating: {},
        reviews: {
            count: 0,
            next: null,
            previous: null,
            results: []
        }
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case SELECT_PROFILE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    ...action.payload.selected
                }
            };
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        case GET_ROLE_DETAIL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    ...action.payload.details
                }
            };
        case GET_RATING_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    rating: action.payload.selected
                }
            };
        case GET_REVIEWS_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    reviews: action.payload.list
                }
            };
        default:
            return state;
    }
}
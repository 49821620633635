import './index.scss';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";


const MetaTags = () => {
    const [title, setMetaTitle] = useState("Work 'n Skill - More than just a freelancing portal");
    const [shareUrl, setShareUrl] = useState('');

    const location = useLocation();

    useEffect(() => {
        // switch (location.pathname) {
        //     case '/browse/projects':
        //     case '/browse/freelancers':
        //     case '/browse/clients':
        //     case '/browse/educational-institutions':
        //         setTitle('BROWSE');
        //         break;
        //     case '/skills':
        //         setTitle('COMPETENCIES');
        //         // setDescription('');
        //         break;

        //     default:
        //         break;
        // }
        setShareUrl(window.location.origin);
        setMetaTitle("Work 'n Skill - More than just a freelancing portal");

    }, [location]);

    // const setTitle = title => {
    //     const el = document.querySelector("title");
    //     el.innerText = `${el.text} | ${title}`;
    // };

    // const setDescription = desc => {
    //     const el = document.querySelector("meta[name='description']");
    //     el.setAttribute('content', desc)
    // }

    return (
        <>
            <div className="share-container">

                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={36} round />
                </FacebookShareButton>

                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TwitterIcon size={36} round />
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                    <LinkedinIcon size={36} round />
                </LinkedinShareButton>

                <TelegramShareButton
                    url={shareUrl}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TelegramIcon size={36} round />
                </TelegramShareButton>

            </div>
        </>
    );
}

export default MetaTags;

import React from "react";
import Footer from './components/Footer/index';
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { PrivateRoute } from './utils/routes/privateRoute';

// Pages
import NoMatch from './utils/routes/no-match';
import LandingPage from './containers/Landing';
import LoginPage from './containers/Auth/Login';
import RegisterPage from './containers/Auth/Register';
import ForgotPasswordForm from './containers/Auth/ForgotPassword';
import ResetPasswordForm from './containers/Auth/ResetPassword';
import ActivateAccountForm from './containers/Auth/Activate';
import RegisterRolePage from './containers/Auth/Register/RegisterRole';
import GlobalContactList from "./components/Chat/globalContactList";
// import ProjectsList from './containers/Browse/Projects';
// import ProjectDetails from './containers/Browse/Project/projectDetails';
// import TaskDetails from './containers/Browse/Task/taskDetails';
// import ProjectCreate from './containers/Browse/Project/Create';
// import CreateTask from './containers/Browse/Task/createTask';
// import TasksList from './containers/Browse/Tasks';
// import FreelancersPage from './containers/Browse/Freelancers';
// import ClientsPage from './containers/Browse/Clients';
// import AuditorsPage from './containers/Browse/Auditors';
// import EducationalInstitutionsPage from './containers/Browse/Educational-Institutions';
// import DashboardPage from './containers/Dashboard';
// import MyWorkPage from './containers/MyWork';
// import MyProjectsPage from './containers/MyProjects';
// import MyInstitutionPage from "./containers/MyInstitution";
// import MyAuditsPage from './containers/MyAudits';
import ProfilePage from './containers/Profile';
import ProfileSetupPage from './containers/Profile/Setup';
// import ProfileSetupFinishedPage from './containers/Profile/Setup/finished';
// import PublicProfilePage from './containers/Profile/publicProfile';
// import GamificationPage from './containers/Profile/Gamification';
// import HelpPage from './containers/Help';
// import TermsPage from './containers/Miscellaneous/Legal/Terms';
// import PrivacyPolicyPage from './containers/Miscellaneous/Legal/PrivacyPolicy';
// import ContactPage from './containers/Contact';
// import ThemePage from './containers/Theme/index';
// import ErrorView from "./components/Common/GlobalErrorHandler/errorView";
// import AboutPage from './containers/About';
// import MessagesPage from './containers/Messages';
// import NotificationsHistoryPage from './containers/Notifications';
import SettingsPage from './containers/Settings/index';
import ResolutionCenterPage from './containers/Resolution-Center';
// import SkillsPage from './containers/Competencies';
// import SkillDetailsPage from './containers/Competencies/Competency';
// import MyWallet from './containers/Wallet';
// import DespositWithdraw from './containers/Wallet/deposit-witdraw';


import loadable from "@loadable/component";
// import loadable from  './utils/routes/custom-loadable';

// Browse - OK
const ProjectsList = loadable(() => import("./containers/Browse/Projects"));
const TasksList = loadable(() => import("./containers/Browse/Tasks"));
const FreelancersPage = loadable(() => import("./containers/Browse/Freelancers"));
const ClientsPage = loadable(() => import("./containers/Browse/Clients"));
const AuditorsPage = loadable(() => import("./containers/Browse/Auditors"));
const EducationalInstitutionsPage = loadable(() => import("./containers/Browse/Educational-Institutions"));

// Skills - OK
const SkillsPage = loadable(() => import("./containers/Competencies"));
const SkillDetailsPage = loadable(() => import("./containers/Competencies/Competency"));

// Project / Task - OK
const ProjectDetails = loadable(() => import("./containers/Browse/Project/projectDetails"));
const TaskDetails = loadable(() => import("./containers/Browse/Task/taskDetails"));
const ProjectCreate = loadable(() => import("./containers/Browse/Project/Create"));
const CreateTask = loadable(() => import("./containers/Browse/Task/createTask"));

// My work
const DashboardPage = loadable(() => import("./containers/Dashboard"));
const MyWorkPage = loadable(() => import("./containers/MyWork"));
const MyProjectsPage = loadable(() => import("./containers/MyProjects"));
const MyInstitutionPage = loadable(() => import("./containers/MyInstitution"));
const MyAuditsPage = loadable(() => import("./containers/MyAudits"));

// Profile
// const ProfilePage = loadable(() => import("./containers/Profile"));
// const ProfileSetupPage = loadable(() => import("./containers/Profile/Setup"));
const ProfileSetupFinishedPage = loadable(() => import("./containers/Profile/Setup/finished"));
const PublicProfilePage = loadable(() => import("./containers/Profile/publicProfile"));
const GamificationPage = loadable(() => import("./containers/Profile/Gamification"));

// Messaging
const MessagesPage = loadable(() => import("./containers/Messages"));
const NotificationsHistoryPage = loadable(() => import("./containers/Notifications"));
// const SettingsPage = loadable(() => import("./containers/Settings/index"));
// const ResolutionCenterPage = loadable(() => import("./containers/Resolution-Center"));

// Wallet
const MyWallet = loadable(() => import("./containers/Wallet"));
const DespositWithdraw = loadable(() => import("./containers/Wallet/deposit-witdraw"));

// Misc - OK
const HelpPage = loadable(() => import("./containers/Help")); // OK
const TermsPage = loadable(() => import("./containers/Miscellaneous/Legal/Terms")); // OK
const PrivacyPolicyPage = loadable(() => import("./containers/Miscellaneous/Legal/PrivacyPolicy")); // OK
const ContactPage = loadable(() => import("./containers/Contact")); // OK
const ThemePage = loadable(() => import("./containers/Theme/index")); // OK
const ErrorView = loadable(() => import("./components/Common/GlobalErrorHandler/errorView")); // OK
const AboutPage = loadable(() => import("./containers/About")); // OK



function Routes({ location, isAuthenticated, isInitialized, activeRole }) {
    return (
        <div className="wrapper">
            <TransitionGroup className="transition-group">
                <CSSTransition key={location.key} timeout={{ enter: 200, exit: 200 }} classNames="fade">
                    <section className="route-section">
                        <div style={{marginTop: '60px', marginBottom: '30px'}}>
                        {/* <ScrollToTop /> */}
                        <Switch location={location}>
                            <Route exact path="/" component={LandingPage} />
                            <Route exact path="/auth/login" component={LoginPage} />
                            <Route exact path="/auth/register" component={RegisterPage} />
                            <Route exact path="/auth/forgot-password" component={ForgotPasswordForm} />
                            <Route exact path="/auth/reset-password/:uid/:token" component={ResetPasswordForm} />
                            <Route exact path="/auth/activate-account/:token" component={ActivateAccountForm} />
                            <PrivateRoute exact path="/auth/register-role" component={RegisterRolePage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />

                            <Route path="/browse/projects" component={props => <ProjectsList {...props} />} />
                            <Route path="/browse/tasks" component={props => <TasksList {...props} />} />
                            <Route path="/browse/freelancers" component={props => <FreelancersPage {...props} />} />
                            <Route path="/browse/clients" component={props => <ClientsPage {...props} />} />
                            <Route path="/browse/auditors" component={props => <AuditorsPage {...props} />} />
                            <Route path="/browse/educational-institutions" component={props => <EducationalInstitutionsPage {...props} />} />

                            <Route path="/skills" component={props => <SkillsPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />
                            <Route path="/skill/:uid" component={props => <SkillDetailsPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />

                            <PrivateRoute path="/projects/:uid/:tab?" component={props => <ProjectDetails {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/project/:projectId/task/:uid" component={props => <TaskDetails {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/project/new" component={props => <ProjectCreate {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />
                            <PrivateRoute path="/project-task/new/:projectId" component={props => <CreateTask {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />

                            <PrivateRoute path="/dashboard" component={props => <DashboardPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <PrivateRoute path="/my-work/:uid?/:tabKey?" component={props => <MyWorkPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="DEV" activeRole={activeRole} />
                            
                            <PrivateRoute path="/my-projects/:uid?/:taskid?/:tabKey?" component={props => <MyProjectsPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />
                            <PrivateRoute path="/my-projects" component={props => <MyProjectsPage {...props} />}  isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />

                            <PrivateRoute path="/my-institution" component={props => <MyInstitutionPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="EDU" activeRole={activeRole} />
                            <PrivateRoute path="/my-audits/:uid?" component={props => <MyAuditsPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="AUD" activeRole={activeRole} />

                            <PrivateRoute exact path="/profile" component={ProfilePage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            {/* <PrivateRoute exact path="/profile" component={props => <ProfilePage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            <PrivateRoute path="/profile-setup/:step" component={ProfileSetupPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            {/* <PrivateRoute path="/profile-setup/:step" component={props => <ProfileSetupPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            <PrivateRoute path="/profile-setup-finished" component={props => <ProfileSetupFinishedPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute exact path="/profile/gamification" component={props => <GamificationPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute exact path="/user/:id" component={props => <PublicProfilePage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute exact path="/user/:id/gamification" component={props => <GamificationPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            
                            <PrivateRoute path="/messages" component={props => <MessagesPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/notification-history" component={props => <NotificationsHistoryPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/settings/:area?" component={SettingsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            {/* <PrivateRoute path="/settings/:area?" component={props => <SettingsPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            {/* <PrivateRoute path="/resolution-center"component={props => <ResolutionCenterPage {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            <PrivateRoute path="/resolution-center"component={ResolutionCenterPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            {/* <PrivateRoute path="/wallet/:uid?/:walletid?" component={MyWallet}  isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            <PrivateRoute path="/wallet/:uid?/:walletid?" component={props => <MyWallet {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            {/* <PrivateRoute path="/deposit-withdraw/:uid?/:walletid?" component={DespositWithdraw} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} /> */}
                            <PrivateRoute path="/deposit-withdraw/:uid?/:walletid?" component={props => <DespositWithdraw {...props} />} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            
                            <Route exact path="/help/:activeTab?" component={props => <HelpPage {...props} />} />
                            <Route path="/legal/terms-and-conditions" component={props => <TermsPage {...props} />} />
                            <Route path="/legal/privacy-policy" component={props => <PrivacyPolicyPage {...props} />} />
                            <Route path="/contact" component={props => <ContactPage {...props} />} />
                            <Route path="/theme" component={props => <ThemePage {...props} />} />
                            <Route path="/ooops" component={props => <ErrorView {...props} />} />
                            <Route path="/about" component={props => <AboutPage {...props} />} />
                            <Route component={NoMatch} />

                        </Switch>
                        </div>
                        {isAuthenticated && <GlobalContactList />}
                        <Footer />
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

export default withRouter(Routes);

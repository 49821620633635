import { CREATE_TASK_SUCCESS, COMPLETE_SUBMIT_TASK_SUCCESS, UPDATE_TASK_SUCCESS } from '../../../actions/tasks/types'
import { CREATE_TASK_PROPOSAL_SUCCESS, UPDATE_TASK_PROPOSAL_SUCCESS } from '../../../actions/taskProposals/types'
import {
    GET_MY_MILESTONES_SUCCESS, CREATE_MILESTONE_SUCCESS, UPDATE_MILESTONE_SUCCESS,
    SELECT_MY_MILESTONE_SUCCESS, DELETE_MILESTONE_SUCCESS
} from './../../../actions/milestone/types';
import { UPDATE_TASK_DISPUTE_CASES_SUCCESS } from '../../../actions/taskDisputeCases/types';
const INITIAL_STATE = {
    list: [],
    // selected: {
    //     id: null,
    //     tasks: [],
    //     name: '',
    //     short_description: '',
    //     description: '',
    //     color: null,
    //     project: null
    // },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_MY_MILESTONES_SUCCESS:
            return { ...state, list: action.payload.list }
        case CREATE_MILESTONE_SUCCESS:
            return {
                // ...state, selected: INITIAL_STATE.selected,
                ...state,
                list: state.list.concat(action.payload.milestone)
            }
        case UPDATE_MILESTONE_SUCCESS:
            return {
                // ...state, selected: INITIAL_STATE.selected,
                ...state,
                list: state.list.map(milestone =>
                    milestone.id === action.payload.milestone?.id ?
                        { ...milestone, ...action.payload.milestone } : milestone)
            }
        case DELETE_MILESTONE_SUCCESS:
            return {
                // ...state, selected: INITIAL_STATE.selected,
                ...state,
                list: state.list.filter((milestone) => milestone.id !== action.payload.deleted)
            }
        case SELECT_MY_MILESTONE_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case CREATE_TASK_SUCCESS:
            const ct = action.payload.selected
            return {
                // ...state, selected: {
                //     ...state.selected, tasks: (state.selected.id === null && ct.milestone === null) || state.selected.id === ct.milestone?.id ?
                //         state.selected?.tasks.concat(action.payload.selected) : state.selected
                // },
                ...state,
                list: state.list?.map(mile =>
                    (mile.id === null && ct.milestone === null) || mile.id === ct.milestone?.id ?
                        { ...mile, tasks: mile.tasks.concat(ct) } : mile)

            }
        case UPDATE_TASK_SUCCESS:
            const ut = action.payload.selected
            return {
                // ...state, selected: {
                //     ...state.selected, tasks: state.selected?.tasks.map(task =>
                //         task.id === ut.id ?
                //             { ...task, ...ut } : task
                //     )
                // },
                ...state,
                list: state.list.map(mile => {
                    if (mile.tasks.some(task => task.id === ut.id)) {
                        var oldTask = mile.tasks.find(task => task.id === ut.id);
                        if (oldTask.milestone?.id !== ut.milestone?.id) {
                            return { ...mile, tasks: mile.tasks.filter(task => task.id !== oldTask.id) }
                        } else {
                            return {
                                ...mile, tasks: mile.tasks.map(task => task.id === ut.id ?
                                    { ...task, ...ut } : task)
                            }
                        }
                    } else {
                        if ((mile.id === null && ut.milestone === null) || mile.id === ut.milestone?.id) {
                            return { ...mile, tasks: mile.tasks.concat(ut) }
                        } else {
                            return mile;
                        }
                    }
                })
            }
        case COMPLETE_SUBMIT_TASK_SUCCESS:
            const cst = action.payload.selected;
            return {
                // ...state, selected: {
                //     ...state.selected, tasks: state.selected?.tasks.map(task =>
                //         task.id === cst.id ?
                //             { ...task, ...cst } : task
                //     )
                // },
                ...state,
                list: state.list.map(mile =>
                    (mile.id === null && cst.milestone === null) || mile.id === cst.milestone?.id ?
                        {
                            ...mile, tasks: mile.tasks.map(task => task.id === cst.id ?
                                { ...task, ...cst } : task)
                        } : mile)
            }
        case CREATE_TASK_PROPOSAL_SUCCESS:
            const taskProp = action.payload.selected;
            return {
                // ...state, selected: {
                //     ...state.selected, tasks: state.selected?.tasks.map(task =>
                //         task.id === taskProp.task.id ? { ...task, number_of_proposals: task.number_of_proposals + 1, status: taskProp.task.status } : task
                //     )
                // },
                ...state,
                list: state.list?.map(mile =>
                    mile.tasks && mile.tasks.some(task => task.id === taskProp.task.id) ?
                        {
                            ...mile, tasks: mile.tasks.map(task => task.id === taskProp.task.id ?
                                { ...task, number_of_proposals: task.number_of_proposals + 1, status: taskProp.task.status } : task)
                        }
                        : mile)
            };
        case UPDATE_TASK_PROPOSAL_SUCCESS:
            const myWork = action.payload.onMyWork;
            const tp = action.payload.selected;
            return myWork ? state : {
                // ...state, selected: {
                //     ...state.selected, tasks: state.selected?.tasks.map(task =>
                //         task.id === tp.task.id ? { ...task, status: tp.task.status } : task
                //     ),

                // },
                ...state,
                list: state.list?.map(mile =>
                    mile.tasks && mile.tasks.some(task => task.id === tp.task.id) ?
                        {
                            ...mile, tasks: mile?.tasks?.map(task => task.id === tp.task.id ?
                                { ...task, status: tp.task.status } : task)
                        }
                        : mile)
            };
        case UPDATE_TASK_DISPUTE_CASES_SUCCESS:
            const myAudits = action.payload.onMyAudits;
            const td = action.payload.selected;
            return myAudits ? state : {
                ...state,
                list: state.list?.map(mile =>
                    mile.tasks && mile.tasks.some(task => task.id === td.task.id) ?
                        {
                            ...mile, tasks: mile?.tasks?.map(task => task.id === td.task.id ?
                                { ...task, status: td.task.status } : task)
                        }
                        : mile)
            };
        default:
            return state;
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/styles/main.scss';
import '../src/assets/styles/styles.scss';
import App from './App';
import store from './core/store';
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd';
import * as serviceWorker from './serviceWorker';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import { GetLocationStart } from './core/actions/location'
import { FetchUserFromLocalStorage } from './core/actions/auth';
import { StartCryptoFeed } from './core/actions/crypto';
import ErrorBoundary from './components/Common/GlobalErrorHandler';

// store.dispatch(GetLocationStart());
store.dispatch(FetchUserFromLocalStorage());
store.dispatch(StartCryptoFeed());

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </DndProvider>
    </Provider>
    ,
    document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

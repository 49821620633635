import { combineReducers } from "redux";
import userInfoReducer from './userInfoReducer';
// import userLocationReducer from './userLocationReducer';
import authInfoReducer from './authInfoReducer';
import googleAuthReducer from './googleAuthReducer';
import userSubRoleReducer from "./userInfoReducer/userSubRoleReducer";
import userGamificationReducer from './userGamificationReducer';
import userWalletReducer from "./userWalletReducer";
import statsReducer from '../user/userInfoReducer/userStatsReducer';

export default combineReducers({
    auth: authInfoReducer,
    google_auth: googleAuthReducer,
    info: userInfoReducer,
    subroles: userSubRoleReducer,
    gamification: userGamificationReducer, 
    wallet: userWalletReducer,
    roleStats: statsReducer,
    // location: userLocationReducer,
})

import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ResendActivationEmailStart } from './../../core/actions/auth/index';
import _ from 'lodash';


class InfoBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    checkGoogleAuth(socials) {
        let soc = false;
        if(_.isEmpty(socials)) {
            return;
        }
        socials.forEach(e => {
            if (e.provider === 'google') { soc = true }
        })
        return soc;
    }

    renderSubmittedTasksBar() {
        const { role } = this.props;
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon2">
                        <Image src="/assets/img/svg/complete_your_profile.svg"></Image>
                    </div>

                    <div className="infobar-content">
                        {role.type === "DEV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.get_hired" defaultMessage={`Get hired today!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.complete_profile" defaultMessage={`Complete your profile and increase your chances of getting hired.`} />
                                    </div>
                                }
                            </>
                        }
                        {role.type === "INV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_hiring" defaultMessage={`Start hiring today!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.complete_profile_2" defaultMessage={`Please complete your profile before you start hiring.`} />
                                    </div>
                                }
                            </>
                        }
                        {(role.type === "EDU" || role.type === "EAS" || role.type === "EAP" || role.type === "EAC") &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_edu" defaultMessage={`Complete profile!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.edu_complete" defaultMessage={`Please complete your profile.`} />
                                    </div>
                                }
                            </>
                        }
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/profile-setup/1')} >
                            <FormattedMessage id="infobar.setup_profile" defaultMessage={`Profile setup.`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderGoogleLoginBar() {
        /* if (role.type !== 'DEV') return null; */
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon">
                        <Image src="/assets/img/icon/email-white.png"></Image>
                    </div>

                    <div className="infobar-content">
                        <div>
                            <FormattedMessage id="infobar.add_google" defaultMessage="Add Google account" />
                        </div>
                        {window.innerWidth > 768 &&
                            <div>
                                <FormattedMessage id="infobar.add_google_to_enable" defaultMessage="Please connect your Google account to enable Blockchain skills" />
                            </div>
                        }
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/settings/account')}>
                            <FormattedMessage id="infobar.connect" defaultMessage="Connect" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderActivateEmailBar() {
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon">
                        <Image src="/assets/img/icon/email-white.png"></Image>
                    </div>

                    <div className="infobar-content">
                        <div>
                            <FormattedMessage id="browse.project.verify_email_short" defaultMessage={`Verify email`} />
                        </div>
                        {window.innerWidth > 768 &&
                            <div>
                                <FormattedMessage id="browse.project.verify_email" defaultMessage="Please verify your email to start working with the platform." />
                            </div>
                        }
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => this.props.ResendActivationEmailStart()} >
                            <FormattedMessage id="browse.project.re-send_email" defaultMessage={`Re-send activation email`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSetupProfileBar() {
        if (this.props.completed > 70) return null;
        const { role } = this.props;
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon2">
                        <Image src="/assets/img/svg/complete_your_profile.svg"></Image>
                    </div>

                    <div className="infobar-content">
                        {role.type === "DEV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.get_hired" defaultMessage={`Get hired today!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.complete_profile" defaultMessage={`Complete your profile and increase your chances of getting hired.`} />
                                    </div>
                                }
                            </>
                        }
                        {role.type === "INV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_hiring" defaultMessage={`Start hiring today!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.complete_profile_2" defaultMessage={`Please complete your profile before you start hiring.`} />
                                    </div>
                                }
                            </>
                        }
                        {(role.type === "EDU" || role.type === "EAS" || role.type === "EAP" || role.type === "EAC") &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_edu" defaultMessage={`Complete profile!`} />
                                </div>
                                {window.innerWidth > 768 &&
                                    <div>
                                        <FormattedMessage id="infobar.edu_complete" defaultMessage={`Please complete your profile.`} />
                                    </div>
                                }
                            </>
                        }
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/profile-setup/1')} >
                            <FormattedMessage id="infobar.setup_profile" defaultMessage={`Profile setup.`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderRegisterRole() {
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon2">
                        <Image src="/assets/img/svg/complete_your_profile.svg"></Image>
                    </div>

                    <div className="infobar-content">
                        <div>
                            <FormattedMessage id="roles.no_active_role_yet" defaultMessage="No active role yet?" />
                        </div>
                        <div>
                            <FormattedMessage id="roles.please_register_role" defaultMessage="Please register a role, that you will be using." />
                        </div>
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/auth/register-role')} >
                            <FormattedMessage id="forms.registerRoleForm" defaultMessage="Register role" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    checkForGoogleBar() {
        const { role } = this.props;
        let show = false;
        if(role?.profile_completion?.availability && role?.profile_completion?.competences && role?.profile_completion?.description && role?.profile_completion?.education && role.profile_completion?.email_verification && role.profile_completion?.experience && role.profile_completion?.personal_info) {
            show = true;
        }

        return show;
    }

    render() {
        const { user, auth: { isAuthenticated }, hasRole } = this.props;

        if (!isAuthenticated) return null;

        if (!user.isEmailVerified) {
            if (!hasRole) {
                return this.renderRegisterRole();
            } else {
                return this.renderActivateEmailBar();
            }
        }
        if (!this.checkGoogleAuth(this.props.socials) && user.isEmailVerified && this.checkForGoogleBar() /* role?.profile_completion?.quality === 100 */) {
            return this.renderGoogleLoginBar();
        }
        else {
            if (hasRole) {
                return this.renderSetupProfileBar();
            } else {
                return this.renderRegisterRole();
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        user: state.user.info,
        role: state.user.info.settings.active_role_obj,
        hasRole: state.user.info.settings.active_role,
        completed: state.user.info?.settings?.active_role_obj?.profile_completion?.quality,
        socials: state.user.info?.socials?.results
    }
}

export default connect(mapStateToProps, { ResendActivationEmailStart })(InfoBar);
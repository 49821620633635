import React from 'react';
import { Link } from 'react-router-dom';

const SectionResolutionServiceInformation = props => {
    // const { item } = props;

    return (
        <>
            <h1>Dispute Resolution Service</h1>
            <div className="body-small-text mb-3">The Dispute Resolution Service is offered by Ecta.io to clients who wish to contest the task execution and payment.</div>
            <div className="body-small-text mb-3">If an issue arises, you have the option to propose a resolution or file a dispute.</div>
            <div className="body-small-text mb-3">By entering the dispute resolution process you should be aware of the following:</div>
            <ul className="body-small-text mb-4" style={{ 'fontSize': '12px' }}>
                {/* <li>the Platform will send out invitations to registered and competent users to become the auditors in this case</li> */}
                <li>We will act as an auditor in the dispute case</li>
                <li>the Freelancer will be notified that the task completion is pending and is waiting for auditor to decide in the case</li>
                <li>Auditor have 48 hours to accept the case and then 7 days to decide in the case</li>
                <li>Auditor can decide in either sides favour or decide about a partially executed task and partial payment</li>
                {/* <li>Auditor are anonymous</li> */}
                <li>Auditor decision is final</li>
                <li>Auditor are compensated for the work with 5% of the budget of the Task in question or minimum 5$</li>
                <li>In the future, the platform will onboard qualified auditors and invite them to participate in the process</li>
            </ul>
            <div className="body-small-text mb-4">You can get more information about the Dispute Resolution Service in the <Link to="/help" target="_blank">FAQ section.</Link></div>
        </>
    )
}

export default SectionResolutionServiceInformation
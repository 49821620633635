import * as types from './types';
import { apiCon } from '../../../API';
import { getParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS as INITIAL_PACKAGES_FILTERS } from '../../reducers/user/userGamificationReducer/gamificationPackagesReducer';
import { getUrlParameterObject } from '../../../utils/common';
import { GetTaskFundingEstimateFail } from '../taskProposals';

const ALLOWED_PACKAGES_FILTERS = ['limit', 'search', 'ordering'];

//-------------------------------------
// Actions Change packages List Filters 
//-------------------------------------
export const ChangeGamificationPackagesFiltersStart = (params = INITIAL_PACKAGES_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_PACKAGES_FILTERS);
    const payload = { succ_msg: "Successfully changed packages filters.", filters: filters }
    return { type: types.CHANGE_PACKAGES_FILTERS, payload: payload };
}

//------------------------
// Actions Get My Packages
//------------------------
export const GetMyGamificationPackages = (params = INITIAL_PACKAGES_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_GAMIFICATION_PACKAGES_REQUEST });
    const filters = getUrlParameterObject(params, ALLOWED_PACKAGES_FILTERS);
    await apiCon.get('/blockchain/chips/', { params: filters })
        .then(response => {
            dispatch(GetMyGamificationPackagesSuccess(response.data))
        })
        .catch(err => {
            dispatch(GetMyGamificationPackagesFail(err.response));
        })
}

export const GetMyGamificationPackagesChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_GAMIFICATION_PACKAGES_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyGamificationPackagesSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetMyGamificationPackagesFail(err.response));
        })
}

export const GetMyGamificationPackagesSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received my Gamification packages info.", list: data, add: add }
    return { type: types.GET_GAMIFICATION_PACKAGES_SUCCESS, payload: payload };
}

export const GetMyGamificationPackagesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get ny Gamification packages failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Gamification packages failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_GAMIFICATION_PACKAGES_FAILURE, payload: payload };
}

export const GetPackageCostEstimateStart = () => async dispatch => {
    dispatch({ type: types.GET_PACKAGE_COST_ESTIMATE_REQUEST });
    await apiCon.post('/blockchain/chips/estimation/210')
        .then(response => {
            dispatch(GetPackageCostEstimateSuccess(response.data))
        })
        .catch(err => {
            dispatch(GetTaskFundingEstimateFail(err.response));
        })
}

export const GetPackageCostEstimateSuccess = (data) => {
    const payload = { succ_msg: "Successfuly got chips costs.", estimation: data }
    return { type: types.GET_PACKAGE_COST_ESTIMATE_SUCCESS, payload: payload };
}

export const GetTaskFundingEstimateFailed = (err) => {
    const err_msg = { errorType: err.status, detail: err.statusText };
    var toast_msg = "Ooops! Get chips estimated cost failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get chips estimated cost failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_GAMIFICATION_PACKAGES_FAILURE, payload: payload }
}
//MyTasks
export const GET_MY_TASK_PROPOSALS_REQUEST = "GET_MY_TASK_PROPOSALS_REQUEST";
export const GET_MY_TASK_PROPOSALS_SUCCESS = "GET_MY_TASK_PROPOSALS_SUCCESS";
export const GET_MY_TASK_PROPOSALS_FAILURE = "GET_MY_TASK_PROPOSALS_FAILURE";

export const SELECT_MY_TASK_PROPOSAL_REQUEST = "SELECT_MY_TASK_PROPOSAL_REQUEST";
export const SELECT_MY_TASK_PROPOSAL_SUCCESS = "SELECT_MY_TASK_PROPOSAL_SUCCESS";
export const SELECT_MY_TASK_PROPOSAL_FAILURE = "SELECT_MY_TASK_PROPOSAL_FAILURE";

export const DESELECT_MY_TASK_PROPOSAL = 'DESELECT_MY_TASK_PROPOSAL';

export const CREATE_TASK_PROPOSAL_REQUEST = "CREATE_TASK_PROPOSAL_REQUEST";
export const CREATE_TASK_PROPOSAL_SUCCESS = "CREATE_TASK_PROPOSAL_SUCCESS";
export const CREATE_TASK_PROPOSAL_FAILURE = "CREATE_TASK_PROPOSAL_FAILURE";

export const UPDATE_TASK_PROPOSAL_REQUEST = "UPDATE_TASK_PROPOSAL_REQUEST";
export const UPDATE_TASK_PROPOSAL_SUCCESS = "UPDATE_TASK_PROPOSAL_SUCCESS";
export const UPDATE_TASK_PROPOSAL_FAILURE = "UPDATE_TASK_PROPOSAL_FAILURE";

export const GET_TASK_FUNDING_ESTIMATE_REQUEST = "GET_TASK_FUNDING_ESTIMATE_REQUEST";
export const GET_TASK_FUNDING_ESTIMATE_SUCCESS = "GET_TASK_FUNDING_ESTIMATE_SUCCESS";
export const GET_TASK_FUNDING_ESTIMATE_FAILURE = "GET_TASK_FUNDING_ESTIMATE_FAILURE";
import './index.scss';
import React from 'react';
import history from '../../utils/routes/history';
import UserMenu from './userMenu';
import DropdownUserMenu from './DropdownUserMenu';
import DropdownNotifications from './DropdownNotifications';
import { connect } from 'react-redux';
import { LogoutStart } from '../../core/actions/auth';
import { ChangeRoleStart } from '../../core/actions/role';
import { Nav, Navbar, Image, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Container } from 'react-bootstrap';
import OutsideAlerter from "./OutsideAlerter";
import ModalResendActivationEmail from '../Common/ModalResendActivationEmail/modalResendActivationEmail';
import logo from '../../assets/img/WorknSKILL_Logo.svg';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';
import { langService } from '../../utils/languageService';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            landing: false,
            menuOpened: false,
            modalVerifyEmailVisible: false
        };
    }

    changeHeaderOnHamburgerClick() {
        this.setState({ menuOpened: this.state.menuOpened ? false : true });
    }

    openMyPage(url) {
        const { isEmailVerified } = this.props;
        if (isEmailVerified) {
            this.setState({ menuOpened: false })
            history.push(url);
        } else {
            this.setState({ menuOpened: false })
            this.setState({ modalVerifyEmailVisible: true });
        }
    }

    isSubrole(t) {
        return ['EAP', 'EAS', 'EAC'].includes(t);
    }

    handleItemClick = (link) => {
        this.setState({ menuOpened: false });
        history.push(link);
    }

    handleLogoutFromChild = () => {
        this.setState({ menuOpened: false })
        this.props.LogoutStart();
    }

    handleChangeRoleClick = (roleId) => {
        this.setState({ menuOpened: false });
        this.props.ChangeRoleStart(roleId)
    }

    getAddRoleName = () => {
        const res = this.checkRoles();
        if (res === 'dev') {
            return langService.messages['roles.become_freelancer'];
        } else if (res === 'inv') {
            return langService.messages['roles.become_client'];
        } else {
            return langService.messages['roles.add_role'];
        }
    }

    checkRoles = () => {
        if (this.props.roles.some(e => e.type === 'INV') && !this.props.roles.some(e => e.type === 'DEV')) {
            return 'dev';
        } else if (!this.props.roles.some(e => e.type === 'INV') && this.props.roles.some(e => e.type === 'DEV')) {
            return 'inv';
        } else {
            return 'edu';
        }
    }

    renderPublicItems() {
        const hidden = false;
        return (
            <>
                <Nav className={hidden ? "hidden" : "mr-auto"}>
                    <Nav.Link onClick={() => this.handleItemClick("/browse/projects")} className="nav-link">
                        <span className="icon-search"></span>
                        <FormattedMessage id="nav.labels.browse" defaultMessage="Browse" />
                    </Nav.Link>
                    <Nav.Link onClick={() => this.handleItemClick("/skills")} className="nav-link">
                        <span className="icon-competence"></span>
                        {/* <FormattedMessage id="projects.competences" defaultMessage="Skills" /> */}
                        <FormattedMessage id="nav.labels.competencies" defaultMessage="Skills" />
                    </Nav.Link>
                    <Nav.Link onClick={() => this.handleItemClick("/help")} className="nav-link">
                        {/* <span className="icon-information"></span> */}
                        <FormattedMessage id="nav.labels.howitworks" defaultMessage="How it works" />
                    </Nav.Link>
                </Nav>

                <Nav className={hidden ? "hidden" : "ml-auto"}>
                    <Nav.Link onClick={() => this.handleItemClick("/auth/login")} className="nav-link">
                        <FormattedMessage id="nav.labels.signin" defaultMessage="Sign in" />
                    </Nav.Link>
                    <div className="separator-wrapper d-none d-lg-block">
                        <div className="header-separator"></div>
                    </div>
                    {/* <Nav.Link onClick={() => history.push("/auth/register")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                        <FormattedMessage id="nav.labels.register" defaultMessage="Register" />
                    </Nav.Link> */}
                    <div className="btn-wrapper">
                        <div className="btn btn-header" onClick={() => this.handleItemClick("/auth/login")} >
                            <FormattedMessage id="nav.labels.postaproject" defaultMessage="Post a project" />
                        </div>
                    </div>
                </Nav>
            </>
        )
    }

    renderAuthItems() {
        const hidden = false;
        const { hasRole } = this.props;
        const type = this.props.role ? this.props.role.type : undefined;
        return (
            <>
                <Nav className={hidden ? "hidden" : "mr-auto"}>

                    {/* <Nav.Link onClick={() => this.handleItemClick(`/browse/${type === 'DEV' ? 'tasks' : 'projects'}`)} className="nav-link"> */}
                    <Nav.Link onClick={() => this.handleItemClick('/browse/projects')} className="nav-link">
                        <span className="icon-search"></span>
                        <FormattedMessage id="nav.labels.browse" defaultMessage="Browse" />
                    </Nav.Link>

                    <Nav.Link onClick={() => this.handleItemClick("/skills")} className="nav-link">
                        <span className="icon-competence"></span>
                        <FormattedMessage id="nav.labels.competencies" defaultMessage="Skills" />
                    </Nav.Link>

                    {(hasRole && !this.isSubrole(type)) &&
                        <Nav.Link onClick={() => this.handleItemClick("/dashboard")} className="nav-link">
                            <span className="icon-dashboard"></span>
                            <FormattedMessage id="nav.labels.dashboard" defaultMessage="Dashboard" />
                        </Nav.Link>
                    }

                    {type && this.renderMyWorkNavItem(type, false)}

                    {this.state.matches && <UserMenu handleItemClick={(x) => this.handleItemClick(x)} handleRoleChange={(x) => this.handleChangeRoleClick(x)} handleLogoutClick={() => this.handleLogoutFromChild()} />}

                </Nav>

                <Nav className={hidden ? "hidden" : "ml-auto"}>

                    {/* Notifications */}
                    {hasRole && <DropdownNotifications />}

                    {/* User menu */}
                    {!this.state.matches && <DropdownUserMenu />}

                </Nav>
                <ModalResendActivationEmail visible={this.state.modalVerifyEmailVisible} onClose={() => this.setState({ modalVerifyEmailVisible: false })} />
            </>
        )
    }

    renderMyWorkNavItem(role, mobile) {
        switch (role) {
            case "DEV":
                return (
                    <Nav.Link onClick={() => this.openMyPage("/my-work")} className={mobile ? "nav-link oc-link pl-0" : "nav-link"}>
                        <span className="icon-projects"></span>
                        <FormattedMessage id="nav.labels.myWork" defaultMessage="My work" />
                    </Nav.Link>
                );
            case "INV":
                return (
                    <Nav.Link onClick={() => this.openMyPage("/my-projects")} className={mobile ? "nav-link oc-link pl-0" : "nav-link"}>
                        <span className="icon-projects"></span>
                        <FormattedMessage id="nav.labels.myProjects" defaultMessage="My projects" />
                    </Nav.Link>
                );
            case "AUD":
                return (
                    <Nav.Link onClick={() => this.openMyPage("/my-audits")} className={mobile ? "nav-link oc-link pl-0" : "nav-link"}>
                        <span className="icon-projects"></span>
                        <FormattedMessage id="nav.labels.myAudits" defaultMessage="My Audits" />
                    </Nav.Link>
                );
            case "EDU":
            case "EAS":
            case "EAP":
                return (
                    <Nav.Link onClick={() => this.openMyPage("/my-institution")} className={mobile ? "nav-link oc-link pl-0" : "nav-link"}>
                        <span className="icon-projects"></span>
                        <FormattedMessage id="nav.labels.myInstitution" defaultMessage="My Institution" />
                    </Nav.Link>
                );
            default:
                return null;
        }
    }

    renderMobileNav() {
        const { user: { settings, roles }, auth: { isAuthenticated }, counter, wallet: { balances }, chips } = this.props;
        return (
            <OutsideAlerter onOutsideClick={() => this.setState({ menuOpened: false })}>
                <nav id="wns-nav-mobile" className="navbar navbar-expand-lg fixed-top" ref={this.boxRef}>

                    <div className="mobile-navbar">

                        <button className="navbar-toggler navbar-light p-0 border-0" type="button" data-toggle="offcanvas" onClick={() => this.changeHeaderOnHamburgerClick()}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <Image src="/assets/img/WorknSKILL_Logo.svg" onClick={() => this.handleItemClick("/")} height="40" className="d-inline-block align-top" alt="Work 'n Skill logo" />

                        {isAuthenticated
                            ?
                            <div className="mr-1" onClick={() => this.handleItemClick("/notification-history")}>
                                <span className="icon-nitifications"></span>
                                {counter > 0 && <span className="badge badge-pill badge-danger nav-badge2">{counter}</span>}
                            </div>
                            : <div className="xs-join" onClick={() => this.handleItemClick("/auth/register")}>Join</div>
                        }

                    </div>

                    <div className={this.state.menuOpened ? "navbar-collapse offcanvas-collapse open" : "navbar-collapse offcanvas-collapse"}>

                        <div className="offcanvas-close" onClick={() => this.changeHeaderOnHamburgerClick()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>

                        {isAuthenticated
                            ?
                            <>
                                <div style={{ 'display': 'flex', alignItems: 'center' }}>
                                    {settings?.active_role_obj?.profile_image
                                        ? <Image className="dd-role-withimg-active" src={settings.active_role_obj.profile_image} alt="Active profile thumb" />
                                        : <Image className="dd-role-withimg-active" src="/assets/img/default-avatar.png" alt="Active profile thumb" />
                                    }
                                    <div className="ml-2">
                                        <div className="offcanvas-username">{settings?.active_role_obj?.name}</div>
                                        <div className="offcanvas-role"><FormattedMessage id={"roles.name." + settings.active_role_obj?.type} defaultMessage="Developer" /></div>
                                    </div>
                                </div>

                                {/* Roles */}
                                {(settings?.active_role_obj?.type !== 'EAS' && settings?.active_role_obj?.type !== 'EAP' && settings?.active_role_obj?.type !== 'EAC') &&
                                    <>
                                        <Dropdown.Divider />
                                        <div className="dd-roles">
                                            <button className="btn btn-default btn-developers btn-block pr-0 pl-0 pt-1 pb-1 mt-2 mb-2" onClick={() => this.handleItemClick("/profile")}>
                                                <FormattedMessage id="DropdownUserMenu.myprofile" defaultMessage="My Profile" />
                                            </button>
                                            {
                                                roles?.filter(role => role.id !== settings?.active_role)?.map((role) =>
                                                    <div onClick={() => this.handleChangeRoleClick(role.id)} className="dd-role" style={{ 'display': 'flex', alignItems: 'center' }} key={role.id}>
                                                        {role.profile_image
                                                            ?
                                                            <img className="dd-role-withimg" src={role.profile_image} alt="Role thumb"></img>
                                                            :
                                                            <span className={`dd-${role?.type?.toLowerCase()}-img`}>{role.name.charAt(0).toUpperCase()}</span>
                                                        }
                                                        <div>
                                                            <div className="offcanvas-username">{role.name}</div>
                                                            <div className="offcanvas-role"><FormattedMessage id={"roles.name." + role?.type} defaultMessage="Developer" /></div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                roles?.length < 4 &&
                                                <button className="btn btn-default btn-generic btn-block btn-become pt-1 pb-1" onClick={() => this.handleItemClick("/auth/register-role")}>{this.getAddRoleName()}</button>
                                                
                                            }
                                        </div>
                                        <Dropdown.Divider />
                                    </>
                                }

                                <ul className="navbar-nav mr-auto pt-2">

                                    <li className="nav-item active">
                                        <Nav.Link onClick={() => this.handleItemClick(`/browse/${settings?.active_role_obj?.type === 'DEV' ? 'tasks' : 'projects'}`)} className="oc-link pl-0">
                                            <span className="icon-search"></span>
                                            <FormattedMessage id="nav.labels.browse" defaultMessage="Browse" />
                                        </Nav.Link>
                                    </li>

                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/skills")} className="nav-link oc-link pl-0">
                                            <span className="icon-competence" />
                                            <FormattedMessage id="common.skills" defaultMessage="Skills" />
                                        </Nav.Link>
                                    </li>

                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/dashboard")} className="nav-link oc-link pl-0">
                                            <span className="icon-dashboard"></span>
                                            <FormattedMessage id="nav.labels.dashboard" defaultMessage="Dashboard" />
                                        </Nav.Link>
                                    </li>

                                    <li className="nav-item">
                                        {this.renderMyWorkNavItem(settings?.active_role_obj?.type, true)}
                                    </li>

                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/messages")} className="nav-link oc-link pl-0">
                                            <span className="icon-email"></span>
                                            <FormattedMessage id="messages.messages" defaultMessage="Messages" />
                                        </Nav.Link>
                                    </li>

                                </ul>

                                <Dropdown.Divider />

                                {/* WALLET */}
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/wallet")} className="nav-link oc-link pl-0">
                                            <FormattedMessage id="common.wallet" defaultMessage="Wallet" />
                                        </Nav.Link>
                                    </li>
                                </ul>
                                <div className="pl-2" style={{ fontSize: '14px' }} onClick={() => this.handleItemClick("/wallet")} >
                                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'ethereum.png' : 'bnb.png')} className="crypto-logo mr-2" />
                                        <span>
                                            {Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 6 })}{" " + REACT_APP_NETWORK_CURRENCY()}
                                        </span>
                                    </div>
                                    <div className="mt-2" style={{ 'display': 'flex', alignItems: 'center' }}>
                                        <Image src="/assets/img/dai.png" className="crypto-logo  mr-2" />
                                        <span>
                                            {Number(parseFloat(balances?.DAI)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}{" DAI"}
                                        </span>
                                    </div>
                                    <div className="mt-2" style={{ 'display': 'flex', alignItems: 'center' }}>
                                        <Image src="/assets/img/icon/chip.svg" className="crypto-logo  mr-2" />
                                        <span>
                                            {Number(parseFloat(chips)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}{" CHIPs"}
                                        </span>
                                    </div>
                                </div>

                                <Dropdown.Divider />

                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/settings")} className="nav-link oc-link pl-0">
                                            <FormattedMessage id="common.settings" defaultMessage="Settings" />
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/help")} className="nav-link oc-link pl-0">
                                            <FormattedMessage id="common.help" defaultMessage="Help" />
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.props.LogoutStart()} className="nav-link oc-link pl-0">
                                            <FormattedMessage id="nav.labels.logout" defaultMessage="logout" />
                                        </Nav.Link>
                                    </li>
                                </ul>

                            </>
                            :
                            <>
                                <div className="btn btn-developers-inv" onClick={() => this.handleItemClick("/auth/register")} >
                                    <FormattedMessage id="nav.labels.register" defaultMessage="Register" />
                                </div>

                                <ul className="navbar-nav mr-auto pt-2">
                                    <li className="nav-item active">
                                        <Nav.Link onClick={() => this.handleItemClick("/browse/projects")} className="nav-link pl-0">
                                            <FormattedMessage id="browse.projects" defaultMessage="Projects" />
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-item active">
                                        <Nav.Link onClick={() => this.handleItemClick("/browse/tasks")} className="nav-link pl-0">
                                            <FormattedMessage id="browse.tasks" defaultMessage="Tasks" />
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/browse/freelancers")} className="nav-link pl-0">
                                            <FormattedMessage id="browse.developers" defaultMessage="Freelancers" />
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/skills")} className="nav-link pl-0">
                                            <FormattedMessage id="common.skills" defaultMessage="Skills" />
                                        </Nav.Link>
                                    </li>
                                </ul>

                                <Dropdown.Divider />

                                <ul className="navbar-nav mr-auto pt-2">
                                    <li className="nav-item">
                                        <Nav.Link onClick={() => this.handleItemClick("/auth/login")} className="nav-link pl-0">
                                            <FormattedMessage id="nav.labels.signin" defaultMessage="Sign in" />
                                        </Nav.Link>
                                    </li>
                                </ul>
                            </>
                        }
                    </div>
                </nav>
            </OutsideAlerter>
        )
    }

    render() {
        const hidden = false;
        const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                <Navbar id="wns-nav" sticky="top" expand="md" className="sticky" expanded={this.state.menuOpened}>
                    <Container>
                        <Navbar.Brand onClick={() => history.push("/")}>
                            <Image src={logo} height="40" className="d-inline-block align-top" alt="Work 'n Skill logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle id="hamburger" onClick={() => this.changeHeaderOnHamburgerClick()} aria-controls="responsive-navbar-nav" aria-expanded="false" aria-label="Toggle navigation" className={hidden ? "hidden" : "navbar-light"} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {!isAuthenticated ? this.renderPublicItems() : this.renderAuthItems()}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                {this.renderMobileNav()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        user: state.user.info,
        isEmailVerified: state.user.info.isEmailVerified,
        role: state.user.info.settings.active_role_obj,
        hasRole: state.user.info.settings.active_role,
        roles: state.user.info.roles,
        wallet: state.user.wallet,
        chips: state.user.gamification.credits,
        counter: state.notification.notifications.unseen_notifications
    }
}

export default connect(mapStateToProps, {
    ChangeRoleStart,
    LogoutStart
})(Header);
export const GET_BALANCES_REQUEST = "GET_BALANCES_REQUEST";
export const GET_BALANCES_SUCCESS = "GET_BALANCES_SUCCESS";
export const GET_BALANCES_FAIL = "GET_BALANCES_FAIL";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const TRANSFER_MONEY_REQUEST = "TRANSFER_MONEY_REQUEST";
export const TRANSFER_MONEY_SUCCESS = "TRANSFER_MONEY_SUCCESS";
export const TRANSFER_MONEY_FAIL = "TRANSFER_MONEY_FAIL";

export const ESTIMATED_TRANSFER_COST_REQUEST = "ESTIMATED_TRANSFER_COST_REQUEST";
export const ESTIMATED_TRANSFER_COST_SUCCESS = "ESTIMATED_TRANSFER_COST_SUCCESS";
export const ESTIMATED_TRANSFER_COST_FAIL = "ESTIMATED_TRANSFER_COST_FAIL";

export const GET_MY_WALLET_SETTINGS_REQUEST = "GET_MY_WALLET_SETTINGS_REQUEST";
export const GET_MY_WALLET_SETTINGS_SUCCESS = "GET_MY_WALLET_SETTINGS_SUCCESS";
export const GET_MY_WALLET_SETTINGS_FAIL = "GET_MY_WALLET_SETTINGS_FAIL";

export const UPDATE_MY_WALLET_SETTINGS_REQUEST = "UPDATE_MY_WALLET_SETTINGS_REQUEST";
export const UPDATE_MY_WALLET_SETTINGS_SUCCESS = "UPDATE_MY_WALLET_SETTINGS_SUCCESS";
export const UPDATE_MY_WALLET_SETTINGS_FAIL = "UPDATE_MY_WALLET_SETTINGS_FAIL";

export const CHANGE_TRANSACTIONS_FILTERS = "CHANGE_TRANSACTIONS_FILTERS";
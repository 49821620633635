import { cloneDeep } from 'lodash';
import {
    GET_CONTACTS_V2_SUCCESS,
    SELECT_CHAT_V2_SUCCESS,
    DESELECT_CHAT_V2,
    WEB_SOCKET_SEND_MESSAGE,
    WEB_SOCKET_RECEIVE_MESSAGE
} from '../../actions/chat_v2/types';
import {
    LOGIN_SUCCESS,
    REGISTER_SUCCESS,
    LOGOUT_SUCCESS,
    TOKEN_REFRESH_FAILURE
} from '../../actions/auth/types';

const INITIAL_STATE = {
    selected: {
        role: null,
        contact: null,
        blocked: null,
        messages: {
            count: 0,
            next: null,
            previous: null,
            results: []
        }
    },
    contactList: {
        count: 0,
        next: null,
        previous: null,
        results: []
    }
}

const findIsBlocked = (selectedRoleId, selectedContactId, contactList) => {
    let isBlocked = false;
    
    contactList.forEach(contactItem => {
        if (contactItem.role.id !== selectedRoleId && contactItem.contact.id !== selectedContactId)
            return;
        
        if (!contactItem.blocked)
            return;
        
        isBlocked = true;
    });

    return isBlocked;
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_CONTACTS_V2_SUCCESS:
            return {
                ...state, contactList: {
                    ...state.contactList, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.contactList.results) : action.payload.list.results
                }
            };
        case SELECT_CHAT_V2_SUCCESS:
            return { ...state, selected: { ...action.payload.selected, blocked: findIsBlocked(action.payload.selected.role.id, action.payload.selected.contact.id, state.contactList.results) } };
        case DESELECT_CHAT_V2:
            return { ...state, selected: cloneDeep(INITIAL_STATE.selected) };
        case WEB_SOCKET_SEND_MESSAGE:
            var message = action.payload.message;
            var chat = {
                ...state, selected: {
                    ...state.selected, messages: {
                        ...state.selected.messages,
                        count: state.selected.messages.count++,
                        results: [{ ...message, sender: message.sender?.id, receiver: message.receiver?.id }].concat(state.selected.messages.results)
                    }
                }
            };
            return chat;
        case WEB_SOCKET_RECEIVE_MESSAGE:
            // eslint-disable-next-line no-redeclare
            var message = action.payload.message;
            // eslint-disable-next-line no-redeclare
            var chat = {
                ...state, selected: {
                    ...state.selected, messages: {
                        ...state.selected.messages,
                        count: state.selected.messages.count++,
                        results: [{ ...message, sender: message.sender?.id, receiver: message.receiver?.id }].concat(state.selected.messages.results)
                    }
                }
            };
            return chat;
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE };
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE };
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE };
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}
import { combineReducers } from 'redux';
import { loadingReducer } from '../../API/loadingReducer';
import { errorReducer } from '../../API/errorReducer';
import langReducer from './lang';
import userReducers from './user';
import myWorkReducers from './myWork'
import browseReducers from './browse';
import competenceReducers from './competence'
import projectCategoryReducers from './projectCategory'
import taskCategoryReducers from './taskCategory'
import chatReducer from './chat'
import notificationReducer from './notification'
import myInstitutionReducers from './myInstitution';
import issuersReducer from './issuer/issuersReducer';
import chat_v2Reducer from './chat_v2';
import cryptoReducer from './crypto';

export default combineReducers({
    chat_v2: chat_v2Reducer,
    chat: chatReducer,
    notification: notificationReducer,
    lang: langReducer,
    user: userReducers,
    browse: browseReducers,
    myWork: myWorkReducers,
    myInstitution: myInstitutionReducers,
    issuers: issuersReducer,
    competence: competenceReducers,
    projectCategory: projectCategoryReducers,
    taskCategory: taskCategoryReducers,
    loading: loadingReducer,
    errors: errorReducer,
    crypto: cryptoReducer
})
export const GET_CONTACTS_V2_REQUEST = "GET_CONTACTS_V2_REQUEST";
export const GET_CONTACTS_V2_SUCCESS = "GET_CONTACTS_V2_SUCCESS";
export const GET_CONTACTS_V2_FAILURE = "GET_CONTACTS_V2_FAILURE";

export const SELECT_CHAT_V2_REQUEST = "SELECT_CHAT_V2_REQUEST";
export const SELECT_CHAT_V2_SUCCESS = "SELECT_CHAT_V2_SUCCESS";
export const SELECT_CHAT_V2_FAILURE = "SELECT_CHAT_V2_FAILURE";

export const DESELECT_CHAT_V2 = "DESELECT_CHAT_V2";

export const SEND_MESSAGE_V2_REQUEST = "SEND_MESSAGE_V2_REQUEST";
export const SEND_MESSAGE_V2_SUCCESS = "SEND_MESSAGE_V2_SUCCESS";
export const SEND_MESSAGE_V2_FAILURE = "SEND_MESSAGE_V2_FAILURE";

// Web socket
export const WEB_SOCKET_USER_STATUS_CHANGED = "WEB_SOCKET_USER_STATUS_CHANGED";
export const WEB_SOCKET_SEND_MESSAGE = "WEB_SOCKET_SEND_MESSAGE";
export const WEB_SOCKET_RECEIVE_MESSAGE = "WEB_SOCKET_RECEIVE_MESSAGE";
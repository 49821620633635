import React from 'react';
import './index.scss';

class ErrorView extends React.Component {

    render() {
        return (
            <section className="page_404">
                <div className="row p-0">
                    <div className="col text-center">

                        <div className="four_zero_four_bg">
                            <h1 className="text-center ">Ooops!</h1>
                        </div>
                        <div className="contant_box_404">
                            <h3 className="h2">
                                Something went wrong.
                            </h3>
                            <p>It's probably our fault.</p>
                            <a href="/" className="link_404">Reload the page</a>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default ErrorView;
import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import FormLogin from './formLogin';
import { Container } from 'react-bootstrap';

class LoginPage extends React.Component {

    render() {

        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <FormLogin modal={false} />
                </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, { })(LoginPage));

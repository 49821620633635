import React from 'react';
import ErrorView from './errorView';
import './index.scss';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorView />
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
//-------------------
//  GAMIFICATION balances
//-------------------
export const GET_MY_GAMIFICATION_BALANCE_REQUEST = "GET_MY_GAMIFICATION_BALANCE_REQUEST";
export const GET_MY_GAMIFICATION_SUCCESS = "GET_MY_GAMIFICATION_SUCCESS";
export const GET_MY_GAMIFICATION_FAILURE = "GET_MY_GAMIFICATION_FAILURE";

//-------------------
//  BOOST task
//-------------------
export const BOOST_MY_TASK_REQUEST = "BOOST_MY_TASK_REQUEST";
export const BOOST_MY_TASK_SUCCESS = "BOOST_MY_TASK_SUCCESS";
export const BOOST_MY_TASK_FAILURE = "BOOST_MY_TASK_FAILURE";

//-------------------
//  BOOST project
//-------------------
export const BOOST_MY_PROJECT_REQUEST = "BOOST_MY_PROJECT_REQUEST";
export const BOOST_MY_PROJECT_SUCCESS = "BOOST_MY_PROJECT_SUCCESS";
export const BOOST_MY_PROJECT_FAILURE = "BOOST_MY_PROJECT_FAILURE";

//-------------------
//  GAMIFICATION badges
//-------------------
export const GET_MY_GAMIFICATION_BADGES_REQUEST = "GET_MY_GAMIFICATION_BADGES_REQUEST";
export const GET_MY_GAMIFICATION_BADGES_SUCCESS = "GET_MY_GAMIFICATION_BADGES_SUCCESS";
export const GET_MY_GAMIFICATION_BADGES_FAILURE = "GET_MY_GAMIFICATION_BADGES_FAILURE";
export const CHANGE_BADGES_FILTERS = "CHANGE_BADGES_FILTERS";

//-------------------
//  GAMIFICATION achievements
//-------------------
export const GET_MY_GAMIFICATION_ACHIEVEMENTS_REQUEST = "GET_MY_GAMIFICATION_ACHIEVEMENTS_REQUEST";
export const GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS = "GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS";
export const GET_MY_GAMIFICATION_ACHIEVEMENTS_FAILURE = "GET_MY_GAMIFICATION_ACHIEVEMENTS_FAILURE";
export const CHANGE_ACHIEVEMENTS_FILTERS = "CHANGE_ACHIEVEMENTS_FILTERS";

//-------------------
//  GAMIFICATION events
//-------------------
export const GET_GAMIFICATION_EVENTS_REQUEST = "GET_GAMIFICATION_EVENTS_REQUEST";
export const GET_GAMIFICATION_EVENTS_SUCCESS = "GET_GAMIFICATION_EVENTS_SUCCESS";
export const GET_GAMIFICATION_EVENTS_FAILURE = "GET_GAMIFICATION_EVENTS_FAILURE";
export const CHANGE_EVENTS_FILTERS = "CHANGE_EVENTS_FILTERS";

//-----------------------
//  GAMIFICATION packages
//-----------------------
export const GET_GAMIFICATION_PACKAGES_FAILURE = "GET_GAMIFICATION_PACKAGES_FAILURE";
export const GET_GAMIFICATION_PACKAGES_SUCCESS = "GET_GAMIFICATION_PACKAGES_SUCCESS";
export const GET_GAMIFICATION_PACKAGES_REQUEST = "GET_GAMIFICATION_PACKAGES_REQUEST";
export const CHANGE_PACKAGES_FILTERS = "CHANGE_PACKAGES_FILTERS";

//-----------------------
//  AVAILABLE BOOSTS
//-----------------------
export const GET_PROJECT_AVAILABLE_BOOSTS_REQUEST = "GET_PROJECT_AVAILABLE_BOOSTS_REQUEST";
export const GET_PROJECT_AVAILABLE_BOOSTS_SUCCESS = "GET_PROJECT_AVAILABLE_BOOSTS_SUCCESS";
export const GET_PROJECT_AVAILABLE_BOOSTS_FAILURE = "GET_PROJECT_AVAILABLE_BOOSTS_FAILURE";

//------------------------
// ESTIMATED PACKAGE COSTS
//.....................---
export const GET_PACKAGE_COST_ESTIMATE_SUCCESS = "GET_PACKAGE_COST_ESTIMATE__SUCCESS";
export const GET_PACKAGE_COST_ESTIMATE_REQUEST = "GET_PACKAGE_COST_ESTIMATE_REQUEST";
export const GET_PACKAGE_COST_ESTIMATE_FAILURE = "GET_PACKAGE_COST_ESTIMATE__FAILURE";
import { CHANGE_ACHIEVEMENTS_FILTERS, GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS } from "../../../actions/gamification/types";

export const INITIAL_STATE_FILTERS = {
    limit: 10,
    search: '',
    ordering: '',
}

const INITIAL_STATE = {
    list: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case CHANGE_ACHIEVEMENTS_FILTERS:
            return {
                ...state, list: {
                    ...state.list,
                    filters: { ...state.list.filters, ...action.payload.filters }
                }
            };
        case GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        default:
            return state;
    }
}
import './index.scss';
import './sectionWhatWeOffer.scss';
import Lottie from "lottie-react";
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Container, Row } from "react-bootstrap";
import clientAnimation from "../../assets/lottie/hire-3197867.json";
import freelancerAnimation from "../../assets/lottie/web-developers.json";


const SectionWhatWeOffer = () => {
    return (
        <>
            <Fade bottom>
                <div className="section-title"><FormattedMessage id="home.offer_title" defaultMessage="What we offer" /></div>
            </Fade>
            <div className="section-why mb-5">
                <Container>
                    <div className="d-flex justify-content-center mb-5">
                        <Fade bottom cascade>
                            <div className="section-subtitle why-contain-75">
                                <FormattedMessage id="home.offer_subtitle" defaultMessage="WNS offers the tools to sidestep any background checking processes, thus, on the one hand empowering the individuals in job searching and, on the other hand disburdening the clients by reducing the overhead in hiring processes." />
                            </div>
                        </Fade>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="why-contain-75">
                            <Row>
                                <Col sm={12} md={6}>
                                    <Lottie className="mb-3 pl-3 pr-3" animationData={clientAnimation} />
                                    <h3 className="text-center">
                                        <FormattedMessage id="home.offer_client" defaultMessage="Client" />
                                    </h3>
                                    <div className="d-flex mt-3 justify-content-center">
                                        <Fade left cascade>
                                            <ul className="offer-ul">
                                                <li><FormattedMessage id="home.offer_client_1" defaultMessage="Find a perfect skill match for your task" /></li>
                                                <li><FormattedMessage id="home.offer_client_2" defaultMessage="Award and issue skill NFTs" /></li>
                                                <li><FormattedMessage id="home.offer_client_3" defaultMessage="Repeat and finish your project" /></li>
                                                <li><FormattedMessage id="home.offer_client_4" defaultMessage="Trustworthy access to global talent" /></li>
                                                <li><FormattedMessage id="home.offer_client_5" defaultMessage="Zero-risk transactions" /></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <Lottie className="mb-3 pb-1" animationData={freelancerAnimation} />
                                    <h3 className="text-center">
                                        <FormattedMessage id="home.offer_freelancer" defaultMessage="Freelancer" />
                                    </h3>
                                    <div className="d-flex mt-3 justify-content-center">
                                        <Fade right cascade>
                                            <ul className="offer-ul">
                                                <li><FormattedMessage id="home.offer_freelancer_1" defaultMessage="Each task is equipped with a set of skills" /></li>
                                                <li><FormattedMessage id="home.offer_freelancer_2" defaultMessage="By completing the task, you acquire the skills as NFTs" /></li>
                                                <li><FormattedMessage id="home.offer_freelancer_3" defaultMessage="Repeat and develop your skill set" /></li>
                                                <li><FormattedMessage id="home.offer_freelancer_4" defaultMessage="Showcase your credibility" /></li>
                                                <li><FormattedMessage id="home.offer_freelancer_5" defaultMessage="Advance your career" /></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default injectIntl(connect(null, {})(SectionWhatWeOffer));

// eslint-disable-next-line no-lone-blocks
{/* <div className="section-title">What we offer</div>
<div className="section-what-we-offer">
    <Container>
        <Row>
            <Col lg={5} className="ul-section">
                <p>Work 'n Skill is different because:</p>
                <ul>
                    <li className="mt-4 mb-4">Addresses time-sensitive issues by eliminating overhead of finding suitable workers.</li>
                    <li className="mb-4">Solves the issues of circumventing false profiles and references on job portals.</li>
                    <li className="mb-4">Secures payments with escrow smart contract that automatically releases funds when (and only when) the service is provided.</li>
                    <li>Establishes fairness with neutral auditors that evaluate work and resolve any disputes between the parties.</li>
                </ul>
            </Col>
            <Col lg={7} className="text-center">

                <Row className="mb-4">
                    <Col xs={12} sm={6} className="text-center offer-col">
                        <Image src={for1} alt="For companies"></Image>
                        <div className="title-20-bold">For Companies</div>
                        <div>Post projects and find credible freelancers</div>
                    </Col>
                    <Col xs={12} sm={6} className="text-center">
                        <Image src={for2} alt="For freelancers"></Image>
                        <div className="title-20-bold">For Freelancers & Job seekers</div>
                        <div>Build strong CV, find work and get paid</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} className="text-center offer-col">
                        <Image src={for3} alt="For Educational institutions"></Image>
                        <div className="title-20-bold">For Educational institutions</div>
                        <div>Keep track of your alumnis and keep your educational programs up-to-date</div>
                    </Col>
                    <Col xs={12} sm={6} className="text-center">
                        <Image src={for4} alt="For auditors"></Image>
                        <div className="title-20-bold">For auditors</div>
                        <div>A neutral auditors evaluate work and resolve any disputes between the parties.</div>
                    </Col>
                </Row>

            </Col>
        </Row>
    </Container>
</div> */}
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { GET_REVIEW_TODOS_FOR_TASK_SUCCESS, GET_REVIEW_TODO_FOR_TASK_SUCCESS, SUBMIT_REVIEW_FOR_TASK_SUCCESS } from '../../actions/rating/types';
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types'

const INITIAL_STATE = {
    selected: {
        id: null,
        ratings_to_fullfill: [],
        expiration_date: '',
        rating: null,
        comment: ''
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_REVIEW_TODOS_FOR_TASK_SUCCESS:
            return { ...state, list: action.payload.list };
        case GET_REVIEW_TODO_FOR_TASK_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case SUBMIT_REVIEW_FOR_TASK_SUCCESS:
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: {
                    ...state.list, results: state.list.results?.filter(review =>
                        review.id !== action.payload.selected.id)
                }
            };
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}
export const GET_COMPETENCES_MINT_REQUEST = "GET_COMPETENCES_MINT_REQUEST";
export const GET_COMPETENCES_MINT_SUCCESS = "GET_COMPETENCES_MINT_SUCCESS";
export const GET_COMPETENCES_MINT_FAIL = "GET_COMPETENCES_MINT_FAIL";

export const UPDATE_COMPETENCES_MINT_REQUEST = "UPDATE_COMPETENCES_MINT_REQUEST";
export const UPDATE_COMPETENCES_MINT_SUCCESS = "UPDATE_COMPETENCES_MINT_SUCCESS";
export const UPDATE_COMPETENCES_MINT_FAIL = "UPDATE_COMPETENCES_MINT_FAIL";

export const GET_ESTIMATION_COMPETENCE_MINT_REQUEST = "GET_ESTIMATION_COMPETENCE_MINT_REQUEST";
export const GET_ESTIMATION_COMPETENCE_MINT_SUCCESS = "GET_ESTIMATION_COMPETENCE_MINT_SUCCESS";
export const GET_ESTIMATION_COMPETENCE_MINT_FAIL = "GET_ESTIMATION_COMPETENCE_MINT_FAIL";

export const CHANGE_COMPETENCES_MINT_FILTERS = "CHANGE_COMPETENCES_MINT_FILTERS";
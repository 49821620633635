import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import { Field } from 'react-final-form';
// import { required } from './validators';

const objToArray = (obj) => {
    let keys = Object.keys(obj);
    keys = [].concat(keys).sort();
    let array = [];
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        array.push({ value: obj[key], label: obj[key] });
    }
    return array;
}

export const Input = props => {
    return (
        <Form.Group controlId={props.name} id={props.name} style={props.style} className={props.visible === "false" ? "hidden" : ""}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control {...props.input} {...props} placeholder={props.placeholder} isInvalid={props.meta.touched && props.meta.error} />
            <Form.Control.Feedback type="invalid">{props.meta.error}</Form.Control.Feedback>
        </Form.Group>
    )
};

export const Radio = props => {
    let options = props.options;
    if (!Array.isArray(options)) {
        options = objToArray(options);
    }
    return (
        <Form.Group controlId={props.name} style={props.style}>
            <Form.Label >{props.label}</Form.Label>
            {options.map(el => (
                <Form.Check type="radio" key={el.value} onChange={props.onChange} label={el.label} name="roleSelect" value={el.value} />
            ))}
        </Form.Group>
    );
}

export const RadioCustom = props => {
    let options = props.options;
    if (!Array.isArray(options)) {
        options = objToArray(options);
    }
    return (
        <Form.Group controlId={props.name} style={props.style}>
            <Form.Label >{props.label}</Form.Label>
            {options.map(el => (
                <Form.Group className="custom-control custom-radio" key={'w' + el.value}>
                    <Form.Control type="radio" className="custom-control-input" key={el.value} onChange={props.onChange} id={el.value} name="roleSelect" value={el.value} isInvalid={props.meta.touched && props.meta.error} />
                    <Form.Label className="custom-control-label" htmlFor={el.value} key={'l' + el.value}>{el.label}</Form.Label>
                </Form.Group>
            ))}
        </Form.Group>
    );
}

export const Select = props => {
    let options = props.options;
    if (!Array.isArray(props.options)) {
        options = objToArray(options);
    }
    return (
        <Form.Group controlId={props.name} style={props.style}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Form.Control {...props.input} {...props} as="select" className="form-select">
                {options.map(el => (
                    <option key={el.value} value={el.value}>{el.label}</option>
                ))}
            </Form.Control>
        </Form.Group>
    )
};

export const CheckBoxCustom = props => {
    return (
        <Form.Group controlId={props.name} className="custom-control custom-checkbox">
            <Form.Control type="checkbox" className="custom-control-input" id="customCheck" name={props.name} onChange={props.input.onChange} />
            <Form.Label className="custom-control-label" htmlFor="customCheck">{props.label}</Form.Label>
        </Form.Group>
    )
};

export const CheckBoxAgreeWithTerms = props => {
    return (
        <Form.Group controlId={props.name} className="custom-control custom-checkbox">
            <Form.Control type="checkbox" className="custom-control-input" id="customCheck" name={props.name} onChange={props.input.onChange} isInvalid={props.meta.touched && props.meta.error} />
            <Form.Label className="custom-control-label" htmlFor="customCheck"></Form.Label>
            <Form.Text className="auth-small" style={{ display: 'initial' }}>
                <FormattedMessage id="forms.labels.iagreewiththe" defaultMessage="I agree with the" />
                &nbsp;
                <Link to="/legal/terms-and-conditions" target="_blank">
                    <FormattedMessage id="forms.labels.termsandconditions" defaultMessage="Terms and Conditions" />
                </Link>
                &nbsp;
                <FormattedMessage id="forms.labels.and" defaultMessage="and" />
                &nbsp;
                <Link to="/legal/privacy-policy" target="_blank">
                    <FormattedMessage id="forms.labels.privacypolicy" defaultMessage="Privacy Policy" />
                </Link>
            </Form.Text>
            <Form.Control.Feedback type="invalid">{props.meta.error}</Form.Control.Feedback>
        </Form.Group>
    )
};
import { GET_BALANCES_SUCCESS, GET_BALANCES_FAIL, GET_TRANSACTIONS_SUCCESS, GET_MY_WALLET_SETTINGS_SUCCESS } from '../../actions/blockchain/types';
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_TRANSACTIONS_FILTERS, UPDATE_MY_WALLET_SETTINGS_SUCCESS } from '../../actions/blockchain/types';

export const INITIAL_STATE_FILTERS = {
    limit: 10,
}

const INITIAL_STATE = {
    id: null,
    owner: null,
    address: '',
    balances: {
        ETH: 0,
        DAI: 0
    },
    transactions: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    settings: {
        auto_claim_competence: null,
        display_currency: ''
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_BALANCES_SUCCESS:
            return { ...state, ...action.payload }
        case GET_BALANCES_FAIL:
            return { ...INITIAL_STATE }
        case CHANGE_TRANSACTIONS_FILTERS:
            return {
                ...state, transactions: {
                    ...state.transactions,
                    filters: { ...state.transactions.filters, ...action.payload.filters }
                }
            };
        case GET_TRANSACTIONS_SUCCESS:
            return {
                ...state, transactions: {
                    ...state.transactions, ...action.payload.transactions,
                    results: action.payload.add ? [...state.transactions.results, ...action.payload.transactions.results] : action.payload.transactions.results
                }
            };
        case GET_MY_WALLET_SETTINGS_SUCCESS:
            return { ...state, settings: action.payload.settings };
        case UPDATE_MY_WALLET_SETTINGS_SUCCESS:
            return {
                ...state, settings: action.payload.selected
            }
        //RESTART
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';


class ProfileReviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            this.props.reviews?.count > 0 ?
                <div className="card p-0">
                    <div className="card-head"><FormattedMessage id="profile.reviewsupper" defaultMessage="Reviews" /><span className=""> ({this.props.reviews?.count})</span></div>
                    <div className="card-body pb-0">
                        <div className="section-rows">
                            {this.props.reviews?.results.map((r, i) =>
                                <div className="section-row" key={i}>
                                    <Image src="/assets/img/default-review.png" className="sec-img"></Image>
                                    <div className="sec-info">
                                        <div>{r.reviewer.name}</div>
                                        <div>{r.comment}</div>
                                    </div>
                                    <div className="p-info-name">
                                        <svg style={{ color: '#FFD700', marginTop: '2px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 18 18">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg>
                                        {/* <span className="p-star mr-1" role="img" aria-label="Star rating">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                            </svg>
                                        </span> */}
                                        <span>
                                            <span className="rating-big">{r.rating}</span>
                                            {/* <span className="rating-small">/5</span> */}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> : <></>
        )
    }
}


export default injectIntl(connect(null, {
})(ProfileReviews));
import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalCreateANewCompetence.scss';
import Select from 'react-select';
import Levels from '../../../assets/json/blooms-taxonomy.js';
import CustomSelect from '../../Common/CustomSelect';
import AutosizeTextarea from '../../Common/AutosizeTextarea';
import { Link } from 'react-router-dom';
import { apiCon } from '../../../API';
import { connect } from 'react-redux';
import { slugify } from '../../../utils/common/misc';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Alert } from 'react-bootstrap';
import ModalQuickAddCompetence from './modalQuickAddCompetence';
import { createErrorMessageSelector, createLoadingSelector } from '../../../API/selector';
import { GetAvailableIssuersStart, GetMyIssuersStart } from '../../../core/actions/issuer';
import { VerifyCompetenceStart, ValidateCompetenceStart, CreateCompetenceStart } from '../../../core/actions/competences';
import bogusCheck from '../../../utils/i18n/word-blacklist';
import { ButtonSubmit } from '../../../components/Common/Buttons/btnSubmit';

class ModalCreateANewCompetence extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            step: 1,
            name: '',
            description: '',
            level: '',
            sub_level: '',
            parent: null,
            skills: [],
            selectSkills: [],
            levels: [],
            categories: [],
            levelInvalid: false,
            issuer: this.props?.issuer,
            nameRequired: false,
            descRequired: false,
            levelRequired: false,
            modalQuickAddCompetenceVisible: false,
            maxSkills: false
        };
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.generateLevelOptions(Levels);
        if (this.props?.myIssuers) {
            this.props.GetMyIssuersStart();
        } else {
            this.props.GetAvailableIssuersStart();
        }
        this.props.VerifyCompetenceStart('', 1, 1);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "" && !this.state.modalQuickAddCompetenceVisible) {
            this.hide({ id: this.props.selectedCompetence.id, name: this.state.name, level: parseInt(this.state.level) });
        }
        if (prevProps.lang !== this.props.lang) {
            this.generateLevelOptions(Levels);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.issuer && nextProps.availableIssuers.length > 0) {
            this.setState({ issuer: nextProps.availableIssuers[0].owner.id });
        }
    }

    openModalQuickAddCompetence() {
        this.setState({ modalQuickAddCompetenceVisible: true });
    }

    hideModalQuickAddCompetency() {
        const temp = this.state.issuer;
        this.setState({ issuer: null });
        setTimeout(() => {
            this.setState({ issuer: temp, modalQuickAddCompetenceVisible: false });
        }, 300);
        this.setState({ modalQuickAddCompetenceVisible: false });
    }

    loadCategoriesData = inputValue => {
        const url = '/project/task/categories/'
        const params = {
            'limit': 100,
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        return apiCon.get(url, { params })
            .then(response => response.data.results.map(x => {
                return {
                    value: x.id,
                    label: x.name
                }
            })).catch(err => { process.env.NODE_ENV !== "production" && console.log('Bad Luck', err); })
    };

    getOptionLabel = option => {
        return (
            <div><strong>{option.label}</strong></div>
        );
    }

    generateLevelOptions(data) {
        if (!data.levels) { return; };
        const { lang } = this.props;
        var options = [];
        data.levels.forEach(e => {
            var item = { label: "'" + (lang === 'en' ? e.verb : e.verb_si) + "' - " + e.name, options: [] };
            e.verbs.forEach(o => {
                item.options.push({ label: lang === 'en' ? o.verb : o.verb_si, value: o.id });
            });
            options.push(item);
        });
        this.setState({ levels: options });
    }

    hide(competence) {
        this.props.onClose(competence);
    }

    handleChange = input => event => {
        if (input === 'level') {
            const level = event.value.toString().substr(0, 1);
            if (this.state.name) {
                this.props.VerifyCompetenceStart(this.state.name, level, this.state.issuer);
            }
            this.setState({ level: level, sub_level: event.value, levelInvalid: false, levelRequired: false });
        } else if (input === 'name') {
            this.props.VerifyCompetenceStart(event.target.value, this.state.level, this.state.issuer);
            // this.setState({ name: event.target.value, nameRequired: false });
            !bogusCheck(event.target.value) && this.setState({ name: event.target.value, nameRequired: false });
        } else {
            this.setState({ [input]: slugify(event.target.value) });
        }
    }

    handleIssuerChange = input => event => {
        this.setState({
            [input]: event.target.value,
            parentOption: [],
            selectSkills: []
        });
        if (this.state.name && this.state.level && this.state.issuer) {
            this.props.VerifyCompetenceStart(this.state.name, this.state.level, event.target.value);
        }
    }

    textAreaValueChanged(input, event) {
        if (input === 'description' && bogusCheck(event.target.value))
            return;
        if (input === 'description') {
            if (event.target.value.length > 1000) return
            if (event.target.value.length === 0) {
                this.setState({ [input]: event.target.value, descRequired: true });
            } else {
                this.setState({ [input]: event.target.value, descRequired: false });
            }
        }
    }

    onSkillsChanged = (values) => {
        const issuer = this.props.availableIssuers.find(el => el.owner.id === this.state.issuer);
        const newSkills = values?.map(x => x.value);
        if (issuer?.max_skill_number) {
            if (issuer.max_skill_number !== 0) {
                if (newSkills.length <= issuer.max_skill_number) {
                    this.setAndValidateSkills(values, false);
                } else {
                    this.setAndValidateSkills(values, true);
                }
            } else {
                // unlimited
                this.setAndValidateSkills(values, false);
            }
        } else {
            this.setAndValidateSkills(values, false);
        }
    }

    setAndValidateSkills = (values, maxed) => {
        this.setState({ maxSkills: maxed, selectSkills: values, skills: values?.map(x => x.value) }, () =>
            this.props.ValidateCompetenceStart({
                name: this.state.name,
                description: this.state.description,
                level: this.state.level,
                sub_level: this.state.sub_level,
                parent: this.state.parent,
                skills: this.state.skills,
                task_categories: this.state.task_categories,
                issuer: this.state.issuer
            }));
    }

    onCategoryChanged = (values) => {
        this.setState({ categories: values, task_categories: values?.map(x => x.value) });
    }

    onParentChanged = (value) => {
        this.setState({ parentOption: value, parent: value?.value });
    }

    getDefaultValueLevel() {
        let res = {};
        if (this.state.level && this.state.sub_level) {
            const level = parseInt(this.state.level) - 1;
            const sub_level = parseInt(this.state.sub_level.toString().substr(1, 1)) - 1;
            res = this.state.levels[level].options[sub_level];
        }
        return res;
    }

    getDefaultValueParent() {
        return [this.state.parentOption];
    }

    getDefaultValueRelated() {
        return this.state.selectSkills;
    }

    getDefaultValueCategories() {
        return this.state.categories;
    }

    save(draft) {
        this.props.CreateCompetenceStart({
            name: this.state.name,
            description: this.state.description,
            level: this.state.level,
            sub_level: this.state.sub_level,
            parent: this.state.parent,
            skills: this.state.skills,
            task_categories: this.state.task_categories,
            issuer: this.state.issuer,
            status: draft ? 1 : 2
        });
    }

    stepAvailable(s) {
        let res = 'not-available';
        switch (s) {
            case 2:
                if (this.state.name) { res = ''; }
                break;
            case 3:
                if (this.state.name && this.state.description && this.state.level && this.state.sub_level) { res = ''; }
                break;
            default:
                res = '';
                break;
        }
        return res;
    }

    setStep(s) {
        switch (s) {
            case 2:
                this.state.name && this.setState({ step: 2 });
                break;
            case 3:
                (this.state.name && this.state.description && this.state.level && this.state.sub_level) && this.setState({ step: 3 });
                break;
            default:
                this.setState({ step: 1 });
                break;
        }
    }

    next() {
        if (this.state.step === 1 && this.state.name === '') {
            this.setState({ nameRequired: true });
        } else if (this.state.step === 2) {
            if (this.state.level === '' || this.state.sub_level === '') {
                this.setState({ levelRequired: true });
            } else if (this.state.description === '') {
                this.setState({ descRequired: true });
            } else if (!this.props.already_exists) {
                this.setState({ step: this.state.step + 1 });
            }
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    }

    back() {
        this.setState({ step: this.state.step - 1 });
    }

    renderBack() {
        return (
            <>
                <div className="header-back back-arrow-wrap">
                    <div style={{ 'display': 'flex', 'alignItems': 'center' }}>
                        <span className="icon-back_arrow" onClick={() => this.back()}></span>
                        <span onClick={() => this.back()}>
                            <FormattedMessage id="common.back" defaultMessage="Back" />
                        </span>
                    </div>
                </div>
            </>
        )
    }

    renderStep1() {
        const { already_exists, availableIssuers, disableIssuerSelect } = this.props;
        return (
            <>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="competences.name" defaultMessage="Skill name" />*
                    </Form.Label>
                    <Form.Control type="text" name="nameInput" className={already_exists && "is-invalid"} onChange={this.handleChange('name')} value={this.state.name} required maxLength="100" />
                    {already_exists &&
                        <div>
                            <small style={{ 'color': 'red' }}><FormattedMessage id="competency.warning.name_level_exists" defaultMessage="A skill with this name and level already exists!" /></small><br />
                            <small><FormattedMessage id="'competency.can_change_level'" defaultMessage="You can change the current level or provide your own definition for the existing skill" /> <Link to="/"><FormattedMessage id="common.here" defaultMessage="here" /></Link>.</small>
                        </div>
                    }
                    {this.state.nameRequired && <small style={{ 'color': 'red' }}><FormattedMessage id="competency.warning.required_field" defaultMessage="This is a required field" /></small>}
                </Form.Group>
                {(availableIssuers?.length > 1 && !disableIssuerSelect) &&
                    <Form.Group style={{ 'width': '35%' }}>
                        <Form.Label>
                            <FormattedMessage id="issuers.issuer" defaultMessage="Issuer" />
                        </Form.Label>
                        <Form.Control as="select" onChange={this.handleIssuerChange('issuer')} value={this.state.issuer} required >
                            {availableIssuers.map((i, x) => <option key={x} value={i.owner ? i.owner?.id : ''}>{i.name.toUpperCase()}</option>)}
                        </Form.Control>
                    </Form.Group>
                }
            </>
        )
    }

    renderStep2() {
        const { levelInvalid } = this.state;
        const { already_exists } = this.props;
        return (
            <>
                {this.renderBack()}
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="modalEditProfileInfo.description" defaultMessage="Description" />&nbsp;(<span className="clink" onClick={() => window.open("/help", "_blank")}><FormattedMessage id="competency.blooms_taxonomy" defaultMessage="Bloom's Taxonomy" /></span>)
                    </Form.Label>
                    <div><small><FormattedMessage id="competency.blooms_taxonomy_description" defaultMessage="Bloom's Taxonomy helps us classify educational learning objectives. Simply finish the sentence starting with:" /></small></div>
                </Form.Group>
                <Form.Group>
                    <div className="desc-wrap mt-2 mb-2">
                        <div className="this-person">
                            <FormattedMessage id="competences.this_person_can" defaultMessage="This person can" />
                        </div>
                        <Select
                            required
                            blurinputonselect="true"
                            defaultValue={this.getDefaultValueLevel()}
                            ref={this.selectRef}
                            className="select-level"
                            classNamePrefix="select"
                            name="selLevel"
                            options={this.state.levels}
                            onChange={this.handleChange('level')}
                            styles={{ control: (provided, state) => levelInvalid ? { ...provided, border: "1px solid #dc3545" } : provided }}
                        />
                    </div>
                </Form.Group>
                <Form.Group controlId="formCDesc" >
                    <AutosizeTextarea rows={2} input="description" value={this.state.description} handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                </Form.Group>
                {already_exists &&
                    <div>
                        <small style={{ 'color': 'red' }}><FormattedMessage id="competency.warning.name_level_exists" defaultMessage="A skill with this name and level already exists!" /></small><br />
                        <small> <FormattedMessage id="competency.can_change_level" defaultMessage="You can change the current level or provide your own definition for the existing skill " /><Link to="/"><FormattedMessage id="common.here" defaultMessage="here" /></Link>.</small>
                    </div>
                }
                {this.state.descRequired && <small style={{ 'color': 'red' }}><FormattedMessage id="competency.warning_complete_sentence" defaultMessage="Please complete the sentence (enter definition)." /></small>}
                {this.state.levelRequired && <small style={{ 'color': 'red' }}><FormattedMessage id="competency.warning.select_level" defaultMessage="Please select a level and complete the sentence." /></small>}
            </>
        )
    }

    renderStep3() {
        const { warnings } = this.props;
        return (
            <>
                {this.renderBack()}

                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="competences.parent" defaultMessage="Parent skill" />
                    </Form.Label>
                    <div><small><FormattedMessage id="competences.parent_description" defaultMessage="The parent skill positions skill vertically in the registry." /></small></div>
                    <CustomSelect
                        issuers={this.state.issuer ? [this.state.issuer] : []}
                        onSelectedChange={this.onParentChanged}
                        selected={this.getDefaultValueParent()}
                    />
                    <div className="form-sublabel create-competence" onClick={() => this.openModalQuickAddCompetence()} >
                        + <FormattedMessage id="competency.quick_add" defaultMessage="Quick add" />
                    </div>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="competences.skills" defaultMessage="Base skills" />
                    </Form.Label>
                    <div><small><FormattedMessage id="competences.related_description" defaultMessage="Related or base skills that this skill includes or consists of." /></small></div>
                    <CustomSelect
                        issuers={this.state.issuer ? [this.state.issuer] : []}
                        isMulti={true}
                        onSelectedChange={this.onSkillsChanged}
                        selected={this.getDefaultValueRelated()}
                    />
                    {warnings.map((w, x) =>
                        <Alert variant="warning" className="c-validate-warning" dismissible key={x}>
                            <div className="warning-txt">
                                <small>
                                    <FormattedMessage id="competency.the_competency" defaultMessage="The skill " /><strong>
                                        <Link to={'/skill/' + w.value.competence1.id} target="_blank" className="warning-link">{w.value.competence1.name}</Link>
                                    </strong>
                                    <FormattedMessage id="competency.related_to" defaultMessage="is related to " />
                                    <strong>
                                        <Link to={'/skill/' + w.value.competence2.id} target="_blank" className="warning-link">{w.value.competence2.name}</Link>
                                    </strong>
                                    <FormattedMessage id="competency.not_necessary" defaultMessage="so it is not necessary to add it." />
                                </small>
                            </div>
                        </Alert>
                    )}
                    {this.state.maxSkills &&
                        <Alert variant="danger" className="c-validate-warning" dismissible>
                            <div className="warning-txt">
                                <small>
                                    <FormattedMessage id="competency.warning_limited_relations" defaultMessage="The issuer has limited the number of related skills that can be added. Please remove some." />
                                </small>
                            </div>
                        </Alert>
                    }
                    <div className="form-sublabel create-competence" onClick={() => this.openModalQuickAddCompetence()} >
                        + <FormattedMessage id="competency.quick_add" defaultMessage="Quick add" />
                    </div>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="competences.categories" defaultMessage="Categories" />
                    </Form.Label>
                    <div><small><FormattedMessage id="competency.define_common" defaultMessage="Defines where this skill is commnly used." /></small></div>
                    <CustomSelect
                        loadData={this.loadCategoriesData}
                        isMulti={true}
                        onSelectedChange={this.onCategoryChanged}
                        getOptionLabel={this.getOptionLabel}
                        selected={this.getDefaultValueCategories()}
                    />
                    {/* <div className="text-muted"><small><FormattedMessage id="competences.suggested" defaultMessage="Suggested" /></small></div> */}
                </Form.Group>

                {this.state.modalQuickAddCompetenceVisible && <ModalQuickAddCompetence issuer={this.state.issuer} visible={this.state.modalQuickAddCompetenceVisible} onClose={(x) => this.hideModalQuickAddCompetency(x)} />}
            </>
        )
    }

    render() {
        const { step } = this.state;
        const { visible } = this.props;
        return (
            <Rodal className="new-competence-rodal"
                visible={visible}
                width={650}
                // height={500}
                customStyles={{ height: 'auto', bottom: 'auto', maxHeight: '80%', top: '10%', padding: 0 }}
                onClose={() => this.hide()}
                closeMaskOnClick={false}>
                <div>

                    <div className="rodal-header-withimg">
                        <div>
                            <div className="create-title">
                                <FormattedMessage id="common.addCompetency" defaultMessage="Add a new Skill" />
                            </div>
                            <div className="create-c-steps">
                                <div className="connecting-c-line"></div>
                                <div className="step-c-item">
                                    <div className={`step-c-step ${step === 1 ? "step-c-active" : ""} ${this.stepAvailable(1)}`} onClick={() => this.setStep(1)}>1</div>
                                    <div className="step-c-info">
                                        {step > 1 &&
                                            <span className="checkmark">
                                                <div className="checkmark_stem"></div>
                                                <div className="checkmark_kick"></div>
                                            </span>
                                        }
                                        <FormattedMessage id="competency.add.step1" defaultMessage="Name" />
                                    </div>
                                </div>
                                <div className="step-c-item">
                                    <div className={`step-c-step ${step === 2 ? "step-c-active" : ""} ${this.stepAvailable(2)}`} onClick={() => this.setStep(2)}>2</div>
                                    <div className="step-c-info">
                                        {step > 2 &&
                                            <span className="checkmark">
                                                <div className="checkmark_stem"></div>
                                                <div className="checkmark_kick"></div>
                                            </span>
                                        }
                                        <FormattedMessage id="competency.add.step2" defaultMessage="Definition" />
                                    </div>
                                </div>
                                <div className="step-c-item">
                                    <div className={`step-c-step ${step === 3 ? "step-c-active" : ""} ${this.stepAvailable(3)}`} onClick={() => this.setStep(3)}>3</div>
                                    <div className="step-c-info">
                                        <FormattedMessage id="competency.add.step3" defaultMessage="Relations" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-body">

                        {step === 1 && this.renderStep1()}
                        {step === 2 && this.renderStep2()}
                        {step === 3 && this.renderStep3()}

                        <div className={(step < 3) ? "addcompetency-form-actions" : "addcompetency-form-actions-responsive"}>
                            <div className={step === 3 ? "addcompetency-submit-buttons" : ""} style={{ 'display': 'flex' }}>
                                <button className="btn btn-default btn-generic btn-smallpadding mb-2 mr-2 btn-responsive" onClick={() => this.hide()} >
                                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                                </button>
                            </div>
                            {step === 3 &&
                                <div className={step === 3 ? "addcompetency-submit-buttons" : ""} style={{ 'display': 'flex' }}>
                                    <ButtonSubmit
                                        onClickHandler={() => this.save(true)}
                                        // onCloseHandler={() => props.onClose()}
                                        action={"CREATE_COMPETENCE"}
                                        class={"btn btn-default btn-developers-inv mb-2 mr-2 btn-responsive"}
                                        text={<FormattedMessage id="competency.save_draft" defaultMessage="Save as draft" />} />
                                    <ButtonSubmit
                                        onClickHandler={() => this.save(false)}
                                        // onCloseHandler={() => props.onClose()}
                                        action={"CREATE_COMPETENCE"}
                                        class={"btn btn-default btn-developers-inv mb-2 mr-2 btn-responsive"}
                                        text={<FormattedMessage id="competency.publish_button" defaultMessage="Publish skill" />} />
                                    {/* <button type="submit" className="btn btn-default btn-developers-inv mb-2 mr-2 btn-responsive" onClick={() => this.save(true)}>
                                        <FormattedMessage id="competency.save_draft" defaultMessage="Save as draft" />
                                    </button>
                                    <button type="submit" className="btn btn-default btn-developers-inv mb-2 btn-responsive" onClick={() => this.save(false)}>
                                        <FormattedMessage id="competency.publish_button" defaultMessage="Publish skill" />
                                    </button> */}
                                </div>
                            }
                            {step < 3 &&
                                <div className="btn btn-default btn-developers-inv mb-2" onClick={() => this.next()}>
                                    <FormattedMessage id="forms.labels.continue" defaultMessage="Continue" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Rodal>
        )
    }

}
const loadingSelector = createLoadingSelector(['CREATE_COMPETENCE']);
const errorSelector = createErrorMessageSelector(['CREATE_COMPETENCE']);
const mapStateToProps = state => {
    return {
        lang: state.lang,
        role: state.user.info.settings.active_role_obj,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        already_exists: state.competence.selected.already_exists,
        warnings: state.competence.selected.warnings,
        availableIssuers: state.issuers.available.results,
        issuer: state.issuers.selected?.owner,
        selectedCompetence: state.competence.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    VerifyCompetenceStart,
    ValidateCompetenceStart,
    CreateCompetenceStart,
    GetAvailableIssuersStart,
    GetMyIssuersStart
})(ModalCreateANewCompetence));
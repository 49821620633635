import React from 'react';
import Rodal from 'rodal';
import './profile-modals.scss';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { CreateEducationStart, DeleteEducationStart, CreateEducationVerificationRequestStart } from '../../core/actions/education';
import { UpdateEducationStart } from './../../core/actions/education/index';
import SelectEduWithCreate from './selectEduWithCreate';
import SelectEduProgramWithCreate from './selectEduProgramWithCreate';
import ModalSendEducationVerificationRequest from './modalSendEducationVerificationRequest';
import ModalConfirmDelete from '../Common/ModalConfirmDelete/modalConfirmDelete'

const customStyles = {
    minHeight: '700px',
    bottom: 'auto',
    top: '8%'
};

class ModalEditFreelancerEducation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            school: '',
            program: '',
            date_from: '',
            date_to: '',
            edu_school: null,
            edu_program: null,
            educations: this.props.educations,
            editMode: false,
            visible: false,
            modalVerificationRequestVisible: false,
            modalConfirmDeleteVisible: false,
            selectedEdu: null,
            schoolValid: true,
            programValid: true,
        };
        this.delete = this.delete.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                educations: nextProps.role.educations.list
            });
        }
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleDateChange = input => event => {
        if (input === 'date_from' || input === 'date_to') {
            let { value, min, max } = event.target;
            value = Math.max(Number(min), Math.min(Number(max), Number(value)));
            this.setState({ [input]: value });
        }
    }

    cancel() {
        this.setState({
            selectedId: null,
            school: '',
            program: '',
            date_from: '',
            date_to: '',
            edu_school: null,
            edu_program: null,
            editMode: false,
            schoolValid: true,
            programValid: true,
        })
    }

    select(education) {
        const date_from = new Date(education.date_from);
        const date_to = new Date(education.date_to);
        this.setState({
            selectedId: education.id,
            school: education.school,
            program: education.program,
            date_from: date_from.getFullYear(),
            date_to: date_to.getFullYear(),
            editMode: true
        }, () => process.env.NODE_ENV !== "production" && console.log(this.state))
    }

    onSelectedSchoolChange = (values) => {
        this.setState({ school: values.label, edu_school: parseInt(values.value), schoolValid: true });
    }

    onSchoolCreate = (values) => {
        this.setState({ school: values, edu_school: null, schoolValid: true });
    }

    onSelectedProgramChange = (values) => {
        this.setState({ program: values.label, edu_program: parseInt(values.value), programValid: true });
    }

    onProgramCreate = (values) => {
        this.setState({ program: values, edu_program: null, programValid: true });
    }

    deleteAndReload() {
        this.cancel();
    }

    // update() {
    //     this.props.UpdateEducationStart({
    //         id: this.state.selectedId,
    //         school: this.state.school,
    //         program: this.state.program,
    //         date_from: this.state.date_from + '-01-01',
    //         date_to: this.state.date_to + '-01-01',
    //         ...(this.state.edu_school ? { edu_school: this.state.edu_school } : {}),
    //         ...(this.state.edu_program ? { edu_program: this.state.edu_program } : {})
    //     }, this.cancel());
    // }

    handleSubmit = event => {
        const form = event.currentTarget;

        if (this.state.school === '') {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ schoolValid: false, validated: true });
            return;
        } else {
            this.setState({ schoolValid: true });
        }

        if (this.state.program === '') {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ programValid: false, validated: true });
            return;
        } else {
            this.setState({ programValid: true });
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }
        this.setState({ validated: true });
    }

    save() {
        this.props.CreateEducationStart({
            school: this.state.school,
            program: this.state.program,
            date_from: this.state.date_from + '-01-01',
            date_to: this.state.date_to + '-01-01',
            ...(this.state.edu_school ? { edu_school: this.state.edu_school } : {}),
            ...(this.state.edu_program ? { edu_program: this.state.edu_program } : {})
        });
        this.cancel();
    }

    delete(id, verif) {
        verif 
        ? this.setState({ modalConfirmDeleteVisible: true, deleteId: id})
        : this.props.DeleteEducationStart(id, this.deleteAndReload())
        

    }

    onConfirmDelete() {
        this.props.DeleteEducationStart(this.state.deleteId, this.deleteAndReload());
        this.setState({ modalConfirmDeleteVisible: false})
    }

    exists(eduId, programId) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && req.date_from && (req.status === 1 || req.status === 4)) {
                res = true;
            }
        });
        return res;
    }

    isPending(eduId, programId, applicantEduId, dateFrom, dateTo) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && req.status === 1 && (req.applicant_edu_id === applicantEduId || req.date_from === dateFrom || req.date_to === dateTo)) {
                res = true;
            }
        });
        return res;
        // PENDING = 1
        // CANCEL = 2
        // APPROVED = 3
        // REJECTED = 4
    }

    isRejected(eduId, programId) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && (req.status === 2 || req.status === 4 )) {
                res = true;
            }
        });
        return res;
        // PENDING = 1
        // CANCEL = 2
        // APPROVED = 3
        // REJECTED = 4
    }

    openVerify(edu) {
        if (edu.edu_school) {
            this.setState({ selectedEdu: edu, modalVerificationRequestVisible: true });
        }
    }

    renderEducationIsVerified(edu) {
        const { requests } = this.props;

        if (edu.verified) {
            return (<small><FormattedMessage id="common.verified" defaultMessage="Verified" /></small>)
        } else {
            if (requests) {
                return (
                    <>
                        {this.isPending(edu.edu_school, edu.edu_program, edu.applicant_edu_id, edu.date_from, edu.date_to) && <small><FormattedMessage id="common.pending" defaultMessage="Pending" /></small> }
                        {this.isRejected(edu.edu_school, edu.edu_program) && <small><FormattedMessage id="common.rejected" defaultMessage="Rejected" /></small> }
                        {(!this.exists(edu.edu_school, edu.edu_program) && edu.edu_school) && <div className="btn btn-projects btn-smallpadding btn-sm" onClick={() => this.openVerify(edu)}><small><FormattedMessage id="common.verify" defaultMessage="Verify" /></small></div> }
                    </>
                    )
            } else {
                return (<div><small>uncomfirmed</small></div>)
            }
        }
        //         {/* {(edu.verified) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.verified" defaultMessage="Verified" /></small></div> } */}
        //         {(!edu.verified && this.exists(edu.edu_school, edu.edu_program)) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.pending" defaultMessage="Pending" /></small></div> }
        //         {(!edu.verified && !this.exists(edu.edu_school, edu.edu_program) && edu.edu_school) && <div className="btn btn-projects btn-smallpadding btn-sm" onClick={() => this.openVerify(edu)}><small><FormattedMessage id="common.verify" defaultMessage="Verify" /></small></div> }
    }

    renderList() {
        return (
            <Row className="m-0 mt-2">
                <Col>
                    <div className="msection-rows">
                        {this.state.educations.map((e, i) =>
                            <div className="msection-row pb-2" key={i}>
                                {/* <Image src="/assets/img/default-ex.png" className="sec-img"></Image> */}
                                <div className="msec-info">
                                    <div className="">{e.school}</div>
                                    <div className="">{e.program}</div>
                                    <div className="">{e.date_from && e.date_from.substring(0, 4)} - {e.date_to && e.date_to.substring(0, 4)}</div>

                                    {this.renderEducationIsVerified(e)}

                                    <div></div>
                                </div>
                                <div>
                                    <Image src="/assets/img/svg/delete.svg" className="delete-icon edit-pencil" onClick={() => this.delete(e.id, e.verified)}></Image>
                                    {/* <div className="icon-edit edit-icon edit-pencil" onClick={() => this.select(e)}></div> */}
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        const { visible } = this.props;
        return (
            <>
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={420}
                customStyles={customStyles}
                closeMaskOnClick={false}
                className="rodal-dialog-education"
                >
                <div className="modal-guts" style={{ "padding": "10px" }}>
                    <div className="modal-title">
                        <FormattedMessage id="modalEditFreelancerEducation.heading" defaultMessage="Edit Education" />
                    </div>
                    <div className="modal-body p-2 pt-4">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="school">
                                    <Form.Label>
                                        <FormattedMessage id="education.school" defaultMessage="School/University" />
                                    </Form.Label>
                                    <SelectEduWithCreate preload={this.state.school} onSelectedChange={this.onSelectedSchoolChange} onSchoolCreate={this.onSchoolCreate} required={true} />
                                    {!this.state.schoolValid && <small className="text-danger">Please select school</small>}
                                </Form.Group>
                            </Row>

                            <Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="program">
                                    <Form.Label>
                                        <FormattedMessage id="edu.program" defaultMessage="Program" />
                                    </Form.Label>
                                    <SelectEduProgramWithCreate edu_id={this.state.edu_school} onSelectedChange={this.onSelectedProgramChange} onCreate={this.onProgramCreate} required={true} />
                                    {!this.state.programValid && <small className="text-danger">Please select program</small>}
                                </Form.Group>
                            </Row>

                            <Row className="ml-0 mr-0 edit-profile-break ">
                                <Form.Group as={Col} controlId="CompanyProject">
                                    <Form.Label>
                                        <FormattedMessage id="common.from_years" defaultMessage="From" />
                                    </Form.Label>
                                    <Form.Control type="number" onBlur={this.handleDateChange('date_from')} onChange={this.handleChange('date_from')} value={this.state.date_from || ''} min={1930} max={2022} required />
                                </Form.Group>
                                <Form.Group as={Col} controlId="position">
                                    <Form.Label>
                                        <FormattedMessage id="common.to_years" defaultMessage="To" />
                                    </Form.Label>
                                    <Form.Control type="number" onBlur={this.handleDateChange('date_to')} onChange={this.handleChange('date_to')} value={this.state.date_to || ''} min={1930} max={2022} required />
                                </Form.Group>
                            </Row>

                            <Row className="ml-0 mr-0">
                                <Col>
                                    <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                        {this.state.editMode ? <FormattedMessage id="common.edit" defaultMessage="Edit" /> : <FormattedMessage id="common.add" defaultMessage="Add" />}
                                    </button>
                                    <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => { this.state.editMode ? this.cancel() : this.hide() }}>
                                        {this.state.editMode ? <FormattedMessage id="common.cancel" defaultMessage="Cancel" /> : <FormattedMessage id="common.close" defaultMessage="Close" />}
                                    </div>
                                </Col>
                            </Row>

                        </Form>

                        {this.renderList()}

                    </div>
                </div>
                {this.state.modalVerificationRequestVisible &&
                    <ModalSendEducationVerificationRequest edu={this.state.selectedEdu} visible={this.state.modalVerificationRequestVisible} onClose={() => this.setState({ modalVerificationRequestVisible: false })} />
                }
                {this.state.modalConfirmDeleteVisible &&
                    <ModalConfirmDelete 
                        title="common.confirmDeleteTitle" 
                        text="common.confirmDeleteText" 
                        visible={this.state.modalConfirmDeleteVisible} 
                        onClose={() => this.setState({ modalConfirmDeleteVisible: false })} 
                        onConfirm={() => this.onConfirmDelete()} />
                }
            </Rodal>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        requests: state.user.info.settings.active_role_obj.educations?.requests?.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateEducationStart,
    DeleteEducationStart,
    UpdateEducationStart,
    CreateEducationVerificationRequestStart
})(ModalEditFreelancerEducation));
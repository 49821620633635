import React from 'react';
import '../../containers/Profile/index.scss';
import './profileCompetenciesSection.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import history from '../../utils/routes/history';


class ProfileCompetenciesSectionUserDefined extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { role } = this.props;
        return (
            <div className="user-defined-skills">
                {role.competences.map((c, index) =>
                    <div className="skill-tag" key={index}>
                        <span onClick={() => history.push('/skill/' + c.id)}>{c.name} L{c.level}</span>
                    </div>
                )}
            </div>
        )
    }
}


export default injectIntl(connect(null, {
})(ProfileCompetenciesSectionUserDefined));
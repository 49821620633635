import * as types from './types';
import { apiCon } from '../../../API';
import history from '../../../utils/routes/history';
import { langService } from '../../../utils/languageService';
import { DeselectTask, DeselectMyTask } from './../tasks/index';
import { CreateDocumentsStart } from './../documents/index';
import { getParameterObject, getUrlParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS } from '../../reducers/browse/projectsReducer';
import { GetMyMilestonesStart, GetMilestonesStart } from './../milestone/index';

//----------------------
// Actions Projects
//----------------------

const ALLOWED_FILTERS = ['advanced', 'limit', 'search', 'ordering', 'category', 'budget_min', 'budget_max', 'date_from', 'date_to', 'status', 'my_projects']
//----------------------
// Actions Change Projects List Filters 
//----------------------
export const ChangeProjectFiltersStart = (params = INITIAL_STATE_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_FILTERS);
    const payload = { succ_msg: "Successfully change projects filters.", filters: filters }
    return { type: types.CHANGE_PROJECT_FILTERS, payload: payload };
}

//----------------------
// Actions Get Projects List
//----------------------
export const GetProjectsStart = (params = INITIAL_STATE_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_PROJECTS_REQUEST });
    const url = '/projects/'
    const filters = getUrlParameterObject(params, ALLOWED_FILTERS)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetProjectsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetProjectsFail(err.response));
        })
}
export const GetProjectsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_PROJECTS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetProjectsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetProjectsFail(err.response));
        })
}
export const GetProjectsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received projects info.", list: data, add: add }
    return { type: types.GET_PROJECTS_SUCCESS, payload: payload };
}
export const GetProjectsFail = (err) => {
    var toast_msg = "Ooops! Get Projects failed. Please try again.";
    if (err.data) {
        const err_msg = { errorType: err.data.error, detail: err.data.detail };
        if (err.data.error_first_message) { toast_msg = "Get projects failed. " + err.data.error_first_message; };
        const status = err.status;
        const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
        return { type: types.GET_PROJECTS_FAILURE, payload: payload }
    } else {
        const payload = { err_msg: 'GetProjects uknown error', status: 'Uknown', toast_msg: toast_msg };
        return { type: types.GET_PROJECTS_FAILURE, payload: payload }
    }
}

//----------------------
// Actions Select Project
//----------------------
export const SelectProjectStart = (id, deselectTask = true) => async dispatch => {
    dispatch({ type: types.SELECT_PROJECT_REQUEST });
    await apiCon.get('/project/' + id)
        .then(response => {
            dispatch(SelectProjectSuccess(response.data, deselectTask));
            dispatch(GetMilestonesStart(id))
        }
        )
        .catch(err => {
            dispatch(SelectProjectFail(err.response));
        })
}
export const SelectProjectSuccess = (data, deselectTask) => async dispatch => {
    const payload = { succ_msg: "Successfully received projects info.", selected: data }
    dispatch({ type: types.SELECT_PROJECT_SUCCESS, payload: payload });
    if (deselectTask)
        dispatch(DeselectTask());
}
export const SelectProjectFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select Project failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select project failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_PROJECT_FAILURE, payload: payload };
}
export const DeselectProject = () => async dispatch => {
    const payload = { succ_msg: "Successfully deselected project info." }
    dispatch({ type: types.DESELECT_PROJECT, payload: payload });
    dispatch(DeselectTask())
}

//----------------------
// Actions My Projects
//----------------------

//----------------------
// Actions Get My Projects List
//----------------------
export const GetMyProjectsStart = (limit = 10, search, status = ['DRAFT', 'PUBLISHED', 'ACTIVE', 'COMPLETED'], ordering) => async dispatch => {
    dispatch({ type: types.GET_MY_PROJECTS_REQUEST });
    var url = '/me/projects/'
    const params = {
        'limit': limit,
        'search': search,
        'status': status,
        'ordering': ordering,
    }
    const filters = getUrlParameterObject(params)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMyProjectsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyProjectsFail(err.response));
        })
}
export const GetMyProjectsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_PROJECTS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyProjectsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetMyProjectsFail(err.response));
        })
}
export const GetMyProjectsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received projects info.", list: data, add: add }
    return { type: types.GET_MY_PROJECTS_SUCCESS, payload: payload };
}
export const GetMyProjectsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Projects failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Projects failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_PROJECTS_FAILURE, payload: payload }
}

//----------------------
// Actions Select My Project
//----------------------
export const SelectMyProjectStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_PROJECT_REQUEST });
    await apiCon.get('me/project/' + id)
        .then(response => {
            dispatch(SelectMyProjectSuccess(response.data));
            dispatch(GetMyMilestonesStart(id))
        }
        )
        .catch(err => {
            dispatch(SelectMyProjectFail(err.response));
        })
}
export const SelectMyProjectSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received projects info.", selected: data }
    dispatch(DeselectMyTask())
    dispatch({ type: types.SELECT_MY_PROJECT_SUCCESS, payload: payload });
}
export const SelectMyProjectFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Project failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Project failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_PROJECT_FAILURE, payload: payload }
}
export const DeselectMyProject = () => async dispatch => {
    const payload = { succ_msg: "Successfully deselected my project info." }
    dispatch(DeselectMyTask());
    dispatch({ type: types.DESELECT_MY_PROJECT, payload: payload });
}

//----------------------
// Actions Update Project
//----------------------
export const UpdateProjectStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_PROJECT_REQUEST });
    const address = `/me/project/` + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateProjectSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateProjectFail(err.response));
        });

}
export const UpdateProjectSuccess = (data) => async dispatch => {
    const succ_msg = "Project updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch(DeselectMyTask());
    dispatch({ type: types.UPDATE_PROJECT_SUCCESS, payload: payload });
}
export const UpdateProjectFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Project failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Update project failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.UPDATE_PROJECT_FAILURE, payload: payload };
}

//----------------------
// Actions Project Logo Picture
//----------------------
export const UploadProjectLogoPictureStart = (projectId, file) => async dispatch => {
    dispatch({ type: types.UPLOAD_PROJECT_LOGO_PICTURE_REQUEST });
    const formData = new FormData();
    formData.append('logo_image', file);
    const address = `/me/project/` + projectId;
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await apiCon.patch(address, formData, config)
        .then((response) => {
            dispatch(UploadProjectLogoPictureSuccess(response.data));
        })
        .catch(err => {
            dispatch(UploadProjectLogoPictureFail(err.response));
        })
}
export const UploadProjectLogoPictureSuccess = (data) => {
    const succ_msg = "Successfully updated projects logo image.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPLOAD_PROJECT_LOGO_PICTURE_SUCCESS, payload: payload };
}

export const UploadProjectLogoPictureFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["project.upload.logo_pic.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPLOAD_PROJECT_LOGO_PICTURE_FAILURE, payload: payload };
}

//----------------------
// Actions Project Cover Picture
//----------------------
export const UploadProjectCoverPictureStart = (projectId, file) => async dispatch => {
    dispatch({ type: types.UPLOAD_PROJECT_COVER_PICTURE_REQUEST });
    const formData = new FormData();
    formData.append('cover_image', file);
    const address = `/me/project/` + projectId;
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await apiCon.patch(address, formData, config)
        .then((response) => {
            dispatch(UploadProjectCoverPictureSuccess(response.data));
        })
        .catch(err => {
            dispatch(UploadProjectLogoPictureFail(err.response));
        })
}
export const UploadProjectCoverPictureSuccess = (data) => {
    const succ_msg = "Successfully updated projects cover image.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPLOAD_PROJECT_COVER_PICTURE_SUCCESS, payload: payload };
}

export const UploadProjectCoverPictureFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["project.upload.cover_pic.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPLOAD_PROJECT_COVER_PICTURE_FAILURE, payload: payload };
}

//----------------------
// Actions Create Project
//----------------------
export const CreateProjectStart = (values, logoImage, coverImage, privateFiles) => async dispatch => {
    dispatch({ type: types.CREATE_PROJECT_REQUEST });
    let config = null;
    let body = null;
    if (logoImage || coverImage) {
        config = { headers: { 'content-type': 'multipart/form-data' } }
        body = new FormData()
        for (let key in values) {
            body.append(key, values[key]);
        }
        body.append('logo_image', logoImage ?? '');
        body.append('cover_image', coverImage ?? '');
    }
    else {
        config = { headers: { 'Content-Type': 'application/json' } };
        values['logo_image'] = null
        values['cover_image'] = null;
        body = JSON.stringify(values);
    }
    const address = `/me/projects/`;
    await apiCon.post(address, body, config)
        .then(response => {
            return new Promise((resolve) => {
                dispatch(CreateProjectSuccess(response.data));
                resolve();
            }).then(array => {
                if (privateFiles) {
                    dispatch(CreateDocumentsStart(privateFiles, response.data.id))
                }
            });
        })
        .catch(err => {
            dispatch(CreateProjectFail(err.response));
        });

}
export const CreateProjectSuccess = (data) => async dispatch => {
    const succ_msg = "Project created successfully! You can now add tasks and publish the project.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch(DeselectMyTask());
    dispatch({ type: types.CREATE_PROJECT_SUCCESS, payload: payload });
    history.push('/project-task/new/' + data.id)
}
export const CreateProjectFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Project failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create project failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_PROJECT_FAILURE, payload: payload };
}


import { GET_MY_GAMIFICATION_SUCCESS, BOOST_MY_PROJECT_SUCCESS, GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS, GET_MY_GAMIFICATION_BADGES_SUCCESS, GET_GAMIFICATION_EVENTS_SUCCESS, GET_GAMIFICATION_PACKAGES_SUCCESS, GET_PROJECT_AVAILABLE_BOOSTS_SUCCESS, GET_PACKAGE_COST_ESTIMATE_SUCCESS } from "../../actions/gamification/types";
import gamificationAchievementReducer from './userGamificationReducer/gamificationAchievementsReducer';
import gamificationBadgesReducer from './userGamificationReducer/gamificationBadgesReducer';
import gamificationEventsReducer from './userGamificationReducer/gamificationEventsReducer';
import gamificationPackagesReducer from "./userGamificationReducer/gamificationPackagesReducer";

const INITIAL_STATE = {
    credits: "0.00",
    init_points: "0.00",
    is_locked: false,
    level: null,
    levels: 1,
    max_points: "0.00",
    points: "0.00",
    achievements: gamificationAchievementReducer,
    badges: gamificationBadgesReducer,
    events: gamificationEventsReducer,
    packages: gamificationPackagesReducer,
    project_boost: {
        tasks: [],
        full_price: "",
        discount: "",
        discounted_price: ""
    },
    package_cost_estimate: {
        estimated_cost: ""
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_MY_GAMIFICATION_SUCCESS:
            return {
                ...state, ...action.payload.data,
            }
        /* case BOOST_MY_TASK_SUCCESS:
            return { ...state, ...action.payload.data } */
        case BOOST_MY_PROJECT_SUCCESS:
            return { ...state, ...action.payload.data }
        //ACHIEVEMENTS
        case GET_MY_GAMIFICATION_ACHIEVEMENTS_SUCCESS:
            return {
                ...state, ...action.payload.data,
                achievements: gamificationAchievementReducer(state.achievements, action)
            }
        //BADGES
        case GET_MY_GAMIFICATION_BADGES_SUCCESS:
            return {
                ...state, ...action.payload.data,
                badges: gamificationBadgesReducer(state.badges, action)
            }
        //EVENTS
        case GET_GAMIFICATION_EVENTS_SUCCESS:
            return {
                ...state, ...action.payload.data,
                events: gamificationEventsReducer(state.events, action)
            }
        case GET_GAMIFICATION_PACKAGES_SUCCESS:
            return {
                ...state, ...action.payload.data,
                packages: gamificationPackagesReducer(state.packages, action)
            }
        case GET_PACKAGE_COST_ESTIMATE_SUCCESS:
            return {
                ...state, ...action.payload.data,
            }
        case GET_PROJECT_AVAILABLE_BOOSTS_SUCCESS:
            return {
                ...state, project_boost: {
                    ...state.project_boost, ...action.payload.project_boost,
                    project_boost: action.payload.add ? [...state.project_boost.results, ...action.payload.project_boost.results] : action.payload.project_boost.results
                }
            }
        default:
            return state;
    }
}
import React from 'react';
import AsyncSelect from 'react-select/async';
import { injectIntl, FormattedMessage } from 'react-intl';
import { apiCon } from '../../../API';
import { getBloomVerb, getBloomName } from '../../../utils/common/misc';
import { langService } from '../../../utils/languageService';
import { getUrlParameterObject } from '../../../utils/common/index';

// https://react-select.com/styles
const colourStyles = {
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: 'rgba(0, 141, 206, 0.1)'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'rgba(0, 141, 206, 1)',
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: 'rgba(0, 141, 206, 0.8)',
        ':hover': {
            backgroundColor: 'rgba(0, 141, 206, 0.1)',
            color: 'rgba(0, 141, 206, 1)',
        },
    }),
};

// https://react-select.com/async
class CustomSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected ? this.props.selected : []
        };
    }
    
    loadData = (inputValue) => {
        const url = '/competences/'
        const params = {
            'limit': 10,
            ...(this.props.issuers ? { 'issuers':  this.props.issuers[0] } : {}),
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        const filters = getUrlParameterObject(params);
        return apiCon.get(url, { params: filters }).then(response =>
            response.data.results.map(x => {
                return {
                    value: x.id.toString(),
                    label: x.name,
                    level: x.level
                }
            }))
            .catch(err => {
                process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
            })
    };

    getOptionLabel = option => {
        if (!option.label || !option.level) return null;
        return (
            <div><strong>{option.label}</strong> <small>({getBloomName(option.level)} - {getBloomVerb(1, option.level, langService.locale)})</small></div>
        );
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        return inputValue;
    };

    addRemoveSelected = (values) => {
        this.setState({ selected: values });
        this.props.onSelectedChange(values);
    }

    render() {
        const { issuers, isMulti, getOptionLabel, loadData, placeholder } = this.props;
        return (
            <div>
                <AsyncSelect
                    key={JSON.stringify(issuers)}
                    isMulti={isMulti ?? false}
                    blurinputonselect="true"
                    cacheOptions={false}
                    defaultOptions={true}
                    value={this.state.selected}
                    loadOptions={loadData ?? this.loadData}
                    placeholder={<FormattedMessage id={placeholder ?? 'competences.search'} defaultMessage={`${placeholder} message not defined`} />}
                    onInputChange={this.handleInputChange}
                    getOptionLabel={getOptionLabel ?? this.getOptionLabel}
                    onChange={this.addRemoveSelected}
                    styles={colourStyles}
                    maxMenuHeight={165}
                    // menuPortalTarget={document.body} 
                />
            </div>
        );
    }
}

export default injectIntl(CustomSelect);
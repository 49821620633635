import React from 'react';
import '../../containers/Profile/index.scss';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalEditFreelancerExperience from './modalEditFreelancerExperience';



class ProfileExperienceSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalFreelancerExperienceVisible: false
        };
    }


    render() {
        const { role } = this.props;
        return (
            <>
            <div className="card p-0">
                <span className="icon-edit" onClick={() => this.setState({ modalFreelancerExperienceVisible: true })}></span>
                <div className="card-head"><FormattedMessage id="profile.experience" defaultMessage="Experience" /></div>
                <div className="card-body pb-0">
                    {role.experiences?.list?.length > 0
                        ?
                        <div className="section-rows">
                            {role.experiences.list.map((e, i) =>
                                <div className="section-row" key={i}>
                                    {/* <Image src="/assets/img/default-ex.png" className="sec-img"></Image> */}
                                    <div className="sec-info">
                                        <div className="">{e.company_name}</div>
                                        <div className="">{e.title}</div>
                                        <div className="">{e.date_from && e.date_from.substring(0, 4)} - {e.date_to && e.date_to.substring(0, 4)}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className="p-section empty-section">
                            <div className="">
                                <Image src="/assets/img/svg/experience.svg" className="section-img"></Image>
                            </div>
                            <div className="">
                                <div className="">
                                    <FormattedMessage id="experience.add.desc" defaultMessage="Make your profile more credible by adding your experience." />
                                </div>
                                <div className="add-link" onClick={() => this.setState({ modalFreelancerExperienceVisible: true })}>
                                    <FormattedMessage id="experience.add" defaultMessage="Add Experience" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {this.state.modalFreelancerExperienceVisible &&
                <ModalEditFreelancerExperience
                    experiences={role.experiences?.list}
                    visible={this.state.modalFreelancerExperienceVisible}
                    onClose={() => this.setState({ modalFreelancerExperienceVisible: false })} />
            }
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
})(ProfileExperienceSection));
import { GET_COMPETENCES_MINT_SUCCESS, CHANGE_COMPETENCES_MINT_FILTERS } from "../../actions/blockchain/competences/types";

export const INITIAL_STATE_FILTERS = {
    limit: 10,
    search: '',
    ordering: '',
    issuers: null,
    unclaimed_only: true,
    token_status: ''
}

const INITIAL_STATE = {
    competencesMint: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case CHANGE_COMPETENCES_MINT_FILTERS:
            return {
                ...state.competencesMint,
                filters: { ...state.competencesMint.filters, ...action.payload.filters }
            }
        case GET_COMPETENCES_MINT_SUCCESS:
            return {
                ...state.competencesMint, ...action.payload.competencesMint,
                results: action.payload.add ? [...state.competencesMint.results, ...action.payload.competencesMint.results] : action.payload.competencesMint.results
            };
        default:
            return state;
    }
}
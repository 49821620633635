import * as types from './types';
import { apiCon } from '../../../API';

//----------------------
// Actions Chat
//----------------------




//----------------------
// Actions Get Chats
//----------------------
export const GetChatsStart = (limit = 10) => async dispatch => {
    dispatch({ type: types.GET_CHATS_REQUEST });
    var url = '/me/messaging/chats/?limit=' + limit
    await apiCon.get(url)
        .then(response => {
            dispatch(GetChatsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetChatsFail(err.response));
        })
}
export const GetChatsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_CHATS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetChatsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetChatsFail(err.response));
        })
}
export const GetChatsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Chats.", list: data, add: add }
    return { type: types.GET_CHATS_SUCCESS, payload: payload };
}
export const GetChatsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Chats failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_CHATS_FAILURE, payload: payload }
}

//----------------------
// Actions Select Chat
//----------------------
export const SelectChatStart = (senderId, taskId) => async dispatch => {
    dispatch({ type: types.SELECT_CHAT_REQUEST });
    var url = '/me/messaging/chat/?sender=' + senderId
    if (taskId)
        url += '&task=' + taskId
    await apiCon.get(url)
        .then(response => {
            dispatch(SelectChatSuccess(response.data, senderId, taskId));
        }
        )
        .catch(err => {
            dispatch(SelectChatFail(err.response));
        })
}
export const SelectChatSuccess = (data, userId, taskId) => {
    const payload = { succ_msg: "Successfully received Chat info.", selected: { taskId: taskId, userId: userId, messages: data } }
    return { type: types.SELECT_CHAT_SUCCESS, payload: payload };
}
export const SelectChatFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Chat failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_CHAT_FAILURE, payload: payload };
}
export const DeselectChat = () => {
    const payload = { succ_msg: "Successfully deselected Chat info." }
    return { type: types.DESELECT_CHAT, payload: payload };
}

//----------------------
// Actions Send Message
//----------------------
export const SendMessageStart = (receiverId, taskId, message, files) => async dispatch => {
    dispatch({ type: types.SEND_MESSAGE_REQUEST });
    var fileIds = [];

    //Files
    if (files) {
        const address = '/me/messaging/attachments/';
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            await apiCon.post(address, formData, config)
                .then((response) => {
                    fileIds.concat(response.data['id']);
                })
                .catch(err => {
                    dispatch(SendMessageFail(err.response));
                })
        };
    }

    const json = {
        "task": taskId,
        "receiver": receiverId,
        "message": message,
        "attachments": fileIds
    }
    const address = '/me/messaging/send/';
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(SendMessageSuccess(response.data));
        })
        .catch(err => {
            dispatch(SendMessageFail(err.response));
        });
}
export const SendMessageSuccess = (data) => {
    const succ_msg = "Message sent successfully!";
    const payload = { succ_msg: succ_msg, message: data };
    return { type: types.SEND_MESSAGE_SUCCESS, payload: payload };
}
export const SendMessageFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Send message failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SEND_MESSAGE_FAILURE, payload: payload };
}
import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CompleteSubmitTaskStart } from '../../core/actions/tasks';
import { SelectTaskDisputeSettlement, CreateTaskDisputeSettlementStart, UpdateTaskDisputeSettlementStart } from '../../core/actions/taskDisputeSettlement';
import { CreateDisputeSettlementTopicStart } from '../../core/actions/taskDisputeSettlementTopic';
import { createErrorMessageSelector, createLoadingSelector } from './../../API/selector';
import AddRemoveInputFields from './addRemoveInputFields';
import ModalAlert from '../../components/Common/ModalAlert/modalAlert';
import ModalFileDisputeConfirmation from './modalFileDisputeConfirmation';
import SectionResolutionServiceInformation from '../../components/Audit/sectionResolutionServiceInformation';
import SectionDisputeResolutionServiceCosts from '../../components/Audit/sectionResolutionServiceCosts';

class ResolutionCenterPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // comment: '',
            // isValid: '',
            modalVisible: false,
            step: 1,
            proposed_percentage: this.props.settlement?.proposed_percentage ? this.props.settlement?.proposed_percentage : 0,
            proposed_amount: this.props?.task?.price,
            alreadyCreated: false,
            modalAlertVisible: false,
        };
    }

    componentDidMount() {
        // this.props.SelectTaskDisputeSettlement(this.props.task.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            if (this.props.settlement.status === 'IN_NEGOTIATION') {
                this.setState({ modalVisible: false });
                history.push('/my-projects/' + this.props.task.project.id + '/' + this.props.task.id + '/1');
            }
        }
    }

    openConfirmationModal() {
        this.setState({ modalVisible: true });
    }

    handleChange = input => event => {
        const pAmount = parseFloat(this.props.task.price * (event.target.value / 100));
        this.setState({ [input]: event.target.value, proposed_amount: pAmount });
    }

    next() {

        this.state.proposed_percentage >= 5 && this.state.proposed_percentage < 101 ?
            this.props.settlement.task ?
                this.props.UpdateTaskDisputeSettlementStart(this.props.task.id, 'CREATE', this.props.settlement.last_task_dispute_settlement_change, this.state.proposed_percentage).then(res => {
                    this.setState({ step: 2, alreadyCreated: true });
                })
                :
                this.props.CreateTaskDisputeSettlementStart(this.props.task.id, this.state.proposed_percentage).then(res => {
                    this.setState({ step: 2, alreadyCreated: true });
                })
            :
            this.setState({ modalAlertVisible: true })
    }

    back() {
        this.setState({ step: 1 });
    }

    onConfirm() {
        this.props.UpdateTaskDisputeSettlementStart(this.props.task.id, 'SEND_IN_NEGOTIATION', this.props.settlement.last_task_dispute_settlement_change)
            .then(x => this.setState({ modalVisible: false }));
    }

    renderDisputeDetails() {
        const { task } = this.props;
        return (
            <div className="body-small-text">

                <SectionDisputeResolutionServiceCosts task={task} />

                <hr />

                {this.state.step === 1 &&
                    <>
                        <Row className="mb-3">
                            <Col>
                                <span className="propose-label">
                                    <FormattedMessage id="resolutionCenter.proposePercentage" defaultMessage="I propose to resolve this by paying the following percentage (%) of the agreed amount" /> :
                                </span>
                            </Col>
                        </Row>
                        <div className="proposed-amount-wrap">
                            <input type="number" className="form-control proposed-amount" min="5" max="100" value={parseInt(this.state.proposed_percentage)} onChange={this.handleChange('proposed_percentage')}></input>
                            <span className="proposed-amount-info">% = $ {parseFloat(this.state.proposed_amount).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                    </>
                }

                {this.state.step === 2 &&
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <strong>
                                    <FormattedMessage id="resolutionCenter.keyPoints" defaultMessage="Problematic keypoints (add your comments)" /> :
                                </strong>
                            </div>
                            <AddRemoveInputFields />
                        </Col>
                    </Row>
                }

                <div className="modal-submit-buttons">
                    {this.state.step === 1 &&
                        <div className="btn btn-default btn-projects-inv" onClick={() => this.next()}><FormattedMessage id="common.next" defaultMessage="Next" /></div>
                    }
                    {this.state.step === 2 &&
                        <div className="btn btn-default btn-projects-inv" onClick={() => this.openConfirmationModal()}><FormattedMessage id="auditor.file_dispute_proposal" defaultMessage="FILE A DISPUTE PROPOSAL" /></div>
                    }
                </div>
                <ModalFileDisputeConfirmation visible={this.state.modalVisible} taskId={task.id} onConfirm={() => this.onConfirm()} onClose={() => this.setState({ modalVisible: false })} />
                {this.state.modalAlertVisible &&
                    <ModalAlert
                        title="common.alertTitle"
                        text='common.alertTextDisputeCase'
                        visible={this.state.modalAlertVisible}
                        onClose={() => this.setState({ modalAlertVisible: false })}
                    />
                }
            </div>
        )
    }

    render() {
        const { task } = this.props;
        return (
            <Container className="mt-4">
                <Row>
                    <Col>
                        <SectionResolutionServiceInformation />

                        <Card className="p-0">
                            <div className="card-head"><FormattedMessage id="auditor.dispute_case_details" defaultMessage="Dispute Case Details" /></div>
                            <div className="card-body">
                                {(!task.id || task.status !== "SUBMITTED") && <div className="body-small-text"><FormattedMessage id="resolutionCenter.noTaskSelected" defaultMessage="No task selected. Go to" /> <Link to="/my-projects" onClick={() => history.push("/my-projects")} >
                                    <FormattedMessage id="nav.labels.myProjects" defaultMessage="My Projects" />...</Link></div>}
                                {(task.id && task.status === "SUBMITTED") && this.renderDisputeDetails()}
                            </div>
                        </Card>

                    </Col>
                </Row>
            </Container>
        )
    }
}
const loadingSelector = createLoadingSelector(['UPDATE_TASK_DISPUTE_SETTLEMENT']);
const errorSelector = createErrorMessageSelector(['UPDATE_TASK_DISPUTE_SETTLEMENT']);
const mapStateToProps = state => {
    return {
        task: state.myWork.myTasks.selected,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        settlement: state.myWork.myDisputeSettlements.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    // SelectMyTaskStart,
    CompleteSubmitTaskStart,
    SelectTaskDisputeSettlement,
    CreateTaskDisputeSettlementStart,
    UpdateTaskDisputeSettlementStart,
    CreateDisputeSettlementTopicStart
})(ResolutionCenterPage));
import './index.scss';
import './DropdownUserMenu.scss';
import React from 'react';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { LogoutStart } from '../../core/actions/auth';
import { Nav, Row, Col, Image } from 'react-bootstrap';
import { ChangeRoleStart } from '../../core/actions/role';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';
import { langService } from '../../utils/languageService';

class DropdownUserMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = { isOpen: false }
    }

    handleOpen = () => {
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    handleItemClick = (link) => {
        this.setState({ isOpen: false });
        history.push(link);
    }

    handleChangeRoleClick = (roleId) => {
        this.setState({ isOpen: false });
        this.props.ChangeRoleStart(roleId)
    }

    getAddRoleName = () => {
        const res = this.checkRoles();
        if (res === 'dev') {
            return langService.messages['roles.become_freelancer'];
        } else if (res === 'inv') {
            return langService.messages['roles.become_client'];
        } else {
            return langService.messages['roles.add_role'];
        }
    }

    checkRoles = () => {
        if (this.props.roles.some(e => e.type === 'INV') && !this.props.roles.some(e => e.type === 'DEV')) {
            return 'dev';
        } else if (!this.props.roles.some(e => e.type === 'INV') && this.props.roles.some(e => e.type === 'DEV')) {
            return 'inv';
        } else {
            return 'edu';
        }
    }

    render() {
        let { user: { settings, roles }, hasRole, wallet: { balances }, chips } = this.props;

        return (
            <Dropdown as={Nav.Item}
                align="end"
                onMouseEnter={this.handleOpen}
                onMouseLeave={this.handleClose}
                show={this.state.isOpen}
                style={{ marginTop: '0' }}
            >
                <Dropdown.Toggle as={Nav.Link} className={this.props.transparent ? "nav-link-landing" : "nav-link"}>
                    {settings?.active_role_obj?.profile_image
                        ? <img className="nav-user-img" src={settings.active_role_obj.profile_image} alt="Active profile thumb"></img>
                        : <span className={`${settings?.active_role_obj?.type ? 'nav-' + settings.active_role_obj.type.toLowerCase() : 'nav-dev'}`}>{settings?.active_role_obj?.name?.charAt(0)?.toUpperCase()}</span>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right">

                    {/* ACTIVE ROLE */}
                    {hasRole &&
                        <div className="dd-active">
                            {settings?.active_role_obj?.profile_image
                                ?
                                <img className="dd-role-withimg-active" src={settings.active_role_obj.profile_image} alt="Active profile thumb"></img>
                                :
                                <span className={`dd-${settings?.active_role_obj?.type?.toLowerCase()}-img-active`}>{settings?.active_role_obj?.name?.charAt(0)?.toUpperCase()}</span>
                            }
                            <div style={{ width: "135px" }}>
                                <span className="title">{settings?.active_role_obj?.name}</span>
                                <span className="subtitle pb-2"><FormattedMessage id={"roles.name." + settings.active_role_obj?.type} defaultMessage="Developer" /></span>
                                <button className="btn btn-default btn-developers btn-block pr-0 pl-0 pt-1 pb-1" onClick={() => this.handleItemClick("/profile")}>
                                    <FormattedMessage id="DropdownUserMenu.myprofile" defaultMessage="My Profile" />
                                </button>
                            </div>
                        </div>
                    }
                    {/* ROLES */}
                    {(settings?.active_role_obj?.type !== 'EAS' && settings?.active_role_obj?.type !== 'EAP' && settings?.active_role_obj?.type !== 'EAC') &&
                        <>
                            <Dropdown.Divider />
                            <div className="dd-roles">
                                {
                                    roles?.filter(role => role.id !== settings?.active_role)?.map((role) =>
                                        <div onClick={() => this.handleChangeRoleClick(role.id)} className="dd-role" key={role.id}>
                                            {role.profile_image
                                                ?
                                                <img className="dd-role-withimg" src={role.profile_image} alt="Role thumb"></img>
                                                :
                                                <span className={`dd-${role?.type?.toLowerCase()}-img`}>{role.name.charAt(0).toUpperCase()}</span>
                                            }
                                            <div>
                                                <span className="title">{role.name}</span>
                                                <span className="subtitle"><FormattedMessage id={"roles.name." + role?.type} defaultMessage="Developer" /></span>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    roles?.length < 4 &&
                                    <button className="btn btn-default btn-generic btn-block pt-1 pb-1" onClick={() => this.handleItemClick("/auth/register-role")}>{this.getAddRoleName()}</button>
                                }
                            </div>
                        </>
                    }

                    <Dropdown.Divider />
                    {hasRole &&
                        <>
                            <Dropdown.Item onClick={() => this.handleItemClick("/settings")}>
                                <FormattedMessage id="DropdownUserMenu.settings" defaultMessage="Settings" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => this.handleItemClick("/profile/gamification")}>
                                <FormattedMessage id="DropdownUserMenu.gamification" defaultMessage="Gamification" />
                            </Dropdown.Item>
                        </>
                    }

                    {/* WALLET */}
                    <Dropdown.Item onClick={() => this.handleItemClick("/wallet")} >
                        <FormattedMessage id="DropdownUserMenu.myWallet" defaultMessage="My wallet" />
                    </Dropdown.Item>
                    <div className="h-balance" onClick={() => this.handleItemClick("/wallet")} >
                        <Row className="center-content mb-2">
                            <Col sm="3" className="crypto-logo-p">
                                <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'ethereum.png' : 'bnb.png')} className="crypto-logo" />
                            </Col>
                            <Col className="text-left balance align-self-center">
                                {Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 6 })}{" " + REACT_APP_NETWORK_CURRENCY()}
                            </Col>
                        </Row>
                        <Row className="center-content mb-2">
                            <Col sm="3" className="crypto-logo-p align-self-center">
                                <Image src="/assets/img/dai.png" className="crypto-logo" />
                            </Col>
                            <Col className="text-left balance">
                                {Number(parseFloat(balances?.DAI)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}{" DAI"}
                            </Col>
                        </Row>
                        <Row className="center-content mb-2">
                            <Col sm="3" className="crypto-logo-p align-self-center">
                                <Image src="/assets/img/icon/chip.svg" className="crypto-logo" style={{ height: '24px' }} />
                            </Col>
                            <Col className="text-left balance">
                                {Number(parseFloat(chips)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}{" CHIPs"}
                            </Col>
                        </Row>
                    </div>

                    <Dropdown.Divider />

                    <Dropdown.Item onClick={() => this.handleItemClick("/help")}>
                        <FormattedMessage id="DropdownUserMenu.help" defaultMessage="Help" />
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => this.props.LogoutStart()}><FormattedMessage id="nav.labels.logout" defaultMessage="logout" /></Dropdown.Item>

                </Dropdown.Menu>

            </Dropdown >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info,
        hasRole: state.user.info.settings.active_role,
        roles: state.user.info.roles,
        wallet: state.user.wallet,
        chips: state.user.gamification.credits,
    }
}

export default connect(mapStateToProps, { LogoutStart, ChangeRoleStart })(DropdownUserMenu);
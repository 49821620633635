import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatDateRange } from '../../utils/common/index';

class SectionResolutionServiceCosts extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }
    
    getCosts(price) {
        const cost = parseFloat(price) * 0.05;
        if (cost <= 5) {
            return "$5"
        } else {
            return "$" + cost.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " (5% of task price)";
        }
    }

    render() {
        const { task } = this.props;
        if (!task) { return null }
        return (
            <>
                <Row className="mb-3">
                    <Col sm="3">
                        <strong>
                            <FormattedMessage id="common.task" defaultMessage="Task" /> :
                            </strong>
                    </Col>
                    <Col sm="9">
                        <strong><Link to={"/my-projects/" + task.project.id + "/" + task.id}>{task.name}</Link></strong>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="3">
                        <strong>
                            <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" /> :
                            </strong>
                    </Col>
                    <Col sm="9">
                        <strong><Link to={"/user/" + task.active_freelancer.id}>{task.active_freelancer.name}</Link></strong>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="3">
                        <strong>
                            <FormattedMessage id="resolutionCenter.agreedTerms" defaultMessage="Agreed Terms" /> :
                            </strong>
                    </Col>
                    <Col sm="9">
                        <div>
                            <FormattedMessage id="forms.labels.price" defaultMessage="Price" />:&nbsp;
                                <strong>${parseFloat(task.price).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></div>
                        <div>
                            <FormattedMessage id="browse.project.timeframe" defaultMessage="Timeframe" />:&nbsp;
                                <strong>{formatDateRange(new Date(task.start_date), new Date(task.end_date))}</strong> </div>
                        <div>
                            <FormattedMessage id="projects.competences" defaultMessage="Skills" />:&nbsp;
                                <strong>{task.competences.map((el, i) => <span key={i}>{el.name}_{el.level}, </span>)}</strong>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="3">
                        <strong>
                            <FormattedMessage id="resolutionCenter.costOfDispute" defaultMessage="Cost of dispute audit" /> :
                            </strong>
                    </Col>
                    <Col sm="9">
                        <strong>{this.getCosts(task.price)}</strong>
                    </Col>
                </Row>
            </>
        )
    }

}

export default injectIntl(connect(null, { })(SectionResolutionServiceCosts));
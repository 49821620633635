import * as types from './types';
import { apiCon } from '../../../API';
import { getParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS_MY as INITIAL_MY_COMPETENCES_FILTERS } from '../../reducers/myInstitution/myCompetencesReducer';
//import { langService } from '../../../utils/languageService';

const ALLOWED_MY_COMPETENCES_FILTERS = ['issuers'];

export const ChangeMyCompetencesFiltersStart = (params = INITIAL_MY_COMPETENCES_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_MY_COMPETENCES_FILTERS);
    const payload = { succ_msg: "Successfully change skills filters.", filters: filters }
    return { type: types.CHANGE_MY_COMPETENCES_FILTERS, payload: payload };
}

//--------------------------
// Get my competences
//--------------------------

export const GetMyCompetencesStart = (params = ALLOWED_MY_COMPETENCES_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_MY_COMPETENCES_REQUEST });
    let filters = getParameterObject(params, ALLOWED_MY_COMPETENCES_FILTERS);
    await apiCon.get(`/competences/`, { params: filters })
        .then(response => {
            dispatch(GetMyCompetencesSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetMyCompetencesFail(err.response));
        })
}

export const GetMyCompetencesSuccess = (data, add = false) => async dispatch => {
    const payload = { succ_msg: "Successfully received Competences info.", list: data, add: add } //"Successfully recieved my issuers.";
    dispatch({ type: types.GET_MY_COMPETENCES_SUCCESS, payload: payload });
}

export const GetMyCompetencesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competences failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_COMPETENCES_FAILURE, payload: payload };
}

//--------------------------
// Select my competences
//--------------------------

export const SelectMyCompetenceStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_COMPETENCE_REQUEST });
    await apiCon.get('/competence/' + id)
        .then(response => {
            dispatch(SelectMyCompetenceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyCompetenceFail(err.response));
        })
}

export const SelectMyCompetenceSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Competence info.", selected: data }
    return { type: types.SELECT_MY_COMPETENCE_SUCCESS, payload: payload };
}

export const SelectMyCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competence failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_COMPETENCE_FAILURE, payload: payload };
}

export const DeselectMyCompetence = () => {
    const payload = { succ_msg: "Successfully deselected Competence info." }
    return { type: types.DESELECT_MY_COMPETENCE, payload: payload };
}

//--------------------------
// Update my competences
//--------------------------

export const UpdateMyCompetenceStart = (id, values) => async dispatch => {
    dispatch({ type: types.UPDATE_MY_COMPETENCE_REQUEST });
    const json = JSON.stringify(values);
    const address = `/competence/` + id;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateMyCompetenceSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateMyCompetenceFail(err.response));
        });
}

export const UpdateMyCompetenceSuccess = (data) => {
    const succ_msg = "Competence updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPDATE_MY_COMPETENCE_SUCCESS, payload: payload };
}

export const UpdateMyCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Update Competence failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_MY_COMPETENCE_FAILURE, payload: payload };
}
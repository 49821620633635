import './index.scss';
import React from "react";
import img from '../../assets/img/how-it-works-2.svg';
import { ImageMap } from "@qiuz/react-image-map";
import history from '../../utils/routes/history';


const onMapClick = (area, index) => {
    switch (index) {
        case 0:
            history.push('/help/client');
            break;
        case 1:
            history.push('/help/auditor');
            break;
        case 2:
            history.push('/help/freelancer');
            break;
        case 3:
            history.push('/help/educational-institution');
            break;
        case 4:
            history.push('/help/skills');
            break;
        default:
            break;
    }
};

const mapArea = [
    {
        width: "14.818181818181813%",
        height: "5.403693931398417%",
        left: "45.29074529074529%",
        top: "25.36499560246262%",
        className: "image-map__map usage-map_map_span-0 img-map"
    }, {
        width: "16.953316953316953%",
        height: "5.364995602462621%",
        left: "3.6970208845208843%",
        top: "73.08707124010554%",
        className: "image-map__map usage-map_map_span-1 img-map"
    }, {
        width: "17.133956386292834%",
        height: "4.682274247491638%",
        left: "64.6831191588785%",
        top: "85.11705685618729%",
        className: "image-map__map usage-map_map_span-2 img-map"
    }, {
        width: "17.91277258566978%",
        height: "5.518394648829431%",
        left: "75.89807242990653%",
        top: "51.00334448160535%",
        className: "image-map__map usage-map_map_span-3 img-map"
    }, {
        width: "15.457413249211358%",
        height: "3.5593220338983054%",
        left: "44.891068611987386%",
        top: "53.898305084745765%",
        className: "image-map__map usage-map_map_span-4 img-map"
    }
];


export default function HowItWorksDesktop() {

    const ImageMapComponent = React.useMemo(
        () => (
            <ImageMap
                className="usage-map"
                src={img}
                map={mapArea}
                onMapClick={onMapClick}
            />
        ),
        []
    );

    return <div>{ImageMapComponent}</div>;
}

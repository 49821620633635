import { GET_SUBROLES_SUCCESS, GET_SUBROLE_DETAIL_SUCCESS } from "../../../../actions/subrole/types";



const INITIAL_STATE = {
    selected: {
        id: null,
        is_locked: false,
        type: '',
        name: '',
        first_name: '',
        last_name: '',
        description: '',
        company_name: '',
        address_line_1: '',
        address_line_2: '',
        post_code: '',
        city: '',
        state: '',
        country: '',
        language: '',
        national_id: '',
        is_company: false,
        vat: '',
        company_reg_no: '',
        company_city: '',
        company_country: '',
        is_verified: false,
        profile_image: '',
        cover_image: ''
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_SUBROLES_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.list.results) : action.payload.list.results
                }
            };
        case GET_SUBROLE_DETAIL_SUCCESS:
            return { ...state, selected: action.payload.selected };
        default:
            return state;
    }
}
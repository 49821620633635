import {
    CREATE_EXPERIENCE_SUCCESS, UPDATE_EXPERIENCE_SUCCESS, SELECT_EXPERIENCE_SUCCESS, DELETE_EXPERIENCE_SUCCESS
} from '../../../../actions/experience/types'
import { UPDATE_ROLE_DETAILS_SUCCESS, GET_MY_ROLE_DETAIL_SUCCESS } from '../../../../actions/role/types';

const INITIAL_STATE = {
    list: [],
    selected: {
        id: null,
        title: '',
        company_name: '',
        date_from: '',
        date_to: '',
        owner: null
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case UPDATE_ROLE_DETAILS_SUCCESS:
            return { ...state, list: action.payload.details.experiences }
        case GET_MY_ROLE_DETAIL_SUCCESS:
            return { ...state, list: action.payload.details.experiences }
        case CREATE_EXPERIENCE_SUCCESS:
            const exp = action.payload.experience
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.concat(exp)
            }
        case UPDATE_EXPERIENCE_SUCCESS:
            const exp2 = action.payload.experience
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.map(experience =>
                    experience.id === exp2.id ?
                        exp2 : experience)
            }
        case DELETE_EXPERIENCE_SUCCESS:
            const exp3 = action.payload.deleted
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.filter((experience) => experience.id !== exp3)
            }
        case SELECT_EXPERIENCE_SUCCESS:
            return { ...state, selected: action.payload.selected };
        default:
            return state;
    }
}
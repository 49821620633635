import * as types from './types';
import history from '../../../utils/routes/history'
import { conWithoutToken } from '../../../API'
import { LoginSuccess, RegisterSuccess, GetProfileCompletionStart } from '../auth'
import { GetNotificationsStatusStart } from '../notification';
import { langService } from '../../../utils/languageService';
import { REGISTER_SUCCESS } from '../auth/types';

/**
 * 
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */
export const LoginGoogleStart = (accesstoken, redirect = true) => async dispatch => {
  if (!accesstoken) { return }

  dispatch({ type: types.GOOGLE_LOGIN_REQUEST });
  await conWithoutToken.post(
    '/auth/google/', { access_token: accesstoken, },
  ).then(res => {
    dispatch(LoginSuccess(res.data, redirect));
  })
    .catch(err => {
      dispatch(LoginGoogleFail(err.response));
    })
}

// export const LoginGoogleSuccess = (data) => async dispatch => {
//   const token = { access_token: data.access_token, refresh_token: data.refresh_token };
//   const user = { ...data.user }
//   const payload = { token: token, user: user, succ_msg: "Login sucessful!" };
//   dispatch({ type: types.GOOGLE_LOGIN_SUCCESS, payload: payload });
//   if (user.roles && user.roles.length > 0) {
//       dispatch(GetProfileCompletionStart());
//       dispatch(GetNotificationsStatusStart());
//       if (user.settings.active_role_obj.type === 'DEV') {
//           history.push("/browse/tasks");
//       } else {
//           history.push("/browse/projects");
//       }
//   }
//   else
//       history.push("/auth/register-role");
// }


export const LoginGoogleFail = (err) => {
  const err_msg = { errorType: err.data.error, detail: err.data.detail };
  var toast_msg = langService.messages["login.error"];
  if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
  const status = err.status;
  const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
  return { type: types.GOOGLE_LOGIN_FAILURE, payload: payload };
}

/**
 * 
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */
export const RegisterGoogleStart = (accesstoken, redirect = true) => async dispatch => {
  if (!accesstoken) {
    return
  }

  dispatch({ type: types.GOOGLE_REGISTER_REQUEST });
  await conWithoutToken.post(
    '/auth/google/',
    {
      access_token: accesstoken,
    },
  ).then(res => {
    // dispatch(RegisterGoogleSuccess(res.data));
    dispatch(RegisterSuccess(res.data, redirect));
  })
    .catch(err => {
      dispatch(RegisterGoogleFail(err.response));
    })
}

export const RegisterGoogleSuccess = (data) => async dispatch => {
  const token = { access_token: data.access_token, refresh_token: data.refresh_token };
  const user = { ...data.user }
  const payload = { token: token, user: user, succ_msg: "Register was successful." };
  dispatch({ type: REGISTER_SUCCESS, payload: payload }); // REGISTER_SUCCESS
  if (user.roles && user.roles.length > 0) {
    dispatch(GetProfileCompletionStart());
    dispatch(GetNotificationsStatusStart());
    if (user.settings.active_role_obj.type === 'DEV') {
      history.push("/browse/tasks");
    } else {
      history.push("/browse/projects");
    }
  } else {
    history.push("/auth/register-role");
  }
}

export const RegisterGoogleFail = (err) => {
  const err_msg = { errorType: err.data.error, detail: err.data.detail };
  var toast_msg = langService.messages["register.error"];
  if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
  const status = err.status;
  const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
  return { type: types.GOOGLE_REGISTER_FAILURE, payload: payload };
}

//-----------------------
// Connect google account
//-----------------------
export const ConnectGoogleStart = (googleResponse) => async dispatch => {
  dispatch({ type: types.CONNECT_GOOGLE_REQUEST });
  await conWithoutToken.post(
    '/auth/google/', { access_token: googleResponse.accessToken },
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('django_access_token')
      }
    }
  ).then(res => {
    dispatch(ConnectGoogleSuccess(res.data));
  })
    .catch(err => {
      dispatch(ConnectGoogleFail(err.response));
    })
}

export const ConnectGoogleSuccess = (data) => async dispatch => {
  const succ_msg = "Successfully connected account with google.";
  const payload = { succ_msg: succ_msg, toast_msg: succ_msg };
  dispatch({ type: types.CONNECT_GOOGLE_SUCCESS, payload: payload, data: data });
}

export const ConnectGoogleFail = (err) => {
  const err_msg = { errorType: err.data.error, detail: err.data.detail };
  var toast_msg = 'Error connecting account with google. Please try again.'
  if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
  const status = err.status;
  const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
  return { type: types.CONNECT_GOOGLE_FAILURE, payload: payload };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        'common.save': 'Save',
        'common.save_and_continue': 'Save and Continue',
        'common.save_and_add': 'Add more',
        'common.confirm_and_save': 'Confirm and Save',
        'common.edit': 'Edit',
        'common.remove': 'Remove',
        'common.delete': 'Delete',
        'common.close': 'Close',
        'common.download': 'Download',
        'common.cancel': 'Cancel',
        'common.counter': 'Counter',
        'common.reject': 'Reject',
        'common.accept': 'Accept',
        'common.add': 'Add',
        'common.add_new': '+ Add new',
        'common.upload': 'Upload',
        'common.submit': 'Submit',
        'common.verify': 'Verify',
        'common.verified': 'Verified',
        'common.back': 'Go Back',
        'common.next': 'Next Step',
        'common.finish': 'Finish',
        'common.project': 'Project',
        'common.task': 'Task',
        'common.rec_task': 'Recurring Task',
        'common.misc': 'Misc',
        'common.read_more': 'Read more...',
        'common.more': 'More...',
        'common.contribute': 'CONTRIBUTE',
        'common.level': 'Level',
        'common.logintocontribute': 'Login to contribute',
        'common.verifytocontribute': 'Verify email to contribute',
        'common.history': 'History',
        'common.yes_delete': 'YES, DELETE',
        'common.sure_about_delete': 'Are you sure you want to delete this item?',
        'common.sure_about_publishing': 'Are you sure you want to publish this skill?',
        'common.confirmation': 'Confirmation',
        'common.up': 'Up',
        'common.down': 'Down',
        'common.enabled': 'Enabled',
        'common.disabled': 'Disabled',
        'common.yes': 'YES',
        'common.no': 'NO',
        'common.all': 'All',
        'common.from': 'From',
        'common.to': 'To',
        'common.from_years': 'Year from',
        'common.to_years': 'Year to',
        'common.draft': 'Draft',
        'common.published': 'Published',
        'common.in_negotiation': 'In Negotiation',
        'common.pending_funding': 'Fund in pending',
        'common.funding_in_progress': 'Funding in progress',
        'common.active': 'Active',
        'common.in_progress': 'In Progress',
        'common.submitted': 'Submitted',
        'common.pending_audit': 'Pending Audit',
        'common.audit': 'Audit',
        'common.in_audit': 'In Audit',
        'common.disputed': 'Disputed',
        'common.in_dispute': 'In dispute',
        'common.auditor_confirmed': 'Auditor Confirmed',
        'common.auditor_failed': 'Auditor Failed',
        'common.completed_with': 'Audit completed with ',
        'common.percentage_success': '% success.',
        'common.completed': 'Completed',
        'common.confirmed': 'Confirmed',
        'common.rejected': 'Rejected',
        'common.pending': 'Pending',
        'common.invited': 'Invited',
        'common.closed': 'Closed',
        'common.open': 'Open/Active',
        'common.confirm': 'CONFIRM',
        'common.failed': 'Failed',
        'common.public': 'Public',
        'common.private': 'Private',
        'common.files.no_preview': 'Cannot preview this file. Please download to view.',
        'common.files.preview': 'File viewer',
        'common.comment': 'Comment',
        'common.custom': 'Custom',
        'common.active_freelancer': 'Active Freelancer',
        'common.recurring': "Recurring",
        'common.reccurrance': "Task Recurrance",
        'common.reccurring_task': "Recurring Task",
        'common.reccurring_type': "Recurring Type",
        'common.reccurring_times': "Repeat X",
        'common.interval': "INTERVAL (start/end date)",
        'common.weekly': "WEEKLY",
        'common.monthly': "MONTHLY",
        'common.create': "CREATE",
        'common.milestone': "Milestone",
        'common.milestones': "Milestones",
        'common.milestones.create': "Create a New Milestone",
        'common.conditions': "Conditions",
        'common.of_task_price': "of task price",
        'common.task_success': "Task success",
        'common.search_freelancers': 'Search freelancers',
        'common.administrator': 'Administrator',
        'common.administrators': 'Administrators',
        'common.monday_short': 'Mon',
        'common.tuesday_short': 'Tue',
        'common.wednesday_short': 'Wed',
        'common.thursday_short': 'Thu',
        'common.friday_short': 'Fri',
        'common.saturday_short': 'Sat',
        'common.sunday_short': 'Sun',
        'common.monday': 'Monday',
        'common.tuesday': 'Tuesday',
        'common.wednesday': 'Wednesday',
        'common.thursday': 'Thursday',
        'common.friday': 'Friday',
        'common.saturday': 'Saturday',
        'common.sunday': 'Sunday',
        'common.year': 'Year',
        'common.days': 'Days',
        'common.hours': 'Hours',
        'common.minutes': 'Minutes',
        'common.seconds': 'Seconds',
        'common.mins': 'Min',
        'common.secs': 'Sec',
        'common.ooops': 'Ooops!',
        'common.warning': 'Warning',
        'common.page_not_found': "The page you requested does not exist or you don't have the permission to view the URL.",
        'common.leave_a_comment': 'Leave a comment...',
        'common.name': 'Name',
        'common.list': 'List',
        'common.national_id': 'National ID number',
        'common.verify_email_to_view': 'Verify email to view',
        'common.did_not_get_verify_email': 'Did not receive activation email?',
        'common.confirmDeleteTitle': 'Confirmation',
        'common.confirmDeleteText': 'Are you sure you want to delete this item?',
        'common.addCompetency': 'Add a skill',
        'common.definition': 'Definition',
        'common.relations': 'Relations',
        'competency.save_button': 'Save skill',
        'competency.publish_button': 'Publish skill',
        'competency.define_common': 'Defines where this skill is commnly used.',
        'common.alertTitle': 'Alert',
        'common.alertTextCompetencies': 'Choose at least 5 skills.',
        'common.alertTextDisputeCase': 'You must choose percentage betwen 5 and 100.',
        'common.status': 'Status',
        'common.actions': 'Actions',
        'common.all.w': 'All',
        'common.published.w': 'Published',
        'common.draft.w': 'Drafted',
        'common.publish': 'Publish',
        'common.here': 'here',
        'common.continue': 'Continue',
        
        'common.connect_google': 'Connect with google account',
        'common.advanced-filter': 'Advanced filter',
        'common.iam': 'I am',
        'common.weare': 'We are',
        'common.a_company': 'A COMPANY',
        'common.a_freelancer': 'A FREELANCER',
        'common.an_edu': 'AN EDUCATIONAL INSTITUTION',
        'common.amount': 'Amount: ',
        'common.time': 'Time',
        'common.click_here': 'click here',
        'common.required': '*required',
        'common.earned': 'EARNED',
        'common.user_defined': 'USER DEFINED',
        'common.browse_freelancers': 'Browse freelancers',
        'common.browse_tasks': 'Browse tasks',
        'common.wallet': 'Wallet',
        'common.settings': 'Settings',
        'common.help': 'Help',
        'common.skills': 'Skills',

        'tooltip.fill_out': 'Fill out these sections:',
        'tooltip.email_verification': 'Email verification',
        'tooltip.personal_info': 'Personal info',
        'tooltip.description': 'Description',
        'tooltip.profile_image': 'Profile image',
        'tooltip.cover_image': 'Cover image',
        'tooltip.availability': 'Availability',
        'tooltip.competencies': 'Skills',
        'tooltip.experience': 'Experience',
        'tooltip.education': 'Education',
        'tooltip.issuer_id': 'Used to generate skill Id prefix. Example "universename_skillname".',
        'tooltip.issuer_task_competencies': 'Control if and how do you allow your skills to be used in tasks created by clients and earned by freelancers.',
        'tooltip.issuer_disabled': 'Do not allow third party usage of our skills in tasks.',
        'tooltip.issuer_sys_certificate': 'Allow using our skills in tasks by making copies signed with system issuer.',
        'tooltip.issuer_my_certificate': 'Allow using our skills in tasks.',
        'tooltip.issuer_public_adding': 'Allow any third person to create new skills on your behalf.',
        'tooltip.issuer_public_voting': 'Make your skills definitions and relations subject to public consensus about content of skills.',
        'tooltip.issuer_external_issuer': 'Select this if you are external issuer our platform will try to sync your skills. Contact us for more details.',
        'tooltip.issuer_external_issuer_details': 'If you are external issuer our platform will try to sync your skills. Contact us for more details.',
        'tooltip.issuer_external_address': 'Provide external address if you are external issuer.',
        'tooltip.earned_competencies': 'Skills the user was awarded either by completing tasks or through a verified educational program.',
        'tooltip.userdefined_competencies': 'Skills added to his profile by the user himself or herself.',
        'tooltip.mint_no_competencies': 'There are no available skills to be minted.',
        'tooltip.auto_mint_competencies': 'Your skills that you gain from finishing jobs, can be automatically saved to Blockchain. This does cost something.\n You can always change this in the settings area of your profile',
        'tooltip.mint_competencies': 'You can "mint" or generate NFT tokens on Blockchain as a proof for your earned skills.\nThis does cost something, but you become the actual owner of your "certified skills".',

        // LANDING
        'home.header_title': 'More than just a freelancing portal',
        'home.header_subtitle': 'With the help of blockchain we provide verified proof of skills, enforce payment discipline, ensure quality audits and mediate any disputes.',
        'home.header_employer': 'I am an employer',
        'home.header_freelancer': 'I am a freelancer',
        'home.header_edu': 'I am an educational institution',
        'home.countdown_title': 'Coming Q4 2022',
        
        'home.offer_title': 'What we offer',
        'home.offer_subtitle': 'WNS offers the tools to sidestep any background checking processes, thus, on the one hand empowering the individuals in job searching and, on the other hand disburdening the clients by reducing the overhead in hiring processes.',
        'home.offer_client': 'Client',
        'home.offer_client_1': 'Find a perfect skill match for your task',
        'home.offer_client_2': 'Award and issue skill NFTs',
        'home.offer_client_3': 'Repeat and finish your project',
        'home.offer_client_4': 'Trustworthy access to global talent',
        'home.offer_client_5': 'Zero-risk transactions',
        'home.offer_freelancer': 'Freelancer',
        'home.offer_freelancer_1': 'Each task is equipped with a set of skills',
        'home.offer_freelancer_2': 'By completing the task, you acquire the skills as NFTs',
        'home.offer_freelancer_3': 'Repeat and develop your skill set',
        'home.offer_freelancer_4': 'Showcase your credibility',
        'home.offer_freelancer_5': 'Advance your career',

        'home.hire_title': 'Hire or get hired today!',
        'home.hire_subtitle': 'Are you tired of the middle man slowing things down, worries about getting paid and stress about the quality of work?',

        'home.challenges_title': 'The challenges we saw',
        'home.challenges_companies': 'AS COMPANIES',
        'home.challenges_companies_txt': 'How do you find credible future employees or freelancers and hire them with confidence?',
        'home.challenges_companies_txt2': 'By making background checks obsolete, with WNS you get what you see.',
        
        'home.challenges_freelancers': 'AS FREELANCERS & JOB SEEKERS',
        'home.challenges_freelancers_txt': 'How do you build a unified and credible CV?',
        'home.challenges_freelancers_txt2': 'By completing tasks and building a comprehensive skill deck and taking your freelancing career to the next level.',
        'home.challenges_edus': 'AS EDUCATIONAL INSTITUTIONS',
        'home.challenges_edus_txt': 'How do you make sure your educational programs are meeting the needs of todays markets?',
        'home.challenges_edus_txt2': 'By becoming a part of our growing ecosystem as a recognized issuer of Educational Credentials. Measure and track the market success of your degrees.',

        'home.solution_title': 'We built a solution for all',
        'home.solution_subtitle': 'In order to build a credibility network we used a unit that all companies, job seekers and educational institutions share with each other - <b>a verified Skill.</b> {n}A Skill uniquely describes certain knowledge and by building a unified registry for skills we ensure that all parties use a unified language regarding the skills required for a particular project or job.',

        'home.howitworks_title': 'How it works',
        'home.howitworks_subtitle': 'Trustless, permissionless and free of middlemen.',
        
        'home.contact_title': 'Have questions?',
        'home.contact_subtitle': 'Get in touch with us',
        'home.contact_action': 'CONTACT US',

        'home.how_title': 'How do we achieve it',
        'home.how_1_title': 'Community based inputs',
        'home.how_1_txt': 'We give the opportunity to everybody to upload their definition of a skill.',
        'home.how_2_title': 'Voting system',
        'home.how_2_txt': 'Upvote or downvote skill definitions.',
        'home.how_3_title': 'Use of Blockchain technology',
        'home.how_3_txt': 'Our community driven universal registry of skills is blockchain backed.',
        'home.how_4_title': 'Auditors',
        'home.how_4_txt': 'A neutral auditor can be invited to evaluate work and resolve any disputes.',
        'home.how_5_title': 'Use of Smart Contracts',
        'home.how_5_txt': 'All disputes are resolved and payments are made using smart contracts.',

        'home.joinus_title': 'Join us and contribute',
        'home.joinus_1': 'Create an account',
        'home.joinus_2': 'Enter missing skills in our register or vote existing ones',
        'home.joinus_3': 'Set up your profile',

        'home.partners_title': 'Companies and organizations that support us',
        // END LANDING
        

        'setup_profile.company_info': 'Company information',
        'setup_profile.required': 'Required information before you can hire someone',
        'setup_profile.work_info': 'Work information',
        'setup_profile.availability': 'Set up your current availability',
        'setup_profile.full_time': 'Full time',
        'setup_profile.part_time': 'Part time',
        'setup_profile.all': 'All',
        'setup_profile.not_available': 'Not accepting work',
        'setup_profile.hourly_rate': 'Your hourly rate',
        'setup_profile.select_days': 'Select working days',
        'setup_profile.desc': 'A complete profile raises your reputation and increases your chances of finding what you are looking for.',
        'setup_profile.finish': 'Great job!',
        'setup_profile.finish.desc': 'You can keep improving your profile through My Profile page.',
        'setup_profile.projects.desc': "We have already found some projects for you!",
        'setup_profile.projects': 'Browse projects',
        'setup_profile.profile': 'View my profile',
        'setup_profile.fill_company_data': 'Please fill out the following information if you represent a company.',
        'setup_profile.represent': 'I represent a company',
        'setup_profile.projects_duration': 'Projects duration',
        'setup_profile.interest_projects_duration': 'I\'m interested in projects with duration',
        'setup_profile.interest_task_pricing': 'I\'m interested in tasks with remuneration',
        'setup_profile.dev.finished': 'We already found some task for you!',
        'setup_profile.inv.finished': 'Browse through existing projects or create a new project.',
        'setup_profile.edu.finished': 'Browse through educational institutions or go to MyInstitution page.',
        'setup_profile.aud.finished': 'Browse through auditors or go to MyAudits page.',

        'activate_profile.awesome': 'Awesome!',
        'activate_profile.desc': 'Your account has been activated!',
        'activate_profile.desc_long': 'You can now login and continue with setting up your profile.',
        'activate_profile.error': 'Ooops!',
        'activate_profile.error_desc': 'Token is invalid or is expired!',
        'activate_profile.error_desc_long': 'Please login, go to your profile settings and send the activation email again.',

        'my_projects.add_tasks': 'Add Task',
        'my_projects.create_task': 'Create Task',
        'my_projects.not_published': 'Your Project is not published',
        'my_projects.publish': 'Publish Project',
        'my_projects.private': 'Your Project is private. Invite people to open tasks.',
        'my_projects.project_overview': 'Project Overview',
        'my_projects.invoices': 'Invoices',
        'my_projects.is_working': 'Is working on the task',
        'my_projects.finished_work': 'Finished work on task',
        'my_projects.view_task': 'View Task',
        'my_projects.submited_work': 'Submitted work',
        'my_projects.review_work': 'Review Work',
        'my_projects.preview': 'Preview and publish',
        'my_projects.you_can_complete_your_project': 'You can complete your project',
        'my_projects.complete': 'Complete Project',
        'my_projects.view': 'View Project',
        'my_projects.invite_freelancers': 'Invite freelancers',
        'my_projects.boost': 'Boost',

        'my_projects.warning_info': 'Some parameters of the task your are editing are out of scope of the parameters of the project',
        'my_projects.task_price_exceeds': 'Task price exceeds the project estimated budget by <b>{price}</b>.',
        'my_projects.task_start_date_before': 'Task start date (<b>{task_date}</b>) is before the project start date (<b>{project_date}</b>).',
        'my_projects.task_end_date_after': 'Task end date (<b>{task_date}</b>) is after the project end date (<b>{project_date}</b>).',
        'my_projects.save_will_update': 'You can confirm to continue and the project will be updated the following values',

        'my_task.upload': 'Upload Documents',
        'my_task.complete': 'Complete task',
        'my_task.not_published': 'Your Task is not published',
        'my_task.publish': 'Publish Task',
        'my_task.publish_invite': 'Publish your project and invite people to this private task.',
        'my_task.private': 'Your Task is private. Invite people to open negotiation.',
        'my_task.invite': 'Invite people.',
        'my_task.back_to_all': 'Back To All Tasks',
        'my_task.review_work': 'Review final work',
        'my_task.review_user': 'Review User',
        'my_task.task_overview': 'Task Overview',
        'my_task.task_proposals': 'Task Proposals',
        'my_task.submited_work': 'Submitted work',
        'my_task.new_offer': 'New offer',
        'my_task.send_offer': 'Send offer',
        'my_task.final': 'Final submission',
        'my_task.submit': 'Submit work',
        'my_task.completed': 'Task completed?',
        'my_task.completed_desc': 'Submit your final files and request for payment.',
        'my_task.estimated_cost': 'Total estimated cost',
        'my_task.cost_explanation': 'If a frelancer accepts the terms you will need to fund the task and cover platform fee in DAI plus the blockchain transaction fee in',
        'my_task.fee_calculation': 'Please wait, we need to calculate the fees...',
        'my_task.task_renumeration': 'Task renumeration: ',
        'my_task.platform_fee': 'Platform fee: ',
        'my_task.total': 'Total: ',
        'my_task.transaction_fee': 'Transaction fee: ',
        'my_task.error_cost': 'Error getting total costs estimate. Please try again.',
        'my_task.fund_task': 'Fund task',
        'my_task.insufucient_balance': 'Not enough wallet balance',
        'my_task.available_dai': 'Available DAI balance',
        'my_task.available_bnb': 'Available BNB balance',

        'my_task_tooltip.negotiation': 'List of tasks that are in negotiation phase.',
        'my_task_tooltip.confirmed': 'List of tasks that have been confirmed.',
        'my_task_tooltip.in_progress': 'List of tasks that are in progress.',
        'my_task_tooltip.submitted': 'List of tasks that have been submitted.',
        'my_task_tooltip.completed': 'List of tasks that have been completed.',
        'my_task_tooltip.in_audit': 'List of tasks that are in audit phase.',
        'my_task.tooltip.rejected': 'List of tasks that have been rejected or failed.',
        'my_task.tooltip.status': 'Task status',

        'my_audits.negotiation': 'Negotiation',
        'my_audits.communication': 'Communication',
        'my_audits.file_submission': 'File submissions',
        'my_audits.complete': 'Complete History',

        //Message Sent
        'history.freelancer_send_message.title': 'Freelancer sent a message',
        'history.freelancer_send_message.text': '"<b>{message}</b>"',
        'history.client_send_message.title': 'Client sent a message',
        'history.client_send_message.text': '"<b>{message}</b>"',
        //Task Status Changed
        'history.task_status_changed.title': 'Task status changed',
        'history.task_is_in_negotiation.text': 'Task went into negotiation.',
        'history.task_is_published.text': 'Task was published.',
        'history.task_is_draft.text': 'Task was was changed to draft.',
        'history.task_is_in_dispute.text': 'Client filed a dispute.',
        'history.task_is_active.text': 'Task went into active status.',
        'history.task_is_in_progress.text': 'Task start date has started.',
        'history.task_is_submitted.text': 'Freelancer marked task as completed.',
        'history.task_is_pending_audit.text': 'Client filed a dispute with a complaint:',
        'history.task_is_in_audit.text': 'Auditor accepted to review the dispute.',
        'history.task_is_auditor_confirmed.text': 'Auditor marked task as confirmed.',
        'history.task_is_auditor_failed.text': 'Auditor marked task as failed.',
        'history.task_is_completed.text': 'Task was successfully completed.',
        'history.task_is_pending_funding.text': 'Task funding is pending.',
        'history.task_is_funding_in_progress.text': 'Task funding is in progress.',

        //Task Auditor Changed
        'history.task_auditor_changed.title': 'Auditor for task <b>{task_name}</b> changed',
        'history.task_auditor_changed.text': 'Because of time emitted, active auditor changed.',
        //Task Proposal Changed
        //CLIENT_INVITED, FREELANCER_PROPOSED, TASK_EDITED, FILE_EDITED, SENT_FINAL, ACCEPTED, COUNTERED, REJECTED, AUTO_REJECTED
        'history.task_proposal_changed_client_invited.title': 'Client <b>{client_name}</b> has sent invitation',
        'history.task_proposal_changed_client_invited.text': 'Client has invited freelancer to participate in task',
        'history.task_proposal_changed_freelancer_proposed.title': 'New task proposal',
        'history.task_proposal_changed_freelancer_proposed.text': 'Freelancer sent a task proposal',
        'history.task_proposal_changed_task_changed.title': 'Task <b>{task_name}</b> data changed',
        'history.task_proposal_changed_task_changed.text': 'Client <b>{client_name}</b> changed task data, so the proposal reset.',
        'history.task_proposal_changed_file_edited.title': 'Task <b>{task_name}</b> documentation changed',
        'history.task_proposal_changed_file_edited.text': 'Client <b>{client_name}</b> changed task documents, so the proposal reset.',
        'history.task_proposal_changed_sent_final.title': 'Final task proposal',
        'history.task_proposal_changed_sent_final.text': 'Client sent a task proposal',
        'history.task_proposal_changed_accepted.title': 'Task Proposal accepted',
        'history.task_proposal_changed_accepted.text': 'Freelancer accepted the proposal',
        'history.task_proposal_changed_countered.title': 'Task Proposal has been changed',
        'history.task_proposal_changed_countered.text': 'A new proposal has been submitted.',
        'history.task_proposal_changed_rejected.title': 'Task Proposal has been rejected',
        'history.task_proposal_changed_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Rejected</b>.',
        'history.task_proposal_changed_auto_rejected.title': 'Other proposal has been accepted',
        'history.task_proposal_changed_auto_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Auto Rejected</b>, because other proposal has been accepted.',
        //Task Result
        'history.task_result_added.title': 'Freelancer sent results',
        'history.task_result_added.text': '"<b>{result_name}</b>"',
        'history.task_result_edited.title': 'Task result modified',
        'history.task_result_edited.text': 'Freelancer changed new result set with comment: "<b>{result_name}</b>"',
        //Task Result Document
        'history.task_result_document_added.title': 'Freelancer added files to results:',
        'history.task_result_document_added.text': '<b>{freelancer_name}</b> added a document named "<b>{document_name}</b>"',
        'history.task_result_document_deleted.title': 'Freelancer <b>{freelancer_name}</b> removed a document',
        'history.task_result_document_deleted.text': '<b>{freelancer_name}</b> removed a document named "<b>{document_name}</b>"',

        //Plural
        "plural.tasks": "{count, plural, =0 {No Tasks} one {# Task} other{# Tasks}}",
        "plural.projects": "{count, plural, =0 {No Projects} one {# Project} other {# Projects}}",
        "plural.auditors": "{count, plural, =0 {No Auditors} one {# Auditor} other {# Auditors}}",
        "plural.clients": "{count, plural, =0 {No Clients} one {# Client} other {# Clients}}",
        "plural.freelancers": "{count, plural, =0 {No Freelancers} one {# Freelancer} other {# Freelancers}}",
        "plural.competencies": "{count, plural, =0 {No Skills} one {# Skill} other {# Skills}}",
        "plural.edus": "{count, plural, =0 {No Educational Institutions} one {# Educational Institution} other {# Educational Institutions}}",
        "plural.competences.selected": "{count, plural, =0 {No Skills Selected} one {# Skills Selected} other {# Skills Selected}}",
        "plural.files.attached": "{count, plural, =0 {No Files Attached} one {# File Attached} other {# Files Attached}}",
        "plural.task.proposals": "{count, plural, =0 {No Task Proposals}  one {# Task Proposal} other {# Task Proposals}}",
        "plural.profile.reviews": "{count, plural, =0 {No Reviews}  one {# Review} other {# Reviews}}",


        'demo.hello': 'Hello.',
        'forms.loginForm': 'Login',
        'forms.registrationForm': 'Register',
        'forms.registerRoleForm': 'Register Role',
        'forms.forgotPasswordForm': 'Forgot your password? ',
        'forms.resetPasswordForm': 'Reset Password',

        'forms.reset_filters': 'Reset filters',
        'forms.min_finished_tasks': 'Min number',

        'forms.labels.username': 'Username',
        'forms.labels.password': 'Password',
        'forms.labels.newPassword': 'New Password',
        'forms.labels.confirmPassword': 'Repeat password',
        'forms.labels.email': 'Email',
        'forms.labels.name': 'Name',
        'forms.labels.firstName': 'First Name',
        'forms.labels.lastName': 'Last Name',
        'forms.labels.gender': 'Gender',
        'forms.labels.gender.male': 'Male',
        'forms.labels.gender.female': 'Female',
        'forms.labels.login': 'Login',
        'forms.labels.loginWithSocial': 'Login with your social account.',
        'forms.labels.forgotPassword': 'Forgot your password? ',
        'forms.labels.resetHere': 'Reset here.',
        'forms.labels.noAccountYet': 'Don\'t have an account? ',
        'forms.labels.goToRegistration': 'Register here.',
        'forms.labels.sendMail': 'Send Mail',
        'forms.labels.register': 'Register',
        'forms.labels.resetPassword': 'Reset Password',
        'forms.labels.alreadyHaveAccount': 'Already have an account? ',
        'forms.labels.goToLogin': 'Go to login.',
        'forms.labels.update': "Update",
        'forms.labels.first_name': "First name",
        'forms.labels.last_name': "Last name",
        'forms.labels.address_line_1': "Address line 1",
        'forms.labels.address_line_2': "Address line 2",
        'forms.labels.post_code': "Post code",
        'forms.labels.city': "City",
        'forms.labels.state': "State",
        'forms.labels.country': "Country",
        'forms.labels.vat': "VAT number",
        'forms.labels.register_num': "Registration number",
        'forms.labels.company_name': "Company name",
        'forms.labels.howitworks': "How it works",
        'forms.labels.roleSelect': 'Choose your role (you can add more later)',
        'forms.labels.continue': 'Continue',
        'forms.labels.rememberme': 'Remember Me',
        'forms.labels.continueWithGoogle': 'Continue with Google',
        'forms.labels.iagreewiththe': 'I agree with the',
        'forms.labels.termsandconditions': 'Terms and Conditions',
        'forms.labels.and': 'and',
        'forms.labels.more': 'more',
        'forms.labels.privacypolicy': 'Privacy Policy',
        'forms.labels.status': 'Status',
        'forms.labels.project_name': 'Project name',
        'forms.labels.task_name': 'Task name',
        'forms.labels.category': 'Category',
        'forms.labels.short_description': 'Short Description',
        'forms.labels.description': 'Description',
        'forms.labels.documents': 'Documents',
        'forms.labels.add_documents': 'Add Documents',
        'forms.labels.select_documents': 'Select your documents',
        'forms.labels.selected_documents': 'Selected documents',
        'forms.labels.drag_documents': "Drag 'n' drop some documents here or click the button to select",
        'forms.labels.estimated_start_end': 'Estimated start/end date',
        'forms.labels.price': 'Price',
        'forms.labels.required_competences': 'Required skills',
        'forms.labels.visibility': 'Visibility',
        'forms.labels.accept_proposal': 'Are you sure you want to accept this task proposal?',
        'forms.labels.accept_fund': 'Are you sure you want to fund this task?',
        'forms.labels.comment': 'Comment',
        'forms.labels.public_link': 'Public link',
        'forms.labels.min_chars': '(min {min} characters)',
        'forms.labels.invite_freelancers_to_task': 'Invite freelancers to tasks',
        'forms.labels.contributed_by': 'Contributed by',
        'forms.labels.registerWithGoogle': "Register with Google",
        'forms.labels.invited_freelancers': 'Invited freelancers:',
        'forms.labels.phone': 'Phone number',
        'forms.labels.message': 'Message',

        'forms.placeholders.comment': 'Add a comment to your work...',
        'forms.placeholders.select_chat': 'Select a contact to chat...',
        'forms.placeholders.messages': 'Write a message...',
        'forms.placeholders.competence': 'Add 1-5 skills',
        'forms.placeholders.addcompetence': 'Add a skill',
        'forms.placeholders.select': 'Select...',
        'forms.placeholders.username': 'Input your username...',
        'forms.placeholders.email': 'Input your email...',
        'forms.placeholders.newPassword': 'Input new password...',
        'forms.placeholders.confirmPassword': 'Confirm new password...',
        'forms.placeholders.category': 'Select category...',
        'forms.placeholders.select_country': 'Select a country...',
        'forms.placeholders.search_country': 'Search...',
        'forms.placeholders.select_freelancer': 'Select freelancers...',
        'forms.placeholders.select_status': 'Select status',
        'forms.placeholders.select_level': 'Select level',
        'forms.placeholders.select_skills': 'Select skills',
        'forms.placeholders.select_category': 'Select category',
        'forms.placeholders.select_sort': 'Sort by',
        'forms.placeholder.select_tasks': 'Select tasks...',

        'validators.required': 'This field is required!',
        'validators.minLenIs': 'Should contain at least {num, number} characters.',
        'validators.minLen8': 'Should contain at least 8 characters.',
        'validators.textInputRequired': 'This field is required!',
        'validators.passwordMatch': 'Passwords must match.',
        'validators.maxSize': 'Max allowed size is 20Mb.',
        'validators.minChars': 'Minimum 10 characters ',
        'validators.left': ' left',
        'validators.needsNumber': 'Please include a number.',
        'validators.needsMixedCharacters': 'Please include mixed uppercase and lowercase characters.',
        'validators.needsSpecialCharasters': 'Please include a special character.',
        'validators.tasks': 'You need to select tasks!',
        'validator.freelancers': 'You need to select freelancers!',

        'nav.labels.logout': 'Logout',
        'nav.labels.profile': 'Profile',
        'nav.labels.profile.select': 'Select Profile',
        'nav.labels.login': 'Login',
        'nav.labels.signin': 'Sign in',
        'nav.labels.register': 'Sign Up',
        'nav.labels.browse': 'Browse',
        'nav.labels.competencies': 'Skills',
        'nav.labels.dashboard': 'Dashboard',
        'nav.labels.myProjects': 'My Projects',
        'nav.labels.myTasks': 'My Tasks',
        'nav.labels.myWork': 'My Work',
        'nav.labels.myInstitution': 'My Institution',
        'nav.labels.myAudits': 'My Audits',
        'nav.labels.myClients': 'My Clients',
        'nav.labels.howitworks': 'How it works',
        'nav.labels.postaproject': 'Post a project',
        'nav.labels.profile_setup': 'Profile setup',
        'nav.labels.published_projects': 'Published Projects',

        'infobar.get_hired': 'Get hired today!',
        'infobar.complete_profile': 'Complete your profile and increase your chances of getting hired.',
        'infobar.start_hiring': 'Start hiring today!',
        'infobar.complete_profile_2': 'Please complete your profile before you start hiring.',
        'infobar.setup_profile': 'Setup profile',
        'infobar.add_google': 'Add Google account',
        'infobar.add_google_to_enable': 'Connect your Google account to enable Blockchain Skills',
        'infobar.connect': 'Connect',

        'roles.role': 'Role',
        'roles.name.DEV': 'Freelancer',
        'roles.name.INV': 'Client',
        'roles.name.AUD': 'Auditor',
        'roles.name.EDU': 'Education Institution',
        'roles.name.EAP': 'Program administrator',
        'roles.name.EAS': 'Student administrator',
        'roles.name.EAC': 'Skills administrator',
        'roles.name.NEW': 'New role',
        'roles.add.DEV': 'A freelancer, who wants to get hired.',
        'roles.add.INV': 'A client, looking for freelancers.',
        'roles.add.AUD': 'I am an Auditor',
        'roles.add.EDU': 'We are an educational institution',
        'roles.legalName': 'Legal name*',
        'roles.nameAndSurname': 'Your full name*',
        'roles.no_active_role_yet': 'No active role yet?',
        'roles.please_register_role': 'Please register a role, that you will be using.',
        'roles.delete_this_role': 'Delete this role?',
        'roles.add_admin': 'Add administrator',
        'roles.become_freelancer': 'Become a Freelancer',
        'roles.become_client': 'Become a Client',
        'roles.become_auditor': 'Become an auditor',
        'roles.become_edu': 'Become an Educational institution',
        'roles.add_role': 'Add a role',

        'edu.students': 'Students',
        'edu.student_list': 'Student list',
        'edu.student_requests': 'Student Certification Requests',
        'edu.verification_requests': 'Verification Requests',
        'edu.confirm_requests': 'Confirm and aproove requests from your alumni students.',
        'edu.alumni_list': 'A list of your certified alumni students.',
        'edu.no_student_requests': 'No student requests',
        'edu.edu_programs': 'Educational Programs',
        'edu.programs': 'Programs',
        'edu.degrees': 'Degrees',
        'edu.program': 'Program',
        'edu.degree': 'Degree',
        'edu.inst_admins': 'Institution administrators',
        'edu.manage_admins': 'Manage users authorised to administer your institution.',
        'edu.student_admins': 'Student administrators',
        'edu.program_admins': 'Program administrators',
        'edu.competencies_admins': 'Skills administrators',
        'edu.manage_programs': 'Manage educational programs and degrees of your institution',
        'edu.send_request': 'Request verification',
        'edu.student_id': 'Student Number',
        'edu.diploma_id': 'Diploma Id Number',
        'edu.edu_competencies': 'Skills',
        'edu.manage_competencies_all': 'A list of all skills of your institution.',
        'edu.manage_competencies_published': 'A list of published skills of your institution.',
        'edu.manage_competencies_drafted': 'A list of drafted skills of your institution.',

        'degrees.new': 'New degree',
        'degrees.addnew': 'Add a new degree',
        'degree.create.error': 'Error while creating new degree. ',
        'degree.update.error': 'Error while editing degree. ',
        'degree.delete.error': 'Error while deleting degree. ',

        'program.create.error': 'Error while creating new program. ',
        'program.update.error': 'Error while editing program. ',
        'program.delete.error': 'Error while deleting program. ',
        'programs.addnew': 'Add a New Program',
        'programs.your_competencies': 'Skills (search or create your own)',

        'rating.satisfied': 'Are you satisfied with the result?',
        'rating.review': 'Review user',
        'rating.review_aud': 'Review audit process',
        'rating.review_client': 'Give feedback on working with this client',
        'rating.review_inv': 'Review client',
        'rating.feedback': 'Leave feedback',
        'rating.feedback_long': 'Leave public review for this user...',
        'rating.review_work': 'Review Submitted Work',
        'rating.open_dispute': 'Open dispute',
        'rating.confirm_and_pay': 'Confirm and Pay',
        'rating.confirm_and_pay_repeat': 'Confirm and Repeat',
        'rating.review_audit_title': 'Are you satisfied with the audit process?',
        'rating.review_audit': 'Review the audit process by these categories',

        'rating.category.communication': 'Communication',
        'rating.category.quality': 'Quality of work',
        'rating.category.price': 'Price satisfaction',
        'rating.category.recommend': 'Would recommend',
        'rating.category.work_again': 'Would you work again',
        'rating.category.documentation': 'Clear work specifications',
        'rating.category.quality_of_explanation': 'Quality of explanation',
        'rating.category.length_of_explanation': 'Length of explanation',
        'rating.category.satisfaction_with_verdict': 'Satisfaction with the verdict',

        'messages.messages': 'Messages',
        'messages.people': 'People',
        'messages.online': 'Online',
        'messages.offline': 'Offline',
        'messages.you': 'You',

        'components.languageSelect': 'Language Select',
        'components.roleSelect': 'Role Select',

        'browse.projects': 'Projects',
        'browse.tasks': 'Tasks',
        'browse.developers': 'Freelancers',
        'browse.investors': 'Clients',
        'browse.auditors': 'Auditors',
        'browse.edus': 'Schools',
        'browse.browse_projects': 'Browse projects',
        'browse.browse_tasks': 'Browse tasks',
        'browse.browse_skills': 'Browse skills',
        'browse.browse_auditors': 'Browse auditors',
        'browse.browse_edus': 'Browse educational institutions',
        'browse.goto_myedu': 'Go to My-Institution',
        'browse.goto_myaudits': 'Go to My-Audits',

        'login.error': 'Ooops! Wrong username or password. Please try again.',
        'register.error': 'Ooops! Registration failed. Please try again.',
        'forgotpassword.error': 'Ooops! Forgot Password failed. Please try again.',
        'resetpassword.error': 'Ooops! Reset Password failed. Please try again.',

        'role.change.error': 'Ooops! Change Role failed. Please try again.',
        'role.create.error': 'Ooops! Create Role failed. Please try again.',
        'role.get.error': 'Ooops! Get Role failed. Please try again.',
        'role.update.error': 'Ooops! Update Role failed. Please try again.',
        'role.review.submit.error': 'Ooops! Submit Rating Review failed. Please try again.',
        'role.detail.update.error': 'Ooops! Update Role Detail failed. Please try again.',
        'role.experience.create.error': 'Ooops! Create Role Experience failed. Please try again.',
        'role.experience.update.error': 'Ooops! Update Role Experience failed. Please try again.',
        'role.experience.delete.error': 'Ooops! Delete Role Experience failed. Please try again.',
        'role.education.create.error': 'Ooops! Create Role Education failed. Please try again.',
        'role.education.update.error': 'Ooops! Update Role Education failed. Please try again.',
        'role.education.delete.error': 'Ooops! Delete Role Education failed. Please try again.',
        'role.freelacers.get.error': 'Ooops! Get Freelancers failed. Please try again.',
        'role.clients.get.error': 'Ooops! Get Clients failed. Please try again.',
        'role.auditors.get.error': 'Ooops! Get Auditors failed. Please try again.',
        'role.edus.get.error': 'Ooops! Get Educational Institution failed. Please try again.',
        'role.upload.profile_pic.error': 'Ooops! Update Profile Picture failed. Please try again.',
        'role.upload.cover_pic.error': 'Ooops! Update Cover Picture failed. Please try again.',

        'project.upload.logo_pic.error': 'Ooops! Update Logo Picture failed. Please try again.',
        'project.upload.cover_pic.error': 'Ooops! Update Cover Picture failed. Please try again.',

        'landing.slogan': 'The place where great ideas are born.',
        'landing.forEmployers': 'FOR CLIENTS',
        'landing.forDevelopers': 'FOR FREELANCERS',

        'footer.about': 'About',
        'footer.how': 'How it works',
        'footer.terms': 'Terms & Conditions',
        'footer.privacy': 'Privacy',
        'footer.questions': 'Questions?',
        'footer.settings': 'Manage your account and privacy',
        'footer.contact': 'Contact us',

        'browse.common.industry': 'Industry',
        'browse.common.show': 'Show',
        'browse.common.language': 'Language',
        'browse.common.country': 'Country',
        'browse.common.rating': 'Rating',
        'browse.common.activeprojects': 'Active projects',
        'browse.common.opentasks': 'Open tasks',
        'browse.common.view_my_projects': 'View in My Projects',
        'browse.common.view': 'View',
        "browse.common.viewinmyprojects": 'View in My Projects',
        'browse.common.search': 'Search',
        'browse.common.sortby': 'Sort by',
        'browse.common.loading': 'Loading',
        'browse.common.createProject': 'Create a new project',
        'browse.common.commingsoon': 'Coming soon',
        'browse.common.view_my_work': 'View in My Work',
        'browse.common.allrecordsloaded': 'All records loaded',
        'browse.common.select-issuer': 'Select issuer',


        'browse.order.startingsoon': 'Starting soon',
        'browse.order.highestbudget': 'Highest budget',
        'browse.order.lowestbudget': 'Lowest budget',
        'browse.order.byprojects': 'By projects',
        'browse.order.byclient': 'By client',
        'browse.order.highestrating': 'Highest rating',
        'browse.order.lowestrating': 'Lowest rating',
        'browse.order.mostactivetasks': 'Most active tasks',
        'browse.order.mostfinishedtasks': 'Most finished tasks',
        'browse.order.highesthourlyrate': 'Highest hourly rate',
        'browse.order.lowesthourlyrate': 'Lowest hourly rate',
        'browse.order.nameasc': 'Name ASC',
        'browse.order.namedesc': 'Name DESC',

        'browse.project.budget': 'Budget',
        'browse.project.timeframe': 'Timeframe',
        'browse.project.open_tasks': 'Tasks',
        'browse.project.participating': 'Participating in project',
        'browse.project.about': 'About',
        'browse.project.join': 'Join the project!',
        'browse.project.explore': 'Explore tasks',
        'browse.project.viewtask': 'View details',
        'browse.project.verify_email_short': 'Verify your email',
        'browse.project.verify_email': 'Please verify your email to continue working with the website',
        'browse.project.re-send_email': 'Re-send activation email',
        'browse.project.company': 'Company',
        'browse.project.location': 'Location',
        'browse.project.about_client': 'About Client',
        'browse.project.project_details': 'Project Details',
        'browse.category.all': 'All categories',
        'browse.category.Graphics & Design': 'Graphics & Design',
        'browse.category.Hardware development': 'Hardware development',
        'browse.category.Marketing': 'Marketing',
        'browse.category.Software development': 'Software development',

        'browse.task.negotiation': 'Negotiations in progress',
        'browse.task.negotiations_completed': 'Negotiations completed',
        'browse.task.post_negotiation': 'Someone is already working on this task.',
        'browse.task.private_invite_only': 'Your task is private. Invite freelancers to this task.',
        'browse.task.invite_only': 'Task is invite only',
        'browse.task.view_my_work': 'View in My Work',
        'browse.task.submit_task_proposal': 'Submit task proposal',
        'browse.task.submit_task_proposal_counter': 'Submit a task proposal counter offer',
        'browse.task.like_task': 'You like this task?',
        'browse.task.invite_free_long': 'Invite freelancers to this task.',
        'browse.task.invite_free': 'Invite freelancers',
        'browse.task.goto_my_projects': 'Go to My Projects',
        'browse.task.switch_to_participate': 'Switch role to participate',
        'browse.task.back': 'Back to All Tasks',
        'browse.task.about': 'About',
        'browse.task.required_competences': 'Required skills',
        'browse.task.description': 'Long description',
        'browse.task.milestone': 'Milestones',
        'browse.task.remuneration': 'Task remuneration',
        'browse.task.fixed': 'Fixed',
        'browse.task.timeframe': 'Timeframe',
        'browse.task.details': 'Task Details',
        'browse.task.proposal_rejected': 'Proposal rejected',

        'contact.banner': 'We\'d love to hear from you!',
        'contact.h1': 'Tell us more about you',
        'contact.contactus': 'Contact us',
        'contact.select_above': 'Select one of the above to see the contact form.',
        'contact.success': 'Thanks! Your message has been sent. We will get back to you as soon as possible.',
        'contact.error': 'Ooops! Something went wrong. Please try again or contact us directly at office@worknskill.com',

        'dashboard.welcome': 'Welcome',
        'dashboard.setup': 'Setup',
        'dashboard.browse': 'Browse',
        'dashboard.statistics': 'Statistics',
        'dashboard.clients': 'Clients',
        'dashboard.myactivity': 'My Activity',
        'dashboard.sidebar': 'Sidebar',

        'competency': 'Skill',
        'competency.new': 'New skill',
        'competences.this_person_can': 'This person can ',
        'competences.id': 'Skill Id',
        'competences.name': 'Skill name',
        'competences.create': 'Create a new Skill',
        'competences.edit': 'Edit skills',
        'competences.my': 'My skills',
        'competences.add_five': 'Add at least five skills.',
        'competences.add': 'Add skills',
        'competences.add.desc': 'Add your skills and we will suggest the perfect projects for you!',
        'competences.clients_search': 'What clients search for the most',
        'competences.search': 'Search...',
        'competences.least_one': 'Please select at least one skill',
        'competences.parent': 'Parent skill',
        'competences.base': 'Related skill',
        'competences.no_parent': 'No parent skill',
        'competences.skills': 'Related skills',
        'competences.related_skills': 'Related skills',
        'competences.no_related_skills': 'No related skills',
        'competency.add.step1': 'Name',
        'competency.add.step2': 'Definition',
        'competency.add.step3': 'Relations',
        'competency.save_draft': 'Save as draft',
        'competences.parent_description': 'The parent skill positions skill vertically in the registry.',
        'competences.related_description': 'Related or base skills that this skill includes or consists of.',
        'competency.sure_about_publishing': 'Are you sure you want to publish this skill?',

        'competences.written_by_you': 'Skills are written by you',
        'competences.created_by_you': 'Skills are created by you!',
        'competences.top_definition': 'TOP DEFINITION',
        'competences.to_skills': 'You gain skills from finishing jobs',

        'competences.add_skill_title': 'Suggest related skills',
        'competences.add_description_title': 'Suggest skill description',
        'competences.add_parent_title': 'Suggest skill parent',
        'competences.add_skill': 'Suggest related skills',
        'competences.add_description': 'Suggest Description',
        'competences.add_parent': 'Suggest Parent',
        'competences.contribute_info': 'Participate in consensus about the skill details by contributing or voting.',
        'competences.commonly_used': 'Commonly used in',
        'competences.categories': 'Categories',
        'competences.commonly_used_in': 'Categories in which this skill is commonly used.',
        'competences.platform_only': 'Platform only',
        'competences.suggested': 'Suggested',
        'competency.blooms_taxonomy': "Blooms's Taxonomy",
        'competency.blooms_taxonomy_description': "Bloom's Taxonomy helps us classify educational learning objectives. Simply finish the sentence starting with:",
        'competency.blooms_taxonomy_info': "Bloom's Taxonomy helps us classify educational learning objectives.",
        'competency.description': "Skill description",
        'competency.quick_add': 'Quick add',
        'competency.warning_limited_relations': 'The issuer has limited the number of related skills that can be added. Please remove some.',
        'competency.the_competency': 'The skill ',
        'competency.related_to': 'is related to ',
        'competency.not_necessary': 'so it is not necessary to add it.',
        'competency.warning_complete_sentence': 'Please complete the sentence (enter definition).',
        'competency.can_change_level': 'You can change the current level or provide your own definition for the existing skill ',
        'competency.warning.select_level': 'Please select a level and complete the sentence.',
        'competency.warning.required_field': 'This is a required field',
        'competency.warning.name_level_exists': 'A skill with this name and level already exists!',
        'competency.quick_add_header': 'Quick add skill',
        'competency_warning_exists': 'Already exists',
        'competency_warning_cannot_edit': 'Published skill name and level can not be edited',
        'competences.nolisted': 'This person has no skills listed.',
        'competences.earned': 'Verified',
        'competences.user_defined': 'User defined',
        'competences.no_defined': 'User has no defined skills',
        'competences.no_earned': 'User has no earned skills',

        'competences.weights': 'Weights',
        'competences.weights_singular': 'Weight',
        'competences.weights_for': 'Weights for',
        'competences.weights_task_provided': 'Tasks that provided these weights',
        'competences.weights.title': 'Edit skills weights',
        'competences.weights.distribution': 'Skills weight distribution',
        'competences.weights.remaining_points': 'Remaining weights',
        'competences.weights.no_left': 'You have no weights left',

        'blooms.whats_this': 'What\'s this',
        'blooms.L1': 'REMEMBER (define, duplicate, list, memorize, repeat...) is the 1st level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L2': 'UNDERSTAND (classify, discuss, explain, report...) is the 2nd level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L3': 'APPLY (execute, implement, solve, use, interpret...) is the 3rd level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L4': 'ANALYZE (differentiate, organize, compare, distinguish...) is the 4th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L5': 'EVALUATE (argue, judge, support, critique, weigh...) is the 5th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L6': 'CREATE (design, assemble, construct, develop, author...) is the 6th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',

        'blooms.filter_L1': 'L1 - REMEMBER',
        'blooms.filter_L2': 'L2 - UNDERSTAND',
        'blooms.filter_L3': 'L3 - APPLY',
        'blooms.filter_L4': 'L4 - ANALYZE',
        'blooms.filter_L5': 'L5 - EVALUATE',
        'blooms.filter_L6': 'L6 - CREATE',

        'projects.heading': 'Find the best projects and tasks for you',
        'projects.industry': 'Industry',
        'projects.employment': 'Employment',
        'projects.employmentduration': 'Job timeframe',
        'projects.tasks': 'Tasks',
        'projects.competences': 'Skills',
        'projects.duration': 'Duration',
        'projects.logintoview': 'Login to view',
        'projects.image': 'Project image',
        'projects.image_desc': 'Make your project more attractive by adding a cover photo and profile image/logo.',
        'projects.change.image': 'Change Project image',
        'projects.change.cover': 'Change Project cover image',
        'projects.estimated_budget': 'Estimated budget',
        'projects.estimated_start': 'Estimated start date',
        'projects.estimated_end': 'Estimated end date',

        'education.add': 'Add Education',
        'education.add.desc': 'Make your profile more credible by adding your education.',
        'education.school': 'School/University',
        'education.degree': 'Degree/Program',

        'experience.add': 'Add Experience',
        'experience.add.desc': 'Make your profile more credible by adding your experience.',
        'experience.edit': 'Edit Experience',
        'experience.company': 'Company/Project',
        'experience.position': 'Position/Role',

        'freelancers.heading': 'Verified freelancers for your next project',
        'freelancers.availability': 'Availability',
        'freelancers.competences': 'Skills',
        'freelancers.hourlyrate': 'Hourly rate',
        'freelancers.completedprojects': 'Completed projects',
        'freelancers.language': 'Language',
        'freelancers.country': 'Country',
        'freelancers.studiedat': 'Studied at',
        'freelancers.hour': 'hour',
        'freelancers.completedTasks': 'Completed tasks',

        'clients.heading': 'Reputable clients and their projects',

        'educationalinstitutions.heading': 'Educational institutions verify allumnis skills',

        'auditors.heading': 'Most Credible Auditors at one place',
        'auditors.dispute': 'Dispute',
        'auditors.dispute_cases': 'Dispute Cases',
        'auditors.accept_case': 'ACCEPT CASE',
        'auditors.reject_case': 'Reject case',
        'auditors.submit_final_decision': 'SUBMIT FINAL DECISION',

        'auditors.client_filed_dispute': ' Dispute settlement failed',
        'auditors.you_filed_dispute': ' Dispute settlement failed',
        'auditors.dispute_escalated': ' The case was escalated to auditor.',
        'auditors.auditor_accepted': ' Auditor accepted the case',
        'auditors.auditor_accepted_your': ' Auditor accepted your case',
        'auditors.auditor_reviewed': ' Auditor has reviewed the case',
        'auditors.auditor_reviewed_your': ' Auditor has reviewed your case',
        'auditors.auditor_decided_1': 'It is decided that task has been completed ',
        'auditors.auditor_decided_2': 'and a ',
        'auditors.auditor_decided_3': 'compensation has been granted to freelancer.',
        'auditors.audit_compensation': 'Audit compensation',
        'auditors.audit_deadline': 'Audit deadline',
        'auditors.audit_deadline_days': '7 working days',
        'auditors.audit_rules': 'Rules of audit process...',
        'auditors.audit_client_reason': 'Client complaint reason:',
        'auditors.dispute_details': 'Dispute details',
        'auditors.audit_data': 'Audit data',
        'auditors.decided_1': 'Task was executed',
        'auditors.decided_2': 'and',
        'auditors.decided_3': 'will be payed.',
        'auditors.slider_info': '*By moving the slider you decide the proportion of the task that was executed correctly and the amount of compensation the freelancer receives from the original agreed amount. ',
        'auditors.dispute_resolution': 'Dispute resolution:',
        'auditors.auditor_comment': 'Auditor comment:',
        'auditors.keypoint': 'Keypoint',
        'auditors.comment': 'Comment',
        'auditor.dispute_case_details': 'Dispute case details',
        'auditor.agree_to_continue': 'Agree to continue',
        'auditor.file_dispute': 'File dispute',
        'auditor.file_dispute_proposal': 'FILE A DISPUTE PROPOSAL',

        'modalEditProfileImage.changeyourprofileimage': 'Change your profile image',
        'modalEditProfileImage.dragndrop': "Click or drag your picture here",

        'modalEditFreelancerRates.heading': 'Edit Availability and Preferences',
        'modalEditFreelancerRates.availability': 'Availability',
        'modalEditFreelancerRates.acceptingwork': 'Accepting work',
        'modalEditFreelancerRates.notacceptingwork': 'Not accepting work',
        'modalEditFreelancerRates.hourlyrate': 'Hourly Rate (USD/hr)',

        'modalEditFreelancerEducation.heading': 'Edit Education',
        'modalEditFreelancerEducation.studiedat': 'Studied at',

        'modalEditProfileInfo.heading': 'Edit profile information',
        'modalEditProfileInfo.displayname': 'Display Name',
        'modalEditProfileInfo.companyname': 'Company Name',
        'modalEditProfileInfo.vat': 'VAT',
        'modalEditProfileInfo.firstname': 'First name',
        'modalEditProfileInfo.lastname': 'Last name',
        'modalEditProfileInfo.description': 'Description',
        'modalEditProfileInfo.address': 'Address',
        'modalEditProfileInfo.zip': 'ZIP',
        'modalEditProfileInfo.city': 'City',
        'modalEditProfileInfo.state': 'State',
        'modalEditProfileInfo.country': 'Country',

        'profile.hourlyrate': 'Hourly rate',
        'profile.hour': 'hr',
        'profile.availability': 'Availability',
        'profile.completedtasks': 'completed tasks',
        'profile.completedtasksupper': 'Completed tasks',
        'profile.participatedinprojects': 'participated in projects',
        'profile.clients': 'clients',
        'profile.inthepastweek': 'In the past week',
        'profile.clientswholookedfor': 'Clients who looked for',
        'profile.recommendations': 'recommendations',
        'profile.reviews': 'reviews',
        'profile.online': 'Online',
        'profile.nodescription': 'No description',
        'profile.education': 'Education',
        'profile.experience': 'Experience',
        'profile.competences': 'Skills',
        'profile.save_to_blockchain': 'Save to Blockchain',
        'profile.reviewsupper': 'Reviews',
        'profile.participatedinprojectsupper': 'Participated in projects',
        'profile.participatedintasks': 'Participated in tasks',
        'profile.nocity': 'No city',
        'profile.nocountry': 'No country',
        'profile.acceptingwork': 'Accepting work',
        'profile.personal': 'Personal information',
        'profile.personal.desc': 'Become more credible by filling in your personal information.',
        'profile.description.add': 'Add description',
        'profile.description.desc': 'Adding a short description increases your chances of getting hired.',
        'profile.public': 'Public information',
        'profile.public.desc': 'Publicly visible profile details',
        'profile.change.cover': 'Change cover',
        'profile.change.profile': 'Change profile image',
        'profile.change.role': 'Quick switch between my profiles',

        'DropdownUserMenu.myprofile': 'My Profile',
        'DropdownUserMenu.addanotherprofile': 'Add another profile',
        'DropdownUserMenu.settings': 'Settings',
        'DropdownUserMenu.balances': 'Balances',
        'DropdownUserMenu.ndas': "NDA's",
        'DropdownUserMenu.contracts': 'Contracts',
        'DropdownUserMenu.help': 'Help',
        'DropdownUserMenu.resolution_center': "Resolution Center",
        'DropdownUserMenu.gamification': 'Gamification',
        'DropdownUserMenu.myWallet': "My Wallet",

        'settings.account': 'User account',
        'settings.account_settings': 'User account settings',
        'settings.payment': 'Blockchain',
        'settings.notification': 'Notifications',
        'settings.push_notification': 'Push notifications',
        'settings.notification_pref': 'Notification preferences',
        'settings.general': 'General',
        'settings.messaging': 'Messaging',
        'settings.task_related': 'Task related',
        'settings.dispute_related': 'Disputes',
        'settings.security': 'Security',
        'settings.current_role': 'Your current account role',

        'resolutionCenter.finalSubmit': 'I accept the terms and conditions of the Dispute Resolution Service.',
        'resolutionCenter.noTaskSelected': 'No task selected. Go to',
        'resolutionCenter.keyPoints': 'Problematic keypoints (add your comments)',
        'resolutionCenter.proposePercentage': 'I propose to resolve this by paying the following percentage (%) of the agreed amount',
        'resolutionCenter.costOfDispute': 'Cost of dispute audit',
        'resolutionCenter.agreedTerms': 'Agreed Terms',
        'resolutionCenter.more_in_the': 'More in the ',
        'resolutionCenter.faq_section': ' FAQ section...',

        'dispute.proposed': 'proposed a resolution to pay ',
        'dispute.of_price': ' of the agreed price.',
        'dispute.i_propose': 'I propose a payment of   ',
        'dispute.of_price_2': ' % of the agreed price is payed.',
        'dispute.send_counter': 'SEND COUNTER',
        'dispute.counter': 'COUNTER',
        'dispute.accept': 'ACCEPT',
        'dispute.send_to-audit': 'SEND TO AUDIT',
        'dispute.was_escalated': 'Dispute was escalated to auditor.',
        'dispute.expired': 'Dispute settlement time expired. The case was automatically escalated to auditor.',
        'dispute.resolved': 'Dispute was resolved by accepting settlement agreement.',
        'dispute.keypoints_with_comments': 'Dispute keypoints with comments:',
        'dispute.original_comment': 'Original comment by the client:',
        'dispute.replies': 'Replies:',
        'dispute.marked_resolved': 'Client has marked this keypoint as resolved.',
        'dispute.reply': 'Reply',
        'dispute.mark_as_resolved': 'MARK AS RESOLVED',

        /*  My Wallet */
        'wallet.title': 'My wallet',
        // 'wallet.dai_eth_balance': 'DAI and ETH total balance',
        'wallet.deposit': 'Deposit',
        'wallet.withdraw': 'Withdraw',
        'wallet.min_deposit': '*Min deposit is',
        'wallet.recommended_balance': '*Recommended min is ',
        'wallet.show_usd': 'Show in USD',
        'wallet.show_eur': 'Show in EUR',
        'wallet.total_balance': 'Total balance: ',
        'wallet.available_balance': 'Available balance: ',
        'wallet.available_amount': 'Available amount: ',
        'wallet.transaction_fee': 'Transaction fee: ',
        'wallet.will_withdraw': 'You will withdraw: ',
        'wallet.back': 'Back to wallet',
        'wallet.funds_error': "You don't have enoguh funds.",
        'wallet.deposit-receive': 'Deposit/Receive',
        'wallet.withdraw-send': 'Withdraw/Send',
        'wallet.confirm-transaction': 'Confirm',
        'wallet.modal-text': 'Are you sure you want to withdraw?',
        'wallet.warning.required_field': 'This is a required field',
        'wallet.transactions': 'Transactions ',
        'wallet.chips': 'chips',
        'wallet.export': 'Export',
        'wallet.required_dates': 'Dates are required',
        'wallet.no_transactions': 'There are no transactions to be exported',
        'wallet.show_in': 'Show in',
        'wallet.invalid_address': 'Invalid address',
        'wallet.tip_address': 'Ensure the network matches the addresses network Binance smart chain (BEP20) entered to avoid withdrawal losses.',
        'wallet.tip_amount': 'For each withdrawal, a flat fee is paid by users to cover the transaction costs of moving the cryptocurrency out of their Binance account. Withdrawals rates are determined by the blockchain network and can fluctuate without notice due to factors such as network congestion.',
        
        // CHIPS
        'chips.estimated_fee': 'Estimated fee: ',
        'chips.not_enough_dai': 'not enough DAI',
        'chips.chips': 'chips',
        'chips.your_balance': 'Your balance: ',

        //Competencies mint

        'competencies_mint.mint_available_skills': 'Mint available skills',
        'competencies_mint.skills_supported': "Skills that are supported by the project owners to verify the freelancer's experience at the particular skill. For more information ",
        'competencies_mint_available_balance': 'Available balance',
        'competencies_mint.no_skills' : 'There are no skills to be minted',
        'competencies_mint.skill_name': 'Skill name',
        'competencies_mint.estimated_cost': 'Estimated cost',
        'competencies_mint.total_estimated_cost': 'Total estimated cost',
        'competencies_mint.total_estimated_cost_explanation': 'The Total Estimated Cost calculates the overall price for minting the selected skills',
        'competencies_mint.current_balance': 'Current balance',
        'competencies_mint.after_mint': 'After minting',
        'competencies_mint.mint_your_skills': 'Mint your skills',
        'competencies_mint.insuficient_funds_bnb': 'Unfortunately your wallet balance is not enoguh to continue with minting your skills. If you wish you can either buy or deposit BNB into your wallet in order to continue.',
        'competencies_mint.insuficient_funds_eth': 'Unfortunately your wallet balance is not enoguh to continue with minting your skills. If you wish you can either buy or deposit ETH into your wallet in order to continue.',
        'competencies_mint.competencies_mint.confirm': 'Confirm minting skills',

        //TABLE
        'wallet_table.pending': 'Pending',
        'wallet_table.processing': 'Processing',
        'wallet_table.failed': 'Failed',
        'wallet_table.completed': 'Completed',
        'wallet_table.unknown': 'Unknown',
        'wallet_table.sent': 'Sent',
        'wallet_table.minted': 'Minted skills',
        'wallet_table.deployed': 'Deployed smart contract',
        'wallet_table.approve_dai': 'Approved transfer',
        'wallet_table.escrow_arrangement': 'Task arrangement',
        'wallet_table.received': 'Received',
        'wallet_table.date': 'Date',
        'wallet_table.type': 'Type',
        'wallet_table.symbol': 'Symbol',
        'wallet_table.amount': 'Amount',
        'wallet_table.from_to': 'From/To',
        'wallet_table.status': 'Status',
        'wallet_table.from': 'From',
        'wallet_table.to': 'To',
        'wallet.buy': 'Buy',
        'wallet.subscribe': 'Subscribe',
        'wallet_table.escrow-transfer-freelancer': 'Task payment',
        'wallet_table.escrow-transfer-auditor': 'Audit payment',
        'wallet_table.escrow-refund': 'Escrow refund',
        'wallet_table.invoice': 'Invoice',
        'wallet.view-in-explorer': 'View in explorer',

        // DEPOSIT
        'deposit.title': 'What do you want to deposit?',
        'deposit.tips': 'Tips:',
        'deposit.qr_title': 'Scan the QR code or copy the wallet address to make a deposit',
        'deposit.wallet_address': 'Wallet address: ',
        'deposit.copy_to_clipboard': 'Copy to clipboard',
        'deposit.copied_to_clipboard': 'Copied to clipboard',
        'deposit.scan_code': 'Scan the code on the withdrawal page of the trading platform or wallet.',
        'deposit.send_bnb': 'Send only BNB to this deposit address.',
        'deposit.send_dai': 'Send only DAI to this deposit address.',
        'deposit_ensure_binance': 'Ensure the network is Binance smart Chain (BEP20).',

        //WITHDRAW
        'withdraw.title': 'What do you want to withdraw?',
        'withdraw.recepient_label': "Recepient's Address",
        'withdraw.amount': 'Amount',
        'withdraw.token': 'Coin:',
        'withdraw.date_time': 'Date/Time:',
        'withdraw.network_fee': 'Network fee:',
        'withdraw.total_amount': 'You will withdraw:',


        // Notifications
        'notification.notifications': 'Notifications',
        'notification.markAllAsRead': 'Mark All as Read',
        'notification.nothingNew': 'Nothing new',
        'notification.upToDate': 'You\'re all up to date!',
        'notification.seeAll': 'See all',

        // Notifications...FOR CLIENT & FREELANCER
        'notification.OnReviewRequestSubmitted.title': 'New review',
        'notification.OnReviewRequestSubmitted.text': 'You got a new review from ',

        // Notifications...FOR CLIENT
        'notification.OnFreelancerTaskProposalProposed.title': 'New task proposal',
        'notification.OnFreelancerTaskProposalProposed.text': ' has sent you a task proposal.',
        'notification.OnFreelancerTaskProposalCountered.title': 'New counter offer',
        'notification.OnFreelancerTaskProposalCountered.text': ' has sent you a counter offer.',
        'notification.OnTaskProposalAutoRejected.title': 'Task proposal rejected',
        'notification.OnTaskProposalAutoRejected.text': 'Your task proposal has been rejected.',
        'notification.OnFreelancerTaskProposalRejected.title': 'Task proposal rejected',
        'notification.OnFreelancerTaskProposalRejected.text': ' has rejected your task proposal.',
        'notification.OnFreelancerTaskProposalRejectedLast.title': 'Task proposal rejected',
        'notification.OnFreelancerTaskProposalRejectedLast.text': ' has rejected your last task proposal.',
        'notification.OnFreelancerTaskProposalAccepted.title': 'Task proposal accepted',
        'notification.OnFreelancerTaskProposalAccepted.text': ' has accepted your task proposal.',
        'notification.OnFreelancerTaskRejected.title': 'Task cancelled',
        'notification.OnFreelancerTaskRejected.text': ' has cancelled work for task.',
        'notification.OnFreelancerTaskSubmitted.title': 'Task completed',
        'notification.OnFreelancerTaskSubmitted.text': ' has submitted final work for the task.',
        'notification.OnProjectMayCompleted.title': 'Project completed',
        'notification.OnProjectMayCompleted.text': 'All tasks on the project have been completed.',
        'notification.OnTaskRecurringCreated.title': 'Recurring task was created.',
        'notification.OnTaskRecurringCreated.text': ' was created.',
        'notification.OnTaskAuditorFailedForClient.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorFailedForClient.text': 'Auditor has made the final decision.',
        'notification.OnTaskAuditorConfirmedForClient.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorConfirmedForClient.text': 'Auditor has made the final decision.',
        'notification.OnTaskInAuditForClient.title': 'Audit in progress',
        'notification.OnTaskInAuditForClient.text': 'Auditor has accepted your dispute case in review.',
        'notification.OnFreelancerTaskResultSubmitted.title': 'New task results',
        'notification.OnFreelancerTaskResultSubmitted.text': ' sent new task results.',
        'notification.OnTaskPublishedStartTimeWarning.title': 'Task start date is nearing',
        'notification.OnTaskPublishedStartTimeWarning.text': 'Task status will go into draft soon.',

        // Notifications...FOR FREELANCER
        'notification.OnClientTaskProposalProposed.title': 'Invite to task',
        'notification.OnClientTaskProposalProposed.text': ' has invited you to a task.',
        'notification.OnClientTaskProposalCountered.title': 'New counter offer',
        'notification.OnClientTaskProposalCountered.text': ' has sent you a counter offer.',
        'notification.OnClientTaskProposalRejected.title': 'Task proposal rejected',
        'notification.OnClientTaskProposalRejected.text': ' has rejected your task proposal.',
        'notification.OnClientTaskProposalSentFinal.title': 'Final counter offer',
        'notification.OnClientTaskProposalSentFinal.text': ' has sent you a final task proposal offer.',
        'notification.OnClientTaskRejected.title': 'Task cancelled',
        'notification.OnClientTaskRejected.text': ' has cancelled a task.',
        'notification.OnClientTaskCompleted.title': 'Task completed',
        'notification.OnClientTaskCompleted.text': ' has confirmed, payed and completed task.',
        'notification.OnTaskInProgress.title': 'Task in progress',
        'notification.OnTaskInProgress.text': ' is due in ',
        'notification.OnClientTaskPendingAudit.title': 'A dispute was escalated to audit',
        'notification.OnClientTaskPendingAudit.text': 'Task name: ',
        'notification.OnClientTaskInAudit.title': 'Audit started',
        'notification.OnClientTaskInAudit.text': 'Auditor has accepted the case.',
        'notification.OnTaskAuditorConfirmedForFreelancer.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorConfirmedForFreelancer.text': 'Auditor has made the final decision.',
        'notification.OnTaskAuditorFailedForFreelancer.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorFailedForFreelancer.text': 'Auditor has made the final decision.',
        'notification.OnTaskInAuditForFreelancer.title': 'Audit in progress',
        'notification.OnTaskInAuditForFreelancer.text': 'Auditor has accepted dispute case in review.',
        // Dispute settlement
        'notification.OnClientTaskInDispute.title': 'Task dispute',
        'notification.OnClientTaskInDispute.text': 'Client has filled a dispute with settlement proposal.',
        'notification.OnClientTaskDisputeSetInNegotiation.title': 'Dispute update',
        'notification.OnClientTaskDisputeSetInNegotiation.text': 'Client has sent a new settlement agreement proposal.',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.title': 'Dispute update',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.text': 'Freelancer has sent a new settlement agreement proposal.',
        'notification.OnFreelancerTaskDisputeSettlementComment.title': 'Dispute update',
        'notification.OnFreelancerTaskDisputeSettlementComment.text': 'Freelancer has sent a new comment on a dispute topic.',
        'notification.OnClientTaskDisputeSettlementComment.title': 'Dispute update',
        'notification.OnClientTaskDisputeSettlementComment.text': 'Client has sent a new comment on a dispute topic.',

        // Notifications...FOR AUDITOR
        'notification.OnNewTaskDisputeCase.title': 'New audit request',
        'notification.OnNewTaskDisputeCase.text': 'You have been invited to review a dispute.',
        'notification.OnTaskDisputeCaseAutoRejected.title': 'New audit request expired',
        'notification.OnTaskDisputeCaseAutoRejected.text': 'Your invite to review a dispute case has been cancelled.',
        'notification.OnTaskDisputeCaseFailed.title': 'Audit decision expired',
        'notification.OnTaskDisputeCaseFailed.text': "You didn't make the decision within required time.",

        // Notifications...ESCROW RESOLVE
        'notification.OnEscrowResolveTaskArrangementAuditor.title': 'You have been paid for audit!',
        'notification.OnEscrowResolveTaskArrangementFreelancer.title': 'You have been paid for a job!',
        'notification.OnEscrowResolveTaskArrangementClientRefund.title': 'You have been refunded!',
        'notification.OnEscrowTaskArrangementInProgress.title': 'Task was successfuly funded!',
        'notification.OnEscrowTaskArrangementFail.title': 'Escrow task arrangement failed!',
        'notification.OnEscrowResolveTaskArrangementAuditor.text': 'Check your new balance in my wallet',
        'notification.OnEscrowResolveTaskArrangementFreelancer.text': 'Check your new balance in my wallet',
        'notification.OnEscrowResolveTaskArrangementClientRefund.text': 'Check your new balance in my wallet',
        'notification.OnEscrowTaskArrangementInProgress.text.freelancer': 'You can start your work on task: ',
        'notification.OnEscrowTaskArrangementInProgress.text.client': 'Work can start on task: ',
        'notification.OnEscrowTaskArrangementFail.text': 'Task name: ',

        // Notificaitons...FUND
        'notification.OnTaskFundingInProgressClient.title': 'Task funding in progress',
        'notification.OnTaskFundingInProgressClient.text': 'Task name: ',
        'notification.OnTaskFundingInProgressFreelancer.title': 'Task funding in progress',
        'notification.OnTaskFundingInProgressFreelancer.text': 'Task name: ',
        'notification.OnTaskPendingFundingClient.title': 'Task funding pending',
        'notification.OnTaskPendingFundingClient.text': 'Task name: ',
        'notification.OnTaskPendingFundingFreelancer.title': 'Task funding pending',
        'notification.OnTaskPendingFundingFreelancer.text': 'Task name: ',

        // EDU
        'notification.OnNewRoleEducationRequest.title': 'New verification request',
        'notification.OnNewRoleEducationRequest.text': ' has sent you a request to verify his/hers education program.',
        'notification.OnRoleEducationRequestApproved.title': 'Verification request',
        'notification.OnRoleEducationRequestApproved.text': ' has verified your education.',
        'notification.OnRoleEducationRequestRejected.title': 'Verification request',
        'notification.OnRoleEducationRequestRejected.text': ' has rejected your education verification request.',

        // ISSUERS - EN
        'issuers.issuer': 'Issuer',
        'issuers.settings': 'Issuer settings',
        'issuers.add_new': 'Add new issuer',
        'issuers.issuer_name': 'Issuer Id (used to generate skill prefix)',
        'issuers.mint_type': 'Task skills usage',
        'issuers.MINT_DISABLED': 'Disabled',
        'issuers.MINT_ON_SYS_ISSUER': 'Use system certificate',
        'issuers.MINT_ON_SAME_ISSUER': 'Use my certificate',
        'issuers.allow_public_insert': 'Allow public skills adding',
        'issuers.allow_voting': 'Allow public skills voting',
        'issuers.is_external': 'External issuer',
        'issuers.get_my_issuers_success': 'Successfully recieved my issuers.',
        'issuers.get_my_issuers_error': 'Failed to retrieve a list of issuers. ',
        'issuers.delete_issuer_success': 'Issuer deleted successfully.',
        'issuers.delete_issuer_fail': 'There was an error while trying to delete issuer. ',
        'issuers.get_available_issuers_success': 'Successfully recieved available issuers. ',
        'issuers.get_available_issuers_fail': 'Failed to receive available issuers. ',
        'ssuers.get_public_issuers_success': 'Successfully received public issuers. ',
        'ssuers.get_public_issuers_fail': 'Failed to receive public issuers. ',
        'issuers.create_iusser_success': 'Successfully created a new issuer. ',
        'issuers.create_iusser_fail': 'Failed to create a new issuer. ',
        'issuers.get_iusser_details_success': 'Successfully recieved subrole details. ',
        'issuers.get_iusser_details_fail': 'Failed to recieve subrole details. ',
        'issuers.update_issuer_details_success': 'Successfully updated issuer details. ',
        'issuers.update_issuer_details_fail': 'Failed to update issuer details. ',
        'issuers.issuer_details': 'Issuer details',
        'issuers.manage_issuer': 'Manage your issuer settings and skills generating process.',
        'issuers.edit': 'Edit issuer',
        'issuers.issued_by_ecta': 'Issued by WNS',
        'issuers.issued_by': 'Issued by ',
        'issuers.disabled_voting': 'The issuer has disabled public voting on this skill.',
        'issuers.issuer_missing': 'Issuer missing',
        'issuers.issuer_missing_text': 'Please create an Issuer first, before creating your skills related to your educational programs.',

        /* FAQ - EN
           TAB TAGS */
        'help.general': 'General',
        'help.company': 'Client',
        'help.freelancer': 'Freelancer',
        'help.edu': 'Educational institution',
        'help.auditor': 'Auditor',
        'help.competencies': 'Skills',
        'help.blockchain': 'Blockchain',

        // GENERAL faq
        'help_general.acc_types': 'What account types do you offer?',
        'help_general.subscription_tiers': 'What are subscription tiers?',
        'help_general.roles': 'How do I create and manage roles on my account?',
        'help_general.lost_password': 'I lost my password/user ID. What do I do?',
        'help.general.login': 'How to login/logout of my account?',
        'help.general.notification_preference': 'Where can I change notification preferences?',
        'help.general.acc_close': 'How can I close my account?',

        //GENERAL answers
        'help_general.ans_acc_types': 'A client, freelancer, educational institution and auditor.',
        'help_general.ans_roles': 'If you click on your profile you have the option "Add another profile" after you create another profile you can easily switch between profiles.',
        'help_general.ans_lost_password': 'If you lost your password click on the Forget password link in the login screen. Then enter your registered email address and reset the password. You will now be able to login to your Work \'n Skill account with your new password.',
        'help_general.ans_login': 'For login select login from the top navigation bar. For logour select your profile on the top navigation bar and then select logout.',
        'help_general.ans_notification_preference': 'On the top navigation bar, you have a bell and you can customise your notification preferences in settings.',
        'help_general.ans_acc_close': "Click on your profile and then select settings and then you can delete this role (you can delete roles only if you don't have active tasks!).",
        'help_general.ans_subscription_tiers': '...',

        // FREELANCERS faq
        'help_freelancers.profile_setup': 'How do I set up my profile?',
        'help_freelancers.project_apply': 'How do I apply for a project?',
        'help_freelancers.task_accept': 'How do I accept a task?',
        'help_freelancers.work_status': 'Where can I see the status of my work?',
        'help_freelancers.deadline_extend': 'Can I extend the deadline of the task?',
        'help_freelancers.negotiation': 'How can I negotiate?',

        //FREELANCERS answers
        'help_freelancers.ans_profile_setup': "If you haven't set up your profile yet, you will get notification for setting it up. But you can also click on my profile.",
        'help_freelancers.ans_project_apply': 'After you set up your profile, you can click on “browse projects”. And then you select a view on interesting project. And then select a task. Now you can submit a task proposal. And before you submit, you can give them your offer, select timeframe and skills.',
        'help_freelancers.ans_task_accept': 'Select my work on the top navigation bar, and then you will see on task if you have something new in negotiation then you can select task and accept it.',
        'help_freelancers.ans_work_status': 'Select my work on the top navigation bar, and then you can see on the left tasks and status of your work.',
        'help_freelancers.ans_deadline_extend': '...',
        'help_freelancers.ans_negotiation': '...',

        // CLIENTS faq
        'help_clients.profile_setup': 'How do I setup my profile?',
        'help_clients.project_create': 'How do I create a project?',
        'help_clients.task_create': 'How do I create a task?',
        'help_clients.project_status': 'Where can I see the status of my projects?',
        'help_clients.task_proposal': 'What can I do, if I am not satisfied with the task proposal?',

        //CLIENTS answers
        'help_clients.ans_profile_setup': 'Register as a client and then if you click on Browse you will see on the top notification to complete the profile. Then you add your display name, you can choose your profile and cover photo, write a description and add your location.',
        'help_clients.ans_create_project': 'Select browse and then you will see projects and on the top of tle list on the right side you have a button to create a new project. Then you need to fill in information about your project; project name, category, short and long description, you can also upload documents and you have to select estimated start/end date and budget.',
        'help_clients.ans_create_task': 'On the top navigation bar click on my projects and then on the left you have projects and tasks of these projects. You have to click on create a new task there and fill in more details of your task.',
        'help_clients.ans_project_status': 'In my projects you have all your projects and on the left you can filter them by status or you can see the status under the name of project. ',
        'help_clients.ans_task_proposals': 'If you are not satisfied with the proposal you can click on the counter and submit a counter offer or just reject the offer.',

        // COMPETENCIES faq
        'help_competencies.description': 'What are Skills?',
        'help_competencies.bloom_taxonomy': "What is Bloom's Taxonomy?",
        'help_competencies.add_competence': 'How can I add a new skill?',
        'help_competencies.vote_competence': 'How do I vote on an existing skill?',
        'help_competencies.aquire_competence': 'How can I acquire skills?',
        'help_competencies.new_desc_suggestion': 'Where can I suggest a new skill description?',
        'help_competencies.parent_base_competency': 'What are parent and base skills?',

        //COMPETENCIES answers
        'help_competencies.ans_description': 'Skills are particular qualities that a company’s recruiters have decided are desirable for employees to possess. Skills are similar to competencies. Skills are learned, while competencies are inherent qualities an individual possesses – combining skills, knowledge and ability.',
        'help_competencies.ans_bloom_taxonomy': "Bloom’s taxonomy is based on the belief that learners must begin by learning basic, foundational knowledge about a given subject before they can progress to more complex types of thinking such as analysis and evaluation. Bloom’s framework is often presented in the form of a pyramid – much like Maslow’s hierarchy of needs – to show that higher levels of thinking can only be attempted once lower levels have been mastered. For any given course or topic, learners must work through these levels in order and master one level before they can progress to the next. Teachers, course designers, and instructors regularly use Bloom’s taxonomy to help ensure that they are asking appropriate questions and delivering appropriate assignments and assessments during each stage of the learning process. For example, learners must have mastered basic knowledge about a subject before they can start applying their knowledge. Each level acts as a crucial building block for the following level.",
        'help_competencies.ans_add_competence': "On the top navigation bar select skills and contribute. Then you can add a skill, write down skill name after that you have to write down description of this skill based on Bloom's Taxonomy. In the end you have to choose parent skill, base skill and category.",
        'help_competencies.ans_vote_competence': 'In the list of all skills, you can sort them or search for a specific one. Then you choose view details. You can downvote or upvote description, parent skill or base skills.',
        'help_competencies.ans_aquire_competence': 'On job learning and training, participating in new projects, working in groups at the workplace, attending training courses/seminars/conferences...',
        'help_competencies.ans_new_desc_suggestion': 'On the specific skill you have a button "Suggest Description".',
        'help_competencies.ans_parent_base_competency': 'The parent skill positions skill vertically in the registry. Related or base skills that this skill includes or consists of.',
        
        //EDUCATIONAL INSTITUTION faq
        'help_edu.description': 'Lorem ipsum',

        //AUDITORS faq
        'help_auditors.description': 'Lorem ipsum',

        //BLOCKCHAIN faq
        'help_blockchain.smart_contract': 'What is a smart contract?',
        'help_blockchain.description': 'What is a blockchain?',
        'help_blockchain.minting': 'What is minting?',
        'help_blockchain.nft': 'What are non-fungible tokens?',
        'help_blockchain.security': 'How does blockchain ensure my security and privacy?',
        'help_blockchain.pay_tokens': 'What are WNS Chips?',

        //BLOCKCHAIN answers
        'help_blockchain.ans_smart_contract': "Unlike traditional contracts, smart contracts are digital, traceable, secure, irreversible, immutable  and transparent. Any rules about the contract cannot be altered once on the blockchain; this gives both buyer and seller confidence that they won't be cheated during the exchange. Smart contracts are an advanced form of analog contractual agreements, but instead of paper, their medium is digital, decentralized databases, i.e. a blockchain. These blockchain based contracts can self-execute and self-maintain and thus remove the need for the roles of intermediaries (notaries and lawyers).",
        'help_blockchain.ans_description': "Blockchain is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system. A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain. Each block in the chain contains a number of transactions, and every time a new transaction occurs on the blockchain, a record of that transaction is added to every participant’s ledger. The decentralised database managed by multiple participants is known as Distributed Ledger Technology (DLT). Blockchain is a type of DLT in which transactions are recorded with an immutable cryptographic signature called a hash. This means if one block in one chain was changed, it would be immediately apparent it had been tampered with. If hackers wanted to corrupt a blockchain system, they would have to change every block in the chain, across all of the distributed versions of the chain.",
        'help_blockchain.ans_minting': 'Minting is defined as the computer process of validating information, creating a new block and recording that information into the blockchain. Imagine the blockchain as a digital book of records. Just like paper pages, these digital pages can only store a limited amount of information. So new pages are created regularly to store more information. Those pages are blocks in the blockchain. To keep the blockchain network running smoothly, only one block can be created at a time. Proof of stake is the minting process of controlling how blocks are created and how data is added to a block.',
        'help_blockchain.ans_nft': 'If something is fungible, it is mutually exchangeable. To be clearer, it can be exchanged like-for-like with an identical item. US Dollars and Euros are two examples of fungible assets. Bitcoin is a fungible asset because each bitcoin is interchangeable with another. They are identical in every way.  Therefore, if something is non-fungible, it is the opposite. Non-fungible goods are truly unique. A good example of this would be a limited-edition baseball card. There is nothing it can be interchanged with on a like-for-like basis. It has its own distinctive properties, including how it has been preserved, the year it was produced, and its value generally. Non-fungible tokens are unique digital assets. They simply use the blockchain to issue and track immutable digital goods.',
        'help_blockchain.ans_security': 'Blockchain can enable parties with no particular trust in each other to exchange digital data on a peer-to-peer basis with fewer or no third parties or intermediaries. Data could correspond, for instance, to money, insurance policies, contracts, land titles, medical and educational records, birth and marriage certificates, buying and selling goods and services, or any transaction or asset that can be translated into a digital form. The potential of blockchain to engender wide-ranging changes in the economy, industry and society – both now and tomorrow – is currently being explored across sectors and by a variety of organisations.',
        'help_blockchain.ans_pay_tokens': '...',

        //Header
        'help.header': 'How can we help you?',

        /* GAMIFICATION */
        'gamification.boost_project_title': 'Boost project',
        'gamification.boost_task_title': 'Boost task',
        'gamification.boost_content1': 'Boost your project in search results.',
        'gamification.boost_content2.1': 'Your project will appear with ',
        'gamification.boost_content2.2': 'top project ',
        'gamification.boost_content2.3': 'tag for ',
        'gamification.boost_content2.4': '10 days.',
        'gamification.boost_content3.1': 'Increase the reach of your project among freelancers by ',
        'gamification.boost_content3.2': '70%.',
        'gamification.boost_blance': 'Available chips balance',
        'gamification.boost_chips': 'chips',
        'gamification.boost_buy_chips': 'Or buy boosts with your chips.',
        'gamification.boost': 'boost',
        'gamification.boost_beset_offer': 'Best offer',
        'gamification.boost_now': 'Boost now',
        'gamification.boost_content1_task': 'Boost your task in search results.',
        'gamification.boost_content2.1_task': 'Your task will appear with ',
        'gamification.boost_content3.1_task': 'Increase the reach of your project among freelancers by ',
        'gamification.boost_project_not_boosted': 'Your project is not boosted.',
        'gamification.boost_task_not_boosted': 'Your task is not boosted.',

        'landing.more-than-just': 'More than just',
        'landing.a-frelancing-portal': 'a freelancing portal!',
    },
    sl: {
        'common.save': 'Shrani',
        'common.save_and_continue': 'Shrani in nadaljuj',
        'common.save_and_add': 'Dodaj več',
        'common.confirm_and_save': 'Potrdi in shrani',
        'common.edit': 'Uredi',
        'common.remove': 'Odstrani',
        'common.delete': 'Izbriši',
        'common.close': 'Zapri',
        'common.download': 'Prenesi',
        'common.cancel': 'Prekliči',
        'common.counter': 'Nova ponudba',
        'common.reject': 'Zavrni',
        'common.accept': 'Sprejmi',
        'common.add': 'Dodaj',
        'common.add_new': '+ Dodaj',
        'common.upload': 'Naloži',
        'common.submit': 'Pošlji',
        'common.verify': 'Verificiraj',
        'common.verified': 'Verificirano',
        'common.back': 'Pojdi nazaj',
        'common.next': 'Naslednji korak',
        'common.finish': 'Zaključi',
        'common.project': 'Projekt',
        'common.task': 'Naloga',
        'common.rec_task': 'Ponavljajoča se naloga',
        'common.all': 'Vsi',
        'common.from': 'Od',
        'common.to': 'Do',
        'common.from_years': 'Leto od',
        'common.to_years': 'Leto do',
        'common.draft': 'Osnutek',
        'common.published': 'Objavljen',
        'common.in_negotiation': 'V pogajanju',
        'common.pending_funding': 'Financiranje v teku',
        'common.funding_in_progress': 'Financiranje v postopku',
        'common.active': 'Aktiven',
        'common.in_progress': 'V izvajanju',
        'common.submitted': 'Oddano',
        'common.pending_audit': 'Čaka na Revizijo',
        'common.in_audit': 'V Reviziji',
        'common.disputed': 'V sporu',
        'common.in_dispute': 'V sporu',
        'common.audit': 'Revizija',
        'common.auditor_confirmed': 'Revizija Potrjena',
        'common.auditor_failed': 'Revizija Zavrnjena',
        'common.completed_with': 'Revizija zaključena z ',
        'common.percentage_success': '% uspehom.',
        'common.completed': 'Dokončano',
        'common.confirmed': 'Potrjeno',
        'common.rejected': 'Zavrnjeno',
        'common.pending': 'Čaka na potrditev',
        'common.invited': 'Povabila',
        'common.closed': 'Zaprto',
        'common.open': 'Odprto/Aktivno',
        'common.confirm': 'POTRDI',
        'common.failed': 'Neuspešno',
        'common.public': 'Javen',
        'common.private': 'Zaseben',
        'common.files.no_preview': 'Te datoteke ni mogoče pripraviti na ogled. Za ogled ga prenesite.',
        'common.files.preview': 'Pregledovalnik datotek',
        'common.comment': 'Komentar',
        'common.misc': 'Razno',
        'common.read_more': 'Preberite več...',
        'common.more': 'Več...',
        'common.contribute': 'PRISPEVAJ',
        'common.level': 'Stopnja',
        'common.logintocontribute': 'Prijavi se in prispevaj',
        'common.verifytocontribute': 'Potrdi e-pošto in prispevaj',
        'common.history': 'Zgodovina',
        'common.yes_delete': 'DA, ODSTRANI',
        'common.sure_about_delete': 'Ste prepričani, da želite odstraniti ta podatek?',
        'common.confirmation': 'Potrditveno okno',
        'common.up': 'Za',
        'common.down': 'Proti',
        'common.enabled': 'Omogočeno',
        'common.disabled': 'Onemogočeno',
        'common.yes': 'DA',
        'common.no': 'NE',
        'common.custom': 'Po meri',
        'common.active_freelancer': 'Aktiven izvajalec',
        'common.recurring': "Ponavljajoče",
        'common.reccurrance': "Ponavljanje",
        'common.reccurring_task': "Ponavljajoča naloga",
        'common.recurring_type': "Tip ponavljanja",
        'common.reccurring_times': "Ponovi X",
        'common.interval': "INTERVALNO (start/konec datum)",
        'common.weekly': "TEDENSKO",
        'common.monthly': "MESEČNO",
        'common.create': "USTVARI",
        'common.milestone': "Mejnik",
        'common.milestones': "Mejniki",
        'common.milestones.create': "Ustvari nov Mejnik",
        'common.conditions': "Pogoji",
        'common.of_task_price': "cene naloge",
        'common.task_success': "Naloga opravljena",
        'common.search_freelancers': 'Išči izvajalce',
        'common.administrators': 'Administratorji',
        'common.administrator': 'Administrator',
        'common.monday_short': 'Pon',
        'common.tuesday_short': 'Tor',
        'common.wednesday_short': 'Sre',
        'common.thursday_short': 'Čet',
        'common.friday_short': 'Pet',
        'common.saturday_short': 'Sob',
        'common.sunday_short': 'Ned',
        'common.monday': 'Ponedeljek',
        'common.tuesday': 'Torek',
        'common.wednesday': 'Sreda',
        'common.thursday': 'Četrtek',
        'common.friday': 'Petek',
        'common.saturday': 'Sobota',
        'common.sunday': 'Nedelja',
        'common.year': 'Leto',
        'common.days': 'Dni',
        'common.hours': 'Ur',
        'common.minutes': 'Minut',
        'common.seconds': 'Sekund',
        'common.mins': 'Min',
        'common.secs': 'Sek',
        'common.ooops': 'Uuups!',
        'common.warning': 'Opozorilo',
        'common.page_not_found': "Stran, ki jo iščete ne obstaja ali nimate pravic za dostop do te povezave.",
        'common.leave_a_comment': 'Napišite komentar...',
        'common.name': 'Naziv',
        'common.list': 'Seznam',
        'common.national_id': 'EMŠO',
        'common.verify_email_to_view': 'Potrdite e-pošto za ogled',
        'common.did_not_get_verify_email': 'Niste prejeli potrditvene e-pošte?',
        'common.confirmDeleteTitle': 'Potrditev',
        'common.confirmDeleteText': 'Ali ste prepričani?',
        'common.addCompetency': 'Dodaj novo Znanje',
        'common.definition': 'Definicija',
        'common.relations': 'Relacije',
        'common.alertTitle': 'Opozorilno okno',
        'common.alertTextCompetencies': 'Izberite vsaj 5 znanj.',
        'common.alertTextDisputeCase': 'Izbrati morate odstotek med 5 in 100.',
        'common.status': 'Status',
        'common.actions': 'Akcije',
        'common.all_w': 'Vse',
        'common.published_w': 'Objavljene',
        'common.draft_w': 'Osnutki',
        'common.publish': 'Objavi',
        'common.here': 'tukaj',
        'common.continue': 'Nadaljuj',
        'common.skills': 'Znanja',
        'common.connect_google': 'Povežite z google računom',
        'common.advanced-filter': 'Napredni filter',
        'common.iam': 'Sem',
        'common.weare': 'Smo',
        'common.a_company': 'NAROČNIK',
        'common.a_freelancer': 'IZVAJALEC',
        'common.an_edu': 'IZOBRAŽEVALNA USTANOVA',
        'common.amount': 'Znesek: ',
        'common.time': 'Čas: ',
        'common.click_here': 'klikni tukaj',
        'common.required': '*obvezno',
        'common.earned': 'PRIDOBLJENE',
        'common.user_defined': 'UPORABNIŠKE',
        'common.browse_freelancers': 'Poiščite talent',
        'common.browse_tasks': 'Prebrskaj naloge',
        'common.wallet': 'Denarnica',
        'common.settings': 'Nastavitve',
        'common.help': 'Pomoč',

        'tooltip.fill_out': 'Izpolnite naslednje sekcije:',
        'tooltip.email_verification': 'Potrdite vašo e-pošto',
        'tooltip.personal_info': 'Osnovni podatki',
        'tooltip.description': 'Opis',
        'tooltip.profile_image': 'Profilna slika',
        'tooltip.cover_image': 'Naslovna slika',
        'tooltip.availability': 'Razpoložljivost',
        'tooltip.competencies': 'Znanja',
        'tooltip.experience': 'Izkušnje',
        'tooltip.education': 'Izobrazba',
        'tooltip.issuer_id': 'Za generiranje ključa nekega znanja. Primer "imeuniverze_nazivznanja".',
        'tooltip.issuer_task_competencies': 'Upravljajte kako dovoljujete, da se uporabljajo vaša znanja v nalogah ustvrjenih iz strani naročnikov in prislužene iz strani izvajalcev.',
        'tooltip.issuer_disabled': 'Ne dovolite uporabe vaših znanj v nalogah iz strani tretjih uporabnikov.',
        'tooltip.issuer_sys_certificate': 'Dovolite uporabo vaših znanj v nalogah z kopijo podpisano iz strani sistemskega izdajatelja.',
        'tooltip.issuer_my_certificate': 'Dovolite uporabo vaših znanj v nalogah.',
        'tooltip.issuer_public_adding': 'Dovolite drugim uporabnikom, da ustvarijo nova znanja v vašem imenu.',
        'tooltip.issuer_public_voting': 'Omogočite javna mnenja o definicijah in povezavah o vsebinah znanj.',
        'tooltip.issuer_external_issuer': 'Izberite, če ste zunanji eksterni izdajatelj. Naš program bo poskušal nadgraditi vaša znanja. Kontaktirajte nas za več informacij.',
        'tooltip.issuer_external_issuer_details': 'Če ste zunanji eksterni izdajatelj, bo naš program poskušal nadgraditi vaša znanja. Kontaktirajte nas za več informacij.',
        'tooltip.issuer_external_address': 'Navedite zunanju naslov, če ste zunanji eksterni izdajatelj.',
        'tooltip.earned_competencies': 'Znanja, ki jih je uporabnik pridobil ali z uspešno zaključenimi nalogami ali preko potrjene formalne izobrazbe.',
        'tooltip.userdefined_competencies': 'Znanja, ki si jih je uporabnik dodal na svoj profil sam.',
        'tooltip.auto_mint_competencies': 'Vaša znanja, ki si jih pridobite z končanjem nalog so lahko avtomatično shranjene na Blockchain.\n Shranjevanje znanj nekaj stane. Vedno lahko to nastavitev spremenite v nastavitvah vašega profila',
        'tooltip.mint_competencies': 'Za svoja pridobljena znanja z zaključevanjem nalog lahko ustvarite NFT žeton na Blockchainu.\n To nekaj stane, vendar postanete dejanski lastnik svojih "certificiranih znanj".',
        
        // LANDING
        'home.header_title': 'Več kot zgolj portal za samozaposlene',
        'home.header_subtitle': 'S pomočjo tehnologije verižnih blokov zagotavljamo preverjena dokazila o znanjih, uveljavljamo plačilno disciplino, zagotavljamo kakovostne revizije in posredujemo pri morebitnih sporih.',
        'home.header_employer': 'Sem delodajalec',
        'home.header_freelancer': 'Sem samozaposlen',
        'home.header_edu': 'Smo izobraževalna ustanova',
        'home.countdown_title': 'Prihaja v Q4 2022',

        'home.offer_title': 'Kaj ponujamo',
        'home.offer_subtitle': 'WNS ponuja orodja za izogibanje kakršnim koli procesom preverjanja, s čimer po eni strani opolnomoči posameznike pri iskanju zaposlitve, po drugi strani pa razbremeni stranke z zmanjšanjem  stroškov pri procesih zaposlovanja.',
        'home.offer_client': 'Naročnik',
        'home.offer_client_1': 'Poiščite popolno ujemanje znanj za svojo nalogo',
        'home.offer_client_2': 'Podeljujte in izdajajte znanja kot NFT-je.',
        'home.offer_client_3': 'Ponovite in dokončajte svoj projekt',
        'home.offer_client_4': 'Zaupen dostop do globalni talentov',
        'home.offer_client_5': 'Transakcije brez tveganja',
        'home.offer_freelancer': 'Izvajalec',
        'home.offer_freelancer_1': 'Vsaka naloga je opremljena z naborom znanj',
        'home.offer_freelancer_2': 'Z dokončanjem nalog pridobite znanja kot NFT-je',
        'home.offer_freelancer_3': 'Ponovi in razvijaj svoj nabor znanj',
        'home.offer_freelancer_4': 'Predstavite svojo verodostojnost',
        'home.offer_freelancer_5': 'Izboljšaj svoje karierne možnosti ',

        'home.hire_title': 'Najamite primeren talent. Postani iskan talent.',
        'home.hire_subtitle': 'Vas počasni posredniki, skrbi za plačila in kvaliteto naročenega dela, utrujajo?',

        'home.challenges_title': 'Izzivi na trgu dela',
        'home.challenges_companies': 'PODJETJA',
        'home.challenges_companies_txt': 'Kako najti in najeti verodostojne delavce in samozaposlene?',
        'home.challenges_companies_txt2': 'S tem, da smo naredili dodatna preverjanja stvar preteklosti: z WNS dobite to kar vidite.',
        
        'home.challenges_freelancers': 'SAMOZAPOSLENI IN ISKALCI ZAPOSLITVE',
        'home.challenges_freelancers_txt': 'Kako sestaviti enoten in verodostojen življenjepis?',
        'home.challenges_freelancers_txt2': 'Tako da, svoj posel dvignete na višjo raven: pridobite preverjena znanja z vsako uspešno opravljeno nalogo in zgradite obsežen nabor znanj.',
        'home.challenges_edus': 'IZOBRAŽEVALNE USTANOVE',
        'home.challenges_edus_txt': 'Kako poskrbite, da vaši izobraževalni programi ustrezajo potrebam vaših študentov in niso zastareli?',
        'home.challenges_edus_txt2': 'S tem, da postanete del našega rastočega ekosistema kot priznan izdajatelj izobraževalnih potrdil. Z nami lahko merite in spremljate tržni uspeh svojih diplom.',

        'home.solution_title': 'Zgradili smo rešitev za vse!',
        'home.solution_subtitle': 'Za izgradnjo mreže verodostojnosti smo uporabili enoto, ki si jo delijo vsa podjetja, iskalci zaposlitve in izobraževalne ustanove – <b>preverjeno znanje.</b> {n}Kompetenca edinstveno opisuje določeno znanje. in z izgradnjo enotnega registra veščin zagotavljamo, da vse strani uporabljajo enoten jezik glede znanj, potrebnih za določen projekt ali delovno mesto.',

        'home.howitworks_title': 'Kako deluje',
        'home.howitworks_subtitle': 'Zaupanje, brez dovoljenj in brez posrednikov',

        'home.contact_title': 'Imate vprašanja?',
        'home.contact_subtitle': 'Vstopite v stik z nami',
        'home.contact_action': 'KONTAKT',

        'home.how_title': 'Kako smo to dosegli?',
        'home.how_1_title': 'Vsebina, ki temelji na skupnosti',
        'home.how_1_txt': 'Vsakemu dajemo možnost, da prispeva svojo definicijo znanja',
        'home.how_2_title': 'Sistem glasovanja',
        'home.how_2_txt': 'Glasuj za ali proti predlaganim definicijam znanj',
        'home.how_3_title': 'Tehnologije veriženja blokov',
        'home.how_3_txt': 'Za višjo raven zaupanja, je naš univerzalni register znanj zabeležen v verižnih blokih.',
        'home.how_4_title': 'Revizorji',
        'home.how_4_txt': 'Nevtralni revizorji so lahko povabljeni, da ocenijo delo in rešijo morebitne spore.',
        'home.how_5_title': 'Pametne pogodbe',
        'home.how_5_txt': 'Vsi spori in plačila se rešujejo in izvajajo s pametnimi pogodbami.',

        'home.joinus_title': 'Postani del skupnosti in prispevaj',
        'home.joinus_1': 'Ustvari si račun',
        'home.joinus_2': 'Dodaj manjkajoča znanja ali glasuj glede vsebin obstoječih definicij znanj',
        'home.joinus_3': 'Uredi si svoj javni profil',

        'home.partners_title': 'Podjetja in organizacije, ki nas podpirajo',
        // END LANDING

        'setup_profile.company_info': 'Podatki o podjetju',
        'setup_profile.required': 'Potrebne informacije, preden lahko nekoga najamete',
        'setup_profile.work_info': 'Podatki o delu',
        'setup_profile.availability': 'Nastavite razpoložljivost',
        'setup_profile.full_time': 'Polni delovni čas',
        'setup_profile.part_time': 'Krajši delovni čas',
        'setup_profile.all': 'Vsi',
        'setup_profile.not_available': 'Ne sprejemam dela',
        'setup_profile.hourly_rate': 'Tvoja urna postavka',
        'setup_profile.select_days': 'Izberite delovne dni',
        'setup_profile.desc': 'Poišči popolne projekte zate in se loti dela!',
        'setup_profile.finish': 'Odlično opravljeno!',
        'setup_profile.finish.desc': 'Profil lahko nadaljnjo nadgradite na strani Moj profil.',
        'setup_profile.projects.desc': "Za vas smo že našli nekaj projektov!",
        'setup_profile.projects': 'Iščite po projektih',
        'setup_profile.profile': 'Ogled mojega profila',
        'setup_profile.fill_company_data': 'Prosimo izpolnite spodnja polja, če ste zastopnik podjetja.',
        'setup_profile.represent': 'Zastopam podjetje',
        'setup_profile.projects_duration': 'Trajanje projektov',
        'setup_profile.interest_projects_duration': 'Zanimajo me projekti, ki trajajo',
        'setup_profile.interest_task_pricing': 'Zanimajo me naloge z proračunom', // 'I'm interested in tasks with remuneration',
        'setup_profile.dev.finished': 'Za vas smo poiskali že nekaj nalog!',
        'setup_profile.inv.finished': 'Raziščite obstoječe projekte ali ustvarite nov projekt.',
        'setup_profile.edu.finished': 'Raziščite obstoječe Institucije ali pojdite na stran Moja Institucija.',
        'setup_profile.aud.finished': 'Raziščite obstoječe Revizorje ali pojdite na stran Moje Revizije.',

        'activate_profile.awesome': 'Super!',
        'activate_profile.desc': 'Vaš račun je aktiviran!',
        'activate_profile.desc_long': 'Zdaj se lahko prijavite in nadaljujete z nastavitvijo svojega profila.',
        'activate_profile.error': 'Ups!',
        'activate_profile.error_desc': 'Žeton je neveljaven ali pa je potekel!',
        'activate_profile.error_desc_long': 'Prosimo, prijavite se, pojdite na nastavitve svojega profila in ponovno pošljite aktivacijsko e-poštno sporočilo.',

        'my_projects.add_tasks': 'Dodaj nalogo',
        'my_projects.create_task': 'Ustvari Nalogo',
        'my_projects.not_published': 'Tvoj projekt še ni objavljen',
        'my_projects.publish': 'Objavi projekt',
        'my_projects.private': 'Tvoj projekt je privaten. Povabi izvajalce na odprte naloge.',
        'my_projects.project_overview': 'Pregled Projekta',
        'my_projects.invoices': 'Računi',
        'my_projects.is_working': 'Trenutno dela na nalogi',
        'my_projects.finished_work': 'Zaključil delo na nalogi',
        'my_projects.view_task': 'Ogled Naloge',
        'my_projects.submited_work': 'Oddano delo',
        'my_projects.review_work': 'Pregled dela',
        'my_projects.preview': 'Predogled in objava',
        'my_projects.you_can_complete_your_project': 'Projekt je na voljo za zaključek',
        'my_projects.complete': 'Končaj projekt',
        'my_projects.view': 'Ogled Projekta',
        'my_projects.invite_freelancers': 'Povabi razvijalce',
        'my_projects.boost': 'Promoviraj',

        'my_projects.warning_info': 'Nekateri parametri, ki jih skušate urejati so izven obsega parametrov projekta',
        'my_projects.task_price_exceeds': 'Cena naloge presega predvideni proračun projekta za <b>{price}</b>.',
        'my_projects.task_start_date_before': 'Začetni datum naloge (<b>{task_date}</b>) je pred datumom začetka projekta (<b>{project_date}</b>).',
        'my_projects.task_end_date_after': 'Končni datum naloge (<b>{task_date}</b>) je po končnem datumu projekta (<b>{project_date}</b>).',
        'my_projects.save_will_update': 'Potrdite nadaljevanje in projekt bo posodobljen z naslednjimi vrednostmi',

        'my_task.upload': 'Naložite dokumente',
        'my_task.complete': 'Zaključite nalogo',
        'my_task.not_published': 'Tvoja naloga še ni objavljena',
        'my_task.publish': 'Objavi nalogo',
        'my_task.publish_invite': 'Objavite svoj projekt in povabite ljudi k tej zasebni nalogi.',
        'my_task.private': 'Tvoja naloga je zasebna. Povabi izvajalce in začni pogajanje.',
        'my_task.invite': 'Povabi izvajalce.',
        'my_task.back_to_all': 'Nazaj na seznam nalog',
        'my_task.review_work': 'Preglej dokončano delo',
        'my_task.review_user': 'Oceni izvajalca',
        'my_task.task_overview': 'Pregled naloge',
        'my_task.task_proposals': 'Ponudbe',
        'my_task.submited_work': 'Oddano delo',
        'my_task.new_offer': 'Nova ponudba',
        'my_task.send_offer': 'Pošlji ponudbo',
        'my_task.final': 'Končna oddaja',
        'my_task.submit': 'Oddaj delo',
        'my_task.completed': 'Naloga končana?',
        'my_task.completed_desc': 'Priložite datoteke in zaključite z delom.',
        'my_task.estimated_cost': 'Skupno ocenjeni stroški',
        'my_task.cost_explanation': 'Če izvajalec sprejme pogoje, boste morali financirati nalogo in pokriti pristojbino za platformo v DAI ter provizijo za transakcijo v',
        'my_task.fee_calculation': 'Prosim počakajte, moramo izračunati pristojbine...',
        'my_task.task_renumeration': 'Plačilo naloge: ',
        'my_task.platform_fee': 'Pristojbina platforme: ',
        'my_task.total': 'Skupno: ',
        'my_task.transaction_fee': 'Provizija za transakcijo: ',
        'my_task.error_cost': 'Napaka pri pridobivanju ocene skupnih stroškov. Prosim poskusite ponovno.',
        'my_task.fund_task': 'Financiraj nalogo',
        'my_task.insufucient_balance': 'Nimate dovolj sredstev na računu',
        'my_task.available_dai': 'Razpoložljivo DAI stanje',
        'my_task.available_bnb': 'Razpoložljivo BNB stanje',
        'my_task_tooltip.negotiation': 'Seznam nalog, ki so v fazi pogajanja.',
        'my_task_tooltip.confirmed': 'Seznam nalog, ki so bile potrjene.',
        'my_task_tooltip.in_progress': 'Seznam nalog, ki so v fazi izvajanja.',
        'my_task_tooltip.submitted': 'Seznam nalog, ki so bile oddane.',
        'my_task_tooltip.completed': 'Seznam nalog, ki so bile končane.',
        'my_task_tooltip.in_audit': 'Seznam nalog, ki so v fazi pregledovanja.',
        'my_task.tooltip.rejected': 'Seznam nalog, ki so bile zavrnjene ali odpovedane.',
        'my_task.tooltip.status': 'Status ponudbe',

        'my_audits.negotiation': 'Pogajanje',
        'my_audits.communication': 'Komunikacija',
        'my_audits.file_submission': 'Predložitev datotek',
        'my_audits.complete': 'Celotna zgodovina',

        // Message Sent
        'history.freelancer_send_message.title': 'Freelancer sent a message',
        'history.freelancer_send_message.text': '"<b>{message}</b>"',
        'history.client_send_message.title': 'Client sent a message',
        'history.client_send_message.text': '"<b>{message}</b>"',
        //Task Status Changed
        'history.task_status_changed.title': 'Status naloge se je spremenil',
        'history.task_is_in_negotiation.text': 'Naloga je v statusu pogajanja.',
        'history.task_is_published.text': 'Naloga objavljena.',
        'history.task_is_draft.text': 'Naloga je sedaj v osnutku.',
        'history.task_is_in_dispute.text': 'Naročnik je vložil spor.',
        'history.task_is_active.text': 'Naloga je aktivna.',
        'history.task_is_in_progress.text': 'Začetni datum naloge se je spremenil.',
        'history.task_is_submitted.text': 'Izvajalec je zaključil nalogo.',
        'history.task_is_pending_audit.text': 'Naročnik je vložil spor s pritožbo: ',
        'history.task_is_in_audit.text': 'Revizor je sprejel revizijo spora.',
        'history.task_is_auditor_confirmed.text': 'Revizor je označil nalogo kot zaključeno.',
        'history.task_is_auditor_failed.text': 'Revizor je označil nalogo kot neuspelo.',
        'history.task_is_completed.text': 'Naloga je uspešno zaključena.',
        'history.task_is_pending_funding.text': 'Financiranje naloge je v teku.',
        'history.task_is_funding_in_progress.text': 'Financiranje naloge je v obravnavanju.',
        //Task Auditor Changed
        'history.task_auditor_changed.title': 'Revizor za nalogo <b>{task_name}</b> se je spremenil',
        'history.task_auditor_changed.text': 'Zaradi preteklega časa se je spremenil aktivni revizor.',
        //Task Proposal Changed
        //CLIENT_INVITED, FREELANCER_PROPOSED, TASK_EDITED, FILE_EDITED, SENT_FINAL, ACCEPTED, COUNTERED, REJECTED, AUTO_REJECTED
        'history.task_proposal_changed_client_invited.title': 'Naročnik <b>{client_name}</b> je poslal povabilo',
        'history.task_proposal_changed_client_invited.text': 'Naročnik je k sodelovanju povabil izvajalca',
        'history.task_proposal_changed_freelancer_proposed.title': 'Nov predlog naloge',
        'history.task_proposal_changed_freelancer_proposed.text': 'Izvajalec je poslal nov predlog naloge',
        'history.task_proposal_changed_task_changed.title': 'Podatki za nalogo <b>{task_name}</b> so se spremenili',
        'history.task_proposal_changed_task_changed.text': 'Naročnik <b>{client_name}</b> je spremenil podatke naloge, zato se predlog ponastavi.',
        'history.task_proposal_changed_file_edited.title': 'Dokumentacija naloge <b>{task_name}</b> se je spremenila',
        'history.task_proposal_changed_file_edited.text': 'Naročnik <b>{client_name}</b> je spremenil dokumentacijo naloge, zato se predlog ponastavi.',
        'history.task_proposal_changed_sent_final.title': 'Končni predlog naloge',
        'history.task_proposal_changed_sent_final.text': 'Naročnik je poslal predlog naloge',
        'history.task_proposal_changed_accepted.title': 'Predlog naloge sprejet',
        'history.task_proposal_changed_accepted.text': 'Izvajalec je poslal predlog naloge',
        'history.task_proposal_changed_countered.title': 'Predlog naloge se je spremenil',
        'history.task_proposal_changed_countered.text': 'Poslan je bil nov predlog naloge.',
        'history.task_proposal_changed_rejected.title': 'Predlog naloge je bil zavrnjen',
        'history.task_proposal_changed_rejected.text': 'Predlog med izvajalcem <b>{freelancer_name}</b> in naročnikom <b>{client_name}</b> je bilo na tej nalogi <b>Zavrnjeno</b>.',
        'history.task_proposal_changed_auto_rejected.title': 'Ostali predlogi so bili sprejeti',
        'history.task_proposal_changed_auto_rejected.text': 'Predlog med izvajalcem <b>{freelancer_name}</b> in naročnikom <b>{client_name}</b> je bilo na tej nalogi <b>Avtomatsko zavrnjeno</b>, ker so bili ostali predlogi sprejeti.',
        //Task Result
        'history.task_result_added.title': 'Izvajalec je poslal oddajo naloge',
        'history.task_result_added.text': '"<b>{result_name}</b>"',
        'history.task_result_edited.title': 'Oddaja naloge spremenjena:',
        'history.task_result_edited.text': 'Izvajalec je poslal novo oddajo naloge s komentarjem: "<b>{result_name}</b>"',
        //Task Result Document
        'history.task_result_document_added.title': 'Izvajalecje dodal datoteke med oddajo:',
        'history.task_result_document_added.text': '<b>{freelancer_name}</b> je dodal document: "<b>{document_name}</b>"',
        'history.task_result_document_deleted.title': 'Izvajalec <b>{freelancer_name}</b> je odstranil dokument',
        'history.task_result_document_deleted.text': '<b>{freelancer_name}</b> je odstranil dokument: "<b>{document_name}</b>"',

        //Plural
        "plural.tasks": "{count, plural, =0 {Ni Nalog} one {# Naloga} two{# Nalogi} few{# Naloge} =4{# Naloge} other{# Nalog}}",
        "plural.projects": "{count, plural, =0 {Ni Projektov} one {# Projekt} two{# Projekta} few{# Projekti} =4{# Projekta} other{# Projektov}}",
        "plural.auditors": "{count, plural, =0 {Ni Avditorjev} one {# Avditor} two{# Avditorja} few{# Avditorji} =4{# Avditorji} other{# Avditorjev}}",
        "plural.clients": "{count, plural, =0 {Ni Naročnikov} one {# Naročnik} two{# Naročnika} few{# Naročniki} =4{# Naročniki} other{# Naročnikov}}",
        "plural.freelancers": "{count, plural, =0 {Ni Izvajalcev} one {# Izvajalec} two{# Izvajalca} few{# Izvajalci} =4{# Izvajalci} other{# Izvajalcev}}",
        "plural.competencies": "{count, plural, =0 {Ni Znanj} one {# Znanje} other {# Znanj}}",
        "plural.edus": "{count, plural, =0 {Ni Izobraževalnih ustanov} one {# Izobraževalna ustanova} two{# Izobraževalni ustanovi} few{# Izobraževalne ustanove} =4{# Izobraževalne ustanove} other{# Izobraževalnih ustanov}}",
        "plural.competences.selected": "{count, plural, =0 {Ni izbranih znanj} one {# izbrano Znanje} two{# izbrani Znanji} few{# Izbrana Znanja} =4{# Izbrana Znanja} other{# izbranih Znanj}}",
        "plural.files.attached": "{count, plural, =0 {Ni Priloženih Datotek} one {# Priložena Datoteka} two{# Priloženi Datoteki} few{# Priložene Datoteke} =4{# Priložene Datoteke} other{# Priloženih Datotek}}",
        "plural.task.proposals": "{count, plural, =0 {Ni Ponudb} one {# Ponudba} two{# Ponudbi} few{# Ponudbe} =4{# Ponudbe} other{# Ponudb}}",
        "plural.profile.reviews": "{count, plural, =0 {Ni ocen}  one {# Ocena} two{# Oceni} few{# Ocen} =4{# Ocene} other{# Ocen}}",


        'demo.hello': 'Hej.',
        'forms.loginForm': 'Prijava',
        'forms.registrationForm': 'Registracija',
        'forms.registerRoleForm': 'Registracija Vloge',
        'forms.forgotPasswordForm': 'Pozabljeno geslo',
        'forms.resetPasswordForm': 'Ponastavitev gesla',

        'forms.reset_filters': 'Ponastavite filtre',
        'forms.min_finished_tasks': 'Min število',

        'forms.labels.username': 'Uporabniško ime',
        'forms.labels.password': 'Geslo',
        'forms.labels.newPassword': 'Novo geslo',
        'forms.labels.confirmPassword': 'Potrdi geslo',
        'forms.labels.email': 'E-pošta',
        'forms.labels.name': 'Ime',
        'forms.labels.firstName': 'Ime',
        'forms.labels.lastName': 'Priimek',
        'forms.labels.gender': 'Spol',
        'forms.labels.gender.male': 'Moški',
        'forms.labels.gender.female': 'Ženska',
        'forms.labels.login': 'Prijava',
        'forms.labels.loginWithSocial': 'Prijavite se s socialnim omrežjem.',
        'forms.labels.forgotPassword': 'Ste pozabili geslo? ',
        'forms.labels.resetHere': 'Resetirajte geslo.',
        'forms.labels.noAccountYet': 'Še nimate računa? ',
        'forms.labels.goToRegistration': 'Pojdite na registracijo.',
        'forms.labels.sendMail': 'Pošlji sporočilo',
        'forms.labels.register': 'Ustvari račun',
        'forms.labels.resetPassword': 'Ponastavite geslo',
        'forms.labels.alreadyHaveAccount': 'Že imate račun? ',
        'forms.labels.goToLogin': 'Pojdite na prijavo.',
        'forms.labels.update': "Posodobi",
        'forms.labels.first_name': "Ime",
        'forms.labels.last_name': "Priimek",
        'forms.labels.address_line_1': "Naslov",
        'forms.labels.address_line_2': "Naslov - vrstica 2",
        'forms.labels.post_code': "Poštna številka",
        'forms.labels.city': "Kraj",
        'forms.labels.state': "Pokrajina",
        'forms.labels.country': "Država",
        'forms.labels.vat': "Davčna številka",
        'forms.labels.register_num': "Registracijska številka",
        'forms.labels.company_name': "Naziv podjetja",
        'forms.labels.howitworks': "Kako deluje",
        'forms.labels.roleSelect': 'Izberite svojo vlogo (lahko jih dodate več kasneje)',
        'forms.labels.continue': 'Nadaljuj',
        'forms.labels.rememberme': 'Ostani prijavljen',
        'forms.labels.continueWithGoogle': 'Prijavi se z Google-om',
        'forms.labels.iagreewiththe': 'Strinjam se z',
        'forms.labels.termsandconditions': 'pogoji uporabe',
        'forms.labels.and': 'in',
        'forms.labels.more': 'več',
        'forms.labels.privacypolicy': 'politiko zasebnosti',
        'forms.labels.status': 'Status',
        'forms.labels.project_name': 'Ime projekta',
        'forms.labels.task_name': 'Ime naloge',
        'forms.labels.category': 'Kategorija',
        'forms.labels.short_description': 'Kratek opis',
        'forms.labels.description': 'Opis',
        'forms.labels.documents': 'Dokumenti',
        'forms.labels.add_documents': 'Dodaj dokumente',
        'forms.labels.select_documents': 'Izberite svoje dokumente',
        'forms.labels.selected_documents': 'Izbrani dokumenti',
        'forms.labels.drag_documents': 'Povleci dokumente sem ali klikni na gumb izberi',
        'forms.labels.estimated_start_end': 'Predviden čas začetka/konca',
        'forms.labels.price': 'Cena',
        'forms.labels.required_competences': 'Potrebna znanja',
        'forms.labels.visibility': 'Vidnost',
        'forms.labels.accept_proposal': 'Ali ste prepričani, da želite sprejeti ta predlog naloge?',
        'forms.labels.accept_fund': 'Ali ste prepričani, da želite financirati to nalogo?',
        'forms.labels.comment': 'Komentar',
        'forms.labels.public_link': 'Javna povezava',
        'forms.labels.min_chars': '(min {min} znakov)',
        'forms.labels.invite_freelancers_to_task': 'Povabite izvajalce na naloge',
        'forms.labels.contributed_by': 'Prispeval',
        'forms.labels.registerWithGoogle': "Prijava za Google računom",
        'forms.labels.invited_freelancers': 'Povabljeni razvijalci:',
        'forms.labels.phone': 'Telefon',
        'forms.labels.message': 'Sporočilo',

        'forms.placeholders.comment': 'Dodajte komentar k svojemu delu ...',
        'forms.placeholders.select_chat': 'Izberite stik za klepet ...',
        'forms.placeholders.messages': 'Napišite sporočilo...',
        'forms.placeholders.competence': 'Dodajte 1-5 znanj',
        'forms.placeholders.addcompetence': 'Dodaj novo Znanje',
        'forms.placeholders.select': 'Izberite...',
        'forms.placeholders.username': 'Vnesite svoje uporabniško ime...',
        'forms.placeholders.email': 'Vnesite svoj e-poštni naslov...',
        'forms.placeholders.newPassword': 'Vnesite novo geslo...',
        'forms.placeholders.confirmPassword': 'Potrdite novo geslo...',
        'forms.placeholders.category': 'Izberite kategorijo...',
        'forms.placeholders.select_country': 'Izberite državo...',
        'forms.placeholders.search_country': 'Išči...',
        'forms.placeholders.select_freelancer': 'Izberi razvijalce...',
        'forms.placeholders.select_status': 'Izberi status',
        'forms.placeholders.select_level': 'Izberi stopnjo',
        'forms.placeholders.select_skills': 'Izberi znanja',
        'forms.placeholders.select_category': 'Izberi kategorijo',
        'forms.placeholders.select_sort': 'Sortiraj po',
        'forms.placeholder.select_tasks': 'Izberi naloge...',

        'validators.required': 'To polje je obvezno!',
        'validators.minLenIs': 'Geslo mora vsebovati najmanj {num, number} znakov.',
        'validators.minLen8': 'Geslo mora vsebovati najmanj 8 znakov.',
        'validators.textInputRequired': 'To polje je obvezno.',
        'validators.passwordMatch': 'Geslo se mora ujemati.',
        'validators.maxSize': 'Največja dovoljena velikost je 20Mb.',
        'validators.minChars': 'Minimalno 10 znakov ',
        'validators.left': ' preostane',
        'validators.needsNumber': 'Geslo mora vsebovati vsaj eno številko.',
        'validators.needsMixedCharacters': 'Geslo mora vsebovati mešane velik in male črke.',
        'validators.needsSpecialCharasters': 'Geslo mora vsebovati vsaj en poseben znak (!"#$).',
        'validators.tasks': 'Izbrati morate nalogo!',
        'validator.freelancers': 'Izbrati morate izvajalce',

        'nav.labels.logout': 'Odjava',
        'nav.labels.profile': 'Profil',
        'nav.labels.profile.select': 'Izberi Profil',
        'nav.labels.login': 'Prijava',
        'nav.labels.signin': 'Prijava',
        'nav.labels.register': 'Registracija',
        'nav.labels.browse': 'Brskaj',
        'nav.labels.competencies': 'Znanja',
        'nav.labels.myProjects': 'Moji projekti',
        'nav.labels.myTasks': 'Moje naloge',
        'nav.labels.dashboard': 'Nadzorna plošča',
        'nav.labels.myWork': 'Moje delo',
        'nav.labels.myInstitution': 'Moja Ustanova',
        'nav.labels.myAudits': 'Moje delo',
        'nav.labels.myClients': 'Moje stranke',
        'nav.labels.howitworks': 'Kako deluje',
        'nav.labels.postaproject': 'Objavi projekt',
        'nav.labels.profile_setup': 'Nastavitev profila',
        'nav.labels.published_projects': 'Objavljeni projekti',

        'infobar.get_hired': 'Pridobite zaposlitev danes!',
        'infobar.complete_profile': 'Dopolnite svoj profil in povečajte možnosti za zaposlitev.',
        'infobar.start_hiring': 'Začnite zaposlevati ljudi danes!',
        'infobar.complete_profile_2': 'Preden začnete zaposlevati, dopolnite svoj profil.',
        'infobar.setup_profile': 'Dopolni profil',
        'infobar.add_google': 'Dodajte Google račun',
        'infobar.add_google_to_enable': 'Povežite svoj Google račun in omogočite Blockchain potrdila za svoja znanja',
        'infobar.connect': 'Poveži',

        'roles.role': 'Vloga',
        'roles.name.DEV': 'Izvajalec',
        'roles.name.INV': 'Naročnik',
        'roles.name.AUD': 'Avditor',
        'roles.name.EDU': 'Izobraževalna ustanova',
        'roles.name.EAP': 'Administrator programov',
        'roles.name.EAS': 'Administrator študentov',
        'roles.name.EAC': 'Administrator Znanj',
        'roles.name.NEW': 'Nova vloga',
        'roles.add.DEV': 'Razvijalec, ki išče delo.',
        'roles.add.INV': 'Delodajalec, ki išče izvajalce/razvijalce.',
        'roles.add.AUD': 'Avditor',
        'roles.add.EDU': 'Izobraževalna ustanova',
        'roles.legalName': 'Pravno ime*',
        'roles.nameAndSurname': 'Ime in priimek*',
        'roles.no_active_role_yet': 'Brez aktivne vloge?',
        'roles.please_register_role': 'Prosimo, ustvarite si vlogo s katero boste uporabljali ECTO.',
        'roles.delete_this_role': 'Pobriši to vlogo?',
        'roles.add_admin': 'Dodajanje administratorja',
        'roles.become_freelancer': 'Postani izvajalec',
        'roles.become_client': 'Postani naročnik',
        'roles.become_auditor': 'Postani naročnik',
        'roles.become_edu': 'Postani izobraževalna ustanova',
        'roles.add_role': 'Dodaj vlogo',

        'edu.students': 'Študenti',
        'edu.student_list': 'Seznam študentov',
        'edu.student_requests': 'Potrjevanje študentov',
        'edu.no_student_requests': 'Brez podatkov',
        'edu.verification_requests': 'Prošnje za verifikacijo',
        'edu.confirm_requests': 'Preverjanje in potrjevanje prošenj za verifikacijo vaših alumni.',
        'edu.alumni_list': 'Seznam vaših verificiranih študentov alumni',
        'edu.edu_programs': 'Izobraževalni programi',
        'edu.programs': 'Programi',
        'edu.degrees': 'Stopnje',
        'edu.program': 'Program',
        'edu.degree': 'Stopnja',
        'edu.inst_admins': 'Administratorji institucije',
        'edu.manage_admins': 'Urejanje uporabnikov, pooblaščenih za administracijo institucije.',
        'edu.student_admins': 'Administratorji študentov',
        'edu.program_admins': 'Administratorji izobraževalnih programov',
        'edu.competencies_admins': 'Administratorji Znanj',
        'edu.manage_programs': 'Urejanje izobraževalnih programov in stopenj vaše institucije',
        'edu.send_request': 'Prošnja za verifikacijo',
        'edu.student_id': 'Št. študenta',
        'edu.diploma_id': 'Št. diplome',
        'edu.edu_competencies': 'Znanja',
        'edu.manage_competencies_all': 'Seznam vseh Znanj vaše institucije.',
        'edu.manage_competencies_published': 'Seznam objavljenih Znanj vaše institucije.',
        'edu.manage_competencies_drafted': 'Seznam osnutkov Znanj vaše institucije.',

        'degrees.new': 'Nova stopnja',
        'degrees.addnew': 'Dodajanje nove stopnje',
        'degree.create.error': 'Napaka pri kreiranju nove stopnje. ',
        'degree.update.error': 'Napaka pri urejanju stopnje. ',
        'degree.delete.error': 'Napaka pri brisanju stopnje. ',

        'program.create.error': 'Napaka pri kreiranju novega programa. ',
        'program.update.error': 'Napaka pri urejanju programa. ',
        'program.delete.error': 'Napaka pri brisanju programa. ',
        'programs.addnew': 'Dodajanje novega programa',
        'programs.your_competencies': 'Znanja (poiščite ali kreirajte svoje)',

        'rating.satisfied': 'Ste zadovoljni z rezultatom?',
        'rating.review': 'Ocenite uporabnika',
        'rating.review_aud': 'Ocenite postopek revizije',
        'rating.review_client': 'Oddajte povratno informacijo o delu z naročnikom',
        'rating.review_inv': 'Ocenite naročnika',
        'rating.feedback': 'Pustite povratne informacije',
        'rating.feedback_long': 'Zapiši oceno za tega uporabnika ...',
        'rating.review_work': 'Pregled oddanega dela',
        'rating.open_dispute': 'Zavrni in pošlji v revizijo',
        'rating.confirm_and_pay': 'Potrdi in plačaj',
        'rating.confirm_and_pay_repeat': 'Potrdi in ponovi',
        'rating.review_audit_title': 'Ste zadovoljni z postopkom revizije?',
        'rating.review_audit': 'Ocenite postopek glede na spodnje kategorije',

        'rating.category.communication': 'Komunikacija',
        'rating.category.quality': 'Kvaliteta dela',
        'rating.category.price': 'Primerna cena',
        'rating.category.recommend': 'Bi priporočil',
        'rating.category.work_again': 'Bi še sodeloval',
        'rating.category.documentation': 'Kvalitetna dokumentacija',
        'rating.category.quality_of_explanation': 'Kakovostna obrazložitev',
        'rating.category.length_of_explanation': 'Primerno podrobna obrazložitev',
        'rating.category.satisfaction_with_verdict': 'Zadovoljstvo z odločitvijo',

        'messages.messages': 'Sporočila',
        'messages.people': 'Stiki',
        'messages.online': 'Na voljo',
        'messages.offline': 'Ni na voljo',
        'messages.you': 'Ti',

        'components.languageSelect': 'Izbor jezika',
        'components.roleSelect': 'Izbor vloge',

        'browse.projects': 'Projekti',
        'browse.tasks': 'Naloge',
        'browse.developers': 'Izvajalci',
        'browse.investors': 'Naročniki',
        'browse.auditors': 'Avditorji',
        'browse.edus': 'Šole',
        'browse.browse_projects': 'Razišči projekte',
        'browse.browse_tasks': 'Razišči naloge',
        'browse.browse_skills': 'Prebrskaj znanja',
        'browse.browse_auditors': 'Razišči avditorje',
        'browse.browse_edus': 'Razišči izobraževalne ustanove',
        'browse.goto_myedu': 'Pojdi na stran Moja Institucija',
        'browse.goto_myaudits': 'Pojdi na stran Moje Revizije',


        'login.error': 'Ups! Napačno uporabniško ime ali geslo. Prosimo poskusite ponovno.',
        'register.error': 'Ups! Registracija ni uspela. Prosimo poskusite ponovno.',
        'forgotpassword.error': 'Ups! Ponastavitev gesla ni uspela. Prosimo poskusite ponovno.',
        'resetpassword.error': 'Ups! Ponastavitev gesla ni uspela. Prosimo poskusite ponovno.',

        'role.change.error': 'Ups! Sprememba profila ni uspela. Prosimo poskusite ponovno.',
        'role.create.error': 'Ups! Kreiranje profila ni uspelo. Prosimo poskusite ponovno.',
        'role.get.error': 'Ups! Pridobivanje profila ni uspelo. Prosimo poskusite ponovno.',
        'role.update.error': 'Ups! Sprememba profila ni uspela. Prosimo poskusite ponovno.',
        'role.review.submit.error': 'Ups! Pošiljanje ocene ni uspelo. Prosimo poskusite ponovno.',
        'role.detail.update.error': 'Ups! Sprememba podatkov profila ni uspela. Prosimo poskusite ponovno.',
        'role.experience.create.error': 'Ups! Kreiranje izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.experience.update.error': 'Ups! Sprememba izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.experience.delete.error': 'Ups! Odstranitev izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.education.create.error': 'Ups! Kreiranje šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.education.update.error': 'Ups! Sprememba šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.education.delete.error': 'Ups! Odstranitev šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.freelacers.get.error': 'Ups! Pridobivanje izvajalcev ni uspela. Prosimo poskusite ponovno.',
        'role.clients.get.error': 'Ups! Pridobivanje naročnikov ni uspela. Prosimo poskusite ponovno.',
        'role.auditors.get.error': 'Ups! Pridobivanje avditorjev ni uspela. Prosimo poskusite ponovno.',
        'role.edus.get.error': 'Ups! Pridobivanje izobraževalnih ustanov ni uspela. Prosimo poskusite ponovno.',
        'role.upload.profile_pic.error': 'Ups! Sprememba profilne slike ni uspela. Prosimo poskusite ponovno.',
        'role.upload.cover_pic.error': 'Ups! Sprememba naslovne slike ni uspela. Prosimo poskusite ponovno.',

        'project.upload.logo_pic.error': 'Ups! Sprememba logotipa ni uspela. Prosimo poskusite ponovno.',
        'project.upload.cover_pic.error': 'Ups! Sprememba naslovne slike ni uspela. Prosimo poskusite ponovno.',

        'landing.slogan': "Kjer se velike ideje rodijo.",
        'landing.forEmployers': "ZA NAROČNIKE",
        'landing.forDevelopers': "ZA IZVAJALCE",

        'footer.about': 'O projektu',
        'footer.how': 'Kako deluje',
        'footer.terms': 'Pogoji uporabe',
        'footer.privacy': 'Pravilnik o zasebnosti',
        'footer.questions': 'Imate vprašanja?',
        'footer.settings': 'Nastavitve računa in zasebnosti',
        'footer.contact': 'Kontaktirajte nas',

        'browse.common.industry': 'Industrija',
        'browse.common.show': 'Pokaži',
        'browse.common.language': 'Jezik',
        'browse.common.country': 'Država',
        'browse.common.rating': 'Ocena',
        'browse.common.activeprojects': 'Aktivni projekti',
        'browse.common.opentasks': 'Odprte naloge',
        'browse.common.view_my_projects': 'Moji Projekti',
        'browse.common.view': 'Poglej',
        "browse.common.viewinmyprojects": 'Moji Projekti',
        'browse.common.search': 'Iskanje',
        'browse.common.sortby': 'Razvrsti po',
        'browse.common.loading': 'Nalaganje',
        'browse.common.createProject': 'Ustvari projekt',
        'browse.common.commingsoon': 'Prihaja kmalu',
        'browse.common.view_my_work': 'Poglej v \'Mojem Delu\'',
        'browse.common.allrecordsloaded': 'Vsi podatki prikazani',
        'browse.common.select-issuer': 'Izberi izdajatelja',

        'browse.order.startingsoon': 'Se začne kmalu',
        'browse.order.highestbudget': 'Najvišji proračun',
        'browse.order.lowestbudget': 'Najnižji proračun',
        'browse.order.byprojects': 'Po projektih',
        'browse.order.byclient': 'Po izvajalcu',
        'browse.order.highestrating': 'Najvišja ocena',
        'browse.order.lowestrating': 'Najnižja ocena',
        'browse.order.mostactivetasks': 'Največ aktivnih nalog',
        'browse.order.mostfinishedtasks': 'Največ dokončanih nalog',
        'browse.order.highesthourlyrate': 'Najvišjna urna postavka',
        'browse.order.lowesthourlyrate': 'Najnižja urna postavka',
        'browse.order.nameasc': 'Nazivu ASC',
        'browse.order.namedesc': 'Nazivu DESC',

        'browse.project.budget': 'Proračun',
        'browse.project.timeframe': 'Časovni okvir',
        'browse.project.open_tasks': 'Naloge',
        'browse.project.participating': 'Pri projektu sodelujejo',
        'browse.project.about': 'O projektu',
        'browse.project.join': 'Pridruži se projektu!',
        'browse.project.explore': 'Razišči naloge',
        'browse.project.viewtask': 'Poglej podrobnosti',
        'browse.project.verify_email_short': 'Preverite svojo e-pošto',
        'browse.project.verify_email': 'Prosimo potrdite vaš e-poštni naslov pred uporabljanjem platforme',
        'browse.project.re-send_email': 'Ponovno pošlji aktivacijsko e-pošto',
        'browse.project.company': 'Podjetje',
        'browse.project.location': 'Lokacija',
        'browse.project.about_client': 'O podjetju',
        'browse.project.project_details': 'Podrobnosti Projekta',
        'browse.category.all': 'Vse kategorije',
        'browse.category.Graphics & Design': 'Grafika in oblikovanje',
        'browse.category.Hardware development': 'Razvoj strojne opreme',
        'browse.category.Marketing': 'Trženje',
        'browse.category.Software development': 'Razvoj programske opreme',

        'browse.task.negotiation': 'Pogajanje je v teku',
        'browse.task.negotiations_completed': 'Pogajanje je končano',
        'browse.task.post_negotiation': 'Nekdo že opravlja trenutno nalogo.',
        'browse.task.private_invite_only': 'Tvoja naloga je zasebna. Povabite izvajalce k tej nalogi.',
        'browse.task.invite_only': 'Naloga je zasebna',
        'browse.task.view_my_work': 'Poglej v Moje delo',
        'browse.task.submit_task_proposal': 'Oddaj ponudbo',
        'browse.task.submit_task_proposal_counter': 'Oddaj kontra ponudbo',
        'browse.task.like_task': 'Vam je naloga všeč?',
        'browse.task.invite_free_long': 'Povabite izvajalce k tej nalogi.',
        'browse.task.invite_free': 'Povabite izvajalce',
        'browse.task.goto_my_projects': 'Pojdite na moje projekte',
        'browse.task.switch_to_participate': 'Zamenjajte vlogo, če želite sodelovati',
        'browse.task.back': 'Nazaj na seznam nalog',
        'browse.task.about': 'O nalogi',
        'browse.task.required_competences': 'Potrebna znanja',
        'browse.task.description': 'Podroben opis',
        'browse.task.milestone': 'Mejniki',
        'browse.task.remuneration': 'Plačilo naloge',
        'browse.task.fixed': 'Fiksno',
        'browse.task.timeframe': 'Časovni okvir',
        'browse.task.details': 'Podrobnosti Naloge',
        'browse.task.proposal_rejected': 'Ponudba zavrnjena',

        'contact.banner': 'Radi bi slišali od vas!',
        'contact.h1': 'Povejte nam več o sebi',
        'contact.contactus': 'Kontaktni obrazec',
        'contact.select_above': 'Izberite eno izmed opcij zgoraj za kontaktni obrazec.',
        'contact.success': 'Hvala! Vaše sporočilo je bilo poslano. Vrnili se vam bomo v najkrajšem možnem času. ',
        'contact.error': 'Ups! Nekaj je šlo narobe. Poskusite znova ali nas kontaktirajte neposredno na office@worknskill.com ',

        'dashboard.welcome': 'Dobrodošel',
        'dashboard.setup': 'Nastavitev profila',
        'dashboard.browse': 'Išči',
        'dashboard.statistics': 'Statistika',
        'dashboard.clients': 'Stranke',
        'dashboard.myactivity': 'Moja aktivnost',
        'dashboard.sidebar': 'Stranska vrstica',

        'competency': 'Znanje',
        'competency.new': 'Nova znanje',
        'competences.this_person_can': 'Ta oseba je sposobna ',
        'competences.id': 'Id znanja',
        'competences.name': 'Naziv znanja',
        'competences.create': 'Ustvari novo Znanje',
        'competences.edit': 'Uredi Znanje',
        'competences.my': 'Moja Znanja',
        'competences.add_five': 'Dodajte vsaj pet znanj.',
        'competences.add': 'Dodajte novo Znanje',
        'competences.add.desc': 'Dodajte svoja znanja in vam bomo lažje predlagali primerne projekte!',
        'competences.clients_search': 'Kaj stranke najbolj iščejo',
        'competences.search': 'Išči med Znanji',
        'competences.least_one': 'Izberite vsaj eno Znanje',
        'competences.parent': 'Nadrejeno znanje',
        'competences.base': 'Temeljno znanje',
        'competences.no_parent': 'Brez nadrejenega znanja',
        'competences.skills': 'Temeljna znanja',
        'competences.related_skills': 'Temeljna znanje',
        'competences.no_related_skills': 'Brez temeljnih znanj',
        'competency.add.step1': 'Ime',
        'competency.add.step2': 'Definicija',
        'competency.add.step3': 'Relacije',
        'competency.save_draft': 'Shrani kot osnutek',

        'competences.add_skill_title': 'Predlagaj temeljno znanje',
        'competences.add_description_title': 'Predlagaj opis znanja',
        'competences.add_parent_title': 'Predlagaj nadrejeno znanje',
        'competences.add_skill': 'Predlagaj temeljno znanje',
        'competences.add_description': 'Predlagaj opis',
        'competences.add_parent': 'Predlagaj nadrejeno znanje',
        'competences.contribute_info': 'Sodelujte pri konsenzu o podrobnostih znanja z prispevanjem ali glasovanjem.',
        'competences.commonly_used': 'Običajno uporabljena v',
        'competences.categories': 'Kategorija (neobvezno)',
        'competences.commonly_used_in': 'Kategorije v katerih se to znanje pogosto upojavlja.',
        'competences.platform_only': 'Samo platforma',
        'competences.parent_description': 'Matično znanje postavlja znanje na vrh registra.',
        'competences.related_description': 'Povezana ali temeljna znanja, ki jih to znanje vsebuje ali pa je iz njih sestavljeno.',
        'competency.sure_about_publishing': 'Ali ste prepričane, da želite obajviti to znanje?',

        'competences.written_by_you': 'Seznam znanj ustvarja skupnost',
        'competences.created_by_you': 'Znanja lahko prispevate tudi vi!',
        'competences.top_definition': 'TOP DEFINICIJA',
        'competences.to_skills': 'Z pridobivanjem znanj si izboljšuješ ugled',

        'competences.suggested': 'Predlogi',
        'competency.save_button': 'Shrani znanje',
        'competency.publish_button': 'Objavi znanje',
        'competency.define_common': 'Določa kje je to znanje najbolj pogosto uporabljeno.',
        'competency.blooms_taxonomy': "Bloomova taksonomija",
        'competency.blooms_taxonomy_description': "Bloomova taksonomija nam pomaga klasificirati izobraževalne izraze. Zaključite stavek, ki se začne z:",
        'competency.blooms_taxonomy_info': "Bloomova taksonomija nam pomaga klasificirati izobraževalne izraze.",
        'competency.description': "Opis znanja",
        'competency.quick_add': 'Hitro dodajanje',
        'competency.warning_limited_relations': 'Izdajatelj ima omejeno število povezanih znanj, ki jih je mogoče dodati. Prosimo, da jih nekaj odstranite.',
        'competency.the_competency': 'Znanje ',
        'competency.related_to': 'je povezana ',
        'competency.not_necessary': 'zato je ni nujno dodajati.',
        'competency.warning_complete_sentence': 'Prosimo zaključite stavek (vnesite definicijo).',
        'competency.can_change_level': 'Lahko spremenite trenutno raven ali zagotovite lastno definicijo obstoječega znanja ',
        'competency.warning.select_level': 'Prosimo izberite stopnjo in zaključite stavek.',
        'competency.warning.required_field': 'To je obvezno polje',
        'competency.warning.name_level_exists': 'Znanje s tem imenom in stopnjo že obstaja!',
        'competency.quick_add_header': 'Hitro dodajanje znanja',
        'competency_warning_exists': 'Že obstaja',
        'competency_warning_cannot_edit': 'Objavljenemu znanju ni mogoče spreminjati imena in stopnje.',
        'competences.nolisted': 'Ta profil zaenkrat nima objavljenih znanj.',
        'competences.earned': 'Prislužene',
        'competences.user_defined': 'Definirane',
        'competences.no_defined': 'Uporabnik nima definiranih znanj',
        'competences.no_earned': 'Uporabnik nima prisluženih znanj',

        'competences.weights': 'Uteži',
        'competences.weights_singular': 'Utež',
        'competences.weights_for': 'Uteži za',
        'competences.weights_task_provided': 'Naloge, ki so zagotovile te uteži',
        'competences.weights.title': 'Uredi uteži znanj',
        'competences.weights.distribution': 'Porazdelitev uteži znanj',
        'competences.weights.remaining_points': 'Preostale uteži',
        'competences.weights.no_left': 'Na voljo nimate več uteži',

        'blooms.whats_this': 'Kaj je to?',
        'blooms.L1': 'POMNENJE (zapomniti, definirati, duplicirati, navesti, ponoviti...) je 1. Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L2': 'RAZUMEVANJE (klasificirati, razpravljati, pojasnjevati, prepoznati...) je 2. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L3': 'APLICIRANJE (implementirari, izvesti, uporabljati, demonstrirati...) je 3. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L4': 'ANALIZA (razločevati, povezovati, razlikovati, testirati...) je 4. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L5': 'EVALVACIJA (oceniti, debatirati, zagovarjati, presojati kritizirati...) je 5. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L6': 'KREACIJA (zgraditi, sestaviti, oblikovati, razviti, formulirati, raziskati...) je 6. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',

        'blooms.filter_L1': 'L1 - POMNENJE',
        'blooms.filter_L2': 'L2 - RAZUMEVANJE',
        'blooms.filter_L3': 'L3 - APLICIRANJE',
        'blooms.filter_L4': 'L4 - ANALIZA',
        'blooms.filter_L5': 'L5 - EVALVACIJA',
        'blooms.filter_L6': 'L6 - KREACIJA',

        'projects.heading': 'Poišči si najboljše projekte in naloge',
        'projects.industry': 'Industrija',
        'projects.employment': 'Zaposlitev',
        'projects.employmentduration': 'Čas zaposlitve',
        'projects.tasks': 'Naloge',
        'projects.competences': 'Znanja',
        'projects.duration': 'Trajanje',
        'projects.logintoview': 'Prijavi se za pogled',
        'projects.image': 'Slika projekta',
        'projects.image_desc': 'Naredite svoj projekt bolj privlačen, tako da dodate naslovno sliko in sliko / logotip projekta.',
        'projects.change.image': 'Spremeni sliko projekta',
        'projects.change.cover': 'Spremeni naslovno sliko projekta',
        'projects.estimated_budget': 'Predviden proračun',
        'projects.estimated_start': 'Predvideni datum začetka',
        'projects.estimated_end': 'Predvideni datum konca',

        'education.add': 'Dodaj izobraževanje',
        'education.add.desc': 'Z dodanim izobraževanjem naredite svoj profil bolj verodostojen.',
        'education.school': 'Šola/Univerza',
        'education.degree': 'Stopnja/Program',

        'experience.add': 'Dodaj izkušnjo',
        'experience.add.desc': 'Z dodajanjem izkušenj naredite svoj profil bolj verodostojen.',
        'experience.edit': 'Uredi Izkušnjo',
        'experience.company': 'Podjetje/Projekt',
        'experience.position': 'Položaj/Vloga',

        'freelancers.heading': 'Preverjeni izvajalci za vaš naslednji projekt',
        'freelancers.availability': 'Razpoložljivost',
        'freelancers.competences': 'Znanja',
        'freelancers.hourlyrate': 'Urna postavka',
        'freelancers.completedprojects': 'Končani projekti',
        'freelancers.language': 'Jezik',
        'freelancers.country': 'Država',
        'freelancers.studiedat': 'Študiral na',
        'freelancers.hour': 'uro',
        'freelancers.completedTasks': 'Končane naloge',

        'clients.heading': 'Priznani naročniki in njihovi projekti',

        'educationalinstitutions.heading': 'Izobraževalne ustanove potrjujejo študentom znanja',

        'auditors.heading': 'Najbolj kredibilni avditorji na enem mestu',
        'auditors.dispute': 'Spor',
        'auditors.dispute_cases': 'Moji Primeri',
        'auditors.accept_case': 'SPREJMI PRIMER',
        'auditors.reject_case': 'Zavrni primer',
        'auditors.submit_final_decision': 'POŠLJI KONČNO ODLOČITEV',

        'auditors.client_filed_dispute': ' Sporazumna razrešitev spora ni uspela',
        'auditors.you_filed_dispute': '  Sporazumna razrešitev spora ni uspela ',
        'auditors.dispute_escalated': ' Primer je bil predan v revizijski postopek.',
        'auditors.auditor_accepted': ' Revizor je primer sprejel. Rok je 7 delovnih dni.',
        'auditors.auditor_accepted_your': ' Revizor je vaš primer sprejel. Rok je 7 delovnih dni.',
        'auditors.auditor_reviewed': ' Revizor je primer pregledal. Podrobnosti:',
        'auditors.auditor_reviewed_your': ' Revizor je vaš primer pregledal. Podrobnosti:',
        'auditors.auditor_decided_1': 'Odločeno je, da je naloga končana ',
        'auditors.auditor_decided_2': 'in plačilo v znesku ',
        'auditors.auditor_decided_3': 'je bilo dodeljeno izvajalcu.',
        'auditors.audit_compensation': 'Plačilo za revizijo',
        'auditors.audit_deadline': 'Rok za revizijo',
        'auditors.audit_deadline_days': '7 delovnih dni',
        'auditors.audit_rules': 'Pravila revizijskega postopka ...',
        'auditors.audit_client_reason': 'Razlog pritožbe stranke:',
        'auditors.dispute_details': 'O sporu',
        'auditors.audit_data': 'Podatki za revizijo',
        'auditors.decided_1': 'Naloga je opravljena',
        'auditors.decided_2': 'in',
        'auditors.decided_3': 'bo izplačilo.',
        'auditors.slider_info': '*S premikanjem drsnika določite delež pravilno opravljene naloge in višino nadomestila, ki ga freelancer prejme od prvotno dogovorjenega zneska. ',
        'auditors.dispute_resolution': 'Odločitev revizije:',
        'auditors.auditor_comment': 'Auditorjev komentar:',
        'auditors.keypoint': 'Ključna točka',
        'auditors.comment': 'Komentar',
        'auditor.dispute_case_details': 'Podatki o primeru spora',
        'auditor.agree_to_continue': 'Potrdite za nadaljevanje',
        'auditor.file_dispute': 'Sproži spor',
        'auditor.file_dispute_proposal': 'Vloži predlog za rešitev spora',

        'modalEditProfileImage.changeyourprofileimage': 'Spremeni svojo profilno sliko',
        'modalEditProfileImage.dragndrop': 'Klikni ali povleci sliko sem',

        'modalEditFreelancerRates.heading': 'Uredi Razpoložljivost in Preference',
        'modalEditFreelancerRates.availability': 'Razpoložljivost',
        'modalEditFreelancerRates.acceptingwork': 'Sprejemam delo',
        'modalEditFreelancerRates.notacceptingwork': 'Ne sprejemam dela',
        'modalEditFreelancerRates.hourlyrate': 'Urna postavka (USD/ura)',

        'modalEditFreelancerEducation.heading': 'Uredi Izobrazbo',
        'modalEditFreelancerEducation.studiedat': 'Študiral na',

        'modalEditProfileInfo.heading': 'Uredi podatke o profilu',
        'modalEditProfileInfo.displayname': 'Prikazno ime',
        'modalEditProfileInfo.companyname': 'Ime organizacije',
        'modalEditProfileInfo.vat': 'DDV',
        'modalEditProfileInfo.firstname': 'Ime',
        'modalEditProfileInfo.lastname': 'Priimek',
        'modalEditProfileInfo.description': 'Opis',
        'modalEditProfileInfo.address': 'Naslov',
        'modalEditProfileInfo.zip': 'Poštna št.',
        'modalEditProfileInfo.city': 'Mesto',
        'modalEditProfileInfo.state': 'Regija',
        'modalEditProfileInfo.country': 'Država',

        'profile.hourlyrate': 'Urna postavka',
        'profile.hour': 'ura',
        'profile.availability': 'Razpoložljivost',
        'profile.completedtasks': 'dokončane naloge',
        'profile.completedtasksupper': 'Dokončane naloge',
        'profile.participatedinprojects': 'sodeloval pri projektih',
        'profile.clients': 'strank',
        'profile.inthepastweek': 'V preteklem tednu',
        'profile.clientswholookedfor': 'Strani ki so iskale',
        'profile.recommendations': 'priporočil',
        'profile.reviews': 'ocen',
        'profile.online': 'Aktiven',
        'profile.nodescription': 'Ni opisa',
        'profile.education': 'Izobrazba',
        'profile.experience': 'Izkušnje',
        'profile.competences': 'Znanja',
        'profile.save_to_blockchain': 'Shrani na Blockchain',
        'profile.reviewsupper': 'Ocene',
        'profile.participatedinprojectsupper': 'Sodeloval pri projektih',
        'profile.participatedintasks': 'Sodeloval pri nalogah',
        'profile.nocity': 'Ni mesta',
        'profile.nocountry': 'Ni države',
        'profile.acceptingwork': 'Sprejema delo',
        'profile.personal': 'Osebni podatki',
        'profile.personal.desc': 'Če izpolnite svoje osebne podatke, postanete bolj verodostojni.',
        'profile.description.add': 'Dodaj opis',
        'profile.description.desc': 'Če dodate kratek opis, povečate možnosti, da se zaposlite.',
        'profile.public': 'Javne informacije',
        'profile.public.desc': 'Javno vidne podrobnosti profila',
        'profile.change.cover': 'Spremeni sliko',
        'profile.change.profile': 'Spremeni sliko profila',
        'profile.change.role': 'Hitro preklapljanje med mojimi profili',

        'DropdownUserMenu.myprofile': 'Moj profil',
        'DropdownUserMenu.addanotherprofile': 'Dodaj novo vlogo',
        'DropdownUserMenu.settings': 'Nastavitve',
        'DropdownUserMenu.balances': 'Denarnice',
        'DropdownUserMenu.ndas': "NDA's",
        'DropdownUserMenu.contracts': 'Pogodbe',
        'DropdownUserMenu.help': 'Pomoč',
        'DropdownUserMenu.resolution_center': "Reševanje sporov",
        'DropdownUserMenu.gamification': 'Gamifikacija',
        'DropdownUserMenu.myWallet': "Moja denarnica",

        'settings.account': 'Uporabniški račun',
        'settings.account_settings': 'Nastavitve uporabniškega računa',
        'settings.payment': 'Blockchain',
        'settings.notification': 'Obvestila',
        'settings.push_notification': 'Potisna obvestila',
        'settings.notification_pref': 'Nastavitve obveščanja',
        'settings.general': 'Splošno',
        'settings.messaging': 'Sporočila',
        'settings.task_related': 'Nalogam odvisna spročila',
        'settings.dispute_related': 'Obvestila o sporih',
        'settings.security': 'Varnost',
        'settings.current_role': 'Vaša trenutna aktivna vloga',

        'resolutionCenter.finalSubmit': 'Strinjam se s pogoji storitve za reševanje sporov.',
        'resolutionCenter.noTaskSelected': 'Nobena naloga ni izbrana. Pojdi na',
        'resolutionCenter.keyPoints': 'Problematične ključne točke (dodajte svoje komentarje)',
        'resolutionCenter.proposePercentage': 'Predlagam, da spor rešimo s plačilom naslednjega odstotka (%) od dogovorjenega zneska',
        'resolutionCenter.costOfDispute': 'Stroški revizije spora',
        'resolutionCenter.agreedTerms': 'Dogovorjeni pogoji',
        'resolutionCenter.more_in_the': 'Več informacij v ',
        'resolutionCenter.faq_section': ' sekciji Pogosta vprašanja...',

        'dispute.proposed': 'je predlagal plačilo ',
        'dispute.of_price': ' od dogovorjene cene.',
        'dispute.i_propose': 'Predlagam plačilo  ',
        'dispute.of_price_2': ' % od dogovorjene cene.',
        'dispute.send_counter': 'POŠLJI PREDLOG',
        'dispute.counter': 'NOV PREDLOG',
        'dispute.accept': 'SPREJMI',
        'dispute.send_to-audit': 'POŠLJI V REVIZIJO',
        'dispute.was_escalated': 'Spor je bil predan v revizijo avditorju.',
        'dispute.expired': 'Čas za sporazumno razrešitev je potekel. Primer je bil avtomatično poslan v revizijo avditorju.',
        'dispute.resolved': 'Spor je bil uspešno rešen sporazumno. Naloga je uspešno zaključena.',
        'dispute.keypoints_with_comments': 'Oporne točne (z komentarji):',
        'dispute.original_comment': 'Prvotni komentar naročnika:',
        'dispute.replies': 'Odgovori:',
        'dispute.marked_resolved': 'Naročnik je označil točko kot rešeno.',
        'dispute.reply': 'Odgovori',
        'dispute.mark_as_resolved': 'OZNAČI KOT REŠENO',

        /*  My Wallet */
        'wallet.title': 'Moja denarnica',
        // 'wallet.dai_eth_balance': 'Skupno stanje DAI in ETH',
        'wallet.dai_eth_balance': 'Skupno stanje DAI in BNB',
        'wallet.deposit': 'Položi',
        'wallet.withdraw': 'Dvigni',
        'wallet.min_deposit': '*Min polog je',
        'wallet.recommended_balance': '*Min priporočeno stanje je',
        'wallet.show_usd': 'Pokaži v USD',
        'wallet.show_eur': 'Pokaži v EUR',
        'wallet.total_balance': 'Skupno stanje: ',
        'wallet.available_balance': 'Razpoložljivo stanje: ',
        'wallet.available_amount': 'Razpoložljivi znesek: ',
        'wallet.transaction_fee': 'Provizija: ',
        'wallet.will_withdraw': 'Dvignili boste: ',
        'wallet.back': 'Nazaj na denarnico',
        'wallet.funds_error': "Nimate dovolj sredstev.",
        'wallet.deposit-receive': 'Položi/Prejmi',
        'wallet.withdraw-send': 'Dvigni/Pošlji',
        'wallet.confirm-transaction': 'Potrdi',
        'wallet.modal-text': 'Ali ste prepričani?',
        'wallet.warning.required_field': 'To je obvezno polje',
        'wallet.transactions': 'Transakcije ',
        'wallet.chips': 'žetoni',
        'wallet.export': 'Izvozi',
        'wallet.required_dates': 'Datumi so obvezni',
        'wallet.no_transactions': 'Na voljo ni transakcij za izvoz',
        'wallet.show_in': 'Pokaži v',
        'wallet.invalid_address': 'Napačni naslov',
        'wallet.tip_address': 'Da ne pride do izgub pri dvigih, se mora mreža ujemati z vneseno naslovno mrežo Binance smart chain (BEP20).',
        'wallet.tip_amount': 'Pri vsakem dvigu uporabniki plačajo enotno pristojbino, ki krije stroške transakcije kriptovalute. Pristojbine za dvige so določene z mrežo verige blokov in lahko nenapovedano nihajo, in sicer zaradi dejavnikov, kot je preobremenjenost mreže',

        // CHIPS
        'chips.estimated_fee': 'Ocenjena pristojbina: ',
        'chips.not_enough_dai': 'nimate dovolj DAI',
        'chips.chips': 'žetonov',
        'chips.your_balance': 'Vaše stanje: ',

        //Competencies mint
        'competencies_mint.mint_available_skills': 'Zmintaj rapoložljiva znanja',
        'competencies_mint.skills_supported': "Znanja, ki jih preverjajo lastniki projektov na podlagi izvajalčevih izkušenj. Za več informacij ",
        'competencies_mint_available_balance': 'Razpoložljivo stanje',
        'competencies_mint.no_skills' : 'Na voljo nimate nobenih znanj za mintanje',
        'competencies_mint.skill_name': 'Znanje',
        'competencies_mint.estimated_cost': 'Predviden strošek',
        'competencies_mint.total_estimated_cost': 'Skupni predviden strošek',
        'competencies_mint.total_estimated_cost_explanation': 'Skupni predviden strošek je vsota vseh predvidenih stroškoh znanj',
        'competencies_mint.current_balance': 'Trenutno stanje',
        'competencies_mint.after_mint': 'Po mintanju',
        'competencies_mint.mint_your_skills': 'Mintaj svoja znanja',
        'competencies_mint.insuficient_funds_bnb': 'Na žalost vaše stanje na denarnici nezadostno, da bi nadaljevali z mintanjem svojih znanj. Če želite nadaljevati, lahko kupite ali položite BNB v svojo denarnico.',
        'competencies_mint.insuficient_funds_eth': 'Na žalost vaše stanje na denarnici nezadostno, da bi nadaljevali z mintanjem svojih znanj. Če želite nadaljevati, lahko kupite ali položite ETH v svojo denarnico.',
        'competencies_mint.confirm': 'Potrdi mintanje znanj',

        //TABLE
        'wallet_table.pending': 'V teku',
        'wallet_table.processing': 'Obravnavanje',
        'wallet_table.failed': 'Ni uspelo',
        'wallet_table.completed': 'Zaključeno',
        'wallet_table.unknown': 'Neznano',
        'wallet_table.sent': 'Poslano',
        'wallet_table.deployed': 'Postavitev pametne pogobde',
        'wallet_table.minted': 'Potrjeno znanje',
        'wallet_table.received': 'Prejeto',
        'wallet_table.date': 'Datum',
        'wallet_table.type': 'Tip',
        'wallet_table.symbol': 'Simbol',
        'wallet_table.amount': 'Znesek',
        'wallet_table.from_to': 'Od/k',
        'wallet_table.status': 'Status',
        'wallet_table.from': 'Od',
        'wallet_table.to': 'Do',
        'wallet.buy': 'Kupi',
        'wallet.subscribe': 'Naročnina',
        'wallet_table.escrow-transfer-freelancer': 'Plačilo naloge',
        'wallet_table.escrow-transfer-auditor': 'Plačilo revizije',
        'wallet_table.escrow-refund': 'Escrow vračilo',
        'wallet_table.invoice': 'Račun',
        'wallet.view-in-explorer': 'Poglej na raziskovalcu',

        // DEPOSIT
        'deposit.title': 'Kaj želite položiti?',
        'deposit.tips': 'Nasveti:',
        'deposit.qr_title': 'Skenirajte QR kodo ali pa kopirajte naslov denarnice, da izvedete polog.',
        'deposit.wallet_address': 'Naslov denarnice: ',
        'deposit.copy_to_clipboard': 'Kopiraj v izhodišče',
        'deposit.copied_to_clipboard': 'Kopirano v izhodišče',
        'deposit.scan_code': 'Optično preberite kodo na strani dvigov trgovalske platforme ali denarnice.',
        'deposit.send_bnb': 'Na ta naslov za polog pošljite le BNB.',
        'deposit.send_dai': 'Na ta naslov za polog pošljite le DAI.',
        'deposit_ensure_binance': 'Prepričajte se, da je mreža res Binance Chain (BEP2) ali Binance smart Chain (BEP20).',

        //WITHDRAW
        'withdraw.title': 'Kaj želite dvigniti?',
        'withdraw.recepient_label': "Naslov prejemnika",
        'withdraw.amount': 'Znesek',
        'withdraw.token': 'Kovanec:',
        'withdraw.date_time': 'Datum/Čas:',
        'withdraw.network_fee': 'Mrežna pristojbina:',
        'withdraw.total_amount': 'Dvignili boste:',

        // Notifications
        'notification.notifications': 'Obvestila',
        'notification.markAllAsRead': 'Označi vse kot prebrano',
        'notification.nothingNew': 'Nič novega',
        'notification.upToDate': 'Z vsem si na tekočem!',
        'notification.seeAll': 'Poglej vse',

        // Notifications...FOR CLIENT & FREELANCER
        'notification.OnReviewRequestSubmitted.title': 'Nova ocena',
        'notification.OnReviewRequestSubmitted.text': 'Dobili ste novo mnenje in oceno od ',

        // Notifications...FOR CLIENT
        'notification.OnFreelancerTaskProposalProposed.title': 'Nova ponudba',
        'notification.OnFreelancerTaskProposalProposed.text': ' ti je poslal/-a ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalCountered.title': 'Odgovor na ponudbo',
        'notification.OnFreelancerTaskProposalCountered.text': ' ti je poslal/-a nove pogoje ponudbe.',
        'notification.OnTaskProposalAutoRejected.title': 'Ponudba zavrnjena',
        'notification.OnTaskProposalAutoRejected.text': 'Tvoja ponudba je bila zavrnjena.',
        'notification.OnFreelancerTaskProposalRejected.title': 'Ponudba zavrnjena',
        'notification.OnFreelancerTaskProposalRejected.text': ' je zavrnil/-a tvojo ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalRejectedLast.title': 'Ponudba zavrnjena',
        'notification.OnFreelancerTaskProposalRejectedLast.text': ' je zavrnil/-a tvojo zadnjo ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalAccepted.title': 'Ponudba sprejeta',
        'notification.OnFreelancerTaskProposalAccepted.text': ' je sprejel/-a tvojo ponudbo za nalogo.',
        'notification.OnFreelancerTaskRejected.title': 'Naloga preklicana',
        'notification.OnFreelancerTaskRejected.text': ' je prekinil delo na nalogi.',
        'notification.OnFreelancerTaskSubmitted.title': 'Naloga opravljena',
        'notification.OnFreelancerTaskSubmitted.text': ' je poslal/-a rezultate dela na nalogi.',
        'notification.OnProjectMayCompleted.title': 'Projekt zaključen',
        'notification.OnProjectMayCompleted.text': 'Vse naloge projekta so zaključene.',
        'notification.OnTaskRecurringCreated.title': 'Ponavljajoče se naloga ustvarjena',
        'notification.OnTaskRecurringCreated.text': ' je bila ustvarjena.',
        'notification.OnTaskAuditorFailedForClient.title': 'Revizija zaključena',
        'notification.OnTaskAuditorFailedForClient.text': 'Avditor je razsodil o vašem primeru.',
        'notification.OnTaskAuditorConfirmedForClient.title': 'Revizija zaključena',
        'notification.OnTaskAuditorConfirmedForClient.text': 'Avditor je razsodil o vašem primeru.',
        'notification.OnTaskInAuditForClient.title': 'Revizija v teku',
        'notification.OnTaskInAuditForClient.text': 'Avditor je sprejel vaš primer v razsojo.',
        'notification.OnFreelancerTaskResultSubmitted.title': 'Novi rezultati',
        'notification.OnFreelancerTaskResultSubmitted.text': ' vam je poslal nove rezultate naloge.',
        'notification.OnTaskPublishedStartTimeWarning.title': 'Pričetek naloge se bliža',
        'notification.OnTaskPublishedStartTimeWarning.text': 'Status naloge se bo spremenil v osnutek.',

        // Notifications...FOR FREELANCER
        'notification.OnClientTaskProposalProposed.title': 'Povabilo na nalogo',
        'notification.OnClientTaskProposalProposed.text': ' te je povabil/-a na sodelovanje pri nalogi.',
        'notification.OnClientTaskProposalCountered.title': 'Nova ponudba',
        'notification.OnClientTaskProposalCountered.text': ' ti je poslal/-a novo ponudbo za nalogo.',
        'notification.OnClientTaskProposalRejected.title': 'Ponudba zavrnjena',
        'notification.OnClientTaskProposalRejected.text': ' je zavrnil/-a tvojo ponudbo za nalogo.',
        'notification.OnClientTaskProposalSentFinal.title': 'Končna ponudba',
        'notification.OnClientTaskProposalSentFinal.text': ' ti je poslal/-a končno ponudbo za nalogo.',
        'notification.OnClientTaskRejected.title': 'Naloga preklicana',
        'notification.OnClientTaskRejected.text': ' je preklical izvajanje naloge.',
        'notification.OnClientTaskCompleted.title': 'Naloga zaključena',
        'notification.OnClientTaskCompleted.text': ' je potrdil/-a, plačal/-a in zaključil/-a nalogo.',
        'notification.OnTaskInProgress.title': 'Naloga aktivna',
        'notification.OnTaskInProgress.text': ' ima rok za dostavo ',
        'notification.OnClientTaskPendingAudit.title': 'Spor o nalogi je bil predan avditorju.',
        'notification.OnClientTaskPendingAudit.text': 'Naloga: ',
        'notification.OnClientTaskInAudit.title': 'Revizija v teku',
        'notification.OnClientTaskInAudit.text': 'Avditor je sprejel primer v revizijo.',
        'notification.OnTaskAuditorConfirmedForFreelancer.title': 'Revizija zaključena',
        'notification.OnTaskAuditorConfirmedForFreelancer.text': 'Avditor je razsodil o primeru.',
        'notification.OnTaskAuditorFailedForFreelancer.title': 'Revizija zaključena',
        'notification.OnTaskAuditorFailedForFreelancer.text': 'Avditor je razsodil o primeru.',
        'notification.OnTaskInAuditForFreelancer.title': 'Revizija v teku',
        'notification.OnTaskInAuditForFreelancer.text': 'Avditor je sprejel primer v revizijo.',
        // Dispute settlement
        'notification.OnClientTaskInDispute.title': 'Spor pri nalogi',
        'notification.OnClientTaskInDispute.text': 'Naročnik je odprl spor za nalogo z predlogom za razrešitev.',
        'notification.OnClientTaskDisputeSetInNegotiation.title': 'Novosti o sporu',
        'notification.OnClientTaskDisputeSetInNegotiation.text': 'Naročnik je posodobil podatke v sporu o nalogi.',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.title': 'Novosti o sporu',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.text': 'Izvajalec je posodobil podatke v sporu o nalogi.',
        'notification.OnFreelancerTaskDisputeSettlementComment.title': 'Novosti o sporu',
        'notification.OnFreelancerTaskDisputeSettlementComment.text': 'Izvajalec je odgovoril na problematično točko spora.',
        'notification.OnClientTaskDisputeSettlementComment.title': 'Novosti o sporu',
        'notification.OnClientTaskDisputeSettlementComment.text': 'Naročnik je odgovoril na problematično točko spora.',

        // Notifications...FOR AUDITOR
        'notification.OnNewTaskDisputeCase.title': 'Povabilo za revizijo',
        'notification.OnNewTaskDisputeCase.text': 'Povabljeni ste bili za presojo v sporu na nalogi.',
        'notification.OnTaskDisputeCaseAutoRejected.title': 'Povabilo poteklo',
        'notification.OnTaskDisputeCaseAutoRejected.text': 'Rok za sodelovanje v presoji o sporu je potekel.',
        'notification.OnTaskDisputeCaseFailed.title': 'Rok za revizijo je potekel',
        'notification.OnTaskDisputeCaseFailed.text': "Odločitve niste sprejeli v zahtevanem času.",

        // Notifications...ESCROW RESOLVE
        'notification.OnEscrowResolveTaskArrangementAuditor.title': 'Plačani ste bili za revizijo!',
        'notification.OnEscrowResolveTaskArrangementFreelancer.title': 'Plačani ste bili za delo!',
        'notification.OnEscrowResolveTaskArrangementClientRefund.title': 'Dobili ste povračilo!',
        'notification.OnEscrowTaskArrangementInProgress.title': 'Naloga je bila uspešno financirana!',
        'notification.OnEscrowTaskArrangementFail.title': 'Ureditev naloge ni uspela!',
        'notification.OnEscrowResolveTaskArrangementAuditor.text': 'Preverite svoje novo stanje v moji denarnici',
        'notification.OnEscrowResolveTaskArrangementFreelancer.text': 'Preverite svoje novo stanje v moji denarnici',
        'notification.OnEscrowResolveTaskArrangementClientRefund.text': 'Preverite svoje novo stanje v moji denarnici',
        'notification.OnEscrowTaskArrangementInProgress.text.freelancer': 'Lahko pričnete z delom na nalogi: ',
        'notification.OnEscrowTaskArrangementInProgress.text.client': 'Delo se lahko prične na nalogi: ',
        'notification.OnEscrowTaskArrangementFail.text': 'Ime naloge: ',

        // Notificaitons...FUND
        'notification.OnTaskFundingInProgressClient.title': 'Financiranje v obravnjavanju',
        'notification.OnTaskFundingInProgressClient.text': 'Ime naloge: ',
        'notification.OnTaskFundingInProgressFreelancer.title': 'Financiranje v obravnavanju',
        'notification.OnTaskFundingInProgressFreelancer.text': 'Ime naloge: ',
        'notification.OnTaskPendingFundingClient.title': 'Financiranje v teku',
        'notification.OnTaskPendingFundingClient.text': 'Ime naloge: ',
        'notification.OnTaskPendingFundingFreelancer.title': 'Financiranje v teku',
        'notification.OnTaskPendingFundingFreelancer.text': 'Ime naloge: ',

        // EDU
        'notification.OnNewRoleEducationRequest.title': 'Prošnja za verifikacijo',
        'notification.OnNewRoleEducationRequest.text': ' vam je poslal/-la prošnjo za potrditev izobrazbe.',
        'notification.OnRoleEducationRequestApproved.title': 'Prošnja za verifikacijo',
        'notification.OnRoleEducationRequestApproved.text': ' vam je potrdil/-a izobrazbo.',
        'notification.OnRoleEducationRequestRejected.title': 'Prošnja za verifikacijo',
        'notification.OnRoleEducationRequestRejected.text': ' vam je zavrnil/-a prošnjo za verifikacijo izobrazbe.',

        // ISSUERS - SI
        'issuers.issuer': 'Izdajatelj',
        'issuers.settings': 'Nastavitve izdajatelja',
        'issuers.add_new': 'Dodajanje novega izdajatelja',
        'issuers.issuer_name': 'Oznaka izdajatelja (uporaba v imenu izdanih znanj)',
        'issuers.mint_type': 'Uporaba znanj za naloge',
        'issuers.MINT_DISABLED': 'Onemogočeno',
        'issuers.MINT_ON_SYS_ISSUER': 'Uporabi sistemsko digitalno potrdilom',
        'issuers.MINT_ON_SAME_ISSUER': 'Uporabi moje digitalno potrdilo',
        'issuers.allow_public_insert': 'Dovoljeno dodajanje znanj vsem',
        'issuers.allow_voting': 'Omogoči sistem glasovanja za znanja',
        'issuers.is_external': 'Zunanji izdajatelj',
        'issuers.get_my_issuers_success': 'Seznam mojih izdajateljev je bil uspešno pridobljen.',
        'issuers.get_my_issuers_error': 'Napaka pri pridobivanju mojih izdajateljev. ',
        'issuers.delete_issuer_success': 'Izdajatelj pobrisan uspešno.',
        'issuers.delete_issuer_fail': 'Napaka pri brisanju izbranega izdajatelja. ',
        'issuers.get_available_issuers_success': 'Uspešno prejeti razpoložljivi izdajatelji.',
        'issuers.get_available_issuers_fail': 'Ni bilo mogoče sprejeti razpoložljivih izdajateljev. ',
        'ssuers.get_public_issuers_success': 'Uspešno sprejeti javni izdajatelji. ',
        'ssuers.get_public_issuers_fail': 'Javnih izdajateljev ni bilo mogoče sprejeti. ',
        'issuers.create_iusser_success': 'Uspešno ustvarjen nov izdajatelj.',
        'issuers.create_iusser_fail': 'Ni uspelo ustvariti novega izdajatelja. ',
        'issuers.get_iusser_details_success': 'Podrobnosti o vlogi so bila uspešno prejeta. ',
        'issuers.get_iusser_details_fail': 'Podrobnosti o vlogi ni bilo mogoče dobiti. ',
        'issuers.update_issuer_details_success': 'Podrobnosti izdajatelja so bile uspešno posodobljene. ',
        'issuers.update_issuer_details_fail': 'Podrobnosti izdajatelja ni bilo mogoče posodobiti. ',
        'issuers.issuer_details': 'Podrobnosti izdajatelja',
        'issuers.manage_issuer': 'Urejanje nastavitev vašega izdajatelja ter procesa kreiranja znanj.',
        'issuers.edit': 'Urejanje izdajatelja',
        'issuers.issued_by_ecta': 'Izdajatelj WNS',
        'issuers.issued_by': 'Izdajatelj ',
        'issuers.disabled_voting': 'Izdajatelj je onemogočil javno glasovanje za to znanje.',
        'issuers.issuer_missing': 'Manjka izdajatelj',
        'issuers.issuer_missing_text': 'Prosimo, da najprej ustvarite izdajatelja, s katerim boste potem lahko kreirali znanja in jih dodajali na vaše izobraževalne programe.',

        /* TAB TAGS */
        'help.general': 'Splošno',
        'help.company': 'Podjetje',
        'help.freelancer': 'Izvajalec',
        'help.edu': 'Izobraževalna ustanova',
        'help.auditor': 'Revizor',
        'help.competencies': 'Znanja',
        'help.blockchain': 'Veriženje podatkov',

        // GENERAL faq
        'help_general.acc_types': 'Kakšne tipe računov ponujate?',
        'help_general.subscription_tiers': 'Kaj so stopnje naročnin?',
        'help_general.roles': 'Kako ustvarim in upravljam z vlogami na mojem računu?',
        'help_general.lost_password': 'Izgubil/la sem geslo. Kaj lahko naredim?',
        'help_general.login': 'Kako se prijavim/odjavim iz mojega računa?',
        'help_general.notification_preference': 'Kje lahko spremenim nastavitve obvestil?',
        'help_general.acc_close': 'Kako lahko zaprem račun?',

        //GENERAL answers
        'help_general.ans_acc_types': 'Naročnik, izvajalec, izobraževalna ustanova in revizor.',
        'help_general.ans_roles': 'Če klikneš na moj profil imaš možnost izbire "dodaj nov račun". Po tem, ko se račun ustvari lahko menjavaš med profili.',
        'help_general.ans_lost_password': 'Če ste izgubili geslo, kliknite povezavo pozabi geslo na zaslonu za prijavo. Nato vnesite svoj registrirani e-poštni naslov in ponastavite geslo. Zdaj se boste lahko prijavili v svoj račun Work \'n Skill z novim geslom.',
        'help_general.ans_login': 'Za prijavo izberite prijavo v zgornji vrstici za krmarjenje. Za odjavo izberite svoj profil v zgornji vrstici za krmarjenje in nato izberite odjavo.',
        'help_general.ans_notification_preference': 'V zgornji vrstici za krmarjenje je zvonec, nastavitve obvestil pa lahko prilagodite v nastavitvah.',
        'help_general.ans_acc_close': "Kliknite svoj profil in nato izberite nastavitve, nato pa lahko to vlogo izbrišete (vloge lahko izbrišete samo, če nimate aktivnih nalog!).",
        'help_general.ans_subscription_tiers': '...',

        // FREELANCERS faq
        'help_freelancers.profile_setup': 'Kako nastavim svoj profil?',
        'help_freelancers.project_apply': 'Kako se prijavim na projekt?',
        'help_freelancers.task_accept': 'Kako sprejmem nalogo?',
        'help_freelancers.work_status': 'Kje lahko vidim status mojega dela?',
        'help_freelancers.deadline_extend': 'Ali lahko podaljšam rok naloge?',
        'help_freelancers.negotiation': 'Kako se lahko pogajam?',

        //FREELANCERS answers
        'help_freelancers.ans_profile_setup': "Če še niste nastavili svojega profila, boste dobili obvestilo za nastavitev. Lahko pa kliknete tudi na moj profil.",
        'help_freelancers.ans_project_apply': 'Ko nastavite svoj profil, lahko kliknete »brskanje po projektih«. Nato izberete zanimiv projekt. In nato izberite nalogo. Zdaj lahko oddate predlog naloge. In preden oddate, jim lahko ponudite svojo ponudbo, izberete časovni okvir in potrebna znanja.',
        'help_freelancers.ans_task_accept': 'Izberite moje delo v zgornji vrstici za krmarjenje, nato pa boste v nalogi videli, ali imate v pogajanjih kaj novega, potem lahko nalogo izberete in jo sprejmete.',
        'help_freelancers.ans_work_status': 'V zgornji vrstici za krmarjenje izberite moje delo, nato pa boste na levi videli naloge in stanje svojega dela.',
        'help_freelancers.ans_deadline_extend': '...',
        'help_freelancers.ans_negotiation': '...',

        // CLIENTS faq
        'help_clients.profile_setup': 'Kako nastavim svoj profil?',
        'help_clients.project_create': 'Kako ustvarim projekt?',
        'help_clients.task_create': 'Kako ustvarim nalogo?',
        'help_clients.project_status': 'Kje lahko vidim status mojih projektov?',
        'help_clients.task_proposal': 'Kaj lahko naredim, če nisem zadovoljen z oddano nalogo?',

        //CLIENTS answers
        'help_clients.ans_profile_setup': 'Registrirajte se kot stranka in če kliknete išči, boste na vrhu obvestila videli, da izpolnite profil. Nato dodate svoje prikazno ime, lahko izberete svoj profil in naslovno fotografijo, napišete opis in dodate svojo lokacijo.',
        'help_clients.ans_create_project': 'Izberite išči in nato boste videli projekte in na vrhu seznama na desni strani imate gumb za ustvarjanje novega projekta. Nato morate izpolniti podatke o svojem projektu; ime projekta, kategorija, kratek in dolg opis, lahko tudi naložite dokumente in izbrati morate predvideni začetni / končni datum in proračun.',
        'help_clients.ans_create_task': 'V zgornji vrstici za krmarjenje kliknite moje projekte, nato pa na levi strani imate projekte in naloge teh projektov. Tam morate klikniti na novo nalogo in izpolniti več podrobnosti o svoji nalogi.',
        'help_clients.ans_project_status': 'V mojih projektih imate vse svoje projekte, na levi pa jih lahko filtrirate po statusu ali pa stanje vidite pod imenom projekta.',
        'help_clients.ans_task_proposals': 'Če s predlogom niste zadovoljni, lahko kliknete na števec in oddate nasprotno ponudbo ali pa ponudbo preprosto zavrnete.',

        // COMPETENCIES faq
        'help_competencies.description': 'Kaj so Znanja?',
        'help_competencies.bloom_taxonomy': "Kaj je Bloomova taksonomija?",
        'help_competencies.add_competence': 'Kako lahko dodam Znanje?',
        'help_competencies.vote_competence': 'Kako lahko glasujem o že obstoječem znanju?',
        'help_competencies.aquire_competence': 'Kako lahko pridobim znanja?',
        'help_competencies.new_desc_suggestion': 'Kje lahko predlagam nov opis znanja?',
        'help_competencies.parent_base_competency': 'Kaj so nadrejena in temeljna znanja?',

        //COMPETENCIES answers
        'help_competencies.ans_description': 'Znanja so lastnosti, za katere so se zaposleni v podjetju odločili, da so zaželene, da jih imajo zaposleni. Znanja so podobna veščinam in kompetencam. Znanja se naučijo in združujejo veščine in sposobnosti.',
        'help_competencies.ans_bloom_taxonomy': "Bloomova taksonomija temelji na prepričanju, da se morajo učenci najprej naučiti osnovnega temeljnega znanja o določeni temi, preden lahko napredujejo do bolj zapletenih vrst mišljenja, kot sta analiza in evalvacija. Bloomov okvir je pogosto predstavljen v obliki piramide - podobno kot Maslowova hierarhija potreb -, da bi pokazal, da je višje ravni razmišljanja mogoče poskušati šele, ko se obvladajo nižje ravni. Za kateri koli predmet ali temo morajo učenci te stopnje predelati, da bi lahko eno stopnjo obvladali, preden lahko napredujejo na naslednjo. Učitelji, oblikovalci tečajev in inštruktorji redno uporabljajo Bloomovo taksonomijo, da bi zagotovili, da postavljajo ustrezna vprašanja ter dajejo ustrezne naloge in ocene v vsaki fazi učnega procesa. Na primer, učenci morajo obvladati osnovno znanje o temi, preden lahko začnejo uporabljati svoje znanje. Vsaka raven deluje kot ključni gradnik za naslednjo stopnjo.",
        'help_competencies.ans_add_competence': "V zgornji vrstici za krmarjenje izberite znanja in prispevajte. Nato lahko dodate znanje, zapišete ime znanja, nato pa zapišete opis tega znanja na podlagi Bloomove taksonomije. Na koncu morate izbrati starševska znanja in kategorijo.",
        'help_competencies.ans_vote_competence': 'Seznam znanj lahko razvrščate ali iščete. Nato izberete podrobnosti pogleda. Opis, nadrejeno znanje ali temeljno znanje lahko podprete ali podate.',
        'help_competencies.ans_aquire_competence': 'Pri učenju in usposabljanju na delovnem mestu, sodelovanju v novih projektih, delu v skupinah na delovnem mestu, obiskovanju tečajev / seminarjev / konferenc ...',
        'help_competencies.ans_new_desc_suggestion': 'Na strani z podrobnostmi o nekem znanju imate gumb "Predlagaj opis".',
        'help_competencies.ans_parent_base_competency': 'Nadrejeno znanje postavi znanje na vrhu v registru. Temeljna znanja so znanja, ki jih to znanje vključuje ali jih obsega.',
        
        //EDUCATIONAL INSTITUTION faq
        'help_edu.description': 'Lorem ipsum',

        //AUDITORS faq
        'help_auditors.description': 'Lorem ipsum',

        //BLOCKCHAIN faq
        'help_blockchain.smart_contract': 'Kaj je pametna pogodba?',
        'help_blockchain.description': 'Kaj je veriženje podatkov(blockchain)?',
        'help_blockchain.minting': 'Kaj je kovanje(minting)?',
        'help_blockchain.nft': 'Kaj so nezamenljivi žetoni(nft)?',
        'help_blockchain.security': 'Kako veriženje podatkov(blockchain) zagotavlja varnost in zasebnost?',
        'help_blockchain.pay_tokens': 'Kaj so WNS Čipi?',

        //BLOCKCHAIN answers
        'help_blockchain.ans_smart_contract': "Za razliko od tradicionalnih pogodb so pametne pogodbe digitalne, sledljive, varne, nepovratne, nespremenljive in pregledne. Nobenih pravil o pogodbi ni mogoče enkrat spremeniti na verigi blokov; to daje kupcu in prodajalcu samozavest, da med menjavo ne bosta prevarana. Pametne pogodbe so napredna oblika analognih pogodbenih sporazumov, vendar so namesto papirja njihov medij digitalne, decentralizirane podatkovne baze, torej blockchain. Te pogodbe, ki temeljijo na blockchainu, se lahko samoizvajajo in vzdržujejo ter tako odpravijo potrebo po vlogah posrednikov (notarjev in odvetnikov).",
        'help_blockchain.ans_description': "Blockchain je sistem beleženja informacij na način, ki otežuje ali onemogoča spreminjanje, vdor ali goljufanje sistema. Blockchain je v bistvu digitalna knjiga transakcij, ki se podvoji in porazdeli po celotnem omrežju računalniških sistemov na blockchainu. Vsak blok v verigi vsebuje številne transakcije in vsakič, ko se v verigi blokov zgodi nova transakcija, se zapis te transakcije doda v knjigo vsakega udeleženca. Decentralizirana baza podatkov, ki jo upravlja več udeležencev, je znana kot tehnologija porazdeljene knjige (DLT). Blockchain je vrsta DLT, pri kateri se transakcije beležijo z nespremenljivim kriptografskim podpisom, imenovanim hash. To pomeni, da če bi zamenjali en blok v eni verigi, bi bilo takoj razvidno, da je bil z njim posegan. Če bi hekerji želeli pokvariti blockchain sistem, bi morali spremeniti vsak blok v verigi v vseh distribuiranih različicah verige.",
        'help_blockchain.ans_minting': 'Kovanje je opredeljeno kot računalniški postopek preverjanja veljavnosti informacij, ustvarjanja novega bloka in zabeleževanja teh informacij v verigo blokov. Zamislite si verigo blokov kot digitalno knjigo zapisov. Tako kot papirnate strani lahko tudi te digitalne strani zabeležijo le omejeno količino informacij. Tako se redno ustvarjajo nove strani za zabeleževanje več informacij. Te strani so bloki v verigi blokov. Da bi blokovno omrežje nemoteno delovalo, je mogoče hkrati ustvariti samo en blok. Dokaz o vložku je postopek kovanja, ki nadzira, kako se ustvarjajo bloki in kako se v blok dodajajo podatki.',
        'help_blockchain.ans_nft': 'Če je nekaj zamenljivo, je medsebojno zamenljivo. Da bo jasnejša, jo lahko zamenjamo za enako kot z enakim predmetom. Ameriški dolar in evro sta dva primera zamenljivega premoženja. Bitcoin je zamenljivo sredstvo, ker je vsak bitcoin zamenljiv z drugim. V vseh pogledih so enaki. Če je nekaj torej nezamenljivo, je ravno obratno. Nezamenljivo blago je resnično edinstveno. Dober primer tega bi bila baseball karta z omejeno izdajo. Ničesar ni mogoče zamenjati na enak način. Ima svoje značilne lastnosti, vključno s tem, kako se je ohranil, leto proizvodnje in splošno vrednost. Nezamenljivi žetoni so edinstvena digitalna sredstva. Preprosto uporabljajo verigo blokov za izdajanje in sledenje nespremenljivemu digitalnemu blagu.',
        'help_blockchain.ans_security': 'Blockchain lahko strankam, ki si medsebojno ne zaupajo, omogoča izmenjavo digitalnih podatkov na ravni peer-to-peer z manj ali brez tretjih oseb ali posrednikov. Podatki lahko na primer ustrezajo denarju, zavarovalnim policam, pogodbam, zemljiškim naslovom, zdravstvenim in izobraževalnim kartotekam, rojstnim in poročnim listinam, nakupu in prodaji blaga in storitev ali kateri koli transakciji ali sredstvu, ki se lahko prevede v digitalno obliko. Potencial veriženja blokov, ki bo povzročil obsežne spremembe v gospodarstvu, industriji in družbi - tako zdaj kot jutri - trenutno preučujejo različni sektorji in različne organizacije.',
        'help_blockchain.ans_pay_tokens': '...',

        //Header
        'help.header': 'Kako vam lahko pomagamo?',

        /* GAMIFICATION */
        'gamification.boost_project_title': 'Boostaj projekt',
        'gamification.boost_task_title': 'Boostaj nalogo',
        'gamification.boost_content1': 'boostaj projekt v iskalniku.',
        'gamification.boost_content2.1': 'Projekt bo prikazan z ',
        'gamification.boost_content2.2': 'vrhunski projekt ',
        'gamification.boost_content2.3': 'tag for ',
        'gamification.boost_content2.4': '10 dni.',
        'gamification.boost_content3.1': 'Povečajte doseg svojega projekta med izvajalci za ',
        'gamification.boost_content3.2': '70%.',
        'gamification.boost_blance': 'Vaše stanje žetonov',
        'gamification.boost_chips': 'žetoni',
        'gamification.boost_buy_chips': 'Ali pa kupite "boost" s svojimi žetoni.',
        'gamification.boost': 'boost',
        'gamification.boost_beset_offer': 'Najboljša ponudba',
        'gamification.boost_now': 'Boostaj',
        'gamification.boost_content1_task': 'Boost your task in search results.',
        'gamification.boost_content2.1_task': 'Your task will appear with ',
        'gamification.boost_content3.1_task': 'Increase the reach of your project among freelancers by ',
        'gamification.boost_project_not_boosted': 'Projekt še ni promoviran.',
        'gamification.boost_task_not_boosted': 'Naloga še ni promovirana.',

        'landing.more-than-just': 'Več kot samo',
        'landing.a-frelancing-portal': 'freelancer portal!',
    }
}
export const REACT_APP_BASE_WS_URL_BARE = () => {
    if (window?._env_?.REACT_APP_BASE_WS_URL_BARE) { return window._env_.REACT_APP_BASE_WS_URL_BARE; }
    return process.env.REACT_APP_BASE_WS_URL_BARE;
}
export const REACT_APP_BASE_BACKEND_URL_BARE = () => {
    if (window?._env_?.REACT_APP_BASE_BACKEND_URL_BARE) { return window._env_.REACT_APP_BASE_BACKEND_URL_BARE; }
    return process.env.REACT_APP_BASE_BACKEND_URL_BARE;
}
export const REACT_APP_BASE_BACKEND_URL = () => {
    if (window?._env_?.REACT_APP_BASE_BACKEND_URL) { return window._env_.REACT_APP_BASE_BACKEND_URL; }
    return process.env.REACT_APP_BASE_BACKEND_URL;
}
export const REACT_APP_API_URL = () => {
    if (window?._env_?.REACT_APP_API_URL) { return window._env_.REACT_APP_API_URL; }
    return process.env.REACT_APP_API_URL;
}
export const REACT_APP_LOCATION_API_URL = () => {
    if (window?._env_?.REACT_APP_LOCATION_API_URL) { return window._env_.REACT_APP_LOCATION_API_URL; }
    return process.env.REACT_APP_LOCATION_API_URL;
}
export const REACT_APP_GOOGLE_CLIENT_ID = () => {
    if (window?._env_?.REACT_APP_GOOGLE_CLIENT_ID) { return window._env_.REACT_APP_GOOGLE_CLIENT_ID; }
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
}
export const REACT_APP_HOTJAR_CODE = () => {
    if (window?._env_?.REACT_APP_HOTJAR_CODE) { return window._env_.REACT_APP_HOTJAR_CODE; }
    return process.env.REACT_APP_HOTJAR_CODE;
}
export const REACT_APP_GOOGLEANALYTICS_CODE = () => {
    if (window?._env_?.REACT_APP_GOOGLEANALYTICS_CODE) { return window._env_.REACT_APP_GOOGLEANALYTICS_CODE; }
    return process.env.REACT_APP_GOOGLEANALYTICS_CODE;
}
export const REACT_APP_FB_PIXEL_CODE = () => {
    if (window?._env_?.REACT_APP_FB_PIXEL_CODE) { return window._env_.REACT_APP_FB_PIXEL_CODE; }
    return process.env.REACT_APP_FB_PIXEL_CODE;
}
export const REACT_APP_NETWORK_CURRENCY = () => {
    if (window?._env_?.REACT_APP_NETWORK_CURRENCY) { return window._env_.REACT_APP_NETWORK_CURRENCY; }
    return process.env.REACT_APP_NETWORK_CURRENCY;
}
export const REACT_APP_BC_EXPLORER_URL = () => {
    if (window?._env_?.REACT_APP_BC_EXPLORER_URL) { return window._env_.REACT_APP_BC_EXPLORER_URL; }
    return process.env.REACT_APP_BC_EXPLORER_URL;
}
import React from 'react';
import './index.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormCompetencesInformation from '../../../components/Profile/Setup/formCompetences';
import FormProfileInformation from '../../../components/Profile/Setup/formPersonalInformation';
import FormWorkInformation from '../../../components/Profile/Setup/formWorkInformation';
import FormCompanyInformation from '../../../components/Profile/Setup/formCompanyInformation';
import { langService } from './../../../utils/languageService/index';


class ProfileSetupPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
        };
    }

    componentDidMount() {
        const param = this.props.match.params;
        // if (this.props.role.type === "INV" && param.step === "1") {
        //     param.step = 2;
        //     history.push("/profile-setup/2");
        // }
        
        this.setState({ step: parseInt(param.step) });
    }


    render() {
        const step = this.state.step;
        const { role } = this.props;
        return (
            <>
                <div className="jumbo-dash">
                    <Container>
                        <h1>
                            <FormattedMessage id="nav.labels.profile_setup" defaultMessage="Profile setup" />
                        </h1>
                        <p>
                            <FormattedMessage id="setup_profile.desc" defaultMessage="A complete profile raises your reputation and increases your chances of finding what you are looking for." />
                        </p>
                    </Container>
                </div>
                <Container className="mt-4">

                    <Row>
                        <Col>
                            <div>
                                <div className="setup-steps">
                                    <div className={role.type === "DEV" ? "connecting-line-inv connecting-line-inv-responsive" : "connecting-line-inv connecting-line-inv-responsive short-line"}></div>

                                    <div className="step-item-inv">
                                        <div className={step === 1 ? "step-step-inv step-active-inv" : "step-step-inv"} onClick={() => history.push("/profile-setup/1")}>1</div>
                                        <div className={step === 1 ? "step-info-inv step-info-inv-active" : "step-info-inv"} onClick={() => history.push("/profile-setup/1")}>
                                            {(step > 1) &&
                                                <span className="checkmark">
                                                    <div className="checkmark_stem stem-inv"></div>
                                                    <div className="checkmark_kick kick-inv"></div>
                                                </span>
                                            }
                                            <FormattedMessage id="profile.public" defaultMessage="Public profile" />
                                        </div>
                                    </div>

                                    <div className="step-item-inv">
                                        <div className={step === 2 ? "step-step-inv step-active-inv" : "step-step-inv"} onClick={() => history.push("/profile-setup/2")}>2</div>
                                        <div className={step === 2 ? "step-info-inv step-info-inv-active" : "step-info-inv"} onClick={() => history.push("/profile-setup/2")}>
                                            {step > 2 &&
                                                <span className="checkmark">
                                                    <div className="checkmark_stem stem-inv"></div>
                                                    <div className="checkmark_kick kick-inv"></div>
                                                </span>
                                            }
                                            <FormattedMessage id="setup_profile.company_info" defaultMessage="Company info" />
                                        </div>
                                    </div>

                                    { role.type === "DEV" && 
                                        <>
                                        <div className="step-item-inv">
                                            <div className={step === 3 ? "step-step-inv step-active-inv" : "step-step-inv"} onClick={() => history.push("/profile-setup/3")}>3</div>
                                            <div className={step === 3 ? "step-info-inv step-info-inv-active" : "step-info-inv"} onClick={() => history.push("/profile-setup/3")}>
                                                {(step > 3) &&
                                                    <span className="checkmark">
                                                        <div className="checkmark_stem stem-inv"></div>
                                                        <div className="checkmark_kick kick-inv"></div>
                                                    </span>
                                                }
                                                <FormattedMessage id="profile.competences" defaultMessage="Skills" />
                                            </div>
                                        </div>

                                        <div className="step-item-inv">
                                            <div className={step === 4 ? "step-step-inv step-active-inv" : "step-step-inv"} onClick={() => history.push("/profile-setup/4")}>4</div>
                                            <div className={step === 4 ? "step-info-inv step-info-inv-active" : "step-info-inv"} onClick={() => history.push("/profile-setup/4")}>
                                                {langService.messages['setup_profile.work_info']}
                                            </div>
                                        </div>                                    
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {step === 1 &&
                        <FormProfileInformation countryTest={this.props.role.country} />
                    }

                    {step === 2 &&
                        <FormCompanyInformation />
                    }

                    {(step === 3) &&
                        <FormCompetencesInformation />
                    }

                    {(step === 4) &&
                        <FormWorkInformation />
                    }

                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj
    }
}

export default injectIntl(connect(mapStateToProps, {})(ProfileSetupPage));
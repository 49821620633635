import * as types from './types';
import { apiCon } from '../../../API';

//------------------------------------
// Actions Get My gamification balance
//------------------------------------
export const GetMyGamificationBalance = () => async dispatch => {
    dispatch({ type: types.GET_MY_GAMIFICATION_BALANCE_REQUEST });
    await apiCon.get('/me/gamify/balance/')
        .then(response => {
            dispatch(GetMyGamificationBalanceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyGamificationBalanceFail(err.response));
        })
}
export const GetMyGamificationBalanceSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my Gamification balances info.", data }
    dispatch({ type: types.GET_MY_GAMIFICATION_SUCCESS, payload: payload });
}
export const GetMyGamificationBalanceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my Gamification balance failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Gamification balance failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_GAMIFICATION_FAILURE, payload: payload };
}

//----------------------
// Actions BOOST task
//----------------------
export const CreateBoostTaskStart = (values) => async dispatch => {
    dispatch({ type: types.BOOST_MY_TASK_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/project/task/upgrades/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateBoostTaskSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateBoostTaskFailure(err.response));
        })
}

export const CreateBoostTaskSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully boosted task.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.BOOST_MY_TASK_SUCCESS, payload: payload });
}

export const CreateBoostTaskFailure = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Boost task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Boost task failed. " + err.data.error_first_message; }; const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.BOOST_MY_TASK_FAILURE, payload: payload };
}

//----------------------
// Actions BOOST project
//----------------------
export const CreateBoostProjectStart = (values) => async dispatch => {
    dispatch({ type: types.BOOST_MY_PROJECT_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post('/project/upgrade/', json, config)
        .then(response => {
            dispatch(CreateBoostProjectSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateBoostProjectFailure(err.response));
        })
}

export const CreateBoostProjectSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully boosted project.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.BOOST_MY_PROJECT_SUCCESS, payload: payload });
}

export const CreateBoostProjectFailure = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Boost project failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Boost project failed. " + err.data.error_first_message; }; const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.BOOST_MY_PROJECT_FAILURE, payload: payload };
}

//-------------------------------------
// Actions Get Project Available Boosts
//-------------------------------------
export const GetProjectAvailableBoosts = (id) => async dispatch => {
    dispatch({ type: types.GET_PROJECT_AVAILABLE_BOOSTS_REQUEST });
    await apiCon.get('/project/upgrade/' + id)
        .then(response => {
            dispatch(GetProjectAvailableBoostsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetProjectAvailableBoostsFail(err.response));
        })
}
export const GetProjectAvailableBoostsSuccess = (data, add = false ) => async dispatch => {
    const payload = { succ_msg: "Successfully received available tasks to be boosted.", project_boost: data, add: add }
    dispatch({ type: types.GET_PROJECT_AVAILABLE_BOOSTS_SUCCESS, payload: payload });
}
export const GetProjectAvailableBoostsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get available tasks to be boosted failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get available tasks to be boosted failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_PROJECT_AVAILABLE_BOOSTS_FAILURE, payload: payload };
}
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import transakSDK from '@transak/transak-sdk'
import store from '../../core/store';
import { PURCHASE_CRYPTO_SUCCESS } from '../../core/actions/crypto/types';
import iconTransfer from '../../assets/img/exchange.svg';
import { Image } from 'react-bootstrap';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';

class WalletBuyButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    calculateHeight() {
        if (window.innerHeight < 650) {
            return (window.innerHeight - 250) + 'px';
        } else {
            return '650px';
        }
    }

    calculateWidth() {
        if (window.innerWidth < 650) {
            return (window.innerWidth - 0) + 'px';
        } else {
            return '500px';
        }
    }

    openBuy = () => {

        // TODO - Get new OrderID from our backend and include it as "partnerOrderId" in the widget config

        const { wallet, user } = this.props;

        let transakConfig = {
            apiKey: '05a67f2a-8adc-445c-9e5a-c5ca0d9257c1',  // API Key
            networks: 'ethereum',
            environment: 'STAGING', // STAGING/PRODUCTION
            defaultCryptoCurrency: 'ETH',
            walletAddress: wallet.address, // Customer's wallet address
            // email: user.email, // Customer's email address
            themeColor: '008dce',
            fiatCurrency: 'EUR', // INR/GBP
            redirectURL: window.location.origin,
            hostURL: window.location.origin,
            widgetHeight: this.calculateHeight(),
            widgetWidth: this.calculateWidth(),
            cryptoCurrencyList: 'ETH,DAI',
            disableWalletAddressForm: true, // When true, the customer will not be able to change the destination address of where the cryptocurrency is sent to.
            exchangeScreenTitle: 'Work \'n Skill - Buy ETH or DAI',  // To change the exchange screen title. 
            defaultPaymentMethod: 'credit_debit_card', // The default payment method you would prefer the customer to purchase with. If you pass a defaultPaymentMethod, the payment method will be selected by default and the customer can also select another payment method.  This parameter will be skipped if fiatCurrency is not passed. Open this doc to get the possible values.
            partnerCustomerId: user.id  // A customer ID that will be used to identify the customer that made the transaction once a webhook is called back to your app.
            // partnerOrderId:          // An order ID that will be used to identify the transaction once a webhook is called back to your app. This can be your identifier to track your customers. 
            // hideMenu: true 
        };

        if (REACT_APP_NETWORK_CURRENCY() === 'BNB') {
            transakConfig.networks = 'bsc';
            transakConfig.defaultCryptoCurrency = 'BNB';
            transakConfig.cryptoCurrencyList = 'BNB,DAI';
            transakConfig.exchangeScreenTitle = 'Work \'n Skill - Buy BNB or DAI';
        }

        let transak = new transakSDK(transakConfig);
        transak.init();

        transak.on(transak.ALL_EVENTS, (data) => {
            console.log(data)
            // TODO - Update order id in our backend...
        });

        // Successfull payment
        transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            console.log(orderData);
            transak.close();
            // Show notification...
            store.dispatch({ type: PURCHASE_CRYPTO_SUCCESS, payload: { succ_msg: orderData, toast_msg: 'Your order to purchase crypto was submited. It usually takes a couple of minutes to be processed.' } });

            // TODO - Backend API integration to sync order status...
            // https://integrate.transak.com/Sandbox-Staging-Environment-7b2c567382bb4c8b95db608eb3804e68
        });
    }

    render() {
        return (
            <>
                {!this.props.mobile
                    ?
                    <button className="btn" onClick={() => this.openBuy()}>
                        <FormattedMessage id="wallet.buy" defaultMessage="Buy" />
                    </button>
                    :
                    <div className="transfer-box" onClick={() => this.openBuy()}>
                        <Image src={iconTransfer} alt="Transfer" />
                        <div className="transfer-type">Buy</div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info,
        wallet: state.user.wallet
    }
}

export default injectIntl(connect(mapStateToProps, {

})(WalletBuyButton));
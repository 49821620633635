import React from 'react';
import './payments.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';
import Switch from '../../components/Common/ToggleSwitch';
import { GetMyWalletSettingsStart, UpdateMyWalletSettingsStart } from '../../core/actions/blockchain/';

const CURRENCY_OPTIONS = [
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' }
]

class SettingsPayments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            area: '',
            defaultSelected: { label: this.props.wallet?.settings?.display_currency, value: this.props.wallet?.settings?.display_currency },
            autoClaimCompetence: this.props.walletSettings.auto_claim_competence,
        };
    }

    componentDidMount() {
        this.props.GetMyWalletSettingsStart();
    }

    toggleWalletSettings = () => ({ enabled }) => {
        this.props.UpdateMyWalletSettingsStart({ auto_claim_competence: enabled })
    }

    render() {
        const { wallet, did_id } = this.props;
        
        return (
            <div className="card p-0">
                <div className="card-head">
                    Blockchain & Payment settings
                </div>
                <div className="card-body">
                    <Row>
                        <Col>

                            <div className="sett-col-title">
                                Accounts
                            </div>
                            <hr />
                            <div className="sett-col-item">
                                <span className="">
                                    Your wallet address
                                </span>
                                {wallet.address}
                            </div>

                            <hr />
                            <div className="sett-col-title">
                                Skills
                            </div>
                            <hr />
                            <div className="sett-col-item">
                                <span>Your user identification for your skills (DID_ID)</span>
                                <span style={{marginRight: '22px'}}><strong>{did_id}</strong></span>
                            </div>
                            <div className="sett-col-item">
                                <span>Automatically mint my skills on Blockchain</span>
                                <Switch theme="default" className="d-flex" enabled={this.props.walletSettings.auto_claim_competence} onStateChanged={this.toggleWalletSettings()} />
                            </div>

                            <hr />
                            <div className="sett-col-title">
                                Display currency
                            </div>
                            <hr />
                            <div className="sett-col-item">
                                <span className="">
                                    Display money values in
                                </span>
                                <div style={{width: '110px'}}>
                                    <ReactSelect
                                        name="currency-select"
                                        className="order-select"
                                        classNamePrefix="select"
                                        placeholder={"Select currency"}
                                        defaultValue={this.state.defaultSelected}
                                        options={CURRENCY_OPTIONS}
                                        onChange={(e) =>  this.props.UpdateMyWalletSettingsStart({
                                            display_currency: e ? e.value : ''
                                        })}
                                        isLoading={CURRENCY_OPTIONS.loading}
                                        isMulti={false}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        wallet: state.user.wallet,
        currency: state.currency,
        walletSettings: state.user.wallet.settings,
        role: state.user.info.settings.active_role_obj,
        did_id: state.user.info.did_id
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyWalletSettingsStart,
    UpdateMyWalletSettingsStart
})(SettingsPayments));
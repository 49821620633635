import * as types from './types';
import { apiCon } from '../../../API'
import history from '../../../utils/routes/history'
import { langService } from '../../../utils/languageService';
import { GetProfileCompletionStart } from '../../actions/auth/index'
import { GetReviewsStart, GetMyReviewsStart } from './../rating/index';
import { getParameterObject, getUrlParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS as INITIAL_FREELANCER_FILTERS } from '../../reducers/browse/freelancerReducer';
import { INITIAL_STATE_FILTERS as INITIAL_CLIENT_FILTERS } from '../../reducers/browse/clientReducer';
import { INITIAL_STATE_FILTERS as INITIAL_AUDITOR_FILTERS } from '../../reducers/browse/auditorReducer';
import { INITIAL_STATE_FILTERS as INITIAL_EDU_FILTERS } from '../../reducers/browse/eduReducer';

//----------------------
// Actions Update Role Details
//----------------------
export const UpdateRoleDetailsStart = (values, step = 0) => async dispatch => {
    dispatch({ type: types.UPDATE_ROLE_DETAILS_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `me/role/detail/`;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateRoleDetailsSuccess(response.data, step));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(UpdateRoleDetailsFail(err.response));
        })
}

export const UpdateRoleDetailsSuccess = (data, step) => {
    const succ_msg = "Successfully updated role details.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, details: data };
    if (step > 0 && step < 5) {
        history.push('/profile-setup/' + step);
    } else if (step === 5) {
        history.push('/profile-setup-finished');
    }
    return { type: types.UPDATE_ROLE_DETAILS_SUCCESS, payload: payload };
}

export const UpdateRoleDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.detail.update.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_ROLE_DETAILS_FAILURE, payload: payload };
}

//----------------------
// Actions Change ActiveRole
//----------------------
export const ChangeRoleStart = (roleId) => async dispatch => {
    dispatch({ type: types.CHANGE_ACTIVE_ROLE_REQUEST });
    const role = { active_role: roleId };
    await apiCon.put(`me/settings/`, role)
        .then((response) => {
            dispatch(ChangeRoleSuccess(response.data));
        })
        .catch(err => {
            dispatch(ChangeRoleFail(err.response));
        })
}

export const ChangeRoleSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully changed active role. ", settings: data };
    if (data.active_role) {
        dispatch(GetProfileCompletionStart());
        dispatch(GetMyRoleDetailsStart());
        dispatch(GetRoleStart());
        dispatch(GetMyReviewsStart());

        // issuers -> my
        // issuers -> selected

        // myinstitution -> mydegrees
        // myinstitution -> myprograms
        // myinstitution -> mystudents
    }
    dispatch({ type: types.CHANGE_ACTIVE_ROLE_SUCCESS, payload: payload });
}

export const ChangeRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.change.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CHANGE_ACTIVE_ROLE_FAILURE, payload: payload };
}

//----------------------
// Actions Create Role
//----------------------
export const CreateRoleStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_ROLE_REQUEST });
    await apiCon.post(`me/roles/`, values)
        .then(response => {
            dispatch(CreateRoleSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateRoleFail(err.response));
        })
}

export const CreateRoleSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created the role.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    dispatch({ type: types.CREATE_ROLE_SUCCESS, payload: payload });
    if (data.type === 'DEV') {
        history.push('/browse/tasks');
    } else {
        history.push('/browse/projects');
    }
}

export const CreateRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.create.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_ROLE_FAILURE, payload: payload };
}

//----------------------
// Actions Get Role
//----------------------
export const GetRoleStart = () => async dispatch => {
    dispatch({ type: types.GET_ROLE_REQUEST });
    await apiCon.get(`me/role/`)
        .then(response => {
            dispatch(GetRoleSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetRoleFail(err.response));
        })
}

export const GetRoleSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved the role.";
    const payload = { succ_msg: succ_msg, role: data };
    dispatch({ type: types.GET_ROLE_SUCCESS, payload: payload });
}

export const GetRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_ROLE_FAILURE, payload: payload };
}

//----------------------
// Actions Update Role
//----------------------
export const UpdateRoleStart = (values, step = 0, showToast = true) => async dispatch => {
    dispatch({ type: types.UPDATE_ROLE_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `me/role/`;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateRoleSuccess(response.data, step, showToast));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(UpdateRoleFail(err.response));
        })
}

export const UpdateRoleSuccess = (data, step, showToast) => {
    const succ_msg = "Successfully updated the role.";
    let toast_msg = '';
    if (showToast) { toast_msg = succ_msg }
    const payload = { succ_msg, toast_msg, role: data };
    if (step > 0 && step < 5) {
        history.push('/profile-setup/' + step);
    } else if (step === 5) {
        history.push('/profile-setup-finished');
    }
    return { type: types.UPDATE_ROLE_SUCCESS, payload: payload };
}

export const UpdateRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.update.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_ROLE_FAILURE, payload: payload };
}

//----------------------
// Actions Delete Role
//----------------------
export const DeleteRoleStart = (roleId) => async dispatch => {
    dispatch({ type: types.DELETE_ROLE_REQUEST });
    const address = 'me/role/';
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteRoleSuccess(roleId));
        })
        .catch(err => {
            dispatch(DeleteRoleFail(err.response));
        });
}
export const DeleteRoleSuccess = (id) => {
    const succ_msg = "Role deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_ROLE_SUCCESS, payload: payload };
}
export const DeleteRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Role failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Delete Role failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_ROLE_FAILURE, payload: payload };
}

//----------------------
// Actions Role Profile Picture
//----------------------
export const UploadRoleProfilePictureStart = (file) => async dispatch => {
    dispatch({ type: types.UPLOAD_ROLE_PROFILE_PICTURE_REQUEST });
    const formData = new FormData();
    formData.append('profile_image', file);
    const address = `me/role/`;
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await apiCon.patch(address, formData, config)
        .then((response) => {
            dispatch(UploadRoleProfilePictureSuccess(response.data));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(UploadRoleProfilePictureFail(err.response));
        })
}
export const UploadRoleProfilePictureSuccess = (data) => {
    const succ_msg = "Successfully updated roles profile image.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    return { type: types.UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS, payload: payload };
}

export const UploadRoleProfilePictureFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.upload.profile_pic.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPLOAD_ROLE_PROFILE_PICTURE_FAILURE, payload: payload };
}

//----------------------
// Actions Role Cover Picture
//----------------------
export const UploadRoleCoverPictureStart = (file) => async dispatch => {
    dispatch({ type: types.UPLOAD_ROLE_COVER_PICTURE_REQUEST });
    const formData = new FormData();
    formData.append('cover_image', file);
    const address = `me/role/`;
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await apiCon.patch(address, formData, config)
        .then((response) => {
            dispatch(UploadRoleCoverPictureSuccess(response.data));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(UploadRoleCoverPictureFail(err.response));
        })
}
export const UploadRoleCoverPictureSuccess = (data) => {
    const succ_msg = "Successfully updated roles cover image.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    return { type: types.UPLOAD_ROLE_COVER_PICTURE_SUCCESS, payload: payload };
}

export const UploadRoleCoverPictureFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.upload.cover_pic.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPLOAD_ROLE_COVER_PICTURE_FAILURE, payload: payload };
}
const ALLOWED_FREELANCER_FILTERS = ['advanced', 'limit', 'search', 'ordering', 'availability', 'competences', 'min_nmb_of_active_projects', 'min_nmb_of_finished_projects', 'min_nmb_of_finished_tasks', 'min_nmb_of_active_tasks']
//----------------------
// Actions Change Freelancer List Filters 
//----------------------
export const ChangeFreelancerFiltersStart = (params = INITIAL_FREELANCER_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_FREELANCER_FILTERS);
    const payload = { succ_msg: "Successfully change freelancer filters.", filters: filters }
    return { type: types.CHANGE_FREELANCER_FILTERS, payload: payload };
}

//----------------------
// Actions Get Freelancers
//----------------------
export const GetFreelancersStart = (params = INITIAL_FREELANCER_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_FREELANCERS_REQUEST });
    const url = '/freelancers/'
    const filters = getUrlParameterObject(params, ALLOWED_FREELANCER_FILTERS)
    await apiCon.get(url, { params: filters })
        .then((response) => {
            dispatch(GetFreelancersSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetFreelancersFail(err.response));
        })
}

export const GetFreelancersChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_FREELANCERS_REQUEST });
    await apiCon.get(url)
        .then((response) => {
            dispatch(GetFreelancersSuccess(response.data, true));
        })
        .catch(err => {
            dispatch(GetFreelancersFail(err.response));
        })
}

export const GetFreelancersSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully retrieved freelancers.", list: data, add: add };
    return { type: types.GET_FREELANCERS_SUCCESS, payload: payload };
};

export const GetFreelancersFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.freelacers.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_FREELANCERS_FAILURE, payload: payload };
}

const ALLOWED_CLIENT_FILTERS = ['advanced', 'limit', 'search', 'ordering', 'min_nmb_of_finished_projects', 'min_nmb_of_active_projects', 'min_nmb_of_active_tasks ', 'min_nmb_of_finished_tasks', 'industry']
//----------------------
// Actions Change Client List Filters 
//----------------------
export const ChangeClientFiltersStart = (params = INITIAL_CLIENT_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_CLIENT_FILTERS);
    const payload = { succ_msg: "Successfully change client filters.", filters: filters }
    return { type: types.CHANGE_CLIENT_FILTERS, payload: payload };
}

//----------------------
// Actions Get Clients
//----------------------
export const GetClientsStart = (params = INITIAL_CLIENT_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_CLIENTS_REQUEST });
    const url = '/clients/'
    const filters = getUrlParameterObject(params, ALLOWED_CLIENT_FILTERS)
    await apiCon.get(url, { params: filters })
        .then((response) => {
            dispatch(GetClientsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetClientsFail(err.response));
        })
}

export const GetClientsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_CLIENTS_REQUEST });
    await apiCon.get(url)
        .then((response) => {
            dispatch(GetClientsSuccess(response.data, true));
        })
        .catch(err => {
            dispatch(GetClientsFail(err.response));
        })
}

export const GetClientsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully retrieved Clients.", list: data, add: add };
    return { type: types.GET_CLIENTS_SUCCESS, payload: payload };
}

export const GetClientsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.clients.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_CLIENTS_FAILURE, payload: payload };
}

const ALLOWED_AUDITOR_FILTERS = ['limit', 'search', 'ordering', 'availability', 'competences']
//----------------------
// Actions Change Auditor List Filters 
//----------------------
export const ChangeAuditorFiltersStart = (params = INITIAL_AUDITOR_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_AUDITOR_FILTERS);
    const payload = { succ_msg: "Successfully change auditor filters.", filters: filters }
    return { type: types.CHANGE_AUDITOR_FILTERS, payload: payload };
}

//----------------------
// Actions Get Auditors
//----------------------
export const GetAuditorsStart = (params = INITIAL_AUDITOR_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_AUDITORS_REQUEST });
    const url = '/auditors/'
    const filters = getUrlParameterObject(params, ALLOWED_AUDITOR_FILTERS)
    await apiCon.get(url, { params: filters })
        .then((response) => {
            dispatch(GetAuditorsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetAuditorsFail(err.response));
        })
}

export const GetAuditorsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_AUDITORS_REQUEST });
    await apiCon.get(url)
        .then((response) => {
            dispatch(GetAuditorsSuccess(response.data, true));
        })
        .catch(err => {
            dispatch(GetAuditorsFail(err.response));
        })
}

export const GetAuditorsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully retrieved Auditors.", list: data, add: add };
    return { type: types.GET_AUDITORS_SUCCESS, payload: payload };
}

export const GetAuditorsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.auditors.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_AUDITORS_FAILURE, payload: payload };
}

const ALLOWED_EDU_FILTERS = ['advanced', 'limit', 'search', 'ordering']
//----------------------
// Actions Change Edu List Filters 
//----------------------
export const ChangeEduFiltersStart = (params = INITIAL_EDU_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_EDU_FILTERS);
    const payload = { succ_msg: "Successfully change edu filters.", filters: filters }
    return { type: types.CHANGE_EDUS_FILTERS, payload: payload };
}

//----------------------
// Actions Get Edus
//----------------------
export const GetEdusStart = (params = INITIAL_EDU_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_EDUS_REQUEST });
    const url = '/eduinstitutions/'
    const filters = getUrlParameterObject(params, ALLOWED_EDU_FILTERS)
    await apiCon.get(url, { params: filters })
        .then((response) => {
            dispatch(GetEdusSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetEdusFail(err.response));
        })
}

export const GetEdusChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_EDUS_REQUEST });
    await apiCon.get(url)
        .then((response) => {
            dispatch(GetEdusSuccess(response.data, true));
        })
        .catch(err => {
            dispatch(GetEdusFail(err.response));
        })
}

export const GetEdusSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully retrieved edus.", list: data, add: add };
    return { type: types.GET_EDUS_SUCCESS, payload: payload };
}

export const GetEdusFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.edus.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_EDUS_FAILURE, payload: payload };
}

//----------------------
// Actions Select Profile
//----------------------
export const SelectProfileStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_PROFILE_REQUEST });
    await apiCon.get('/role/' + id)
        .then(response => {
            dispatch(SelectProfileSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectProfileFail(err.response));
        })
}
export const SelectProfileSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received profile info.", selected: data }
    dispatch(GetRoleDetailsStart(data.id));
    if (data.type !== 'EDU' && data.type !== 'AUD') {
        dispatch(GetReviewsStart(data.id))
    }
    dispatch({ type: types.SELECT_PROFILE_SUCCESS, payload: payload });
}
export const SelectProfileFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select Profile failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select profile failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_PROFILE_FAILURE, payload: payload };
}

//----------------------
// Actions Get Role Details
//----------------------
export const GetRoleDetailsStart = (id) => async dispatch => {
    dispatch({ type: types.GET_ROLE_DETAIL_REQUEST });
    await apiCon.get('/role/detail/' + id)
        .then(response => {
            dispatch(GetRoleDetailsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetRoleDetailsFail(err.response));
        })
}
export const GetRoleDetailsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received role details info.", details: data }
    dispatch({ type: types.GET_ROLE_DETAIL_SUCCESS, payload: payload });
}
export const GetRoleDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Role Details failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get Role Details failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_ROLE_DETAIL_FAILURE, payload: payload };
}

//----------------------
// Actions Get My Role Details
//----------------------
export const GetMyRoleDetailsStart = () => async dispatch => {
    dispatch({ type: types.GET_MY_ROLE_DETAIL_REQUEST });
    await apiCon.get('me/role/detail/')
        .then(response => {
            dispatch(GetMyRoleDetailsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyRoleDetailsFail(err.response));
        })
}
export const GetMyRoleDetailsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my role details info.", details: data }
    dispatch({ type: types.GET_MY_ROLE_DETAIL_SUCCESS, payload: payload });
}
export const GetMyRoleDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Role Details failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get My Role Details failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_ROLE_DETAIL_FAILURE, payload: payload };
}

//----------------------
// Actions Get socials
//----------------------
export const GetSocialsStart = () => async dispatch => {
    dispatch({ type: types.GET_SOCIALS_REQUEST });
    await apiCon.get(`me/socials/`)
        .then(response => {
            dispatch(GetSocialsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetSocialsFail(err.response));
        })
}

export const GetSocialsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved socials.";
    const payload = { succ_msg: succ_msg, role: data };
    dispatch({ type: types.GET_SOCIALS_SUCCESS, payload: payload });
}

export const GetSocialsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Error getting social data.";
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_SOCIALS_FAILURE, payload: payload };
}

//-----------------------
// Actions Get Role stats
//-----------------------
export const GetRoleStatsStart = () => async dispatch => {
    dispatch({ type: types.GET_ROLE_STATS_REQUEST });
    await apiCon.get(`me/role/detail/stats`)
        .then(response => {
            dispatch(GetRoleStatsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetRoleStatsFail(err.response));
        })
}

export const GetRoleStatsSuccess = (data, add = false) => async dispatch => {
    const succ_msg = "Successfully recieved role stats.";
    const payload = { succ_msg: succ_msg, stats: data, add: add };
    dispatch({ type: types.GET_ROLE_STATS_SUCCESS, payload: payload });
}

export const GetRoleStatsFail = (err) => {
    const err_msg = { errorType: err.data.error, stats: err.data.stats };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_ROLE_STATS_FAILURE, payload: payload };
}
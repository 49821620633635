import React from 'react'
import './index.scss'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ChangeRoleStart } from '../../core/actions/role';
import history from '../../utils/routes/history';
import { langService } from '../../utils/languageService';


class ProfileSwitcherControl extends React.Component {

    getInitials(str) {
        try {
            let name = str.split(" ");
            if (name.length >= 2) {
                return name[0].charAt(0) + name[1].charAt(0);
            }
        } catch (err) { }
        return str.charAt(0);
    }

    changeRole(id) {
        this.props.ChangeRoleStart(id);
    }
   
    getAddRoleName = () => {
        const res = this.checkRoles();
        if (res === 'dev') {
            return langService.messages['roles.become_freelancer'];
        } else if (res === 'inv') {
            return langService.messages['roles.become_client'];
        } else {
            return langService.messages['roles.add_role'];
        }
    }

    checkRoles = () => {
        if (this.props.roles.some(e => e.type === 'INV') && !this.props.roles.some(e => e.type === 'DEV')) {
            return 'dev';
        } else if (!this.props.roles.some(e => e.type === 'INV') && this.props.roles.some(e => e.type === 'DEV')) {
            return 'inv';
        } else {
            return 'edu';
        }
    }

    render() {
        return (
            <>
                {this.props.roles && this.props.roles.length >= 1 &&
                    <>
                        <div className="switch-header">
                            <span><FormattedMessage id='profile.change.role' defaultMessage='Quick switch between my profiles' /></span>
                        </div>
                        <div className="switch-container">
                            {this.props.roles.map((role, i) =>
                                <div className={`switch-item item-${role.type.toLowerCase()} ${role.id === this.props.active_role_id ? "item-" + role.type.toLowerCase() + "-active" : ""}`}
                                    onClick={() => this.changeRole(role.id)}
                                    key={i}
                                    >
                                    {role.profile_image
                                        ?
                                        <img className="dd-role-withimg-active-sm" style={{ 'marginRight': '15px' }} src={role.profile_image} alt="Active profile thumb"></img>
                                        :
                                        <span style={{ 'marginRight': '15px' }} className={`dd-${role.type?.toLowerCase()}-img-active-sm`}>{role.name?.charAt(0)?.toUpperCase()}</span>
                                    }
                                    <div className="item-content">
                                        <span>{role.name}</span>
                                        <span><FormattedMessage id={"roles.name." + role.type} defaultMessage={role.type} /></span>
                                    </div>
                                </div>
                            )}
                            <div className="switch-item item-register" onClick={() => history.push('/auth/register-role')}>
                                {this.props.isEmailVerified && <>
                                    <span style={{ 'marginRight': '15px' }} className={`dd-aud-img-active-sm`}>+</span>
                                    <div className="item-content-register">
                                        <span> {this.getAddRoleName()} </span>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        active_role_type: state.user.info.settings.active_role_obj?.type,
        active_role_id: state.user.info.settings.active_role,
        active_role: state.user.info.settings.active_role_obj,
        roles: state.user.info.roles,
        user: state.user.info,
        isEmailVerified: state.user.info.isEmailVerified
    }
}

export default injectIntl(connect(mapStateToProps, { ChangeRoleStart })(ProfileSwitcherControl));
import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import ImageUploader from '../../Common/ImageUploader';

class ModalAddCover extends React.Component {
    hide() {
        this.props.onClose(null);
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                className="add-cover-photo-modal"
                visible={visible}
                onClose={() => this.hide()}
                width={600}
                height={360}
                closeMaskOnClick={false}>
                <ImageUploader
                    headerText={<FormattedMessage id="profile.change.cover" defaultMessage="Change Cover image" />}
                    footerText={<FormattedMessage id="modalEditProfileImage.dragndrop" defaultMessage="Click or Drag 'n' drop your picture file here" />}
                    initialImage={this.props.image}
                    defaultImage={'/assets/img/profile-cover.png'}
                    saveFn={this.props.onClose}
                    saveFnArgs={[]}
                    closeFn={this.props.onClose}
                    avatarEditorProps={{
                        width: 545,
                        height: 150,
                        border: 0,
                        borderRadius:0
                    }}
                />
            </Rodal>
        )
    }

}


export default injectIntl(connect(null, {
})(ModalAddCover));
import './index.scss';
import "react-multi-carousel/lib/styles.css";
import React from 'react'
import history from '../../utils/routes/history';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Image } from 'react-bootstrap';
import HowItWorksMobile from './howItWorksImageMapMobile';
import HowItWorksDesktop from './howItWorksImageMapDesktop';
import SectionWhatWeOffer from './sectionWhatWeOffer';
import how1 from '../../assets/img/how-1.webp';
import how2 from '../../assets/img/how-2.webp';
import how3 from '../../assets/img/how-3.webp';
import how4 from '../../assets/img/how-4.webp';
import how5 from '../../assets/img/how-5.webp';
import join1 from '../../assets/img/join-1.webp';
import join2 from '../../assets/img/join-2.webp';
import join3 from '../../assets/img/join-3.webp';
import challenges1 from '../../assets/img/challenges-1.svg';
import challenges2 from '../../assets/img/challenges-2.svg';
import challenges3 from '../../assets/img/challenges-3.svg';
import partner1 from '../../assets/img/Partners/pro-bit.png';
import partner2 from '../../assets/img/Partners/kazoja.png';
import partner3 from '../../assets/img/Partners/minicom.png';
import partner4 from '../../assets/img/Partners/quama.png';
import partner5 from '../../assets/img/Partners/simora.png';
import partner6 from '../../assets/img/Partners/stroka.png';
import partner7 from '../../assets/img/Partners/fram.png';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Pulse from 'react-reveal/Pulse';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            days: '0',
            hours: '0',
            minutes: '0',
            seconds: '0',
            expired: false
        }
    }

    loadInterval;

    componentDidMount() {
        this.countdown();
    }

    componentWillUnmount() {
        clearInterval(this.loadInterval);
    }

    countdown() {
        let that = this;
        let countDownDate = new Date("Dec 31, 2022 00:00:00").getTime();

        this.loadInterval = setInterval(function () {
            let now = new Date().getTime();
            let distance = countDownDate - now;

            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            that.setState({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            })

            if (distance < 0) {
                clearInterval(this.loadInterval);
                /* this.setState({ expired: true }); */
            }
        }, 1000);
    }

    render() {
        return (
            <>

                {/* HEADER DESKTOP */}
                <Fade>
                    <div className="jumbo-landing">
                        <Container>
                            <div className="jumbo-landing-content">
                                <Fade bottom cascade>
                                    <div>
                                        <div className="jumbo-landing-title">
                                            <FormattedMessage id="home.header_title" defaultMessage="More than just a freelancing portal" />
                                        </div>
                                        <div className="jumbo-landing-subtitle"><FormattedMessage id="home.header_subtitle" defaultMessage="With the help of blockchain we provide verified proof of skills, enforce payment discipline, ensure quality audits and mediate any disputes." /></div>
                                        <div className="jumbo-landing-actions">
                                            <div className="btn btn-cta btn-developers-inv" onClick={() => history.push('/browse/freelancers')}><FormattedMessage id="home.header_employer" defaultMessage="I am an employer" /></div>
                                            <div className="btn btn-cta btn-developers" onClick={() => history.push('/browse/tasks')}><FormattedMessage id="home.header_freelancer" defaultMessage="I am a freelancer" /></div>
                                        </div>
                                        <Link className="jumbo-landing-link" to="/browse/educational-institutions"><FormattedMessage id="home.header_edu" defaultMessage="I am an educational institution" /></Link>
                                    </div>
                                </Fade>
                            </div>
                        </Container>
                    </div>
                </Fade>

                {/* HEADER MOBILE */}
                <Container>
                    <Fade>
                        <div className="mobile-landing-content">
                            <Fade bottom cascade>
                                <div>
                                    <div className="jumbo-landing-title">
                                        <FormattedMessage id="home.header_title" defaultMessage="More than just a freelancing portal" />
                                    </div>
                                    <div className="jumbo-landing-subtitle"><FormattedMessage id="home.header_subtitle" defaultMessage="With the help of blockchain we provide verified proof of skills, enforce payment discipline, ensure quality audits and mediate any disputes." /></div>
                                    <div className="jumbo-landing-actions">
                                        <div className="btn btn-cta btn-developers-inv mb-3" onClick={() => history.push('/browse/freelancers')}><FormattedMessage id="home.header_employer" defaultMessage="I am an employer" /></div>
                                        <div className="btn btn-cta btn-developers mb-3" onClick={() => history.push('/browse/tasks')}><FormattedMessage id="home.header_freelancer" defaultMessage="I am a freelancer" /></div>
                                    </div>
                                    <Link className="jumbo-landing-link" to="/browse/educational-institutions"><FormattedMessage id="home.header_edu" defaultMessage="I am an educational institution" /></Link>
                                </div>
                            </Fade>
                        </div>
                    </Fade>
                </Container>

                {/* COUNTDOWN */}
                {!this.state.expired &&
                    <div className="jumbo-getintouch text-center mt-5">
                        <Container>
                            <Bounce bottom cascade>
                                <div>
                                    <div className="countdown-title">
                                        <h2><FormattedMessage id="home.countdown_title" defaultMessage="Coming Q4 2022" /></h2>
                                    </div>
                                    <div className="countdown-cont">
                                        <ul>
                                            <li><span>{this.state.days}</span> <FormattedMessage id="common.days" defaultMessage="Days" /></li>
                                            <li><span>{this.state.hours}</span> <FormattedMessage id="common.hours" defaultMessage="Hours" /></li>
                                            <li><span>{this.state.minutes}</span> <FormattedMessage id="common.minutes" defaultMessage="Minutes" /></li>
                                            <li><span>{this.state.seconds}</span> <FormattedMessage id="common.seconds" defaultMessage="Seconds" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </Bounce>
                        </Container>
                    </div>
                }

                {/* WHAT WE OFFER */}
                <SectionWhatWeOffer />

                {/* HIRE OR GET HIRED */}
                <div className="jumbo-hired text-center">
                    <Container>
                        <Zoom bottom cascade>
                            <div>
                                <div className="jumbo-hired-subtitle mb-4"><FormattedMessage id="home.hire_subtitle" defaultMessage="Are you tired of the middle man slowing things down, worries about getting paid and stress about the quality of work?" /></div>
                                <div className="jumbo-hired-title"><FormattedMessage id="home.hire_title" defaultMessage="Hire or get hired today!" /></div>
                                <div className="btn btn-default btn-trans btn-jumbo" onClick={() => history.push('/auth/register')}><FormattedMessage id="nav.labels.register" defaultMessage="Sign Up" /></div>
                            </div>
                        </Zoom>
                    </Container>
                </div>

                {/* THE CHALLENGES WE SAW */}
                <Container>
                    <div className="section-title"><FormattedMessage id="home.challenges_title" defaultMessage="The challenges we saw" /></div>
                    <div className="section-challenges">
                        <Row>
                            <Col xs={12} lg={6}>
                                <Fade left>
                                    <div>
                                        <div className="challenges-who"><FormattedMessage id="home.challenges_companies" defaultMessage="AS COMPANIES" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_companies_txt" defaultMessage="How do you find credible future employees or freelancers and hire them with confidence?" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_companies_txt2" defaultMessage="By making background checks obsolete, with WNS you get what you see." /></div>
                                        <div className="btn btn-cta btn-default btn-developers-inv d-none d-lg-block" onClick={() => history.push('/browse/freelancers')}><FormattedMessage id="common.browse_freelancers" defaultMessage="Browse freelancers" /></div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} lg={6} className="challenges-img">
                                <Fade right>
                                    <div>
                                        <Image fluid="true" src={challenges1} alt="Browse freelancers"></Image>
                                        <div className="btn btn-cta btn-default btn-developers-inv d-block d-lg-none mt-4 w-100" onClick={() => history.push('/browse/freelancers')}><FormattedMessage id="common.browse_freelancers" defaultMessage="Browse freelancers" /></div>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6} className="d-none d-lg-block challenges-img">
                                <Fade left>
                                    <div>
                                        <Image fluid="true" src={challenges2} alt="Browse tasks"></Image>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Fade right>
                                    <div>
                                        <div className="challenges-who"><FormattedMessage id="home.challenges_freelancers" defaultMessage="AS FREELANCERS & JOB SEEKERS" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_freelancers_txt" defaultMessage="How do you build a unified and credible CV?" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_freelancers_txt2" defaultMessage="By completing tasks and building a comprehensive skill deck and taking your freelancing career to the next level." /></div>
                                        <div className="btn btn-cta btn-default btn-developers-inv d-none d-lg-block" onClick={() => history.push('/browse/tasks')}><FormattedMessage id="common.browse_tasks" defaultMessage="Browse tasks" /></div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} lg={6} className="d-block d-lg-none challenges-img">
                                <Fade right>
                                    <div>
                                        <Image fluid="true" src={challenges2} alt="Browse tasks"></Image>
                                        <div className="btn btn-cta btn-default btn-developers-inv mt-4 w-100" onClick={() => history.push('/browse/tasks')}><FormattedMessage id="common.browse_tasks" defaultMessage="Browse tasks" /></div>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Fade left>
                                    <div>
                                        <div className="challenges-who"><FormattedMessage id="home.challenges_edus" defaultMessage="AS EDUCATIONAL INSTITUTIONS" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_edus_txt" defaultMessage="How do you make sure your educational programs are meeting the needs of todays markets?" /></div>
                                        <div className="challenges-txt"><FormattedMessage id="home.challenges_edus_txt2" defaultMessage="By becoming a part of our growing ecosystem as a recognized issuer of Educational Credentials. Measure and track the market success of your degrees." /></div>
                                        <div className="btn btn-cta btn-default btn-developers-inv d-none d-lg-block" onClick={() => history.push('/contact')}><FormattedMessage id="footer.contact" defaultMessage="Contact us" /></div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} lg={6} className="challenges-img">
                                <Fade right>
                                    <div>
                                        <Image fluid="true" src={challenges3} alt="Contact us"></Image>
                                        <div className="btn btn-cta btn-default btn-developers-inv d-block d-lg-none mt-4 w-100" onClick={() => history.push('/contact')}><FormattedMessage id="footer.contact" defaultMessage="Contact us" /></div>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Container>

                {/* SOLUTION FOR ALL */}
                <div className="jumbo-solution pt-5">
                    <Fade bottom>
                        <div className="solution-img">
                            <Container>
                                <Fade bottom cascade>
                                    <div className="solution-txt white">
                                        <div className="solution-title white mb-5"><FormattedMessage id="home.solution_title" defaultMessage="We built a solution for all" /></div>
                                        <div className="solution-subtitle mb-5">
                                            <FormattedMessage id="home.solution_subtitle" values={{
                                                b: chunks => (<strong>{chunks}</strong>),
                                                n: () => { "<br /><br />" },
                                            }} defaultMessage={`In order to build a credibility network we used a unit that all companies, job seekers and educational institutions share with each other - <bold>a verified Skill.</bold><nl><nl>A Skill uniquely describes certain knowledge and by building a unified registry for skills we ensure that all parties use a unified language regarding the skills required for a particular project or job.`} />
                                        </div>
                                        <div className="btn btn-cta btn-default btn-developers-inv" onClick={() => history.push('/skills')}><FormattedMessage id="browse.browse_skills" defaultMessage="Browse skills" /></div>
                                    </div>
                                </Fade>
                            </Container>
                        </div>
                    </Fade>
                </div>

                {/* HOW IT WORKS */}
                <Container className="d-none d-sm-block">
                    <Fade bottom>
                        <div>
                            <div className="section-title"><FormattedMessage id="home.howitworks_title" defaultMessage="How it works" /></div>
                            <div className="section-subtitle">
                                <FormattedMessage id="home.howitworks_subtitle" defaultMessage="Trustless, permissionless and free of middlemen." />
                            </div>
                        </div>
                    </Fade>
                    <div className="section-how-it-works text-center">
                        <HowItWorksDesktop />
                    </div>

                </Container>
                <div className="d-block d-sm-none">
                    <Fade bottom>
                        <div>
                            <div className="section-title"><FormattedMessage id="home.howitworks_title" defaultMessage="How it works" /></div>
                            <div className="section-subtitle"><FormattedMessage id="home.howitworks_subtitle" defaultMessage="Trustless, permissionless and free of middlemen." /></div>
                        </div>
                    </Fade>
                    <div className="section-how-it-works-mb text-center mt-4">
                        <HowItWorksMobile />
                    </div>

                </div>

                {/* CONTACT US */}
                <div className="jumbo-getintouch text-center mt-5">
                    <Container>
                        <Pulse cascade>
                            <div>
                                <div className="jumbo-getintouch-subtitle mb-4"><FormattedMessage id="home.contact_title" defaultMessage="Have questions?" /></div>
                                <div className="jumbo-getintouch-title"><FormattedMessage id="home.contact_subtitle" defaultMessage="Get in touch with us" /></div>
                                <div className="btn btn-default btn-trans btn-jumbo" onClick={() => history.push('/contact')}><FormattedMessage id="home.contact_action" defaultMessage="CONTACT US" /></div>
                            </div>
                        </Pulse>
                    </Container>
                </div>

                {/* HOW DO WE ACHIEVE IT */}
                <Container className="section-how-we-achieve-it">

                    <div className="section-title"><FormattedMessage id="home.how_title" defaultMessage="How do we achieve it" /></div>
                    <div className="section-how-we-do-it text-center">
                        <Row className="text-center mb-2">


                            <Col xs={12} lg={4} className="mb-5">
                                <Flip left cascade>
                                    <div>
                                        <Image src={how1} className="how-img mb-4" alt="Community based inputs"></Image>
                                        <div className="title-20-bold"><FormattedMessage id="home.how_1_title" defaultMessage="Community based inputs" /></div>
                                        <div className="text-18 mt-2"><FormattedMessage id="home.how_1_txt" defaultMessage="We give the opportunity to everybody to upload their definition of a skill." /></div>
                                        <div className="jumbo-link" onClick={() => history.push('/skills')}><FormattedMessage id="common.more" defaultMessage="More..." /></div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col xs={12} lg={4} className="mb-5">
                                <Flip left cascade>
                                    <div>
                                        <Image src={how2} className="how-img mb-4" alt="Voting system"></Image>
                                        <div className="title-20-bold"><FormattedMessage id="home.how_2_title" defaultMessage="Voting system" /></div>
                                        <div className="text-18 mt-2"><FormattedMessage id="home.how_2_txt" defaultMessage="Upvote or downvote skill definitions." /></div>
                                        <div className="jumbo-link" onClick={() => history.push('/skills')}><FormattedMessage id="common.more" defaultMessage="More..." /></div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col xs={12} lg={4} className="mb-5">
                                <Flip left cascade>
                                    <div>
                                        <Image src={how3} className="how-img mb-4" alt="Use of Blockchain technology"></Image>
                                        <div className="title-20-bold"><FormattedMessage id="home.how_3_title" defaultMessage="Use of Blockchain technology" /></div>
                                        <div className="text-18 mt-2"><FormattedMessage id="home.how_3_txt" defaultMessage="Our community driven universal registry of skills is blockchain backed." /></div>
                                        <div className="jumbo-link" onClick={() => history.push('/help')}><FormattedMessage id="common.more" defaultMessage="More..." /></div>
                                    </div>
                                </Flip>
                            </Col>

                        </Row>
                        <Row className="text-center mt-3">

                            <Col xs={12} lg={6} className="mb-5">
                                <Flip left cascade>
                                    <div>
                                        <Image src={how4} className="how-img mb-4" alt="Auditors"></Image>
                                        <div className="title-20-bold"><FormattedMessage id="home.how_4_title" defaultMessage="Auditors" /></div>
                                        <div className="text-18 mt-2"><FormattedMessage id="home.how_4_txt" defaultMessage="A neutral auditor can be invited to evaluate work and resolve any disputes." /></div>
                                        <div className="jumbo-link" onClick={() => history.push('/help')}><FormattedMessage id="common.more" defaultMessage="More..." /></div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col xs={12} lg={6} className="mb-5">
                                <Flip left cascade>
                                    <div>
                                        <Image src={how5} className="how-img mb-4" alt="Use of smart contracts"></Image>
                                        <div className="title-20-bold"><FormattedMessage id="home.how_5_title" defaultMessage="Use of Smart Contracts" /></div>
                                        <div className="text-18 mt-2"><FormattedMessage id="home.how_5_txt" defaultMessage="All disputes are resolved and payments are made using smart contracts." /></div>
                                        <div className="jumbo-link" onClick={() => history.push('/help')}><FormattedMessage id="common.more" defaultMessage="More..." /></div>
                                    </div>
                                </Flip>
                            </Col>

                        </Row>
                    </div>

                </Container>

                {/* JOIN US */}
                <div className="jumbo-joinus text-center">
                    <Container>
                        <Fade bottom cascade>
                            <div className="jumbo-joinus-title mb-5"><FormattedMessage id="home.joinus_title" defaultMessage="Join us and contribute" /></div>
                        </Fade>
                        <Row>
                            <Col xs={12} md={4} className="mb-4">
                                <Fade bottom cascade>
                                    <div>
                                        <Image src={join1} className="join-us-img" alt="Create an account"></Image>
                                        <div className="text-18 white mt-4"><FormattedMessage id="home.joinus_1" defaultMessage="Create an account" /></div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Fade bottom cascade>
                                    <div>
                                        <Image src={join2} className="join-us-img" alt="Enter skills or vote existing"></Image>
                                        <div className="text-18 white mt-4"><FormattedMessage id="home.joinus_2" defaultMessage="Enter missing skills in our register or vote existing ones" /></div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Fade bottom cascade>
                                    <div>
                                        <Image src={join3} className="join-us-img" alt="Set up your profile"></Image>
                                        <div className="text-18 white mt-4"><FormattedMessage id="home.joinus_3" defaultMessage="Set up your profile" /></div>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Fade bottom cascade>
                            <div className="btn btn-default btn-trans btn-jumbo mt-5" onClick={() => history.push('/auth/register')}><FormattedMessage id="nav.labels.register" defaultMessage="Sign Up" /></div>
                        </Fade>
                    </Container>
                </div>


                {/* PARTNERS */}
                <div className="section-title"><FormattedMessage id="home.partners_title" defaultMessage="Companies and organizations that support us" /></div>
                <div className="mb-5">
                    <Container>
                        <Carousel
                            responsive={responsive}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            infinite={true}
                            draggable={false}
                            arrows={false}>
                            <div className="landing-partner"><a href="https://www.pro-bit.si/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner1} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.kazoja.si/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner2} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.minicom.si/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner3} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.quama.si/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner4} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.rk-smz.hr/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner5} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.stroka.si/" target="_blank" rel="noopener noreferrer"><Image className="partner-img paint-to-black" src={partner6} alt="Partner logo" /></a></div>
                            <div className="landing-partner"><a href="https://www.framcreativesolutions.com/" target="_blank" rel="noopener noreferrer"><Image className="partner-img" src={partner7} alt="Partner logo" /></a></div>
                        </Carousel>
                    </Container>
                </div>
            </>
        )
    }
}

export default injectIntl(connect(null, {})(LandingPage));

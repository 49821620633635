import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalMintCompetencies.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Image, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { createErrorMessageSelector, createLoadingSelector } from '../../API/selector';
import { GetMyCompetencesMintStart, ChangeCompetencesMintFiltersStart, UpdateCompetencesMintStart, GetEstimationCompetenceMintStart } from '../../core/actions/blockchain/competences/index';
import _ from 'lodash';
import WalletBuyButton from '../../containers/Wallet/buy';
import history from '../../utils/routes/history';
import { apiCon } from '../../API';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';

const customStyles = {
    height: 'fit-content',
    maxHeight: '70%',
    bottom: 'auto',
    top: '10%',
    overflow: 'auto'
};

class ModalMintCompetencies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            competencesToBeMinted: [],
            competencesMintCost: [],
            totalMintCost: 0,
            step: 1,
            estimation: null,
            spinnerActive: true,
            responseLoaded: []
        };
    }

    getEstimatedTransferCost() {
        const url = '/me/competence/claim/estimation/'
        this.props.competencesMint.forEach(element => {
            const params = {
                user_competence: element.id
            }
            return apiCon.post(url, params).then(response => {
                this.setState({ competencesMintCost: [...this.state.competencesMintCost, response.data] })
                this.setState({ responseLoaded: [...this.state.responseLoaded, response.data.id] })
            })
                .catch(err => {
                    process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
                })
        });
        this.setState({
            spinnerActive: false
        })
    }

    async init() {
        try {
            await this.props.GetMyCompetencesMintStart({ unclaimed_only: true });
            this.getEstimatedTransferCost();
        }
        catch (err) {
            console.log(err);
        }
    }

    componentDidMount() {
        this.init();
    }

    hide() {
        this.props.onClose();
        this.props.GetMyCompetencesMintStart();
    }

    save() {
        this.state.competencesToBeMinted.map(e =>
            this.props.UpdateCompetencesMintStart({
                user_competence: e
            })
        )
        this.setState({
            step: 3
        })
    }

    CheckBoxChange = (e) => {
        var competence_id = parseInt(e.target.id);
        var checked = e.target.checked;
        var comp = this.state.competencesToBeMinted;
        if (checked === true) {
            comp.push(competence_id);
            this.setState({
                competencesToBeMinted: comp
            }, () => {
                this.totalMintCost();
            })
        }
        else if (checked === false) {
            var index = comp.indexOf(competence_id)
            if (index !== -1) {
                comp.splice(index, 1)
                this.setState({
                    competencesToBeMinted: comp
                }, () => {
                    this.totalMintCost();
                })
            }
        }
        // console.log("comp id: " + competence_id);
    }

    totalMintCost() {
        let totalCost = 0;
        this.state.competencesMintCost.map(x => this.state.competencesToBeMinted.map(c => {
            if (c === x.id) {
                totalCost = totalCost + parseFloat(x.estimation_eth_cost)
            }
            return null;
        }))
        this.setState({ totalMintCost: totalCost })
    }

    renderCompetencesOptions(competences) {
        if (_.isEmpty(competences)) {
            return <>
                <div className="mint-competencies-list">
                    <span style={{ 'fontWeight': 'bold', 'fontSize': '18px' }}><FormattedMessage id="competencies_mint.no_skills" defaultMessage="There are no skills to be minted" /></span>
                </div>
            </>
        }
        return (
            <div className="mint-competencies-list">
                <Row className="mint-competencies-header-row">
                    <Col className="mint-left-col">
                        <span className="mint-header-column"><FormattedMessage id="competencies_mint.skill_name" defaultMessage="Skill name" /></span>
                    </Col>
                    <Col className="mint-right-col">
                        <span className="mint-header-column"><FormattedMessage id="competencies_mint.estimated_cost" defaultMessage="Estimated cost" /></span>
                    </Col>
                </Row>

                {competences?.map((c, index) =>
                    <Row key={index} className="mint-competencies-row">
                        <Col className="mint-left-col">
                            <Form.Check className="mint-checkbox" inline id={c.id} type="checkbox" onChange={this.CheckBoxChange} />
                            <span className="mint-competency-name">{c.issuer_competence_name}</span>
                        </Col>
                        <Col className="mint-right-col">
                            <span className="mint-competency-cost">
                                {this.state.responseLoaded.includes(c.id) ?
                                    this.state.competencesMintCost.map(comp =>
                                        comp.id === c.id && <span>{parseFloat(comp.estimation_eth_cost.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 }))} {REACT_APP_NETWORK_CURRENCY()}</span>
                                    )
                                    :
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                }
                            </span>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    renderDisabledCompetencesOptions(competences) {
        if (_.isEmpty(competences)) {
            return <>
                <div className="mint-competencies-list">
                    <span style={{ 'fontWeight': 'bold', 'fontSize': '18px' }}><FormattedMessage id="competencies_mint.no_skills" defaultMessage="There are no skills to be minted" /></span>
                </div>
            </>
        }

        return (
            <div className="mint-competencies-list">
                <Row className="mint-competencies-header-row">
                    <Col className="mint-left-col">
                        <span className="mint-header-column"><FormattedMessage id="competencies_mint.skill_name" defaultMessage="Skill name" /></span>
                    </Col>
                    <Col className="mint-right-col">
                        <span className="mint-header-column"><FormattedMessage id="competencies_mint.estimated_cost" defaultMessage="Estimated cost" /></span>
                    </Col>
                </Row>

                {competences?.map((c, index) =>
                    <Row key={index} className="mint-competencies-row">
                        <Col className="mint-left-col">
                            <Form.Check className="mint-checkbox" inline id={c.id} type="checkbox" onChange={this.CheckBoxChange} disabled={true} />
                            <span className="mint-competency-name">{c.issuer_competence_name}</span>
                        </Col>
                        <Col className="mint-right-col">
                            <span className="mint-competency-cost">{this.state.competencesMintCost.map(comp =>
                                comp.id === c.id && <span>{comp.estimation_eth_cost.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} {REACT_APP_NETWORK_CURRENCY()}</span>
                            )}
                            </span>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    renderCompetenciesFinal(competences) {
        const { balances, cryptoETH, cryptoBNB, currency } = this.props;
        if (_.isEmpty(competences)) {
            return <></>
        }
        return (
            <>
                <div className="mint-competencies-list">
                    <Row className="mint-competencies-header-row">
                        <Col className="mint-left-col">
                            <span className="mint-header-column"><FormattedMessage id="competencies_mint.skill_name" defaultMessage="Skill name" /></span>
                        </Col>
                        <Col className="mint-right-col">
                            <span className="mint-header-column"><FormattedMessage id="competencies_mint.estimated_cost" defaultMessage="Estimated cost" /></span>
                        </Col>
                    </Row>

                    {competences?.map((c, index) =>
                        <Row key={index} className="mint-competencies-row">
                            <Col className="mint-left-col">
                                {this.props.competencesMint.map(e =>
                                    c === e.id &&
                                    <span className="mint-competency-name">{e.issuer_competence_name}</span>
                                )
                                }
                            </Col>
                            <Col className="mint-right-col">
                                <span className="mint-competency-cost">{this.state.competencesMintCost.map(comp =>
                                    comp.id === c && <span>{comp.estimation_eth_cost.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} {REACT_APP_NETWORK_CURRENCY()}</span>
                                )} </span>
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="modal-body p-0 pt-2 mint-modal-body-confirmation">
                    <div className="mint-competencies-list">
                        <Row className="mint-total-row">
                            <Col className="mint-left-col">
                                <div className="mint-total-content">
                                    <span className="mint-total-text"><FormattedMessage id="competencies_mint.total_estimated_cost" defaultMessage="Total estimated cost" /></span>
                                    <span className="mint-help-bottom"><FormattedMessage id="competencies_mint.total_estimated_cost_explanation" defaultMessage="The Total Estimated Cost calculates the overall price for minting the selected skills" /></span>
                                </div>
                            </Col>
                            <Col className="mint-right-col">
                                <div className="mint-total-content">
                                    <span className="mint-total-value">{parseFloat(this.state.totalMintCost).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} {REACT_APP_NETWORK_CURRENCY()}</span>
                                    <span className="mint-help-bottom">≈ {currency === "usd" ?
                                        ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * this.state.totalMintCost).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " USD*"
                                        : ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * this.state.totalMintCost).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " EUR*"}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="mint-modal-footer">
                    <div>
                        <span className="mint-modal-confirm-labels"><FormattedMessage id="competencies_mint.current_balance" defaultMessage="Current balance" /></span>
                        <div className="mint-modal-confirm-box">
                            <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? "ethereum.png" : "bnb.png")} className="dw-crypto-logo-eth" />
                            <div className="crypto-label">
                                <span >{REACT_APP_NETWORK_CURRENCY()} </span>
                                <span className="gray-label">{REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'Ethereum' : 'Binance Coin'}</span>
                            </div>
                            <span className="crypto-balance-eth">{Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</span>
                            <span className="mint-estimated-fiat">≈ {currency === "usd" ?
                                ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " USD*"
                                : ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " EUR*"}
                            </span>
                        </div>
                    </div>
                    <div className="mint-modal-arrow"><Image className="mint-arrow" src="/assets/img/icon/right-arrow-mint.svg" /></div>
                    <div>
                        <span className="mint-modal-confirm-labels"><FormattedMessage id="competencies_mint.after_mint" defaultMessage="After minting" /></span>
                        <div className="mint-modal-confirm-box">
                            <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'ethereum.png' : 'bnb.png')} className="dw-crypto-logo-eth" />
                            <div className="crypto-label">
                                <span >{REACT_APP_NETWORK_CURRENCY()} </span>
                                <span className="gray-label">{REACT_APP_NETWORK_CURRENCY() === 'ETH' ? ' Ethereum' : ' Binance Coin'}</span>
                            </div>
                            {/* change -9 to estimated cost */}
                            <span className="crypto-balance-eth">{Number(parseFloat(balances?.ETH - this.state.totalMintCost)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</span>
                            <span className="mint-estimated-fiat">≈ {currency === "usd" ?
                                ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * (balances?.ETH - this.state.totalMintCost)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " USD*"
                                : ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * (balances?.ETH - this.state.totalMintCost)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " EUR*"}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mint-buttons">
                    <button className="mint-final-confirm-button" onClick={() => this.save()}><FormattedMessage id="competencies_mint.mint_your_skills" defaultMessage="Mint your skills" /></button>
                </div>
            </>
        )
    }

    continue() {
        if (this.state.step === 1 && this.state.competencesToBeMinted.length > 0) {
            this.setState({
                step: 2
            })
        }
        else if (this.state.step === 2) {
            this.setState({
                step: 3
            })
        }
    }

    render() {
        const { visible, balances, currency, cryptoETH, cryptoBNB, competencesMint } = this.props;
        const estimatedCost = 0.001;
        console.log(this.state.responseLoaded)
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={860}
                customStyles={customStyles}
                closeMaskOnClick={false}
                className="rodal-dialog-mint-competencies"
            >
                {/* <div className="modal-guts" style={{ "padding": "10px" }}> */}
                    <div className="modal-title">
                        <div className="mint-modal-title">
                            {this.state.step === 1 && <>
                                <span className="mint-main-title"><FormattedMessage id="competencies_mint.mint_available_skills" defaultMessage="Mint available skills" /></span>
                                <span className="mint-under-title"><FormattedMessage id="competencies_mint.skills_supported" defaultMessage="Skills that are supported by the project owners to verify the freelancer's experience at the particular skill. For more information " /><Link to="/help" className="link-blue"><FormattedMessage id="common.click_here" defaultMessage="click here" /></Link></span>
                                {balances.ETH > estimatedCost ?
                                    <>
                                        <span className="mint-available-title"><FormattedMessage id="competencies_mint_available_balance" defaultMessage="Available balance" /></span>
                                        <div className="cr-row mt-2">
                                            <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'icon/ethereum.svg' : 'bnb.png')} className="wallet-crypto-logo" />
                                            <div className="crypto-content">
                                                <span className="crypto-balance-eth">{Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</span>
                                                <span className="currency-label"> {REACT_APP_NETWORK_CURRENCY()}</span>
                                                <span className="crypto-converted">
                                                    {currency === "usd" && " ≈ " + ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " $"}
                                                    {currency === "eur" && " ≈ " + ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " €"}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span className="mint-low-balance-title"><FormattedMessage id="competencies.mint-low-balance-title" defaultMessage="Not enouh wallet balance" /></span>
                                        <div className="cr-row mt-2">
                                            <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'icon/ethereum.svg' : 'bnb.png')} className="wallet-crypto-logo" />
                                            <div className="crypto-content">
                                                <span className="crypto-balance-eth">{Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</span>
                                                <span className="currency-label"> {REACT_APP_NETWORK_CURRENCY()}</span>
                                                <span className="crypto-converted">
                                                    {currency === "usd" && " ≈ " + ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " $"}
                                                    {currency === "eur" && " ≈ " + ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * balances?.ETH).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " €"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mint-low-block">
                                            <span className="mint-low-text">{REACT_APP_NETWORK_CURRENCY() === 'ETH' ? <FormattedMessage id="competencies_mint.insuficient_funds_eth" defaultMessage="Unfortunately your wallet balance is not enoguh to continue with minting your skills. If you wish you can either buy or deposit ETH into your wallet in order to continue." /> : <FormattedMessage id="competencies_mint.insuficient_funds_bnb" defaultMessage="Unfortunately your wallet balance is not enoguh to continue with minting your skills. If you wish you can either buy or deposit BNB into your wallet in order to continue." />}</span>
                                        </div>
                                        <div className="mint-buttons-block">
                                            <WalletBuyButton />
                                            <button className="btn mint-deposit-button" onClick={() => history.push("/deposit-withdraw")}>Deposit</button>
                                        </div>
                                    </>
                                }
                            </>
                            }
                            {this.state.step === 2 &&
                                <span className="mint-main-title"><FormattedMessage id="competencies_mint.confirm" defaultMessage="Confirm minting skills" /></span>
                            }
                        </div>
                    </div>
                    {(this.state.step === 1 && balances.ETH > estimatedCost) ?
                        <>
                            <div className="modal-body p-0 pt-2 mint-modal-body">
                                {this.renderCompetencesOptions(competencesMint)}
                            </div>
                            {competencesMint.length === 0 ? <></>
                                : <>
                                    <div className="mint-competencies-list">
                                        <Row className="mint-total-row">
                                            <Col className="mint-left-col">
                                                <div className="mint-total-content">
                                                    <span className="mint-total-text"><FormattedMessage id="competencies_mint.total_estimated_cost" defaultMessage="Total estiamted cost" /></span>
                                                    <span className="mint-help-bottom"><FormattedMessage id="competencies_mint.total_estimated_cost_explanation" defaultMessage="The Total Estimated Cost calculates the overall price for minting the selected skills" /></span>
                                                </div>
                                            </Col>
                                            <Col className="mint-right-col">
                                                <div className="mint-total-content">
                                                    <span className="mint-total-value">{this.state.totalMintCost.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} {REACT_APP_NETWORK_CURRENCY()}</span>
                                                    <span className="mint-help-bottom">≈ {currency === "usd" ?
                                                        ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd : cryptoBNB?.usd) * this.state.totalMintCost).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " USD*"
                                                        : ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur : cryptoBNB?.eur) * this.state.totalMintCost).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " EUR*"}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mint-buttons">
                                        <button type="submit" className="btn btn-default mint-continue-button mt-2 mb-2" onClick={() => this.continue()}>
                                            <FormattedMessage id="forms.labels.continue" defaultMessage="Continue" />
                                        </button>
                                    </div>
                                </>
                            }
                        </>
                        : (this.state.step === 1 && balances.ETH < estimatedCost) &&
                        <div className="modal-body p-0 pt-2 mint-modal-body-disabled">{this.renderDisabledCompetencesOptions(competencesMint)}</div>
                    }
                    {this.state.step === 2 &&
                        this.renderCompetenciesFinal(this.state.competencesToBeMinted)
                    }
                    {this.state.step === 3 &&
                        <div className="mint-modal-title" style={{ 'borderBottom': 'none' }}>
                            <span className="mint-completed-title"><FormattedMessage id="competencies.mint-title" defaultMessage="You've successfully minted your skills!" /></span>
                            <span className="mint-under-title"><FormattedMessage id="competencies.mint-under-title" defaultMessage="Great job! The skills are successfully summitted for minting, however it will take some time for the blockchain to confirm the minting. We will notify you when everything is ready." /></span>
                            <Image className="mint-image" src="/assets/img/icon/mint-image.png" />
                            <div className="mint-buttons">
                                <button className="btn btn-default mint-continue-button mt-2 mb-2" onClick={() => history.push("/profile")}>
                                    <FormattedMessage id="mint.profile-page" defaultMessage="Go to profile page" />
                                </button>
                            </div>
                        </div>
                    }
                {/* </div> */}
            </Rodal >
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_COMPETENCES_MINT']);
const errorSelector = createErrorMessageSelector(['GET_COMPETENCES_MINT']);
const mapStateToProps = state => {
    return {
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        balances: state.user.wallet.balances,
        currency: state.currency,
        cryptoETH: state.crypto.ethereum,
        cryptoBNB: state.crypto.bnb,
        competencesMint: state.user.info.competencesMint.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyCompetencesMintStart,
    ChangeCompetencesMintFiltersStart,
    UpdateCompetencesMintStart,
    GetEstimationCompetenceMintStart
})(ModalMintCompetencies));
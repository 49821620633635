import { CHANGE_LANG } from './types';
import { changeLanguage } from './../../../utils/languageService';
import { changeLanguageHeader } from '../../../API';
import { UpdateRoleStart } from '../role';
import store from '../../store';


const SetLocalStorageLang = lang => localStorage.setItem('lang', lang);

export const ChangeLangAction = (lang, isAuthenticated = false) => {
    SetLocalStorageLang(lang);
    changeLanguage(lang);
    changeLanguageHeader(lang);
    if (isAuthenticated) { store.dispatch(UpdateRoleStart({language: lang},  0, false)); }
    return {
        type: CHANGE_LANG,
        payload: lang
    }
}
import React from 'react';
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom';

import LoginForm from '../../containers/Auth/Login';
import DashboardPage from '../../containers/Dashboard';
import RegisterRolePage from '../../containers/Auth/Register/RegisterRole';


const PrivateRoute = ({ component: Component, isAuthenticated, isInitialized, isEmailVerified, requiredRole, activeRole, ...rest }) => {

    const isEDU = (r) => {
        if (['EDU', 'EAP', 'EAS', 'EAC'].includes(r)) { return true; }
        return false;
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (!isInitialized)
                    return ("")
                    // return <Redirect to="/" />
                if (isInitialized && !isAuthenticated) {
                    if (Component !== LoginForm)
                        return <Redirect to="/auth/login" />
                } else {
                    if (isEmailVerified === false) {
                        if (Component !== DashboardPage) {
                            return <Redirect to="/dashboard" />
                        }
                    } else if (activeRole?.id) {
                        if (requiredRole) {
                            if ((requiredRole === activeRole.type) || (requiredRole === 'EDU' && isEDU(activeRole.type))) {
                                // do nothing...
                            } else {
                                if (Component !== DashboardPage)
                                    return <Redirect to="/dashboard" />
                            }
                        }
                    } else {
                        if (Component !== RegisterRolePage)
                            return <Redirect to="/auth/register-role" />
                    }
                    return <Component {...props} />
                }
            }
            }
        />
    );
}

export { PrivateRoute };
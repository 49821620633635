import * as types from './types';
import { apiCon } from '../../../API';
import { INITIAL_STATE_FILTERS as INITIAL_TRANSACTIONS_FILTERS } from '../../reducers/user/userWalletReducer';
import { getParameterObject } from '../../../utils/common';


//----------------------
// Actions Get My Balances
//----------------------
export const GetMyBalancesStart = () => async dispatch => {
    dispatch({ type: types.GET_BALANCES_REQUEST });
    await apiCon.get('/me/balance/')
        .then(response => {
            dispatch(GetMyBalancesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyBalancesFail(err.response));
        })
}

export const GetMyBalancesSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my balances info.", data }
    dispatch({ type: types.GET_BALANCES_SUCCESS, payload: payload.data });
}

export const GetMyBalancesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my balances failed."
    if (err.data.error_first_message) { toast_msg = "Get my balances failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_BALANCES_FAIL, payload: payload };
}

const ALLOWED_TRANSACTIONS_FILTERS = ['limit'];
//----------------------
// Actions Change Transactions List Filters 
//----------------------
export const ChangeTransactionsFiltersStart = (params = INITIAL_TRANSACTIONS_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_TRANSACTIONS_FILTERS);
    const payload = { succ_msg: "Successfully changed transactions filters.", filters: filters }
    return { type: types.CHANGE_TRANSACTIONS_FILTERS, payload: payload };
}

//----------------------
// Actions Get My Transactions
//----------------------
export const GetMyTransactionsStart = (params = INITIAL_TRANSACTIONS_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_TRANSACTIONS_REQUEST });
    var url = '/me/wallet/transactions/';
    let filters = getParameterObject(params, ALLOWED_TRANSACTIONS_FILTERS);
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMyTransactionsSuccess(response?.data));
        })
        .catch(err => {
            dispatch(GetMyTransactionsFail(err?.response));
        })
}

export const GetTransactionsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_TRANSACTIONS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyTransactionsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetMyTransactionsFail(err.response));
        })
}

export const GetMyTransactionsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received transactions info.", transactions: data, add: add }
    return { type: types.GET_TRANSACTIONS_SUCCESS, payload: payload };
}

export const GetMyTransactionsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Oooops! Get my transactions failed.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_TRANSACTIONS_FAIL, payload: payload };
}

//----------------------
// Action transfer
//----------------------

export const TransferMoneyStart = (values) => async dispatch => {
    dispatch({ type: types.TRANSFER_MONEY_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/wallet/transfer/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(TransferMoneySuccess(response.data));
        })
        .catch(err => {
            dispatch(TransferMoneyFail(err.response));
        })
}

export const TransferMoneySuccess = (data) => async dispatch => {
    const succ_msg = "Transaction submitted to processing. Check status in your wallet transactions.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.TRANSFER_MONEY_SUCCESS, payload: payload });
}

export const TransferMoneyFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Transaction failed." + err.data.error_first_message;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.TRANSFER_MONEY_FAIL, payload: payload };
}

//--------------------------------------
// Action get estimated cost of transfer
//--------------------------------------

export const GetEstimatedTransferCostStart = (values) => async dispatch => {
    dispatch({ type: types.ESTIMATED_TRANSFER_COST_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/wallet/transfer/estimation/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(GetEstimatedTransferCostSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetEstimatedTransferCostFail(err.response));
        })
}

export const GetEstimatedTransferCostSuccess = (data) => async dispatch => {
    const succ_msg = "Getting estimated transaction cost successfull.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.ESTIMATED_TRANSFER_COST_SUCCESS, payload: payload });
}

export const GetEstimatedTransferCostFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Getting estimated transaction cost failed." + err.data.error_first_message;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.ESTIMATED_TRANSFER_COST_FAIL, payload: payload };
}
//-------------------------------
// Action get settings for wallet
//-------------------------------

export const GetMyWalletSettingsStart = () => async dispatch => {
    dispatch({ type: types.GET_MY_WALLET_SETTINGS_REQUEST });
    var url = `/me/wallet/settings/`;
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyWalletSettingsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetMyWalletSettingsFail(err.response));
        })
}

export const GetMyWalletSettingsSuccess = (data) => {
    const payload = { succ_msg: "Succesfully received wallet settings info.", settings: data }
    return { type: types.GET_MY_WALLET_SETTINGS_SUCCESS, payload: payload };
}

export const GetMyWalletSettingsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Oooops! Get my wallet settings failed.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_WALLET_SETTINGS_FAIL, payload: payload };
}

//--------------------------
// Update my wallet settings
//--------------------------

export const UpdateMyWalletSettingsStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_MY_WALLET_SETTINGS_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/wallet/settings/`;
    const settings = { headers: { 'Content-type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateMyWalletSettingsSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateMyWalletSettingsFail(err.response));
        })
}

export const UpdateMyWalletSettingsSuccess = (data) => async dispatch => {
    const succ_msg = "Wallet settings were successfully changed.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.UPDATE_MY_WALLET_SETTINGS_SUCCESS, payload: payload });
}

export const UpdateMyWalletSettingsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Updating wallet settings failed." + err.data.error_first_message;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.UPDATE_MY_WALLET_SETTINGS_FAIL, payload: payload };
}
import * as types from './types';
import axios from 'axios';

//----------------------
// Actions Crypto
//----------------------

//----------------------
// Actions Get Crypto List
//----------------------
export const StartCryptoFeed = () => async dispatch => {
    dispatch({ type: types.GET_CRYPTO_REQUEST });

    var requestOptions = { method: 'GET', redirect: 'follow' };
      
    await axios
        .get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cethereum%2Cdai&vs_currencies=eur%2Cusd', requestOptions)
        .then(response => {
            dispatch(StartCryptoFeedSuccess(response.data));
        })
        .catch(err => {
            dispatch(StartCryptoFeedFail(err));
        })
}

export const StartCryptoFeedSuccess = (data) => {
    const payload = { succ_msg: "Successfully received crypto list.", ...data }
    return { type: types.GET_CRYPTO_DONE, payload: payload };
}

export const StartCryptoFeedFail = (err) => {
    const err_msg = { errorType: err.message, detail: err.message };
    // const toast_msg = "Ooops! Get crypto failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.GET_CRYPTO_FAILURE, payload: payload }
}
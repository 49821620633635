import React from 'react'
import store from '../../core/store';
import { FormattedMessage } from 'react-intl'


const TRANSLATION_NAME = 'validators';
// eslint-disable-next-line no-unused-vars
let props = store.getState(); // leave this.

const updateProps = () => {
    props = store.getState();
}

//TODO: wire messages to translations

export const required = value => {
    updateProps();
    if (!value || value === '' || value === false)
        return (<FormattedMessage id={`${TRANSLATION_NAME}.required`} defaultMessage="This field is reqired." />)
    return undefined;
}

export const minLength = (v, min) => {
    updateProps();
    if (typeof (v) === "string") {
        return v.length >= min ? undefined : (<FormattedMessage id={`${TRANSLATION_NAME}.minLenIs`} values={{ num: min }} defaultMessage="Minimum length is " />);
    }
    return (<FormattedMessage id={`${TRANSLATION_NAME}.textInputRequired`} defaultMessage="This field is required!" />)
}

export const minLen8 = (v) => minLength(v, 8);
export const minLen12 = (v) => minLength(v, 12);


// Record-Level Validation
export const passwordConfirmMatch = (values) => {
    updateProps();
    if (values.password1 !== values.password2) {
        return { password2: (<FormattedMessage id="wrong-password" defaultMessage="Passwords must match." />)}
    }
}

export const strongPassword = (value) => {
    updateProps();

    if (!value || value === '' || value === false) {
        return (<FormattedMessage id={`${TRANSLATION_NAME}.textInputRequired`} defaultMessage="This field is required!" />)
    }

    if (!hasNumber(value)) {
        return (<FormattedMessage id={`${TRANSLATION_NAME}.needsNumber`} defaultMessage="Please include a number." />)
    }

    if (!hasMixed(value)) {
        return (<FormattedMessage id={`${TRANSLATION_NAME}.needsMixedCharacters`} defaultMessage="Please include mixed uppercase and lowercase characters." />)
    }

    if (!hasSpecial(value)) {
        return (<FormattedMessage id={`${TRANSLATION_NAME}.needsSpecialCharasters`} defaultMessage="Please include a special character." />)
    }

    if (!hasMinLength(value, 8)) {
        return (<FormattedMessage id={`${TRANSLATION_NAME}.minLen8`} defaultMessage="Password should contain at least 8 characters." />)
    }
}

// export const strongLoginPassword = (values) => {
//     updateProps();

//     if (!hasNumber(values.password)) {
//         return { password: (<FormattedMessage id={`${TRANSLATION_NAME}.needsNumber`} defaultMessage="Please include a number." />)}
//     }

//     if (!hasMixed(values.password)) {
//         return { password: (<FormattedMessage id={`${TRANSLATION_NAME}.needsMixedCharacters`} defaultMessage="Please include mixed uppercase and lowercase characters." />)}
//     }

//     if (!hasSpecial(values.password)) {
//         return { password: (<FormattedMessage id={`${TRANSLATION_NAME}.needsSpecialCharasters`} defaultMessage="Please include a special character." />)}
//     }
// }

export const hasNumber = value => {
    if (!value) return;
    return new RegExp(/[0-9]/).test(value);
}

export const hasMixed = value => {
    if (!value) return;
    return new RegExp(/[a-z]/).test(value) && 
            new RegExp(/[A-Z]/).test(value);
}

export const hasSpecial = value => {
    if (!value) return;
    return new RegExp(/[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/).test(value);
}

export const hasMinLength = (v, min) => {
    updateProps();
    if (typeof (v) === "string") {
        return v.length >= min ? true : false;
    }
    
}

import { combineReducers } from "redux";
import myDegreesReducer from './myDegreesReducer';
import myProgramsReducer from './myProgramsReducer';
import myStudentsReducer from './myStudentsReducer';
import myCompetencesReducer from './myCompetencesReducer';


export default combineReducers({
    myDegrees: myDegreesReducer,
    myPrograms: myProgramsReducer,
    myStudents: myStudentsReducer,
    myCompetences: myCompetencesReducer,
})
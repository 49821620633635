import { GET_ROLE_STATS_SUCCESS } from "../../../../actions/role/types";

const INITIAL_STATE = {
    stats: {
        count: 0,
        next: '',
        previous: '',
        results: []
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_ROLE_STATS_SUCCESS:
            return {
                ...state.stats, ...action.payload.stats,
                results: action.payload.add ? [...state.stats.results, ...action.payload.stats.results] : action.payload.stats.results
            };
        default:
            return state;
    }
}
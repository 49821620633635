import * as types from './types';
import { apiCon } from '../../../API';
import { ChangeRoleSuccess, ChangeRoleFail } from './../role/index';
import {
    CreateTaskProposalSuccess, CreateTaskProposalFail,
    UpdateTaskProposalFail, UpdateTaskProposalSuccess
} from './../taskProposals/index';
import { CompleteSubmitTaskSuccess, CompleteSubmitTaskFail, CreateTaskSuccess } from '../tasks';
import { UpdateTaskDisputeCaseSuccess, UpdateTaskDisputeCaseFail } from '../taskDisputeCases';
import { SelectTaskDisputeSettlement } from '../taskDisputeSettlement';
import { GetTaskDisputeSettlementTopicCommentsStart } from '../taskDisputeSettlementTopicComment';
import { GetMyTransactionsStart, GetMyBalancesStart } from '../blockchain';
import { GetMyGamificationBalance } from '../gamification/index';
//----------------------
// Actions Notification Settings
//----------------------

//----------------------
// Actions Get Notification Settings
//----------------------
export const GetNotificationSettingsStart = (limit = 10) => async dispatch => {
    dispatch({ type: types.GET_NOTIFICATION_SETTINGS_REQUEST });
    var url = 'me/messaging/notifications/settings?limit=' + limit
    await apiCon.get(url)
        .then(response => {
            dispatch(GetNotificationSettingsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetNotificationSettingsFail(err.response));
        })
}
export const GetNotificationSettingsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_NOTIFICATION_SETTINGS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetNotificationSettingsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetNotificationSettingsFail(err.response));
        })
}

export const GetNotificationSettingsSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Notification Settings.", list: data }
    return { type: types.GET_NOTIFICATION_SETTINGS_SUCCESS, payload: payload };
}
export const GetNotificationSettingsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Notification Settings failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_NOTIFICATION_SETTINGS_FAILURE, payload: payload }
}

//----------------------
// Actions Update Notification Settings
//----------------------
export const UpdateNotificationSettingStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_NOTIFICATION_SETTING_REQUEST });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `me/messaging/notification/settings/` + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateNotificationSettingSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateNotificationSettingFail(err.response));
        })
}

export const UpdateNotificationSettingSuccess = (data) => {
    const succ_msg = "Successfully updated Notification Settings.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPDATE_NOTIFICATION_SETTING_SUCCESS, payload: payload };
}

export const UpdateNotificationSettingFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Update Notification Settings failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_NOTIFICATION_SETTING_FAILURE, payload: payload };
}

//----------------------
// Actions Select Notification Setting
//----------------------
export const SelectNotificationSettingStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_NOTIFICATION_SETTING_REQUEST });
    const url = 'me/messaging/notification/settings/' + id
    await apiCon.get(url)
        .then(response => {
            dispatch(SelectNotificationSettingSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectNotificationSettingFail(err.response));
        })
}
export const SelectNotificationSettingSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Notification Setting.", selected: data }
    return { type: types.SELECT_NOTIFICATION_SETTING_SUCCESS, payload: payload };
}
export const SelectNotificationSettingFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Notification Setting failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_NOTIFICATION_SETTING_FAILURE, payload: payload };
}

//----------------------
// Actions Notification
//----------------------

//----------------------
// Actions Get Notifications Status
//----------------------
export const GetNotificationsStatusStart = () => async dispatch => {
    dispatch({ type: types.GET_NOTIFICATION_STATUS_REQUEST });
    var url = 'me/messaging/notification/status/'
    await apiCon.get(url)
        .then(response => {
            dispatch(GetNotificationsStatusSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetNotificationsStatusFail(err.response));
        })
}

export const GetNotificationsStatusSuccess = (data) => {
    var counter = data.count === 0 ? 0 : data.results[0].unseen_notifications;
    const payload = { succ_msg: "Successfully received Notifications Status.", counter: counter }
    return { type: types.GET_NOTIFICATION_STATUS_SUCCESS, payload: payload };
}
export const GetNotificationsStatusFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.GET_NOTIFICATION_STATUS_FAILURE, payload: payload }
}


//----------------------
// Actions Get Notifications
//----------------------
export const GetNotificationsStart = (limit = 10) => async dispatch => {
    dispatch({ type: types.GET_NOTIFICATIONS_REQUEST });
    var url = 'me/messaging/notifications?limit=' + limit
    await apiCon.get(url)
        .then(response => {
            dispatch(GetNotificationsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetNotificationsFail(err.response));
        })
}

export const GetNotificationsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_NOTIFICATIONS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetNotificationsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetNotificationsFail(err.response));
        })
}

export const GetNotificationsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Notifications.", list: data, add: add }
    return { type: types.GET_NOTIFICATIONS_SUCCESS, payload: payload };
}
export const GetNotificationsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Notifications failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_NOTIFICATIONS_FAILURE, payload: payload }
}


//----------------------
// Actions Mark Notification As Read
//----------------------
export const MarkNotificationAsReadStart = (id) => async dispatch => {
    dispatch({ type: types.MARK_NOTIFICATION_AS_READ_REQUEST });
    const url = 'me/messaging/notification/' + id
    await apiCon.get(url)
        .then(response => {
            dispatch(MarkNotificationAsReadSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(MarkNotificationAsReadFail(err.response));
        })
}

export const MarkNotificationAsReadSuccess = (data) => {
    const payload = { succ_msg: "Successfully Marked Notification As Read.", selected: data }
    return { type: types.MARK_NOTIFICATION_AS_READ_SUCCESS, payload: payload };
}

export const MarkNotificationAsReadFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.MARK_NOTIFICATION_AS_READ_FAILURE, payload: payload };
}

//----------------------
// WebSocket Role Changed
//----------------------
export const WebSocketRoleChanged = () => async dispatch => {
    dispatch({ type: types.WEB_SOCKET_ROLE_CHANGED });
    await apiCon.get(`me/settings/`)
        .then(response => {
            dispatch(ChangeRoleSuccess(response.data));
        })
        .catch(err => {
            dispatch(ChangeRoleFail(err.response));
        })
}

//----------------------
// WebSocket TaskProposal Changed
//----------------------
export const WebSocketTaskProposalChanged = (id, isSelectedTaskProposal = true) => async dispatch => {
    dispatch({ type: types.WEB_SOCKET_TASK_PROPOSAL_CHANGED });
    await apiCon.get(`me/project/task/proposal/${id}`)
        .then(response => {
            dispatch(UpdateTaskProposalSuccess(response.data, false, isSelectedTaskProposal));
        })
        .catch(err => {
            dispatch(UpdateTaskProposalFail(err.response, false));
        })
}

//----------------------
// WebSocket TaskProposal Created
//----------------------
export const WebSocketTaskProposalCreated = (id, addToTask = false, addToProposals = true) => async dispatch => {
    dispatch({ type: types.WEB_SOCKET_TASK_PROPOSAL_CREATED });
    await apiCon.get(`me/project/task/proposal/${id}`)
        .then(response => {
            dispatch(CreateTaskProposalSuccess(response.data, false, addToProposals));
            if (addToTask) {
                dispatch(WebSocketTaskCreated(response.data.task.id))
            }
        })
        .catch(err => {
            dispatch(CreateTaskProposalFail(err.response, false));
        })
}

//----------------------
// WebSocket Task Dispute Changed
//----------------------
export const WebSocketTaskDisputeChanged = (id) => async dispatch => {
    dispatch({ type: types.WEB_SOCKET_TASK_PROPOSAL_CHANGED });
    await apiCon.get(`me/project/task/disputecase/${id}`)
        .then(response => {
            dispatch(UpdateTaskDisputeCaseSuccess(response.data, false));
        })
        .catch(err => {
            dispatch(UpdateTaskDisputeCaseFail(err.response, false));
        })
}

//----------------------
// WebSocket Task Dispute Created
//----------------------
export const WebSocketTaskDisputeCreated = (id) => async dispatch => {
    await apiCon.get(`me/project/task/disputecase/${id}`)
        .then(response => {
            const succ_msg = "Task Dispute recieved successfully!";
            const payload = { succ_msg: succ_msg, selected: response.data };
            dispatch({ type: types.WEB_SOCKET_TASK_DISPUTE_CHANGED, payload: payload });
            dispatch(SelectTaskDisputeSettlement(id, false));
        })
        .catch(err => {
            dispatch(UpdateTaskDisputeCaseFail(err.response, false));
        })
}

//----------------------
// WebSocket Task Dispute Created
//----------------------
export const WebSocketTaskDisputeSettlementCreated = (id) => async dispatch => {
    dispatch(SelectTaskDisputeSettlement(id, false));
}

//----------------------
// WebSocket Task Dispute Settlement Changed
//----------------------
export const WebSocketTaskDisputeSettlementChanged = (id) => async dispatch => {
    dispatch(SelectTaskDisputeSettlement(id, false));
}

//----------------------
// WebSocket Task Dispute Settlement Comments Changed
//----------------------
export const WebSocketTaskDisputeSettlementCommentsChanged = (id) => async dispatch => {
    dispatch(GetTaskDisputeSettlementTopicCommentsStart(id));
    // dispatch({ type: types.WEB_SOCKET_TASK_PROPOSAL_CHANGED });
    // await apiCon.get(`me/project/task/disputecase/${id}`)
    //     .then(response => {
    //         dispatch(UpdateTaskDisputeCaseSuccess(response.data, false));
    //     })
    //     .catch(err => {
    //         dispatch(UpdateTaskDisputeCaseFail(err.response, false));
    //     })
}


//----------------------
// WebSocket Task Created
//----------------------
export const WebSocketTaskCreated = (id, onlyMyWork = true) => async dispatch => {
    await apiCon.get(`me/project/task/${id}`)
        .then(response => {
            if (onlyMyWork) {
                const succ_msg = "Task recieved successfully!";
                const payload = { succ_msg: succ_msg, selected: response.data };
                dispatch({ type: types.WEB_SOCKET_TASK_CREATED, payload: payload });
            }
            else {
                dispatch(CreateTaskSuccess(response.data, false, false))
            }
        })
}

//----------------------
// WebSocket Task Changed
//----------------------
export const WebSocketTaskChanged = (id) => async dispatch => {
    dispatch({ type: types.WEB_SOCKET_TASK_CHANGED });
    await apiCon.get(`me/project/task/${id}`)
        .then(response => {
            dispatch(CompleteSubmitTaskSuccess(response.data, false));
            dispatch(SelectTaskDisputeSettlement(id, false));
        })
        .catch(err => {
            dispatch(CompleteSubmitTaskFail(err.response, false));
        })
}

//-------------------------------------------------
// WebSocker Escrow Task Changed For Wallet Refresh
//-------------------------------------------------
export const WebSocketEscrowArrangementChanged = () => async dispatch => {
    dispatch(GetMyTransactionsStart());
    dispatch(GetMyBalancesStart());
}

//-------------------------------------------------
// WebSocket chips balance For Wallet Refresh
//-------------------------------------------------
export const WebSocketGamificationBalanceChanged = () => async dispatch => {
    dispatch(GetMyGamificationBalance());
}

//-------------------------------------------------
// WebSocket Buy chips For Wallet Refresh
//-------------------------------------------------
export const WebSocketGamificationChipsAfterBuy = () => async dispatch => {
    dispatch(GetMyTransactionsStart());
}

//-------------------------------------------------
// WebSocket transaction status changed For Wallet Refresh
//-------------------------------------------------
export const WebSocketTransactionStatusChanged = () => async dispatch => {
    dispatch(GetMyTransactionsStart());
    dispatch(GetMyBalancesStart());
}

//-------------------------------------------------
// WebSocket transaction status changed For Wallet Refresh
//-------------------------------------------------
export const WebSocketNewTransaction = () => async dispatch => {
    dispatch(GetMyTransactionsStart());
    dispatch(GetMyBalancesStart());
}
import { SELECT_TASK_SUCCESS, DESELECT_TASK, GET_TASKS_SUCCESS } from '../../actions/tasks/types'
import { REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_TASK_FILTERS } from './../../actions/tasks/types';

export const INITIAL_STATE_FILTERS = {
    advanced: false,
    limit: 10,
    search: '',
    ordering: '',
    price_max: '',
    price_min: '',
    date_from: '',
    date_to: '',
    status: ['PUBLISHED', 'IN_NEGOTIATION', 'ACTIVE', 'IN_PROGRESS', 'SUBMITTED', 'IN_AUDIT'],
    competences: [],
    project: '',
    my_tasks: false
}

const INITIAL_STATE = {
    selected: {
        id: null,
        competences: [],
        price: '',
        start_date: '',
        end_date: '',
        name: '',
        short_description: '',
        description: '',
        visibility: '',
        status: '',
        recurring: null,
        recurring_type: null,
        recurring_amount: null,
        milestone: null,
        project: null,
        category: null,
        is_boosted: "",
        hit_count: "",
        task_upgrades: ""
    },
    list: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    }
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case CHANGE_TASK_FILTERS:
            return {
                ...state, list: {
                    ...state.list,
                    filters: { ...state.list.filters, ...action.payload.filters }
                }
            };
        case GET_TASKS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        case SELECT_TASK_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_TASK:
            return { ...state, selected: INITIAL_STATE.selected };
        // case LOGIN_SUCCESS:
        //     return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { WorkingDays } from '../../utils/common';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Flag from "react-flags";
import Countries from '../../utils/i18n/countries.js'; // TO DISPLAY COUNTRY NAME... USE Countries[countryCode]
import ProfileSwitcher from '../../components/ProfileSwitcher';
import ModalAddCover from '../../components/Profile/Setup/modalAddCover';
import ModalAddAvatar from '../../components/Profile/Setup/modalAddAvatar';
import ModalEditProfileInfo from '../../components/Profile/modalEditProfileInfo';
import ModalEditFreelancerAvailability from '../../components/Profile/modalEditFreelancerAvailability';
import ProfileEducationSection from '../../components/Profile/profileEducationSection';
import ProfileExperienceSection from '../../components/Profile/profileExperienceSection';
import ProfileCompetenciesSection from '../../components/Profile/profileCompetenciesSection';
import { SelectProjectStart } from '../../core/actions/projects';
import { GetMyReviewsStart } from './../../core/actions/rating/index';
import { GetMyEduVerificationRequestsStart } from '../../core/actions/education';
import { UploadRoleCoverPictureStart, UploadRoleProfilePictureStart, GetMyRoleDetailsStart, GetRoleStart } from '../../core/actions/role';
import ProfileReviews from './profileReviews';
import ParticipatedInProjects from './participatedInProjects';
import Infobar from '../../components/Infobar';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';


class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalCoverVisible: false,
            modalAvatarVisible: false,
            modalProfileEditVisible: false,
            modalFreelancerAvailabilityVisible: false,
            cover_image: this.props.role.cover_image,
            profile_image: this.props.role.profile_image,
            coverPreview: this.props.role.cover_image,
            avatarPreview: this.props.role.profile_image
        };
    }

    componentDidMount() {
        this.props.GetRoleStart();
        this.props.GetMyRoleDetailsStart();
        this.props.GetMyReviewsStart();
        this.props.GetMyEduVerificationRequestsStart();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                profile_image: nextProps.role.profile_image,
                avatarPreview: nextProps.role.profile_image,
                cover_image: nextProps.role.cover_image,
                coverPreview: nextProps.role.cover_image
            });
        }
    }

    hideModalCover = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ cover_image: img, coverPreview: e.target.result, modalCoverVisible: false });
            }
            reader.readAsDataURL(img);
            this.props.UploadRoleCoverPictureStart(img);
        } else {
            this.setState({ modalCoverVisible: false });
        }
    }

    hideModalAvatar = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ profile_image: img, avatarPreview: e.target.result, modalAvatarVisible: false });
            }
            reader.readAsDataURL(img);
            this.props.UploadRoleProfilePictureStart(img);
        } else {
            this.setState({ modalAvatarVisible: false });
        }
    }

    getProfileCompletionTooltipText(r, c) {
        return (
            <div className="tooltip-txt">
                <div><FormattedMessage id="tooltip.fill_out" defaultMessage="Fill out these sections:" /></div>
                <ul style={{ 'margin': 0, 'padding': 0 }}>
                    {!c.email_verification && <li><FormattedMessage id="tooltip.email_verification" defaultMessage="Email verification" /></li>}
                    {!c.personal_info && <li><FormattedMessage id="tooltip.personal_info" defaultMessage="Personal info" /></li>}
                    {!c.description && <li><FormattedMessage id="tooltip.description" defaultMessage="Description" /></li>}
                    {!c.profile_image && <li><FormattedMessage id="tooltip.profile_image" defaultMessage="Profile image" /></li>}
                    {!c.cover_image && <li><FormattedMessage id="tooltip.cover_image" defaultMessage="Cover image" /></li>}
                    {/* Freelancer only */}
                    {(r.type === 'DEV' && !c.availability) && <li><FormattedMessage id="tooltip.availability" defaultMessage="Availability" /></li>}
                    {(r.type === 'DEV' && !c.competences) && <li><FormattedMessage id="tooltip.competencies" defaultMessage="Competencies" /></li>}
                    {(r.type === 'DEV' && !c.experience) && <li><FormattedMessage id="tooltip.experience" defaultMessage="Experience" /></li>}
                    {(r.type === 'DEV' && !c.education) && <li><FormattedMessage id="tooltip.education" defaultMessage="Education" /></li>}
                </ul>
            </div>
        );
    }

    getDurationNiceTextFromValue = value => {
        switch (value) {
            case 'Days':
                return 'd';
            case 'Weeks':
                return 'w';
            case 'Months':
                return ' mos'
            default:
                return 'N/A';
        }
    }

    renderProfileCompletionBar(role, completion) {
        if (!completion?.quality) return null;
        return (
            <>
                {(parseInt(completion.quality) < 100) &&
                    <div className="profile-setup-progress">
                        <div className="progress-title">
                            <div><FormattedMessage id="nav.labels.profile_setup" defaultMessage="Profile setup" /></div>
                            <div><strong>{completion.quality}%</strong></div>
                            <div className="info-icon-wrap">
                                <img src="/assets/img/icon/info.png" data-tip data-for='completion' alt="info-icon" width="13"></img>
                            </div>
                            <ReactTooltip id="completion" place="right" type="dark" effect="solid">
                                {this.getProfileCompletionTooltipText(role, completion)}
                            </ReactTooltip>
                        </div>
                        <div className="progress-wrapper">
                            <div className="setup-progress" style={{ width: completion.quality + '%' }}></div>
                        </div>
                    </div>
                }
            </>
        )
    }

    renderAvailability() {
        const { role } = this.props;
        switch (role.availability) {
            case "F":
                return (<div className="val"><FormattedMessage id="setup_profile.full_time" defaultMessage="Full time" /><div className="val-muted">({WorkingDays(role.availability_days)})</div></div>);
            case "P":
                return (<div className="val"><FormattedMessage id="setup_profile.part_time" defaultMessage="Part time" /></div>);
            case "N":
                return (<div className="val"><FormattedMessage id="setup_profile.not_available" defaultMessage="Not accepting work " /></div>);
            default:
                return null;
        }
    }

    renderSidebar() {
        const { role, wallet: { balances }, currency, cryptoDAI, cryptoETH, cryptoBNB } = this.props;

        switch (this.props.role.type) {

            case "DEV":
                return (
                    <>
                        <div className="card card-availability">
                            <div className="p-rates">
                                <span className="icon-edit" onClick={() => this.setState({ modalFreelancerAvailabilityVisible: true })}></span>

                                <div className="caption">
                                    <FormattedMessage id="profile.hourlyrate" defaultMessage="Hourly Rate" />
                                </div>
                                <div className="val">{"$" + role.hourly_rate + "/h"}</div>

                                {role.availability && (<div className="caption"><FormattedMessage id="profile.availability" defaultMessage="Avaliability" /></div>)}
                                {this.renderAvailability()}

                                {role.project_duration_from && role.project_duration_to > 0 ?
                                    <>
                                        <div className="caption"><FormattedMessage id="setup_profile.projects_duration" defaultMessage="Projects duration" /></div>
                                        <div className="val">
                                            {role.project_duration_from_choice && role.project_duration_from ? `${role.project_duration_from}${this.getDurationNiceTextFromValue(role.project_duration_from_choice)}` : 'N/A'}
                                            <span>&nbsp;-&nbsp;</span>
                                            {role.project_duration_to_choice && role.project_duration_to ? `${role.project_duration_to}${this.getDurationNiceTextFromValue(role.project_duration_to_choice)}` : 'N/A'}
                                        </div>
                                    </>
                                    :
                                    <div></div>
                                }

                                {role.task_remuneration_from && role.task_remuneration_to > 0 ?
                                    <>
                                        <div className="caption"><FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" /></div>
                                        <div className="val">{role.task_remuneration_from ? parseInt(role.task_remuneration_from) : 'N/A'} - {role.task_remuneration_to ? parseInt(role.task_remuneration_to) : 'N/A'} USD</div>
                                    </>
                                    :
                                    <div></div>
                                }
                                {balances?.DAI && balances?.ETH > 0 ?
                                    <>
                                        <div className="caption"><FormattedMessage id="wallet.total_balance" defaultMessage="Total balance" /></div>
                                        <div className="val">{currency === "usd" ?
                                            ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.usd * balances?.ETH : cryptoBNB?.usd * balances?.ETH) + (cryptoDAI?.usd * balances?.DAI)).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " $"
                                            : ((REACT_APP_NETWORK_CURRENCY() === 'ETH' ? cryptoETH?.eur * balances?.ETH : cryptoBNB?.eur * balances?.ETH) + (cryptoDAI?.eur * balances?.DAI)).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</div>
                                    </>
                                    :
                                    <div></div>
                                }
                            </div>

                            {this.state.modalFreelancerAvailabilityVisible &&
                                <ModalEditFreelancerAvailability
                                    visible={this.state.modalFreelancerAvailabilityVisible}
                                    onClose={() => this.setState({ modalFreelancerAvailabilityVisible: false })} />
                            }

                            <div className="p-stats">
                                <div className="p-stats-item">
                                    <span>{role?.completed_tasks_count ?? 0}</span>
                                    <span><FormattedMessage id="profile.completedtasks" defaultMessage="completed tasks" /></span>
                                </div>
                                <div className="p-stats-item">
                                    <span>{role?.participated_in_projects?.length ?? 0}</span>
                                    <span><FormattedMessage id="profile.participatedinprojects" defaultMessage="participated in projects" /></span>
                                </div>
                                <div className="p-stats-item">
                                    <span>{role?.rating?.reviewer_count ?? 0}</span>
                                    <span><FormattedMessage id="profile.reviews" defaultMessage="reviews" /></span>
                                </div>
                            </div>
                        </div>
                    </>
                );

            default:
                break;
        }
    }

    checkGoogleAuth(socials) {
        let soc = false;
        if (_.isEmpty(socials)) {
            return;
        }
        socials.forEach(e => {
            if (e.provider === 'google') { soc = true }
        })
        return soc;
    }

    render() {
        const { role, completion } = this.props;
        const roleType = role.type ? "roles.name." + role.type : "roles.name.DEV";
        return (
            <>
                <Infobar />
                <Container className="mt-4">
                    <Row>
                        <Col md={12} lg={9}>

                            <ProfileSwitcher />

                            {this.renderProfileCompletionBar(role, completion)}

                            <div className="card card-profile">

                                <div className="sp-header">

                                    <div className="sp-cover">
                                        <Image src={this.state.coverPreview ? this.state.coverPreview : "/assets/img/profile-cover.png"} className="p-cover-img"></Image>
                                        <div className="btn btn-default btn-trans btn-spchangecover btn-cover " onClick={() => this.setState({ modalCoverVisible: true })}>
                                            <FormattedMessage id="profile.change.cover" defaultMessage="Change cover" />
                                        </div>
                                    </div>

                                    <div className="sp-avatar-wrapper">

                                        <div className="transbox">
                                            <Image src={this.state.avatarPreview ? this.state.avatarPreview : "/assets/img/default-avatar.png"} className="sp-avatar"></Image>
                                        </div>

                                        <div className="sp-avatar-overlay" onClick={() => this.setState({ modalAvatarVisible: true })}>
                                            <Image src="/assets/img/svg/camera.svg" fluid onClick={() => this.setState({ modalAvatarVisible: true })}></Image>
                                            <div className="" onClick={() => this.setState({ modalAvatarVisible: true })}>
                                                <FormattedMessage id="common.upload" defaultMessage="Upload" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.modalCoverVisible &&
                                    <ModalAddCover
                                        visible={this.state.modalCoverVisible}
                                        image={role?.cover_image}
                                        onClose={(img) => this.hideModalCover(img)}
                                    />
                                }

                                {this.state.modalAvatarVisible &&
                                    <ModalAddAvatar
                                        visible={this.state.modalAvatarVisible}
                                        image={role?.profile_image}
                                        onClose={(img) => this.hideModalAvatar(img)} />
                                }

                                <div className="p-details">
                                    <span className="icon-edit" onClick={() => this.setState({ modalProfileEditVisible: true })}></span>

                                    <div className="p-rating">
                                        <div className="p-location">
                                            <Flag
                                                className="p-flag"
                                                basePath="/assets/img"
                                                name={role.country ? role.country : 'AA'}
                                                format="png"
                                                pngSize={32}
                                                shiny={true}
                                                alt={Countries[role.country]}
                                            />
                                            {role.city ? role.city : <FormattedMessage id="profile.nocity" defaultMessage="No city" />}
                                        </div>
                                        <span>
                                            <FormattedMessage id="plural.profile.reviews" values={{ count: role?.rating?.reviewer_count ?? 0 }}
                                                defaultMessage={`${role?.rating?.reviewer_count ?? 0} Reviews`} />
                                        </span>
                                    </div>

                                    <div className="p-info">
                                        <div className="p-info-name">
                                            <span>{role.name}</span>
                                            {role?.rating?.rating &&
                                                <>
                                                    <svg style={{ color: '#FFD700', marginTop: '2px', marginLeft: '8px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 18 18">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    {/* <span className="p-star ml-2" role="img" aria-label="Star rating">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 18 18">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </span> */}
                                                    <span>{role.rating.rating}</span>
                                                </>
                                            }
                                        </div>
                                        <div className="p-info-tagline">
                                            <FormattedMessage id={roleType} defaultMessage="" />
                                        </div>
                                        <div className="p-info-status">● <FormattedMessage id="profile.online" defaultMessage="Online" /></div>
                                        {role.description
                                            ?
                                            <div className="p-description">
                                                <span>{role.description}</span>
                                            </div>
                                            :
                                            <div className="p-description empty-section">
                                                <div className="">
                                                    <Image src="/assets/img/svg/profile-info.svg" className="section-img"></Image>
                                                </div>
                                                <div className="">
                                                    <div className=""><FormattedMessage id="profile.description.desc" defaultMessage="Adding a short description increases your chances of getting hired" /></div>
                                                    <div className="add-link" onClick={() => this.setState({ modalProfileEditVisible: true })}>
                                                        <FormattedMessage id="profile.description.add" defaultMessage="Add description" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                                {this.state.modalProfileEditVisible &&
                                    <ModalEditProfileInfo
                                        visible={this.state.modalProfileEditVisible}
                                        onClose={() => this.setState({ modalProfileEditVisible: false })} />
                                }

                            </div>

                            {/* COMPETENCES */}
                            {role.type === "DEV" && <ProfileCompetenciesSection role={role} />}

                            {/* EXPERIENCE */}
                            {role.type === "DEV" && <ProfileExperienceSection role={role} />}

                            {/* EDUCATION */}
                            {role.type === "DEV" && <ProfileEducationSection role={role} />}

                            {/* REVIEWS */}
                            <ProfileReviews reviews={this.props.reviews} />

                            {/* PARTICIPATED & PUBLISHED PROJECTS */}
                            <ParticipatedInProjects role={role} />

                        </Col>

                        {role.type === "DEV" &&
                            <Col md={12} lg={3}>
                                {this.renderSidebar()}
                            </Col>
                        }

                        {role.type !== "DEV" &&
                            <Col md={12} lg={3} style={{ "marginTop": "41px" }}>
                                {this.renderSidebar()}
                            </Col>
                        }

                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        hasRole: state.user.info.settings.active_role,
        verified: state.user.info.isEmailVerified,
        completion: state.user.info.settings.active_role_obj.profile_completion,
        completed: state.user.info.settings.active_role_obj.profile_completion?.quality,
        google_token: state.user.auth.google_token,
        reviews: state.user.info.settings.active_role_obj.reviews,
        wallet: state.user.wallet,
        crypto: state.crypto.data,
        cryptoETH: state.crypto.ethereum,
        cryptoDAI: state.crypto.dai,
        cryptoBNB: state.crypto.bnb,
        currency: state.currency,
        socials: state.user.info?.socials?.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    UploadRoleProfilePictureStart,
    GetMyRoleDetailsStart,
    GetMyEduVerificationRequestsStart,
    GetMyReviewsStart,
    GetRoleStart,
    UploadRoleCoverPictureStart,
    SelectProjectStart
})(ProfilePage));
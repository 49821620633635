import { GET_MY_COMPETENCES_SUCCESS, SELECT_MY_COMPETENCE_SUCCESS, DESELECT_MY_COMPETENCE, UPDATE_MY_COMPETENCE_SUCCESS } from "../../actions/institution/types";
import competenceDescriptionReducer from '../competence/competenceDescriptionReducer';
import competenceSkillReducer from '../competence/competenceSkillReducer';
import competenceParentReducer from '../competence/competenceParentReducer';

export const INITIAL_STATE_FILTERS_MY = {
    limit: null,
    search: '',
    starts_with: '',
    ordering: '',
    issuers: null,
    platform_only: false,
    skills: [],
    level_min: '',
    level_max: '',
    categories: null,
}

const INITIAL_STATE_MY = {
    selected: {
        id: null,
        name: '',
        description: '',
        already_exists: false,
        calculated_id: '',
        competence_descriptions: competenceDescriptionReducer,
        competence_skills: competenceSkillReducer,
        competence_parents: competenceParentReducer,
        task_categories: [],
        warnings: []
    },
    list: {
        filters: INITIAL_STATE_FILTERS_MY,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE_MY, action) {
    switch (action.type) {
        case GET_MY_COMPETENCES_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        case SELECT_MY_COMPETENCE_SUCCESS:
            return { ...state, selected: { ...state.selected, ...action.payload.selected } };
        case DESELECT_MY_COMPETENCE:
            return { ...state, selected: INITIAL_STATE_MY.selected };
        default:
            return state;
        case UPDATE_MY_COMPETENCE_SUCCESS:
            return {
                ...state, selected: action.payload.selected.id === state.selected.id ? { ...state.selected, ...action.payload.selected } : state.selected,
                list: {
                    ...state.list, results: state.list.results.map(x =>
                        action.payload.selected.id === x.id ? { ...x, ...action.payload.selected } : x
                    )
                }
            };

    }
}
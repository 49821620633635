import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalCreateANewCompetence.scss';
import Select from 'react-select';
import Levels from '../../../assets/json/blooms-taxonomy.js';
import AutosizeTextarea from '../../Common/AutosizeTextarea';
import { connect } from 'react-redux';
import { slugify } from '../../../utils/common/misc';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Col, Row } from 'react-bootstrap';
import { createErrorMessageSelector, createLoadingSelector } from '../../../API/selector';
import { VerifyCompetenceStart, CreateCompetenceStart } from '../../../core/actions/competences';


const customStyles = {
    width: '500px',
    height: 'auto',
    bottom: 'auto',
    top: '35%',
    transform: 'translateY(-35%)' 
};

class ModalQuickAddCompetence extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            name: '',
            description: '',
            level: '',
            sub_level: '',
            levels: [],
            levelInvalid: false,
            issuer: this.props?.issuer,
            nameRequired: false,
            descRequired: false,
            levelRequired: false
        };
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.generateLevelOptions(Levels);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.hide({ name: this.state.name, level: parseInt(this.state.level), id: this.props.calculated_id });
        }
        if (prevProps.lang !== this.props.lang) {
            this.generateLevelOptions(Levels);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.issuer && nextProps.availableIssuers.length > 0) {
            this.setState({ issuer: nextProps.availableIssuers[0].owner.id });
        }
    }

    getOptionLabel = option => {
        return (
            <div><strong>{option.label}</strong></div>
        );
    }

    generateLevelOptions(data) {
        if (!data.levels) { return; };
        const { lang } = this.props;
        var options = [];
        data.levels.forEach(e => {
            var item = { label: "'" + (lang === 'en' ? e.verb : e.verb_si) + "' - " + e.name, options: [] };
            e.verbs.forEach(o => {
                item.options.push({ label: lang === 'en' ? o.verb : o.verb_si, value: o.id });
            });
            options.push(item);
        });
        this.setState({ levels: options });
    }

    hide(competence) {
        this.props.onClose(competence);
    }

    handleChange = input => event => {
        if (input === 'level') {
            const level = event.value.toString().substr(0, 1);
            if (this.state.name) {
                this.props.VerifyCompetenceStart(this.state.name, level, this.state.issuer);
            }
            this.setState({ level: level, sub_level: event.value, levelInvalid: false, levelRequired: false });
        } else if (input === 'name') {
            this.props.VerifyCompetenceStart(event.target.value, this.state.level, this.state.issuer);
            this.setState({ name: event.target.value, nameRequired: false });
        } else {
            this.setState({ [input]: slugify(event.target.value) });
        }
    }

    textAreaValueChanged(input, event) {
        if (input === 'description') {
            if (event.target.value.length > 1000) return
            if (event.target.value.length === 0) {
                this.setState({ [input]: event.target.value, descRequired: true });
            } else {
                this.setState({ [input]: event.target.value, descRequired: false });
            }
        }
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        const { already_exists } = this.props;

        // name
        var nameInput = event.target.nameInput;
        if (already_exists) {
            nameInput.setCustomValidity('required');
        } else {
            nameInput.setCustomValidity('');
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }
        this.setState({ validated: true });
    }

    save() {
        this.props.CreateCompetenceStart({
            name: this.state.name,
            description: this.state.description,
            level: this.state.level,
            sub_level: this.state.sub_level,
            issuer: this.state.issuer,
            status: 1
        });
    }

    render() {
        const { levelInvalid } = this.state;
        const { visible, already_exists } = this.props;
        return (
            <Rodal className="new-competence-rodal"
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div>

                    <div className="modal-title">
                        <FormattedMessage id="competency.quick_add_header" defaultMessage="Quick add" />
                    </div>

                    <div className="modal-body">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Row>
                                <Form.Group as={Col} controlId="formCName">
                                    <Form.Label>
                                        <FormattedMessage id="competences.name" defaultMessage="Skill name" />
                                    </Form.Label>
                                    <Form.Control type="text" name="nameInput" className={already_exists && "is-invalid"} onChange={this.handleChange('name')} required />
                                    {already_exists && <div className="invalid-feedback"><FormattedMessage id="competency_warning_exists" defaultMessage="Already exists" /></div>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                </Form.Group>
                            </Row>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="modalEditProfileInfo.description" defaultMessage="Description" />&nbsp;(<span className="clink" onClick={() => window.open("/help", "_blank")}><FormattedMessage id="competency.blooms_taxonomy" defaultMessage="Bloom's Taxonomy" /></span>)
                                </Form.Label>
                            </Form.Group>
                            <Form.Group>
                                <div className="desc-wrap">
                                    <div className="this-person">
                                        <FormattedMessage id="competences.this_person_can" defaultMessage="This person can" />
                                    </div>
                                    <Select
                                        required
                                        blurinputonselect="true"
                                        ref={this.selectRef}
                                        className="select-level"
                                        classNamePrefix="select"
                                        name="selLevel"
                                        options={this.state.levels}
                                        onChange={this.handleChange('level')}
                                        styles={{ control: (provided, state) => levelInvalid ? { ...provided, border: "1px solid #dc3545" } : provided }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formCDesc" >
                                <AutosizeTextarea rows={3} input="description" handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                            </Form.Group>

                            <br />
                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2" disabled={!(!already_exists && this.state.level)}>
                                <FormattedMessage id="common.save" defaultMessage={`Save`} />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage={`Cancel`} />
                            </div>
                        </Form>

                    </div>
                </div>
            </Rodal>
        )
    }

}
const loadingSelector = createLoadingSelector(['CREATE_COMPETENCE']);
const errorSelector = createErrorMessageSelector(['CREATE_COMPETENCE']);
const mapStateToProps = state => {
    return {
        lang: state.lang,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        already_exists: state.competence.selected.already_exists,
    }
}

export default injectIntl(connect(mapStateToProps, {
    VerifyCompetenceStart,
    CreateCompetenceStart,
})(ModalQuickAddCompetence));
import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalFileDisputeConfirmation.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
// import history from '../../utils/routes/history';
import { Link } from 'react-router-dom';


const customStyles = {
    width: 'inherit',
    maxWidth: '540px',
    height: '250px',
    bottom: 'auto',
    top: '25%'
};

class ModalFileDisputeConfirmation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    hide() {
        this.props.onClose();
    }

    confirm() {
        this.props.onConfirm();
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}
                className="rodal-dialog-file-duspute-confirmation"
                >
                <div>
                    <div className="modal-title">
                        <FormattedMessage id="auditor.agree_to_continue" defaultMessage="File a dispute confirmation" />
                    </div>
                    <div className="modal-body p-2 pt-4" style={{ "textAlign": "center" }}>
                        <div className="mb-5">
                            <FormattedMessage id="resolutionCenter.finalSubmit" defaultMessage="I accept the terms and conditions of the Dispute Resolution Service." /><br />
                            <FormattedMessage id="resolutionCenter.more_in_the" defaultMessage="More in the " />
                            <Link to="/help" target="_blank">
                                <FormattedMessage id="resolutionCenter.faq_section" defaultMessage="FAQ section..." />
                            </Link>
                        </div>
                        <div className="modal-submit-buttons mt-5">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.close" defaultMessage="Close" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.confirm()}>
                                <FormattedMessage id="auditor.file_dispute_proposal" defaultMessage="FILE A DISPUTE PROPOSAL" />
                            </button>
                        </div>
                    </div>
                </div>
            </Rodal>
        )
    }
}

export default injectIntl(connect(null, {
})(ModalFileDisputeConfirmation));
import {
    GET_MY_PROJECTS_SUCCESS, SELECT_MY_PROJECT_SUCCESS, DESELECT_MY_PROJECT, UPDATE_PROJECT_SUCCESS, CREATE_PROJECT_SUCCESS,
    UPLOAD_PROJECT_COVER_PICTURE_SUCCESS, UPLOAD_PROJECT_LOGO_PICTURE_SUCCESS
} from '../../../actions/projects/types'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../../actions/auth/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../../actions/role/types'
import { CREATE_TASK_SUCCESS, COMPLETE_SUBMIT_TASK_SUCCESS, UPDATE_TASK_SUCCESS } from '../../../actions/tasks/types'
import { CREATE_DOCUMENTS_SUCCESS, DELETE_DOCUMENT_SUCCESS } from '../../../actions/documents/types'
import { CREATE_TASK_PROPOSAL_SUCCESS, UPDATE_TASK_PROPOSAL_SUCCESS } from '../../../actions/taskProposals/types'
import { WEB_SOCKET_PROJECT_MAY_COMPLETE } from '../../../actions/notification/types';
import { GET_MY_MILESTONES_SUCCESS, CREATE_MILESTONE_SUCCESS, UPDATE_MILESTONE_SUCCESS, SELECT_MY_MILESTONE_SUCCESS, DELETE_MILESTONE_SUCCESS } from './../../../actions/milestone/types';
import myMilestoneReducer from './myMilestoneReducer'

const INITIAL_STATE = {
    selected: {
        id: null,
        category: {
            id: null,
            name: '',
            description: ''
        },
        client: {
            id: null,
            competences: [],
            type: '',
            name: '',
            first_name: '',
            last_name: '',
            company_name: '',
            address_line_1: '',
            address_line_2: '',
            post_code: '',
            city: '',
            state: '',
            country: '',
            vat: '',
            status: '',
        },
        logo_image: '',
        cover_image: '',
        may_be_completed: false,
        number_of_tasks: null,
        milestones: myMilestoneReducer,
        participants: [],
        documents: [],
        name: '',
        short_description: '',
        descroption: '',
        estimated_start: '',
        estimated_end: '',
        estimated_budget: null,
        visibility: '',
        status: ''
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_MY_PROJECTS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.list.results) : action.payload.list.results
                }
            };
        case SELECT_MY_PROJECT_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_MY_PROJECT:
            return { ...state, selected: INITIAL_STATE.selected };
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state, selected: { ...state.selected, ...action.payload.selected },
                list: {
                    ...state.list, results: state?.list?.results?.map(proj =>
                        proj.id === action.payload.selected.id ?
                            { ...proj, ...action.payload.selected } : proj)
                }
            };
        case UPLOAD_PROJECT_COVER_PICTURE_SUCCESS:
            return {
                ...state, selected: { ...state.selected, ...action.payload.selected },
                list: {
                    ...state.list, results: state?.list?.results?.map(proj =>
                        proj.id === action.payload.selected.id ?
                            { ...proj, ...action.payload.selected } : proj)
                }
            };
        case UPLOAD_PROJECT_LOGO_PICTURE_SUCCESS:
            return {
                ...state, selected: { ...state.selected, ...action.payload.selected },
                list: {
                    ...state.list, results: state?.list?.results?.map(proj =>
                        proj.id === action.payload.selected.id ?
                            { ...proj, ...action.payload.selected } : proj)
                }
            };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
                list: { ...state.list, results: [action.payload.selected].concat(state.list?.results) }
            };
        case CREATE_TASK_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected, number_of_tasks: state.selected?.number_of_tasks + 1,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                },
                list: {
                    ...state.list, results: state.list?.results?.map(proj =>
                        proj.id === action.payload.selected.project.id ?
                            {
                                ...proj, number_of_tasks: proj.number_of_tasks + 1,
                                milestones: myMilestoneReducer(proj.milestones, action)
                            } : proj)
                }
            }
        case UPDATE_TASK_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                },
            }
        case COMPLETE_SUBMIT_TASK_SUCCESS:
            return action.payload.selected.project.id === state.selected.id ? {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            } : state;
        case CREATE_TASK_PROPOSAL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                },
            };
        case UPDATE_TASK_PROPOSAL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            };
        case DELETE_DOCUMENT_SUCCESS:
            var docId = action.payload.deleted;
            return {
                ...state, selected: {
                    ...state.selected, documents: state.selected?.documents.filter(x => x.id !== docId)
                },
            };
        case CREATE_DOCUMENTS_SUCCESS:
            var createdDocs = action.payload.selected;
            var first = createdDocs[0];
            return first?.project ? {
                ...state, selected: {
                    ...state.selected, documents: state.selected?.documents.concat(action.payload.selected)
                },
            } : state;
        case GET_MY_MILESTONES_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            }
        case CREATE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            }
        case UPDATE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            }
        case DELETE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            }
        case SELECT_MY_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: myMilestoneReducer(state.selected.milestones, action),
                }
            }
        case WEB_SOCKET_PROJECT_MAY_COMPLETE:
            return {
                ...state, selected: {
                    ...state.selected, may_be_completed: true
                }
            };

        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}
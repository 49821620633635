import './index.scss';
import React from "react";
import img from '../../assets/img/how-it-works-mobile.svg';
import { ImageMap } from "@qiuz/react-image-map";
import history from '../../utils/routes/history';


const onMapClick = (area, index) => {
    switch (index) {
        case 0:
            history.push('/help/skills');
            break;
        case 1:
            history.push('/help/client');
            break;
        case 2:
            history.push('/help/auditor');
            break;
        case 3:
            history.push('/help/freelancer');
            break;
        case 4:
            history.push('/help/educational-institution');
            break;
        default:
            break;
    }
};

const mapArea = [
    {
        width: "41.01265822784811%",
        height: "5.373525557011796%",
        left: "31.281645569620252%",
        top: "10.484927916120576%",
        className: "image-map__map usage-map_map_span-0 img-map"
    }, {
        width: "43.037974683544306%",
        height: "7.470511140235911%",
        left: "30.015822784810126%",
        top: "32.43774574049804%",
        className: "image-map__map usage-map_map_span-1 img-map"
    }, {
        width: "40.25316455696203%",
        height: "9.698558322411534%",
        left: "39.889240506329116%",
        top: "47.11664482306684%",
        className: "image-map__map usage-map_map_span-2 img-map"
    }, {
        width: "47.08860759493671%",
        height: "8.715596330275229%",
        left: "31.028481012658226%",
        top: "74.77064220183486%",
        className: "image-map__map usage-map_map_span-3 img-map"
    }, {
        width: "41.265822784810126%",
        height: "7.011795543905635%",
        left: "40.142405063291136%",
        top: "92.46395806028833%",
        className: "image-map__map usage-map_map_span-4 img-map"
    }
];

export default function HowItWorksMobile() {

    const ImageMapComponent = React.useMemo(
        () => (
            <ImageMap
                className="usage-map"
                src={img}
                map={mapArea}
                onMapClick={onMapClick}
            />
        ),
        []
    );

    return <div>{ImageMapComponent}</div>;
}

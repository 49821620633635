import {
    CREATE_TASK_SUCCESS, UPDATE_TASK_SUCCESS, SELECT_MY_TASK_SUCCESS,
    DESELECT_MY_TASK, GET_MY_TASKS_SUCCESS, COMPLETE_SUBMIT_TASK_SUCCESS, GET_MY_TASK_HISTORY_SUCCESS,
    GET_MY_TASK_WITH_WEIGHTS_SUCCESS
} from '../../actions/tasks/types'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';
import { CREATE_TASK_RESULT_SUCCESS } from '../../actions/taskResults/types';
import { CREATE_TASK_RESULT_DOCUMENT_SUCCESS } from '../../actions/taskResultDocuments/types';
import { CREATE_DOCUMENTS_SUCCESS, DELETE_DOCUMENT_SUCCESS } from '../../actions/documents/types'
import { CREATE_TASK_PROPOSAL_SUCCESS, UPDATE_TASK_PROPOSAL_SUCCESS } from '../../actions/taskProposals/types';
import { WEB_SOCKET_TASK_CREATED } from './../../actions/notification/types';
import { UPDATE_TASK_DISPUTE_CASES_SUCCESS } from '../../actions/taskDisputeCases/types';
import { GET_TASK_FUNDING_ESTIMATE_SUCCESS, GET_TASK_FUNDING_ESTIMATE_FAILURE } from '../../actions/taskProposals/types';

const INITIAL_STATE = {
    selected: {
        id: null,
        competences: [],
        competencesWithWeights: null,
        number_of_proposals: null,
        number_of_results: null,
        results: [],
        active_freelancer: null,
        documents: [],
        history: [],
        name: '',
        short_description: '',
        description: '',
        start_date: '',
        end_date: '',
        recurring: null,
        recurring_type: null,
        recurring_amount: null,
        price: null,
        visibility: '',
        status: '',
        project: null,
        category: null,
        exists_dispute_settlement: false
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_MY_TASKS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.list.results) : action.payload.list.results
                }
            };
        case SELECT_MY_TASK_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_MY_TASK:
            return { ...state, selected: INITIAL_STATE.selected };
        case UPDATE_TASK_SUCCESS:
            return {
                ...state, selected: action.payload.selectTask ? action.payload.selected : state.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(task =>
                        task.id === action.payload.selected.id ?
                            action.payload.selected : task)
                }
            };
        case CREATE_TASK_SUCCESS:
            return {
                ...state, selected: action.payload.selectTask ? action.payload.selected : state.selected,
                list: {
                    ...state.list, count: state.list.count + 1,
                    results: [action.payload.selected].concat(state?.list?.results)
                }
            };
        case COMPLETE_SUBMIT_TASK_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(task =>
                        task.id === action.payload.selected.id ?
                            action.payload.selected : task)
                }
            };
        case DELETE_DOCUMENT_SUCCESS:
            var docId = action.payload.deleted;
            return {
                ...state, selected: {
                    ...state.selected, documents: state.selected?.documents.filter(x => x.id !== docId)
                },
            };
        case CREATE_DOCUMENTS_SUCCESS:
            var createdDocs = action.payload.selected;
            var first = createdDocs[0];
            return first?.task ? {
                ...state, selected: {
                    ...state.selected, documents: state.selected?.documents.concat(createdDocs)
                },
            } : state;
        case CREATE_TASK_RESULT_SUCCESS:
            var result = action.payload.selected;
            return {
                ...state, selected: {
                    ...state.selected,
                    results: state.selected.id === result.task ? [result].concat(state.selected.results) : state.selected.results,
                    number_of_results: state.selected.id === result.task ? state.selected.number_of_results + 1 : state.selected.number_of_results
                },
                list: {
                    ...state.list, results: state.list.results.map(task =>
                        task.id === result.task ?
                            { ...task, number_of_results: task.number_of_results + 1 } : task)
                }
            };
        case CREATE_TASK_RESULT_DOCUMENT_SUCCESS:
            const document = action.payload.selected
            return {
                ...state,
                selected: {
                    ...state.selected, results: state.selected.results?.map(taskResult =>
                        taskResult.id === document.task_result ?
                            { ...taskResult, documents: [document].concat(taskResult.documents) } : taskResult)
                },
            };
        case CREATE_TASK_PROPOSAL_SUCCESS:
            const taskProp = action.payload.selected;
            return {
                ...state, selected: state.selected.id === taskProp.task.id ? {
                    ...state.selected,
                    number_of_proposals: state.selected.number_of_proposals + 1,
                    status: taskProp.task.status,
                } : state.selected, list: {
                    ...state.list, results: state.list?.results.map(task =>
                        task.id === taskProp.task.id ? {
                            ...task, number_of_proposals: task.number_of_proposals + 1,
                            status: taskProp.task.status
                        } : task
                    )
                },
            }
        case UPDATE_TASK_PROPOSAL_SUCCESS:
            const tp = action.payload.selected;
            const myWork = action.payload.onMyWork;
            return tp.status
                === 'REJECTED' ?
                myWork ?
                    {
                        ...state, selected: state.selected.id === tp.task.id && state.list.count > 0
                            ? INITIAL_STATE.selected : { ...state.selected, status: tp.task.status },
                        list: {
                            ...state.list, count: state.list?.results.filter((task) => task.id !== tp.task?.id).length, results:
                                state.list?.results.filter((task) => task.id !== tp.task?.id)
                        }
                    } : state :
                {
                    ...state, selected: state.selected.id === tp.task.id ? {
                        ...state.selected,
                        status: tp.task.status,
                    } : state.selected, list: {
                        ...state.list, results: state.list?.results.map(task =>
                            task.id === tp.task.id ? { ...task, status: tp.task.status } : task
                        )
                    }
                }
        case UPDATE_TASK_DISPUTE_CASES_SUCCESS:
            const td = action.payload.selected;
            return {
                ...state, selected: state.selected.id === td.task.id ? {
                    ...state.selected,
                    status: td.task.status,
                } : state.selected, list: {
                    ...state.list, results: state.list?.results.map(task =>
                        task.id === td.task.id ? { ...task, status: td.task.status } : task
                    )
                }
            }
        case GET_MY_TASK_HISTORY_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    ...action.payload.selected,
                }
            }
        case WEB_SOCKET_TASK_CREATED:
            return {
                ...state, list: {
                    ...state.list, count: state.list.count + 1,
                    results: [action.payload.selected].concat(state?.list?.results)
                }
            }

        case GET_MY_TASK_WITH_WEIGHTS_SUCCESS:
            return { ...state, selected: { ...state.selected, competencesWithWeights: action.payload.data } };

        case GET_TASK_FUNDING_ESTIMATE_SUCCESS:
            return {
                ...state, selected: { ...state.selected, funding_estimate: action.payload.estimation }
            };

        case GET_TASK_FUNDING_ESTIMATE_FAILURE:
            return {
                ...state, selected: { ...state.selected, funding_estimate: { error: 'error getting estimate from blockchain' }}
            };


        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE };
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE };
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE };
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}
import React from 'react';
import './index.scss';
import './userMenu.scss';
import { Row, Col, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LogoutStart } from '../../core/actions/auth';
import { ChangeRoleStart } from '../../core/actions/role';
import { REACT_APP_NETWORK_CURRENCY } from '../../utils/common/env-variables';

class UserMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = { isOpen: false }
    }

    handleOpen = () => {
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        let { user: { settings, roles }, hasRole, wallet: { balances } } = this.props;

        return (
            <>
                <div className="user-menu-responsive">

                    {/* Active role */}
                    {hasRole &&
                        <>
                            <div className="dd-active">
                                {settings?.active_role_obj?.profile_image
                                    ?
                                    <img className="dd-role-withimg-active" src={settings.active_role_obj.profile_image} alt="Active profile thumb"></img>
                                    :
                                    <span className={`dd-${settings?.active_role_obj?.type?.toLowerCase()}-img-active`}>{settings?.active_role_obj?.name?.charAt(0)?.toUpperCase()}</span>
                                }
                                <div style={{ width: "135px" }}>
                                    <span className="title">{settings?.active_role_obj?.name}</span>
                                    <span className="subtitle pb-2"><FormattedMessage id={"roles.name." + settings.active_role_obj?.type} defaultMessage="Developer" /></span>
                                </div>
                            </div>
                        </>
                    }

                    {/* Roles */}
                    {(settings?.active_role_obj?.type !== 'EAS' && settings?.active_role_obj?.type !== 'EAP' && settings?.active_role_obj?.type !== 'EAC') &&
                        <>
                            {/* <Dropdown.Divider /> */}
                            <div className="dd-roles">
                                {
                                    roles?.filter(role => role.id !== settings?.active_role)?.map((role) =>
                                        <div onClick={() => this.props.handleRoleChange(role.id)} className="dd-role" key={role.id}>
                                            {role.profile_image
                                                ?
                                                <img className="dd-role-withimg" src={role.profile_image} alt="Role thumb"></img>
                                                :
                                                <span className={`dd-${role?.type?.toLowerCase()}-img`}>{role.name.charAt(0).toUpperCase()}</span>
                                            }
                                            <div>
                                                <span className="title">{role.name}</span>
                                                <span className="subtitle"><FormattedMessage id={"roles.name." + role?.type} defaultMessage="Developer" /></span>
                                            </div>
                                        </div>
                                    )
                                }
                                <button className="btn btn-default btn-developers btn-block pr-0 pl-0 pt-1 pb-1 button-responsive" onClick={() => this.props.handleItemClick("/profile") /* history.push("/profile") */}>
                                    <FormattedMessage id="DropdownUserMenu.myprofile" defaultMessage="My Profile" />
                                </button>
                                {
                                    roles?.length < 4 &&
                                    <button className="btn btn-default btn-generic btn-block pr-0 pl-0 pt-1 pb-1 button-responsive" onClick={() => this.props.handleItemClick("/auth/register-role")}>+ <FormattedMessage id="DropdownUserMenu.addanotherprofile" defaultMessage="Add another profile" /></button>
                                }
                            </div>
                        </>
                    }

                    <div className="dd-misc">
                        {hasRole &&
                            <>
                                <div onClick={() => this.props.handleItemClick("/settings")}>
                                    <FormattedMessage id="DropdownUserMenu.settings" defaultMessage="Settings" />
                                </div>
                            </>
                        }
                        {/* WALLET */}
                        <div className="h-balance">
                            <Row className="center-content mb-2">
                                <Col sm="3" className="crypto-logo-p">
                                    <Image src={"/assets/img/" + (REACT_APP_NETWORK_CURRENCY() === 'ETH' ? 'ethereum.png' : 'bnb.png')} className="crypto-logo" />
                                </Col>
                                <Col className="text-left balance align-self-center">
                                    {Number(parseFloat(balances?.ETH)).toLocaleString(navigator.language, { minimumFractionDigits: 4, maximumFractionDigits: 6 })}
                                    <span > {REACT_APP_NETWORK_CURRENCY()}</span>
                                </Col>
                            </Row>
                            <Row className="center-content mb-2">
                                <Col sm="3" className="crypto-logo-p align-self-center">
                                    <Image src="/assets/img/dai.png" className="crypto-logo" />
                                </Col>
                                <Col className="text-left balance">
                                    {balances?.DAI.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}
                                    <span > DAI</span>
                                </Col>
                            </Row>
                            {/* <Row className="center-content mb-2">
                                <Col sm="3" className="crypto-logo-p align-self-center">
                                    <Image src="/assets/img/icon/chip.svg" className="crypto-logo" />
                                </Col>
                                <Col className="text-left balance">
                                    500
                                    <span > CHIPs</span>
                                </Col>
                            </Row> */}
                        </div>
                        <div onClick={() => this.props.handleItemClick("/help")}>
                            <FormattedMessage id="DropdownUserMenu.help" defaultMessage="Help" />
                        </div>
                        <div onClick={() => this.props.handleLogoutClick()}><FormattedMessage id="nav.labels.logout" defaultMessage="logout" /></div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info,
        hasRole: state.user.info.settings.active_role,
        wallet: state.user.wallet
    }
}

export default connect(mapStateToProps, { LogoutStart, ChangeRoleStart })(UserMenu);
import { GET_SOCIALS_SUCCESS } from "../../actions/role/types";
import { LOGIN_SUCCESS, REGISTER_SUCCESS, TOKEN_REFRESH_FAILURE, LOGOUT_SUCCESS } from "../../actions/auth/types";

const INITIAL_STATE = {
    socials: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default function variable(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_SOCIALS_SUCCESS:
            return { ...state, ...action.payload.role }
        //RESTART
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}
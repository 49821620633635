// import React, { useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useReduxLoadingState } from '../../../utils/hooks/useReduxLoadingState';

const ButtonSubmitComponent = props => {
    const submitForm = useReduxLoadingState(props.action, { loading: false }, 600);
    
    // useEffect(() => {
    //     if (submitForm.success) {
    //         props.onCloseHandler();
    //     }
    // }, [submitForm])

    return (
        <button className={props.class ? props.class : "btn btn-default btn-projects-inv float-right mt-2 mb-2 btn-submit"} onClick={(event) => props.onClickHandler(event)} disabled={submitForm.loading}>
            {submitForm.loading && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
            {props.text}
        </button>
    )
}

const ButtonSubmit = injectIntl(connect(null, {})(ButtonSubmitComponent))
export { ButtonSubmit };
import React from 'react';
import './security.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

class SettingsSecurity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            area: ''
        };
    }

    render() {
        // const { } = this.props;
        return (
            <div className="card p-0">
                <div className="card-head">
                    Security settings
                </div>
                <div className="card-body">
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        // role: state.user.info.settings.active_role_obj
    }
}

export default injectIntl(connect(mapStateToProps, {
})(SettingsSecurity));
/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { durationFromDateRange } from '../../../utils/common/index';

class FormatedDuration extends React.Component {

    render() {
        const seconds = durationFromDateRange(this.props.start_date, this.props.end_date)
        const minutes = (seconds / 60) | 0;
        const hours = (minutes / 60) | 0;
        const days = (hours / 24) | 0;
        const weeks = (days / 7) | 0;
        const months = (weeks / 4) | 0;
        const years = (months / 12) | 0;

        if (years !== 0) {
            return <FormattedNumber value={years} style="unit" unit="year" unitDisplay="long" />
        } else if (months !== 0) {
            return <FormattedNumber value={months} style="unit" unit="month" unitDisplay="long" />
        } else if (weeks !== 0) {
            return <FormattedNumber value={weeks} style="unit" unit="week" unitDisplay="long" />
        } else if (days !== 0) {
            return <FormattedNumber value={days} style="unit" unit="day" unitDisplay="long" />
        } else {
            return <FormattedNumber value={minutes === 0 ? 1 : minutes} style="unit" unit="minute" unitDisplay="long" />
        }
    }
}
export default FormatedDuration;
//--------------------------
//  Authentication actions
//--------------------------
export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

export const GOOGLE_REGISTER_REQUEST = "GOOGLE_REGISTER_REQUEST";
export const GOOGLE_REGISTER_SUCCESS = "GOOGLE_REGISTER_SUCCESS";
export const GOOGLE_REGISTER_FAILURE = "GOOGLE_REGISTER_FAILURE";

export const CONNECT_GOOGLE_REQUEST = "CONNECT_GOOGLE_REQUEST";
export const CONNECT_GOOGLE_SUCCESS = "CONNECT_GOOGLE_SUCCESS";
export const CONNECT_GOOGLE_FAILURE = "CONNECT_GOOGLE_FAILURE";

export const GET_CONNECTION_ACCOUNT_TYPE_REQUEST = "GET_CONNECTION_ACCOUNT_TYPE_REQUEST";
export const GET_CONNECTION_ACCOUNT_TYPE_SUCCESS = "GET_CONNECTION_ACCOUNT_TYPE_SUCCESS";
export const GET_CONNECTION_ACCOUNT_TYPE_FAILURE = "GET_CONNECTION_ACCOUNT_TYPE_FAILURE";
import React, { useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { FormattedMessage } from 'react-intl';

// Props
// headerText: ReactElement;
// footerText: ReactElement;
// initialImage: string;
// defaultImage: string;
// saveFn: () => void;
// saveFnArgs: any[];
// closeFn: () => void;
// avatarEditorProps?: { [key: string]: any };

const ImageUploader = ({ headerText, footerText, initialImage, defaultImage, saveFn, saveFnArgs, closeFn, avatarEditorProps }) => {
    // State
    const [image, setImage] = useState(initialImage ? initialImage : defaultImage);
    const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
    const [imageScaleSliderVal, setImageScaleSliderVal] = useState(100);
    const [imageScale, setImageScale] = useState(1);

    // Refs
    const editorRef = useRef();
    const fileInputRef = useRef();

    const handleDropzoneDrop = dropped => {
        if (dropped.length === 0)
            return;

        if (!dropped[0].type.includes('image/'))
            return;

        setImage(dropped[0]);
        setBtnSaveDisabled(false);
    }

    const handleFileInputChange = e => {
        e.preventDefault();

        if (e.target.files.length === 0)
            return;

        if (!e.target.files[0].type.includes('image/'))
            return;


        setImage(e.target.files[0]);
        setBtnSaveDisabled(false);
    }

    const changeImageScale = value => {
        setImageScaleSliderVal(value);
        setImageScale(value / 100);
        setBtnSaveDisabled(false);
    }

    const handleSave = async () => {
        if (!editorRef)
            return;

        const scaledCanvas = editorRef.current.getImageScaledToCanvas()

        if (!scaledCanvas)
            return;

        try {
            const imageBlob = await new Promise(resolve => scaledCanvas.toBlob(resolve));

            if (typeof image === 'string') {
                const imageNameArr = image.split('/');
                const imageName = imageNameArr[imageNameArr.length - 1];

                const tempSaveFnArgs = [...saveFnArgs];
                tempSaveFnArgs.push(new File([imageBlob], imageName));

                saveFn(...tempSaveFnArgs);
            } else {
                const tempSaveFnArgs = [...saveFnArgs];
                tempSaveFnArgs.push(new File([imageBlob], image.name));

                saveFn(...tempSaveFnArgs);
            }
        } catch (error) {
            process.env.NODE_ENV !== "production" && console.log('[ImageUploader] Ran into error while trying to save image. ', error);
        }
    }

    return (
        <div className="image-uploader" style={{ padding: '10px' }}>
            <p>{headerText}</p>
            <Dropzone
                onDrop={dropped => handleDropzoneDrop(dropped)}
                multiple={false}
                maxSize={8000000}
                accept="image/*"
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps({})}
                        className="drop-zone"
                    >
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            crossOrigin="anonymous"
                            color={[255, 255, 255, 0.6]}
                            width={500}
                            scale={imageScale}
                        {...avatarEditorProps}
                        />
                        <p style={{ cursor: 'pointer' }} onClick={() => fileInputRef.current.click()}>{footerText}</p>
                        <input
                            id="avatar-hidden-upload"
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={e => handleFileInputChange(e)}
                        />
                    </div>
                )}
            </Dropzone>
            <input
                type="range"
                min="50"
                max="150"
                value={imageScaleSliderVal}
                className="img-scale-slider"
                id="myRange"
                onChange={e => changeImageScale(e.target.value)}
            />
            <br />
            <br />
            <div className="mp-img-footer">
                <button className="btn btn-default btn-projects btn-smallpadding mr-2" onClick={() => closeFn(null)}>
                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                </button>
                <button className="btn btn-default btn-projects-inv" onClick={() => handleSave()} disabled={btnSaveDisabled}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </button>
            </div>
        </div>
    );
}

export default ImageUploader;
import * as types from './types';
import { apiCon } from '../../../../API';
import { INITIAL_STATE_FILTERS as INITIAL_COMPETENCES_MINT_FILTERS } from '../../../reducers/user/userWalletReducer';
import { getParameterObject } from '../../../../utils/common';


const ALLOWED_COMPETENCES_MINT_FILTERS = ['limit', 'search', 'ordering', 'issuers', 'unclaimed_only', 'token_status'];

//----------------------
// Actions change Competences for MINT filters
//----------------------

export const ChangeCompetencesMintFiltersStart = (params = INITIAL_COMPETENCES_MINT_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_COMPETENCES_MINT_FILTERS);
    const payload = { succ_msg: "Successfully changed skills mint filters.", filters: filters }
    return { type: types.CHANGE_COMPETENCES_MINT_FILTERS, payload: payload };
}

//----------------------
// Actions Get My Competences for MINT
//----------------------

export const GetMyCompetencesMintStart = (params = ALLOWED_COMPETENCES_MINT_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCES_MINT_REQUEST });
    var url = '/me/competences/';
    let filters = getParameterObject(params, ALLOWED_COMPETENCES_MINT_FILTERS);
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMyCompetencesMintSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetMyCompetencesMintFail(err.response));
        })
}

export const GetMyCompetencesMintPageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCES_MINT_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyCompetencesMintSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetMyCompetencesMintFail(err.response));
        })
}

export const GetMyCompetencesMintSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received skills mint info.", competencesMint: data, add: add }
    return { type: types.GET_COMPETENCES_MINT_SUCCESS, payload: payload };
}

export const GetMyCompetencesMintFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Oooops! Get my skills mint failed.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_COMPETENCES_MINT_FAIL, payload: payload };
}

//----------------------
// Update My Competences MINT
//----------------------

export const UpdateCompetencesMintStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_COMPETENCES_MINT_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/competence/claim/`;
    const settings = { headers: { 'Content-type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(UpdateCompetencesMintSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateCompetencesMintFail(err.response));
        })
}

export const UpdateCompetencesMintSuccess = (data) => async dispatch => {
    const succ_msg = "Mint submitted to processing. Check status in your wallet transactions.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.UPDATE_COMPETENCES_MINT_SUCCESS, payload: payload });
}

export const UpdateCompetencesMintFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Mint failed." + err.data.error_first_message;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.UPDATE_COMPETENCES_MINT_FAIL, payload: payload };
}

//--------------------------------------------
// Actions Get estimation for MINT competences
//--------------------------------------------

export const GetEstimationCompetenceMintStart = (values) => async dispatch => {
    dispatch({ type: types.GET_ESTIMATION_COMPETENCE_MINT_REQUEST }); 
    const json = JSON.stringify(values);
    const address = `/me/competence/claim/estimation/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(GetEstimationCompetenceMintSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetEstimationCompetenceMintFail(err.response));
        })
}

export const GetEstimationCompetenceMintSuccess = (data) => async dispatch => {
    const succ_msg = "Estimating skills mint success.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    dispatch({ type: types.GET_ESTIMATION_COMPETENCE_MINT_SUCCESS, payload: payload });
}

export const GetEstimationCompetenceMintFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Mint estimation failed." + err.data.error_first_message;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_ESTIMATION_COMPETENCE_MINT_FAIL, payload: payload };
}
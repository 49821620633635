import React from 'react'
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ChangeLangAction } from '../../core/actions/lang';
import { Select } from '../../utils/forms/formElements';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/ecta_logo-white.svg';

export class Footer extends React.Component {

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <>
        <footer className="wns-footer white pt-4 pt-md-5">
          <Container>

            <Row>
              <Col>
                <Image className="mb-2" src={logo} alt="Work 'n Skill logo" height="40" />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={5}>
                <ul className="list-unstyled text-small">
                  <li><Link className="white" to="/about"><FormattedMessage id="footer.about" defaultMessage="About" /></Link></li>
                  <li><Link className="white" to="/help"><FormattedMessage id="footer.how" defaultMessage="How it works" /></Link></li>
                  <li><Link className="white" to="/legal/terms-and-conditions"><FormattedMessage id="footer.terms" defaultMessage="Terms & Conditions" /></Link></li>
                  <li><Link className="white" to="/legal/privacy-policy"><FormattedMessage id="footer.privacy" defaultMessage="Privacy" /></Link></li>
                </ul>
              </Col>
              <Col md={5}>
                <ul className="list-unstyled text-small">
                  <li><Link className="white" to="/profile"><FormattedMessage id="footer.settings" defaultMessage="Manage your account and privacy" /></Link></li>
                  <li><Link className="white" to="/contact"><FormattedMessage id="footer.contact" defaultMessage="Contact us" /></Link></li>
                </ul>
              </Col>
              <Col md={2} className="text-right">
                <div className="lang-select-wrap mb-3">
                  <Select
                    name="langSelect"
                    // aria-label="Select language"
                    className="footer-lang"
                    defaultValue={this.props.lang}
                    options={[
                      { label: "Slovenščina", value: 'sl' },
                      { label: "English", value: 'en' }
                    ]}
                    style={{ margin: 0, width: 'auto' }}
                    onChange={(e) => { this.props.ChangeLangAction(e.target.value, this.props.isAuthenticated) }}
                  />
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16" className="footer-socials mr-3" >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16" className="footer-socials">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </Col>

            </Row>
            <Row>
              <Col><small className="d-block mb-3 white">Copyright &copy; {this.getYear()} Work 'n Skill</small></Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    isAuthenticated: state.user.auth.isAuthenticated
  }
}

export default injectIntl(connect(mapStateToProps, { ChangeLangAction })(Footer));

